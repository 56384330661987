import { Box, Button, Container, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { updatePinSchema } from '../../constants/yupSchema';
import { profilePost, resetPasswordPostData } from '../../axios/api';
import { useAxios } from '../../hooks/auth';
import { notificationToastError, notificationToastSuccess } from '../../utils/notification';
import { useSelector } from 'react-redux';
import { collapsedDrawerWidth, openDrawerWidth } from "../../constants/sideBarwidth";
import { Visibility, VisibilityOff } from '@mui/icons-material';



function Updatepin({ profileData }) {
  const isMobile = useMediaQuery('(max-width:800px)');
  const { isCollapsed } = useSelector(state => state.sideBarCollapseSelector);
  const [showPin, setShowPin] = useState({
    oldPin: false,
    newPin: false,
    confirmPin: false
  })
  const oldPinref = useRef();
  const newPinref = useRef();
  const confirmPinref = useRef();

  const axios = useAxios()
  const formik = useFormik({
    initialValues: {
      oldPin: '',
      newPin: '',
      confirmPin: '',
    },
    onSubmit: async (values) => {
      const payloadData = {
        oldPin: values?.oldPin,
        newPin: values?.newPin
      }
      await profilePost(axios, "updatePin", payloadData).then((res) => {
        if (res?.response === "1") {
          notificationToastSuccess(res?.message)
        }
        else {
          notificationToastError(res?.message)
        }
      }).catch((err) => {
        notificationToastError(err?.message)
      })
    },
    validationSchema: updatePinSchema,
    validateOnBlur: true,
    validateOnSubmit: true,
    validateOnChange: true
  });

  useEffect(() => {
    const handleMouseDown = (event) => {
        if (
            oldPinref?.current &&
            !oldPinref?.current.contains(event.target) &&
            showPin?.oldPin
        ) {
            setShowPin((prevState) => ({
                ...prevState,
                oldPin: false,
            }));
        } else if (
            newPinref?.current &&
            !newPinref?.current.contains(event.target) &&
            showPin?.newPin
        ) {
            setShowPin((prevState) => ({
                ...prevState,
                newPin: false,
            }));
        } else if (
            confirmPinref?.current &&
            !confirmPinref?.current?.contains(event.target) &&
            showPin?.confirmPin
        ) {
            setShowPin((prevState) => ({
                ...prevState,
                confirmPin: false,
            }));
        }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
        document.removeEventListener("mousedown", handleMouseDown);
    };
}, [showPin.oldPin, showPin.newPin, showPin.confirmPin]);

  const handlepinChange = (e) => {
    const id = e.target.id;
    if (e.target.value.length <= 4) {
      const input = e.target.value.slice(0, 4).replace(/[^0-9]/g, '');
      if (id === "oldPin") {
        formik.setFieldValue(id, input)
      } else if (id === "newPin") {
        formik.setFieldValue(id, input)
      } else {
        formik.setFieldValue(id, input)
      }
    }

  }

  const handleSubmit = async (e, errors, values) => {
    e.preventDefault();
    formik.submitForm();
    for (const [key, _] of Object.entries(values)) {
      if (errors[Object.keys(errors)[0]] === "Required" || !values[key]) {
        notificationToastError("Please fill all the required fields")
        return
      }
      else {
        if (errors[Object.keys(errors)[0]]) {
          notificationToastError(errors[Object.keys(errors)[0]])
        }

        return;
      }
    }
  }

  const forgotPin = () => {
    const payload = {
      email : profileData.email
    };
    resetPasswordPostData(axios, "resetPin", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
  }

  const handleClickShowPin = (id) => {
    setShowPin((prevState) => {
        return {
            ...prevState,
            [id]: !prevState[id]
        }
    })
}

  return (

    <form
      onSubmit={
        (e) => handleSubmit(e, formik?.errors, formik?.values)

      }

    >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column" ,
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            width:'100%',
            padding : "0"
          }}
        >
          <Typography variant='h6' sx={{ marginTop: "1rem", marginLeft: "1rem" }}>
            Update PIN
          </Typography>
        <Box p={1}>
          <TextField
            id="oldPin"
            label="Old PIN *"
            onBlur={formik.handleBlur}
            onChange={handlepinChange}
            value={formik.values.oldPin}
            name="oldPin"
            error={!!formik.touched.oldPin && !!formik.errors.oldPin}
            helperText={formik.touched.oldPin && formik.errors.oldPin}
            variant='standard'
            type={showPin?.oldPin ? 'number' : 'password'}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPin("oldPin")}
                          edge="end"
                      >
                          {showPin?.oldPin ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                  </InputAdornment>
              ),
            }}
            inputRef={oldPinref}
            sx={{ width: "90%" }}
          />
        </Box>
        <Box p={1}>
          <TextField
            id="newPin"
            label="New PIN *"
            onBlur={formik.handleBlur}
            onChange={handlepinChange}
            value={formik.values.newPin}
            name="newPin"
            error={!!formik.touched.newPin && !!formik.errors.newPin}
            helperText={formik.touched.newPin && formik.errors.newPin}
            variant='standard'
            type={showPin?.newPin ? 'number' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPin("newPin")}
                    edge="end"
                  >
                    {showPin?.newPin ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputRef={newPinref}
            sx={{ width: "90%" }}
          />
        </Box>
        <Box p={1}>
          <TextField
            id="confirmPin"
            label="Confirm New PIN *"
            onBlur={formik.handleBlur}
            onChange={handlepinChange}
            value={formik.values.confirmPin}
            name="confirmPin"
            error={!!formik.touched.confirmPin && !!formik.errors.confirmPin}
            helperText={formik.touched.confirmPin && formik.errors.confirmPin}
            variant='standard'
            type={showPin?.confirmPin ? 'number' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPin("confirmPin")}
                    edge="end"
                  >
                    {showPin?.confirmPin ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          inputRef={confirmPinref}
          sx={{ width: "90%" }}
          />
        </Box>
          <Box
            component="div"
            sx={{
              "& .MuiBox-root": { flexGrow: 1 },
              display: "flex",
              marginTop: "0.5rem",
              width:'55%'
            }}
          >
            <Box
              sx={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <Button variant="contained" type='submit'>
                UPDATE PIN
              </Button>
            </Box>
            <Box
              sx={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <Button variant="contained" onClick={() => forgotPin()}>
                FORGOT PIN
              </Button>
            </Box>
          </Box>
       
        </Box>
    </form>
  )
}

export default Updatepin