import { createSlice } from "@reduxjs/toolkit";

export const patientLogInDataSlice = createSlice({
  name: "patientLogInDataSlice",
  initialState: {
    patientId:"",
    patientReduxData:{}
  },
  reducers: {
    updatePatientLogin: (state, action) => {
      state.patientId = action?.payload;
    },
    updatePatientData: (state, action) => {
      state.patientReduxData = action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePatientLogin,updatePatientData } = patientLogInDataSlice.actions;

export default patientLogInDataSlice.reducer;