import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import {
  Button,
  Container,
  Alert,
} from "../../node_modules/@mui/material/index";
import { useAxios } from "../hooks/auth";
import { adminPostData } from "../axios/api";

function DeleteRecord() {
  const location = useLocation();
  useEffect(() => {
    setFormState({ ...formState, id: location.state?.id });
  }, [location]);
  
  const [formState, setFormState] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const axios = useAxios();
    const payload ={
          id: location.state?.id,
        tableName: location.state?.tableName,
        idColumn: location.state?.idColumn,
    }
    adminPostData(axios, "deleteRecord", payload)
    .then((res) => {
      if (res.data.status === "1") {
        setSuccessMessage(res.data.message);
      } else   {
        setErrorMessage(res.data.message);
      }
    }).catch((err)=>{
      setErrorMessage(err.message);
    })



  //return (    
 // );
}

export default DeleteRecord;
