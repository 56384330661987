import { FormControl, Grid, InputLabel, TextField, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

function MinorPatients({ formState, updateInput, formErrorState, validateEmptyField, patientsPhoneNumber,
    setPatientsPhoneNumber, updatePhoneNumber }) {
        const isMobile = useMediaQuery('(max-width:800px)')
    return (
        <>
            <Typography
            variant='h6'
                style={{
                    paddingLeft: "0.5rem",
                    //   paddingTop: "1rem",
                    margin: "5px 0px"
                }}
            >
                Minor Patient Parent's Info
            </Typography>
            <Box
                component="div"
                sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                }}
            >
                <Grid container>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                    <TextField
                    id="parentLastName"
                    label="Guardian/Caretaker Last Name *"
                    value={formState.parentLastName || ""}
                    variant="standard"
                    onChange={updateInput}
                    error={formErrorState.parentLastName?.error && !formState.parentLastName}

                    helperText={
                        formErrorState?.parentLastName?.error && !formState?.parentLastName
                            ? "required"
                            : ""
                    }
                    onBlur={validateEmptyField.bind(null, "parentLastName")}
                    sx={{width:"90%"}}
                />
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                    <TextField
                    id="parentFirstName"
                    label="Guardian/Caretaker First Name *"
                    value={formState.parentFirstName || ""}
                    variant="standard"
                    onChange={updateInput}
                    error={formErrorState?.parentFirstName?.error && !formState?.parentFirstName}
                    helperText={
                        formErrorState?.parentFirstName?.error && !formState?.parentFirstName
                            ? "required"
                            : ""
                    }

                    onBlur={validateEmptyField.bind(null, "parentFirstName")}
                    sx={{width:"90%"}}

                />
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                    <TextField
                    id="middleInitial"
                    label="MI"
                    sx={{width:"90%"}}
                    // value={formState.middleInitial || ""}
                    variant="standard"
                />
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                    <TextField
                    id="relationShip"
                    label="Relationship"
                    value={formState.relationShip || ""}
                    variant="standard"
                    onChange={updateInput}
                    sx={{width:"90%"}}
                />
                    </Grid>
                </Grid>
            </Box>
            <Box
                component="div"
                sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    flexDirection : isMobile ? "column" : "row"
                }}
            >
                <TextField
                    id="parentPhoneNumber"
                    label="Guardian/Caretaker Best Contact Number *"
                    variant="standard"
                    value={formState.parentPhoneNumber || ""}
                   // helperText="Area Code and Number (only 10 digits)"
                    onChange={updatePhoneNumber}

                    error={(formErrorState?.parentPhoneNumber?.error && !formState?.parentPhoneNumber) || patientsPhoneNumber.parentPhoneNumberError}
                    helperText={
                        formErrorState?.parentPhoneNumber?.error && !formState?.parentPhoneNumber
                            ? "required"
                            : "Area Code and Number (only 10 digits)"
                    }
               
                //  error={patientsPhoneNumber.parentPhoneNumberError}

                    onBlur={() => {
                        if (patientsPhoneNumber?.parentPhoneNumber?.length < 10 && patientsPhoneNumber?.parentPhoneNumber?.length > 0)   {
                            setPatientsPhoneNumber({
                                ...patientsPhoneNumber,
                                parentPhoneNumberError: true
                            })
                        }
                        validateEmptyField.bind(null, "parentPhoneNumber")()

                    }}
                />

                <TextField
                    id="parentEmail"
                    label={"Guardian Email"}
                    value={formState.parentEmail || ""}
                    //   helperText={isEmailRequired ? "required" : ""} 
                    variant="standard"
                    onChange={updateInput}

                />
            </Box>
        </>
    )
}

export default MinorPatients