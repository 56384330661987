import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Typography,
  TextareaAutosize,
  Button,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { studyPostData } from "../../axios/api";
import phoneNumberValidator from "../../utils/phoneNumberValidator";
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import GeneralPulldown from "./pulldowns/GeneralPulldown";

export default function WaitingScheduleDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => { }, 
  notificationToastSuccess,
  notificationToastError,
  isPreviousVisits = false,
  isPatientPortal,
  asDialog = true,
  notShowPopup,
  waitingScheduleData,
  reloadCurrentVisit,
  isWellnecessitiesUser
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [SDATE, setSDATE] = React.useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [inputSDATEError, setInputSDATEError] = useState(false);
  const axios = useAxios();
  const paramNameIn = "name";

  const updateCheckBox = (e) => {
    const checkboxId = e.target.id;
   
    if (checkboxId === "studyDeclined") {
      setFormState((prevState) => ({
        ...prevState,
        patUnreachable: "",
        [e.target.id || e.target.name]: Number(e.target.checked),
      }));
    } else if (checkboxId === "patUnreachable") {
      setFormState({
        ...formState,
        studyDeclined: "",
        [e.target.id || e.target.name]: Number(e.target.checked),
      });
    }

    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  React.useEffect(() => {
    if (row) {
      setFormState({
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        contacAttempt: row?.contacAttemptOrder,
        dmeOrderId: row?.dmeOrderIdOrder,
        patUnreachable: row?.patUnreachable,
        contactLaterDate: row?.contactLaterDate,
        studyDeclined: row?.studyDeclined,
        SDATE: row?.contactLaterDate,
        patientName: row?.patientName,
        bestPhone: row?.bestPhone,
        cellPhone: row?.cellPhone,
        waitingScheduleNotes: row?.waitingScheduleNotes || row?.waitingScheduleNotesDme ,
        reasonDecliningId: row?.reasonDecliningId,
      });
      // setSDATE(formState?.contactLaterDate),
    }else if(waitingScheduleData){
      setFormState({ ...formState, ...waitingScheduleData });
    }
  }, [row,waitingScheduleData]);

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const saveChanges = (e) => {
    e.preventDefault();

    let isValid = true;
    const formStateError = { ...formErrorState };

    // if (!formState.contacAttempt) isValid = false;

    if (formState?.studyDeclined && !formState.reasonDecliningId) {
      isValid = false;
      formStateError["reasonDecliningId"] = { error: true };
    }

    if (!isValid) {
      setErrorMessage("Please fill all required fields");
      setFormErrorState(formStateError);

      return false;
    } else {
      setErrorMessage("");
    }

    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      contactLaterDate: formState.SDATE,
    };
    studyPostData(axios, "waitingSchedule", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
 
    if (reloadCurrentVisit) reloadCurrentVisit(payload);    

  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const updateSDATE = (newValue) => {
    if (!newValue) {
      setInputSDATEError(false);
      return 'Invalid Date';
    }
    const { $d } = newValue;

    if ($d == "Invalid Date") {
      setInputSDATEError(true);
    } else {
      setInputSDATEError(false);
    }
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSDATE(newValue?.format("MM/DD/YYYY"));
    setFormState({ ...formState, SDATE: newValue?.format("YYYY-MM-DD") });
  };

  const patientNameVal = row?.patientName ?? formState?.patientNameVal;
  const cellPhoneVal = phoneNumberValidator(row?.cellPhone) ?? formState?.cellPhone;
  const bestPhoneVal = phoneNumberValidator(row?.bestPhone) ?? formState?.bestPhone;
  if (asDialog) {
    return (
      <ConfirmDialog
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "Save" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog){
    return (
     <>
        <div>
          {" "}
          <FormControl sx={{ mt: 2,mb:2 }}>
            <Typography variant="h6"> Waiting to Schedule: </Typography>
          </FormControl>
        </div>
        {!notShowPopup &&
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <TextField
              id="patientName"
              label="Patient Name"
              variant="standard"
              value={patientNameVal}
              readOnly
              InputProps={{ disableUnderline: true, readOnly: true }}
            />
            <TextField
              id="bestPhone"
              label="Best Contact Number "
              variant="standard"
              value={bestPhoneVal?.formattedPhoneNumber || ""}
              readOnly
              InputProps={{ disableUnderline: true, readOnly: true }}
            />

            <TextField
              id="cellPhone"
              label="Cell Phone Number"
              variant="standard"
              value={cellPhoneVal?.formattedPhoneNumber || ""}
              readOnly
              InputProps={{ disableUnderline: true, readOnly: true }}
            />
          </Box>
        }
  
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <FormControl variant="standard" sx={{ width: 150, mt: 1, mb: 1 }}>
            <InputLabel id="gender-label">Contacted Patient:</InputLabel>
  
            <Select
              labelId="contact-label"
              id="contacAttempt"
              name="contacAttempt"
              value={formState.contacAttempt || ""}
              label="Contacted Patient:"
              onChange={updateInput}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1st Attempt">1st Attempt</MenuItem>
              <MenuItem value="2nd Attempt">2nd Attempt</MenuItem>
              <MenuItem value="Final Attempt">Final Attempt</MenuItem>
            </Select>
          </FormControl>
        </Box>
  
        <div>
          <FormControl variant="standard" sx={{mt:1}}>
            Please check only one of the checkboxes
            <FormControlLabel
              control={
                <Checkbox
                  id="patUnreachable"
                  checked={Boolean(formState.patUnreachable)}
                  onChange={updateCheckBox}
                />
              }
              label="Patient Unreachable"
            />
          </FormControl>
  
          <Box>
            <FormControl variant="standard">
              <FormControlLabel
                control={
                  <Checkbox
                    id="studyDeclined"
                    checked={Boolean(formState.studyDeclined)}
                    onChange={updateCheckBox}
                  />
                }
                label="Study Declined"
              />
            </FormControl>
          </Box>
  
          {formState?.studyDeclined === 1 && (
            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
              }}
            >
            <GeneralPulldown
              key="reasonDecliningId"
              isPreviousVisits={isPreviousVisits}
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="reasonDecliningId"
              style={{ width: "100%" }}
              isPatientPortal={isPatientPortal}
              pulldownApiName="ReasonDecliningPulldown"
              itemName="reason"
              labelName="Select Reason For Declining"
            />
            </Box>
          )}
  
          {formState?.studyDeclined != 1 && (
            <>
              <Box mt={1}>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="CALL/TEXT PT BACK AT LATER DATE"
                      inputFormat="MM/DD/YYYY"
                      className="study-date-data"
                      value={SDATE || formState?.SDATE || ""}
                      onChange={updateSDATE}
                      renderInput={(params) => (
                        <TextField
                          // data-testid="study-date-data"
                          {...params}
                          variant="standard"
                          error={formErrorState.SDATE?.error && !SDATE || inputSDATEError}
                          helperText={
                            formErrorState.SDATE?.error && !SDATE
                              ? "required"
                              : inputSDATEError ? 'Date should be entered in the following format mm/dd/yyyy' : ''
                          }
                          value={
                            SDATE
                              ? moment.utc(SDATE.toLocaleString()).format("MM/DD/YYYY")
                              : ""
                          }
                          onBlur={() => {
                            // setErrorSDATE(!SDATE);
                          }}
                          onMouseDown={handleFocus}
                          placeholder="mm/dd/yyyy"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Box>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", marginTop: 1.5 }}
                >
                  Notes
                  <TextareaAutosize
                    id="waitingScheduleNotes"
                    label="Notes"
                    value={formState?.waitingScheduleNotes || ""}
                    variant="standard"
                    onChange={updateInput}
          
                    rows={2}
                  />
                </FormControl>
              </Box>
            </>
          )}
          {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                disabled={isSaveButtonDisabled}
                sx={{ margin: "1rem 0rem" }}
                onClick={saveChanges}
                data-testid="schedule-save-Button"
              >
                Save Changes
              </Button>
            </div>
          )}
        </div>
     </>

    );
  }
 
}
