import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";


import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";

function StageNamePulldown({formState,updateInput,formErrorState,validateEmptyField=()=>{}}) {

    const [stages, setStages] = useState([]);
    const axios = useAxios();
    useEffect(() => {
        async function setDataFirstTime() {
        pulldownsPostData(axios,"stageNamePulldown")
        .then((res)=>{
          if (res?.data) {
            setStages(res?.data);
          }
        })
        .catch((err)=>{
          console.log(err);
        }) 
          
        }
        if (!stages?.length) {
          setDataFirstTime()
        }
        
      }, []);

return <>
     <FormControl variant="standard" sx={{ m: 1,  width: "97%" }} >
            <InputLabel id="stage-label" error={formErrorState?.stageNameId?.error && !formState?.stageNameId}>Select Stage *</InputLabel>
            <Select
            data-testid="stage-name-pulldown" // Add Cypress ID to the StageNamePulldown component
              labelId="stage-label"
              id="stageNameId"
              name="stageNameId"
              value={formState?.stageNameId || ''}
              label="stage"
              onChange={updateInput}
              error={formErrorState?.stageNameId?.error && !formState?.stageNameId}
              onBlur={validateEmptyField.bind(null, "stageNameId")}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {stages.map((stage) => (
                <MenuItem value={stage.stageNameId} key={stage.stageNameId}>{stage.stageName}</MenuItem>
              ))}
            </Select>
          </FormControl>
</>      

}

export default React.memo(StageNamePulldown);