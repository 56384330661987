import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useEffect } from "react";

const AgeCalculator = ({formState,DOB,setFormState,setFormErrorState}) => {
    useEffect(() => {
        const calculateAge = () => {
            const diffInMilliseconds = new Date() - new Date(DOB);
            const ageDate = new Date(diffInMilliseconds);
            const years = Math.abs(ageDate.getUTCFullYear() - 1970);
            const months = ageDate.getUTCMonth();
            let totalYear = years
            if (months > 6) {
                totalYear += 1;
              }
              if (totalYear < 18) {
                setFormState((prevState)=>{
                    return{
                        ...prevState,
                        minorPatient:1,
                        emergencyContact:0
                    }
                })
                setFormErrorState((prevState)=>{
                  return  {
                    ...prevState,
                    parentFirstName: "",
                    parentLastName: "",
                    parentPhoneNumber:""
                  }
                })
              }else if(totalYear > 18){
   
                if (formState?.addNewPatient){
                setFormState((prevState)=>{
                    return{
                        ...prevState,
                        minorPatient:0,
                        emergencyContact:1
                    }
                })

              }
                setFormErrorState({
                  firstName: "",
                  lastName: "",
                  groupId: "",
                  cellPhone:"",
                  emerContactName:"",
                  emerContactNum:""
                })
              }
        };
        calculateAge();
    }, [DOB]);



    return (
        <FormControl sx={{ m: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              id="minorPatient"
              checked={Boolean(formState.minorPatient)}
            //   onChange={updateCheckBox}
            />
          }
          label="Minor Patient"
        />
      </FormControl>
    );
};

export default AgeCalculator;