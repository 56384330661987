import * as React from "react";
import { useEffect, useState } from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";

function SelectTemplatePulldown({ formState, selectedCheckbox, updateTemplate, formErrorState }) {
    const [templateData, setTemplateData] = useState([]);
    const axios = useAxios();

    useEffect(() => {
        if(selectedCheckbox === "is_form"){
            async function setDataFirstTime() {
                pulldownsPostData(axios, "cygnetTemplatePulldown")
                    .then((res) => {
                        if (res?.data) {
                            setTemplateData(res?.data?.data?.templates);
                        }
                    })
                    .catch((err) => {
                        console.log("err", err);
                    });
            }
            setDataFirstTime();
        }
    }, [selectedCheckbox]);

    return (
        <>
            {
                templateData?.length ?
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel
                        id="template-label"
                        error={formErrorState?.signFormTemplateId?.error && !formState?.signFormTemplateId}
                    >
                        Select Template (for signature required form):
                    </InputLabel>
                    <Select
                        labelId="template-label"
                        id="signFormTemplateId"
                        name="signFormTemplateId"
                        value={formState.signFormTemplateId || ""}
                        label="template"
                        onChange={updateTemplate}
                        error={formErrorState?.signFormTemplateId?.error && !formState.signFormTemplateId}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {templateData?.map((row) => (
                            <MenuItem value={row.template_id} key={row.template_id}>{row.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl> : ""
            }
        </>
    );
}

export default SelectTemplatePulldown;
