import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "../axios/index";
import roleAndPrivilegesFnc from "../utils/roleAndPriviledges";
import { updatePatientLogin } from "../store/slices/patientLogInDataSlice";
import { useDispatch } from "react-redux";
import { updateSelectedPatient } from "../store/slices/selectedPatientSlice";
import jwt from "jwt-decode";

const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookies, removeCookie] = useCookies();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      window.location.reload();
    }
  }, [isLoading]);
  
  const login = async ({ email, password }) => {
    let res;
    try {
      // setError('')
      const { data } = await axios.post(`/api/login.php`, {
        email: email,
        password: password,
      });

      res = data;
    } catch (ex) {
      setError(`Incorrect login or email.`);
      return;
    }
    if (res && res?.jwt && res?.message === "Successful login.") {
      setCookies("token", res.jwt); // your token

      const { tabPath } = roleAndPrivilegesFnc(res.jwt);

      setIsLoading(true);
      navigate(tabPath || '/dashboard');
    
    } else setError(`Incorrect login or email.`);
  };

  const handlePatientLogIn = async ({ email, password }) => {
    let res;
    try {
      // setError('')
      const { data } = await axios.post(`/api/patientLogin.php`, {
        email: email,
        password: password,
      });

      res = data;
    } catch (ex) {
      setError(`Incorrect login or email.`);
      return;
    }
    if (res && res?.jwt && res?.message === "Successful login.") {
      setCookies("token", res.jwt); // your token
      setCookies("isPatient", true);

      setIsLoading(false);
      navigate('/patient-portal');

      const { id ,tempPassword } = roleAndPrivilegesFnc(res.jwt);
      dispatch(updatePatientLogin(id));
      localStorage.setItem('tempPassErr', tempPassword)
    } else setError(`Incorrect login or email.`);
  };
  const logout = () => {
    ["token", "isPatient"].forEach((obj) => removeCookie(obj)); // remove data save in cookies
    localStorage.removeItem('lastActivity');
    localStorage.removeItem('patientId');
    localStorage.removeItem('tempPassErr');
    dispatch(updateSelectedPatient(undefined));
    navigate("/signin-landing");
  };

  const value = useMemo(
    () => ({
      cookies,
      error,
      login,
      logout,
      handlePatientLogIn,
      setError,
      isLoading
    }),
    [cookies, error]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};

export const useAxios = () => {
  const auth = useAuth();

  if (auth) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + auth.cookies.token;
  }

  return axios;
};
