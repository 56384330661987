import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import { FormControl } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import LocationPulldown from "./LocationPulldown";
import PrefInterpPhysicianPulldown from "./PrefInterpPhysicianPulldown";
import PhysicianPulldown from "./PhysicianPulldown";
import StudyTypePulldown from "./StudyTypePulldown";
import { studyPostData } from "../../axios/api";
import InterpFileUpload from "../../pages/patients/components/FileUpload/InterpFileUpload";

export default function UploadComplianceDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState,
  notificationToastSuccess,
  notificationToastError,
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [refreshData, setRefreshData] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  
  React.useEffect(
    () =>
      setFormState({
        physicianId: row?.physicianIdStudy,
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        surveyId: row?.surveyIdSurvey,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        SDATE: row?.date,
        studyTypeId: row?.studyTypeId,
        physicianId: row?.physicianIdStudy,
        locationId: row?.locationIdStudy,
        SHIP: row?.shipDate,
        trackingNum: row?.trackingNum,
        studyId: row?.studyIdStudy,
        email: row?.email,
        cellPhone: row?.cellPhone,
        apptLocationName: row?.locationStudy,
        patientNameIn: row?.patientName,
      }),
    [row]
  );

  let patientName;
  if (row) {
    patientName = row?.patientName;
  }

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  const saveChanges = (e) => {};

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      date: SDATE || formState.SDATE,
      shipDate: SHIP || formState.SHIP,
    };

    studyPostData(axios, "saveStudy", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setIsSaveButtonDisabled(false);
      setViewCloseDialogState(false);
    }, 2000);
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    newValue && setSHIP(newValue.format("YYYY-MM-DD"));
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    newValue && setSDATE(newValue.format("YYYY-MM-DD"));
  };
  const patientNameVal = row?.patientName;

  return (
    <ConfirmDialog
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
    >
      <div>
        {" "}
        <FormControl sx={{ m: 2 }}>
          <b>Upload Compliance Report: </b>
        </FormControl>
      </div>

      <TextField
        id="patientName"
        label="Patient Name"
        variant="standard"
        value={patientNameVal}
        readOnly
        InputProps={{ disableUnderline: true }}
      />

      <FormControl variant="standard" sx={{ width: 100, marginTop: 1 }}>
        <TextField
          id="SDATE"
          label="Study Date"
          variant="standard"
          value={moment(formState.SDATE).format("MM/DD/YYYY")}
          readOnly
          InputProps={{ disableUnderline: true }}
        />
      </FormControl>
      <Box sx={{ marginTop: 2 }}>
        <InterpFileUpload
          patientId={formState?.patientId}
          orderId={formState?.orderId}
          studyId={formState?.studyId}
          surveyId={formState?.surveyId}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setViewCloseDialogState={setViewCloseDialogState}
          notificationToastSuccess={notificationToastSuccess}
          notificationToastError={notificationToastError}
          email={formState?.email}
          cellPhone={formState?.cellPhone}
          patientName={formState?.patientNameIn}
          apptLocationName={formState?.apptLocationName}
        />
      </Box>
    </ConfirmDialog>
  );
}
