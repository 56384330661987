import { Alert, Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { dateConverter } from '../../../utils/dateConverter';
import phoneNumberValidator from '../../../utils/phoneNumberValidator';
import { Link } from 'react-router-dom';

const PatientReadOnlyInfo = () => {
  const { patientReduxData } = useSelector(state => state?.patientLogInDataSelector);
  const { formattedPhoneNumber: cellPhone } = phoneNumberValidator(patientReduxData?.cellPhone);
  const { formattedPhoneNumber: bestPhone } = phoneNumberValidator(patientReduxData?.bestPhone);
  const { formattedPhoneNumber: parentMobile } = phoneNumberValidator(patientReduxData?.parentMobile);



  return (
    <Box>
      <Typography variant='h6'>Patient Information : </Typography>
      <Alert severity="info" sx={{ color: "#1769aa", margin:"16px 0px" }}>
        {" "}
        The following information is Read Only. Please go to the <Link to="/patient-profile"> Patient Profile</Link>
        {' '} 
        to update.
      </Alert>
      <Grid container spacing={2} mb={1}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant='span1'>Name : </Typography>  <Typography variant='span'>{patientReduxData?.firstName} {' '} {patientReduxData?.lastName} </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
        <Typography variant='span1'>DOB : </Typography>  <Typography variant='span'>{ patientReduxData?.dob && dateConverter(patientReduxData?.dob)}  </Typography>

        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
        <Typography variant='span1'>Gender : </Typography>  <Typography variant='span'>{patientReduxData?.gender}  </Typography>

        </Grid>
      </Grid>

      <Grid container spacing={2} mb={1}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant='span1'>Best Contact Number : </Typography>  <Typography variant='span'>{bestPhone}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant='span1'>CellPhone Number : </Typography>  <Typography variant='span'>{cellPhone}  </Typography>

        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant='span1'>Email : </Typography>  <Typography variant='span'>{patientReduxData?.email}  </Typography>

        </Grid>
      </Grid>

      {
        patientReduxData?.parentFirstName &&
        <Grid container spacing={2} mb={1}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant='span1'>Guardian Name :  </Typography>  <Typography variant='span'>{patientReduxData?.parentFirstName} {" "} {patientReduxData?.parentLastName}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant='span1'>Guardian Number : </Typography>  <Typography variant='span'>{parentMobile}  </Typography>

          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant='span1  '>Guardian Email : </Typography>  <Typography variant='span'>{patientReduxData?.parentEmail}  </Typography>

      </Grid>
        </Grid>
        
        
      }
      <Grid container spacing={2} mb={1}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant='span1'>PatientId : </Typography>  <Typography variant='span'>{patientReduxData?.patientNum}</Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography variant='span1'>Address : </Typography> <Typography variant='span'>{ patientReduxData?.address1 ? `${patientReduxData?.address1},` : ""} {patientReduxData?.address2 ? `${patientReduxData?.address2},` : ""}   {patientReduxData?.city ? `${patientReduxData?.city},` : ""} {patientReduxData?.state ? `${patientReduxData?.state},` : ""}  {patientReduxData?.zip ? `${patientReduxData?.zip}` : ""}</Typography> 
        </Grid>
      </Grid>
    </Box>
  )
}
export default PatientReadOnlyInfo
