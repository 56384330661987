import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from '@mui/icons-material/Menu';
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { allRoutePathsFunc, patientPortalRoutes } from "../constants/allRoutePaths";
import useIsPrevileges from "../hooks/useRoleAndPrivileges";
import { adminSubPath, patientsSubTab } from "../constants/tabs";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { updateCollapsedOnNavigate, updateIscollapsed } from "../store/slices/sideBarCollapseSlice";
import classes from './sideBar.module.css'
import { collapsedDrawerWidth, openDrawerWidth } from "../constants/sideBarwidth";
import { Tooltip, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";


export default function PermanentDrawerLeft({ tabPath,email }) {
  const { isCollapsed, isCollapsedOnNavigate} = useSelector(state => state.sideBarCollapseSelector);
  const dispatch = useDispatch()
  const privelegesArray = useIsPrevileges();
  const { logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isPatientsTabOpen, setIsPatientsTabOpen] = React.useState(false)
  const allRoutePaths = allRoutePathsFunc(privelegesArray?.privileges?.subtab?.includes("signOrder") || false);
  const [isMouseEventRequired, setisMouseEventRequired] = React.useState(false)
  const handlecollapse = () => {
    dispatch(updateIscollapsed(!isCollapsed))   
  }
  const handleCollapseOnEnter = () => {
    dispatch(updateIscollapsed(false))
  }
  // React.useEffect(() => {
  //   if (tabPath) {
  //     navigate(tabPath)
  //   }
  // }, [tabPath])
  return (
    <Drawer
      sx={{
        width: isCollapsed ? isCollapsedOnNavigate? openDrawerWidth : collapsedDrawerWidth : openDrawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isCollapsed ? isCollapsedOnNavigate? openDrawerWidth : collapsedDrawerWidth : openDrawerWidth,
          boxSizing: "border-box",
          transition: 'all 0.2s ease-out',
        },
      }}
      variant="permanent"
      anchor="left"
    >

      <Box className={ classes.leftArroeContainer}  >
        
        <Tooltip title={isCollapsed ?"Open Menu" : "Close Menu"} placement="bottom">  
        <MenuIcon onClick={handlecollapse}  />
        </Tooltip>
        { (!isCollapsed && !privelegesArray?.role) &&
          <Box className={classes.patientPortalContainer}>
            <Typography variant="h5" sx={{ color: "#715d9c" }}>PATIENT PORTAL</Typography>
          </Box>
       }
      </Box>

      {
        isCollapsed  ? 
        isCollapsedOnNavigate ? 
        <List>
            {
              !privelegesArray?.role ? (
                patientPortalRoutes?.map((linkObj, index) =>{
                  return(
                    <div key={linkObj.text} >
                    <ListItemLink
                      text={linkObj.text}
                      path={linkObj.path}
                      iconComponent={linkObj.iconComponent}
                      setisMouseEventRequired={setisMouseEventRequired}
                      isMouseEventRequired={isMouseEventRequired}
                      selected={linkObj.path === location.pathname || (adminSubPath?.includes(linkObj.path) && adminSubPath?.includes(location.pathname)) || (patientsSubTab?.includes(linkObj.path) && patientsSubTab?.includes(location.pathname))}
                      setIsPatientsTabOpen={setIsPatientsTabOpen}
                      isPatientsTabOpen={isPatientsTabOpen}
                    />
                  </div>
                  )
                })

              ) :
                (
                  allRoutePaths.map((linkObj, index) => (
                    privelegesArray?.privileges?.tab?.find(item => item?.tabName?.includes(linkObj?.tabName)) &&
                    <div key={linkObj.text} >
                      <ListItemLink
                        text={linkObj.text}
                        path={linkObj.path}
                        iconComponent={linkObj.iconComponent}
                        setisMouseEventRequired={setisMouseEventRequired}
                        isMouseEventRequired={isMouseEventRequired}
                        selected={linkObj.path === location.pathname || (adminSubPath?.includes(linkObj.path) && adminSubPath?.includes(location.pathname)) || (patientsSubTab?.includes(linkObj.path) && patientsSubTab?.includes(location.pathname))}
                        setIsPatientsTabOpen={setIsPatientsTabOpen}
                        isPatientsTabOpen={isPatientsTabOpen}
                      />
                      {
                        //  ( isPatientsTabOpen && linkObj.text === "PATIENTS" ) && <PatientsSubTab/>
                      }
                    </div>
                  ))
                )
            }
            <Divider />
             <Box sx={{ marginTop: "10px", wordBreak: "break-all" }} > <AccountCircleIcon /><Typography sx={{ fontWeight: "600", fontSize: "14px", margin: "0px 10px" }}>  {email}</Typography></Box>
              <li
                style={{
                  display: "flex",
                  minHeight: "57px",
                  alignItems: "center",
                }}
              >
                <div style={{ paddingLeft: "20px" }}>
                  <LogoutIcon onClick={logout} />
                </div>
                <Typography variant="link" sx={{ fontWeight: "800", fontSize: "16px", margin: "0px 10px" }} onClick={logout}>Sign Out</Typography>
              </li>
          </List>
        :
        <List>
          {
          !privelegesArray?.role ?
          (
            patientPortalRoutes?.map((linkObj, index) => (
              <div key={linkObj.text}>
                <ListItemLink
                  path={linkObj.path}
                  iconComponent={linkObj.iconComponent}
                  setisMouseEventRequired={setisMouseEventRequired}
                  isMouseEventRequired={isMouseEventRequired}
                  selected={linkObj.path === location.pathname || (adminSubPath?.includes(linkObj.path) && adminSubPath?.includes(location.pathname)) || (patientsSubTab?.includes(linkObj.path) && patientsSubTab?.includes(location.pathname))}
                />
              </div>
            ))
          )
          :
          (
            allRoutePaths.map((linkObj, index) => (
              privelegesArray?.privileges?.tab?.find(item => item?.tabName?.includes(linkObj?.tabName)) &&
              <div key={linkObj.text}>
                <ListItemLink
                  path={linkObj.path}
                  iconComponent={linkObj.iconComponent}
                  setisMouseEventRequired={setisMouseEventRequired}
                  isMouseEventRequired={isMouseEventRequired}
                  selected={linkObj.path === location.pathname || (adminSubPath?.includes(linkObj.path) && adminSubPath?.includes(location.pathname)) || (patientsSubTab?.includes(linkObj.path) && patientsSubTab?.includes(location.pathname))}
                />
              </div>
            ))
          )
         }
          <Divider />
          <li
            style={{
              display: "flex",
              minHeight: "57px",
              alignItems: "center",
            }}
          >
            <div style={{ paddingLeft: "20px" }}>
              <LogoutIcon onClick={logout}/>
            </div>
          </li>
        </List>
          : <List>
            {
              !privelegesArray?.role ? (
                patientPortalRoutes?.map((linkObj, index) =>{
                  return(
                    <div key={linkObj.text} >
                    <ListItemLink
                      text={linkObj.text}
                      path={linkObj.path}
                      iconComponent={linkObj.iconComponent}
                      setisMouseEventRequired={setisMouseEventRequired}
                      isMouseEventRequired={isMouseEventRequired}
                      selected={linkObj.path === location.pathname || (adminSubPath?.includes(linkObj.path) && adminSubPath?.includes(location.pathname)) || (patientsSubTab?.includes(linkObj.path) && patientsSubTab?.includes(location.pathname))}
                      setIsPatientsTabOpen={setIsPatientsTabOpen}
                      isPatientsTabOpen={isPatientsTabOpen}
                    />
                  </div>
                  )
                })

              ) :
                (
                  allRoutePaths.map((linkObj, index) => (
                    privelegesArray?.privileges?.tab?.find(item => item?.tabName?.includes(linkObj?.tabName)) &&
                    <div key={linkObj.text} >
                      <ListItemLink
                        text={linkObj.text}
                        path={linkObj.path}
                        iconComponent={linkObj.iconComponent}
                        setisMouseEventRequired={setisMouseEventRequired}
                        isMouseEventRequired={isMouseEventRequired}
                        selected={linkObj.path === location.pathname || (adminSubPath?.includes(linkObj.path) && adminSubPath?.includes(location.pathname)) || (patientsSubTab?.includes(linkObj.path) && patientsSubTab?.includes(location.pathname))}
                        setIsPatientsTabOpen={setIsPatientsTabOpen}
                        isPatientsTabOpen={isPatientsTabOpen}
                      />
                      {
                        //  ( isPatientsTabOpen && linkObj.text === "PATIENTS" ) && <PatientsSubTab/>
                      }
                    </div>
                  ))
                )
            }
            <Divider />
          <Box sx={{  marginTop: "10px", wordBreak: "break-all" }} > <AccountCircleIcon /><Typography sx={{ fontWeight: "600", fontSize: "14px", margin: "0px 10px" }}>  {email}</Typography></Box>
           <li
              style={{
                display: "flex",
                minHeight: "57px",
                alignItems: "center",
              }}
            >
              <div style={{ paddingLeft: "20px" }}>
                <LogoutIcon onClick={logout} />
              </div>
              <Typography variant="link" sx={{fontWeight:"800",fontSize:"16px", margin:"0px 10px"}} onClick={logout}>Sign Out</Typography>
            </li>   
          </List>
      }

    </Drawer>
  );
}
export const ListItemLink = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isCollapsed,isCollapsedOnNavigate } = useSelector(state => state.sideBarCollapseSelector);
  const handleIconNavigate = () => {
    navigate(props.path)
    if (isCollapsed) {
      dispatch(updateCollapsedOnNavigate(false))
       props.setisMouseEventRequired(true)
 }
  }
  const handleCollapseOnEnter = () => {
      if (isCollapsed && !props?.isMouseEventRequired) {
           dispatch(updateCollapsedOnNavigate(true))
      }
  }
  const handleCollapseOnleave = () => {
    if (isCollapsed) {
      dispatch(updateCollapsedOnNavigate(false))
      props.setisMouseEventRequired(false)
 }
    
  }
  return (
    <ListItem key={props.text} disablePadding selected={props.selected} sx={props.sx}>
      <ListItemButton onClick={handleIconNavigate} onMouseEnter={handleCollapseOnEnter} onMouseLeave={handleCollapseOnleave}  className={classes.listContainer} >
        <ListItemIcon className={classes.IconItem} >
          {props?.iconComponent}
        </ListItemIcon>
        {
          (!isCollapsed ) && <ListItemText>
          
            <Link to={props.path}>{props.text}</Link>
          </ListItemText>
        }
        {
          (isCollapsed  && isCollapsedOnNavigate) && <ListItemText>
          
            <Link to={props.path}>{props.text}</Link>
          </ListItemText>
        }
      </ListItemButton>
    </ListItem>
  )
}
