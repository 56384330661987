import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ReportLocationPulldown from "../misc/ReportLocationPulldown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StudyTypePulldown from "../misc/StudyTypePulldown";
import GroupPulldown from "../misc/GroupPulldown";

const SearchReport = ({
  formInputState,
  formErrorState,
  updateDates,
  submitHandler,
  updateReportInput,
  reportColumns,
  canFiltered,
}) => {
  // console.log("🚀 ~ canFiltered:", canFiltered)
  return (
    <Card sx={{ width: "100%", marginBottom: "20px" }}>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <Typography variant="h6">Select Report</Typography>

        <FormControl variant="standard" fullWidth>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={2.5} lg={2.5} md={6} sm={12}>
              <FormControl
                variant="standard"
                sx={{ m: 1, width: "85%", minWidth: "120px" }}
              >
                <InputLabel
                  id="reportType-label"
                  error={
                    formErrorState["reporttypeId"]?.error &&
                    !formInputState["reporttypeId"]
                  }
                  sx={{ color: formErrorState.reporttypeId ? "red" : "" }}
                >
                  Select Report *
                </InputLabel>

                <Select
                  labelId="reportType-label"
                  id={"reporttypeId"}
                  name={"reporttypeId"}
                  value={formInputState["reporttypeId"] || ""}
                  label="report"
                  onChange={updateReportInput}
                  error={
                    formErrorState["reporttypeId"]?.error &&
                    !formInputState["reporttypeId"]
                  }
                >
                  {reportColumns?.map((item,i) => (
                    <MenuItem value={item?.reportParam} key={i}>
                      {item?.reportName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            {
              formInputState.reporttypeId ?
                <Grid item xs={2.5} lg={2.5} md={6} sm={12}>
                  <GroupPulldown
                    formState={formInputState}
                    updateInput={updateReportInput}
                    formErrorState={formErrorState}
                  />
                </Grid> : ""
            }

            {canFiltered?.locationFilter ? (
              <Grid item xs={2.5} lg={2.5} md={6} sm={12}>
                <ReportLocationPulldown
                  key={"locationId"}
                  formState={formInputState}
                  updateInput={updateReportInput}
                  formErrorState={formErrorState}
                  attributeName={"locationId"}
                  // style={{ maxWidth: "300px" }}
                />
              </Grid>
            ) : null}

            {canFiltered?.dateFilter ? (
              <Grid item xs={2.5} lg={2.5} md={6} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="MM/DD/YYYY"
                    value={formInputState?.startDate}
                    onChange={(newValue) => updateDates(newValue, "startDate")}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "90%" }}
                        {...params}
                        variant="standard"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            ) : null}

            {canFiltered?.dateFilter ? (
              <Grid item xs={2.5} lg={2.5} md={6} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="MM/DD/YYYY"
                    value={formInputState?.endDate}
                    onChange={(newValue) => updateDates(newValue, "endDate")}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "90%" }}
                        {...params}
                        variant="standard"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            ) : null}

            {canFiltered?.studyTypeFilter ? (
              <Grid item xs={2.5} lg={2.5} md={6} sm={12}>
                <StudyTypePulldown
                  key={"studyTypeId"}
                  formState={formInputState}
                  updateInput={updateReportInput}
                  formErrorState={formErrorState}
                  attributeName={"studyTypeId"}
                  style={{ width: "100%" }}
                />
              </Grid>
            ) : null}

            <Grid item xs={2} lg={2} md={6} sm={12}>
              <Button
                size="small"
                variant="contained"
                type="submit"
                onClick={submitHandler}
              >
                Get Report
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default SearchReport;
