import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "../../../node_modules/@mui/material/index";
import { useState } from "react";
import * as React from "react";

function StopBangQuestionaire() {
  const isMobile = useMediaQuery('(max-width:800px)');
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
  });
  const [formState, setFormState] = useState({});
  const [BMIErrorState, setBMIErrorState] = useState({
    heightFeet: "",
    heightInches: "",
    weight: "",
  });
  const [surveyCheck, setSurveyCheck] = useState({});
  const [BMI, setBMIState] = useState({});
  const updateInput = (e) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const [DOB, setDOB] = React.useState(null);
  const updateDOB = (newValue) => {
    setDOB(newValue);
  };
  const [errorDOB, setErrorDOB] = React.useState(false);
  const validatePositiveNumber = (id, value) => {
    if (id==="heightInches") {
      if (value !== '' && !Number.isNaN(value) && Number(value) > -1) {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: false },
        }));
      } else {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: true },
        }));
      }
    }else{
      if (value !== '' && !Number.isNaN(value) && Number(value) > 0) {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: false },
        }));
      } else {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: true },
        }));
      }
    }
  };
  const updateBMIInput = (e) => {
    const {id, value} = e?.target ;
    if (id === 'heightFeet' || id === 'heightInches' || id === 'weight') {
      validatePositiveNumber(id, value);
    }
    const newBMIState = { ...BMI, [e.target.id]: e.target.value };
    const height =
      parseInt(newBMIState.heightFeet) * 12 +
      parseInt(newBMIState.heightInches);
    const bmi = Math.round((newBMIState.weight * 703) / Math.pow(height, 2));
    if (!isNaN(bmi)) {
      newBMIState.bmi = bmi;
    }
    if (bmi > 25) {
      updateSurveyCheck({ target: { id: "patientBMI", checked: true } });
    } else updateSurveyCheck({ target: { id: "patientBMI", checked: false } });
    setBMIState(newBMIState);
  };

  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: e.target.checked,
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState, bangScore: sum });

    setSurveyCheck(newSurveyState);
  };

  return (
    <Container maxWidth="lg">
      <div
        style={{
          paddingLeft: "0.5rem",
          paddingTop: "1rem",
          textAlign: "center",
        }}
      >
        {/*
        <Typography variant="h3">WellNecessities</Typography>
        <Typography variant="h5" style={{ paddingTop: "1rem" }}>
          8835 LINE AVENUE•SHREVEPORT LA •71106 • OFFICE 800.892.9994•FAX
          318.222.0883
        </Typography>
      */}
      
        <Typography variant="h5" style={{
            paddingTop: "1rem",
          }}
        >
          SLEEP APNEA SCREENING • MODIFIED STOP BANG QUESTIONAIRE
        </Typography>
      </div>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "40px",
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
       <Box sx={{ display: isMobile ? 'grid' : "flex", gridTemplateColumns: isMobile ? '1fr 1fr' : "0fr 0fr" , width : "100%"}}>

           <TextField
            id="lastName"
            {...getErrorValidationProperty("lastName")}
            label="Last Name"
            value={formState.lastName}
            variant="standard"
            onChange={updateInput}
            required
          />
          <TextField
            id="firstName"
            label="First Name"
            {...getErrorValidationProperty("firstName")}
            value={formState.firstName}
            variant="standard"
            onChange={updateInput}
            required
          />



          <TextField
            id="midInit"
            label="MI"
            sx={{
              maxWidth: "5rem",
            }}
            value={formState.midInit}
            variant="standard"
            onChange={updateInput}
          />
        
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="DOB *"
              inputFormat="MM/DD/YYYY"
              value={DOB}
              onChange={updateDOB}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          </Box>
        </Box>

        <Box
          component="div"
          sx={{
            "& .MuiBox-root": { flexGrow: 1 },
            display: "flex",
            gap: "2rem",
          }}
        ></Box>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "98%" },
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginTop: "10px",
          }}
        ></Box>
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Typography
        variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          {" "}
          BMI Calculation
        </Typography>

        <TextField
          id="heightFeet"
          {...getErrorValidationProperty("heightFeet")}
          label="Height (Feet)"
          type="number"
          value={BMI.heightFeet}
          variant="standard"
          onChange={updateBMIInput}
          error={BMIErrorState?.heightFeet?.error }
          helperText={
            (BMIErrorState.heightFeet?.error && BMI.heightFeet) ? "height should be positive number" :""
          }
          required
        />
        <TextField
          id="heightInches"
          {...getErrorValidationProperty("heightInches")}
          label="Height (Inches)"
          value={BMI.heightInches}
          // helperText=""
          type="number"
          variant="standard"
          onChange={updateBMIInput}
          required
          error={BMIErrorState?.heightInches?.error }
          helperText={
            (BMIErrorState.heightInches?.error && BMI.heightInches) ? "height should be positive number" :""
          }
        />
        <TextField
          id="weight"
          {...getErrorValidationProperty("weight")}
          label="Weight (lbs)"
          value={BMI.weight}
          // helperText=""
          type="number"
          variant="standard"
          onChange={updateBMIInput}
          required
          error={BMIErrorState?.weight?.error }
          helperText={
            (BMIErrorState.weight?.error && BMI.weight) ? "height should be positive number" :""
          }
        />

        <TextField
          id="bmi"
          label={`Calculated BMI`}
          value={BMI.bmi === undefined ? "" : BMI.bmi}
          helperText=""
          variant="standard"
          onChange={updateBMIInput}
        />
      </Box>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Typography
         variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          Check all that apply
        </Typography>

        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={<Checkbox id="male" onChange={updateSurveyCheck} />}
              checked={Boolean(surveyCheck.male)}
              label="Are you male?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={<Checkbox id="snore" onChange={updateSurveyCheck} />}
              checked={Boolean(surveyCheck.snore)}
              label="Do you snore?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={<Checkbox id="feelTired" onChange={updateSurveyCheck} />}
              checked={Boolean(surveyCheck.feelTired)}
              label="Do you often feel Tired, Fatigued, or Sleepy during the daytime?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox id="stopBreathing" onChange={updateSurveyCheck} />
              }
              checked={Boolean(surveyCheck.stopBreathing)}
              label="Has anyone observed you stop breathing or choking/gasping during your sleep?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox id="highBloodPressure" onChange={updateSurveyCheck} />
              }
              checked={Boolean(surveyCheck.highBloodPressure)}
              label="Do you have (or are you being treated for) high blood pressure?"
            />
          </FormControl>
        </div>

        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox id="olderFifty" onChange={updateSurveyCheck} />
              }
              checked={Boolean(surveyCheck.olderFifty)}
              label="Are you older than 50 years of age?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox id="neckSizeLarge" onChange={updateSurveyCheck} />
              }
              checked={Boolean(surveyCheck.neckSizeLarge)}
              label='Neck size large? (measured around Adam apple. 17" shirt collar for males, or 16" for females)'
            />
          </FormControl>
        </div>
        
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={<Checkbox id="patientBMI" />}
              label="Patient's BMI > 25?"
              checked={Boolean(surveyCheck.patientBMI)}
            />
          </FormControl>
        </div>
        <div>
          <TextField
            id="bangScore"
            label="STOP-BANG score"
            value={formState.bangScore === undefined ? "" : formState.bangScore}
            helperText=""
            variant="standard"
            onChange={updateInput}
          />
        </div>
      </Box>

      <Box sx={{ paddingTop: isMobile ? "1rem" : "2rem", paddingBottom: isMobile ? "4rem" : "2rem" }}>
        <Button variant="contained">Submit</Button>  
      </Box>
    </Container>
  );
}

export default StopBangQuestionaire;
