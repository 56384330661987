import { BottomNavigation, BottomNavigationAction, Box, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react'
import classes from './mobileBottomNavigation.module.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from 'react-router-dom';
import { allRoutePathsFunc, patientPortalRoutes } from '../../constants/allRoutePaths';
import useIsPrevileges from '../../hooks/useRoleAndPrivileges';
import { useAuth } from '../../hooks/auth';
function MobileBottomNavigation() {
    const privelegesArray = useIsPrevileges();
    const allRoutePaths = allRoutePathsFunc(privelegesArray?.privileges?.subtab?.includes("signOrder") || false);
    const { logout } = useAuth();
    const [value, setValue] = React.useState(0);
    const navigate =useNavigate()
    const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
    const handleIconNavigate = (path) => {
        navigate(path);
      };
      const handleMoreMenuOpen = (event) => {
        setMoreMenuOpen(true);
      };
    
      const handleMoreMenuClose = () => {
        setMoreMenuOpen(false);
      };
  return (
    <Box sx={{position:'fixed',width:"100%",bottom: 0,backgroundColor:"#E7EBF0"}}>
     <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{justifyContent:"space-evenly"}}
      >
        { 
        !privelegesArray?.role ? 
        (
          patientPortalRoutes?.slice(0, 3).map((linkObj, index) => {
            return (
                <BottomNavigationAction
                  key={linkObj.text}
                  label={linkObj.text}
                  icon={linkObj.iconComponent}
                  onClick={() => handleIconNavigate(linkObj.path)}
                  sx={{
                    "&.MuiBottomNavigationAction-root" : {
                      padding : "0 6px",
                      maxWidth: "88px",
                      minWidth: "60px",
                    }
                  }}
                />
            )
          })
        )
        :
        (
          allRoutePaths?.map((linkObj, index) => {
            return (
              privelegesArray?.privileges?.tab?.slice(0, 3).find(item => item?.tabName?.includes(linkObj?.tabName)) && (
                
                <BottomNavigationAction
                  key={linkObj.text}
                  label={linkObj.text}
                  icon={linkObj.iconComponent}
                  onClick={() => handleIconNavigate(linkObj.path)}
                  sx={{
                    "&.MuiBottomNavigationAction-root" : {
                      padding : "0 6px",
                      maxWidth: "88px",
                      minWidth: "60px",
                    }
                  }}
                />
              )
            )
          })
        )
        }
        {
          privelegesArray?.privileges?.tab?.length < 4 && privelegesArray?.role ?
            <Box className={classes.logoutButtonWrapper}>
              <Box className={classes.logOutIconContainer} >
                <LogoutIcon sx={{width:"21px",height:"21px"}} onClick={logout} />
              </Box>
              <Typography variant="link" sx={{fontWeight:"800"}} onClick={logout}>SIGN OUT</Typography>
            </Box> :
             <Box className={classes.logoutButtonWrapper}>
             <Box className={classes.logOutIconContainer} onClick={handleMoreMenuOpen}>
               <MoreHorizIcon sx={{width:"21px",height:"21px"}}  />
             </Box>
             <Typography variant='subtitle1' onClick={handleMoreMenuOpen}>MORE</Typography>
           </Box>

        }
        {(privelegesArray?.privileges?.tab?.length > 3 && privelegesArray?.role) ? (
            <Menu
              anchorEl={isMoreMenuOpen}
              open={isMoreMenuOpen}
              onClose={handleMoreMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
                <Box className={classes.menuItemContainer}>
                {allRoutePaths.map((linkObj, index) => (
                privelegesArray?.privileges?.tab?.slice(3)?.find(item => item?.tabName?.includes(linkObj?.tabName)) && (
                    <BottomNavigationAction
                    key={linkObj.text}
                    label={linkObj.text}
                    icon={linkObj.iconComponent}
                    onClick={() => handleIconNavigate(linkObj.path)}
                  />
                )
              ))}

            <BottomNavigationAction
              key={"LOG OUT"}
              label={"LOG OUT"}
              icon={<LogoutIcon/>}
              onClick={logout}
            />
                </Box>
          
            </Menu>
        ) : 
        (
          <Menu
            anchorEl={isMoreMenuOpen}
            open={isMoreMenuOpen}
            onClose={handleMoreMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
              <Box className={classes.menuItemContainer}>
              {patientPortalRoutes?.slice(3)?.map((linkObj, index) => (
                  <BottomNavigationAction
                  key={linkObj.text}
                  label={linkObj.text}
                  icon={linkObj.iconComponent}
                  onClick={() => handleIconNavigate(linkObj.path)}
                />
              // )
            ))}

          <BottomNavigationAction
            key={"LOG OUT"}
            label={"LOG OUT"}
            icon={<LogoutIcon/>}
            onClick={logout}
          />
              </Box>
        
          </Menu>
        )
        }
      </BottomNavigation>
  </Box>
  )
}

export default MobileBottomNavigation