import React, { createContext, useState, useContext } from 'react';

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
    const tempPass = localStorage.getItem('tempPassErr') || null;
    const [errorMessage, setErrorMessage] = useState(tempPass == 1 ? 'Please change the temporary password under Profile.' : '');

  return (
    <ErrorContext.Provider value={{ errorMessage, setErrorMessage }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);