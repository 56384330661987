import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import classes from './NotFoundPages.module.css'
import { Navigate, useNavigate } from 'react-router-dom';
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useCookies } from 'react-cookie';
import SignInLanding from '../patientPortal/signInLanding/SignInLanding';

const NotFoundPage = () => {
const navigate = useNavigate()
const [cookies] = useCookies();
if (!cookies?.token) {
    return <> <Navigate to={'/signin-landing'}/> <SignInLanding/></>
}
const handleBack = ()=>{
    if (cookies?.token && !cookies?.isPatient ) {
        navigate('/dashboard')
    }else if(cookies?.token && cookies?.isPatient){
        navigate('/patient-portal')
    }
}
    return (
        <Box className={classes.NotFoundPageWrapper}>
            <Box className={classes.NotFoundContentWrapper}>
                <Typography variant='h3'>404 Not Found</Typography>
                <Typography variant='span'>The page you are looking for does not exist.</Typography>
                <Box>
                <Button
                    startIcon={<KeyboardReturnIcon />}
                    onClick={handleBack}
                >
                    Back
                </Button> 
                </Box>
                
            </Box>
        </Box>
    );
};
export default NotFoundPage;