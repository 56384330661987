import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "../../../node_modules/@mui/material/index";
import { Radio, Typography } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import { useState } from "react";
import * as React from "react";
import classes from './SatisfactorySurvey.module.css';
import SelectComponent from "../../components/formComponent/SelectComponent";
import ConfirmDialog from "../../components/misc/Dialog";
import moment from "moment";
import { pulldownsPostData, webformPostData, webFormsGetData , visitsGetStudyData } from "../../axios/api";
import { notificationToastError, notificationToastSuccess } from "../../utils/notification";
import { useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import { Download } from "@mui/icons-material";  
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function SatisfactionSurvey({ asDialog, handleClose, patientData, visitDataObj, studyId, reloadFormTable = ()=> {}, providerPortal }) {
  const isMobile = useMediaQuery('(max-width:800px)');
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
  });
  const [formState, setFormState] = useState({});
  const [surveyCheck, setSurveyCheck] = useState({});
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false)
  const satisfactionRef = React.useRef(null);

  const onDownload = () => {
    const input = satisfactionRef.current;

    // Use html2canvas to convert HTML to canvas
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('patientSatisfactionSurvey.pdf');
    });
  }

  React.useEffect(() => {
    if (visitDataObj?.patientId) {
      let payload = {
        patientId: visitDataObj?.patientId,
        studyTypeId: visitDataObj?.studyTypeId,
        prefInterpPhysId: visitDataObj?.prefInterpPhysId,
        locationId: visitDataObj?.locationId,
        studyId: visitDataObj?.studyId,
        physicianId: visitDataObj?.physicianId,
      }
      pulldownsPostData(axios, "scheduleLocationPulldown", payload)
        .then((res) => {
          if (res && res.data) {
            let location = res.data.find((i) => i.locationId === visitDataObj?.locationId)?.location
            setFormState({
              ...formState,
              orderDate: visitDataObj?.date,
              interpretingPhysician: visitDataObj?.interpretingPhysician,
              referringPhysician: visitDataObj?.orderingPhysician,
              activeLocation: location,
            })
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [visitDataObj])

 

  console.log("111 = ", formState) 


  React.useEffect(() => {

    if (studyId > 0) {

      let payload = {
        studyId: studyId,
      }
    
      visitsGetStudyData (axios, "viewStudy", payload).then((res) => {

      if (res[0]) {
        setFormState((prevState) => ({
          ...prevState,
          ...res[0]
      }));
      
    }
      
      }).catch((err) => {
          console.log("err==>", err);
      }).finally(() => {
          setIsLoading(false)
      })
      
    }
  
  }, [patientData])

  React.useEffect(() => {

    if (studyId > 0) {
    
      webFormsGetData (axios, studyId, "satisfactionSurveyForm").then((res) => {
        

        setFormState((prevState) => ({
          ...prevState,
            ...res[0],
            patientId: patientData?.patientId,
            lastName: patientData?.lastName,
            firstName: patientData?.firstName,
            midInit: patientData?.middleInitial,
            dob: patientData?.dob,
            gender: patientData?.gender,
            age: patientData?.age,
            studyId: studyId,
          }))
      }).catch((err) => {
          console.log("err==>", err);
      }).finally(() => {
          setIsLoading(false)
      })
  
    } else {

      setFormState((prevState) => ({
        ...prevState,
      patientId: patientData?.patientId,
      lastName: patientData?.lastName,
      firstName: patientData?.firstName,
      dob: patientData?.dob,
      midInit: patientData?.middleInitial,
      studyId: studyId,
    }))
  }
  }, [patientData])


  const isEmptyField = (key) =>
  formState[key] === undefined ||
  formState[key] === null ||
  formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const updateInput = (e) => {
    setFormState({ ...formState, [e.target.id || e.target.name]: e.target.value });
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const [DOB, setDOB] = React.useState(null);
  const updateDOB = (newValue) => {
    setFormState({
      ...formState,
      dob: newValue.format("YYYY-MM-DD")
    })
    setDOB(newValue);
  };

  const updateOrderDate = (newValue) => {
    setFormState({
      ...formState,
      orderDate: newValue?.format("YYYY-MM-DD")
    })
  };

  const [errorDOB, setErrorDOB] = React.useState(false);

  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id || e.target.name]: e.target.checked,
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState, bangScore: sum, [e.target.id || e.target.name]: e.target.value });

    setSurveyCheck(newSurveyState);
  };

  
  const saveChanges = () => {
    let isValid = true
    if (isValid) {
      setIsDisabled(true)
      const payload = {
        ...formState,
        date: moment(formState.date).format('YYYY-MM-DD'),
        orderDate: moment(formState.orderDate).format('YYYY-MM-DD'),
        providerPortal: providerPortal ? 1 : 0
      };
      webformPostData(axios, "submitPatSatSurvey", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message); 
            reloadFormTable(true)
            setIsDisabled(false)
            /*
            setTimeout(() => {   
              window.location.reload();
            }, 2000);
           */
              
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        })
        .finally(() => {
          handleClose();
        });
    }
  };

  let isSaveButtonDisabled = false;
  if (formState?.completed) isSaveButtonDisabled = true; 

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        maxWidth="none"
       isSaveButtonDisabled={isSaveButtonDisabled || isDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }
  function renderForm(asDialog) {
    return (
      <div>
        <Button
          sx={{ width: 130, float: "right", mt: 2, mr: 2 }}
          variant="contained"
          onClick={() => onDownload()}
        >
          Download <Download />
        </Button>
      <Container
        maxWidth="lg"
        sx={{
          borderStyle: "double"
          }}
          ref={satisfactionRef}
          >
        <div
          style={{
            marginTop: "1rem",
            textAlign: "center",
          }}
        >
          { /* <Typography variant='h3'>WellNecessities</Typography>
        <Typography variant='h6' style={{ paddingTop: "1rem" }}>
          8835 LINE AVENUE•SHREVEPORT LA •71106 • OFFICE 800.892.9994•FAX
          318.222.0883
        </Typography>
        */}
          <Typography
            variant='h4'
            sx={{ textDecoration: "underline" }}
          >
            Patient Satisfaction Survey
          </Typography>
        </div>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
            border: "double",
            borderColor: "rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginTop: "40px",
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <Box sx={{ display: isMobile ? 'grid' : "flex", gridTemplateColumns: isMobile ? '1fr 1fr' : "0fr 0fr", width: "100%" }}>

             <TextField
                id="lastName"
                label="Last Name *"
                value={formState?.lastName || ""}
                variant="standard"
                onChange={updateInput}
                error={formErrorState.lastName?.error && !formState.lastName}
                helperText={
                  formErrorState.lastName?.error && !formState.lastName
                    ? "required"
                    : ""
                }
                onBlur={validateEmptyField.bind(null, "lastName")}
              />

              <TextField
                id="firstName"
                label="First Name"
                {...getErrorValidationProperty("firstName")}
                value={formState?.firstName  || ""}
                variant="standard"
                onChange={updateInput}
                required
              />
              <TextField
                id="midInit"
                label="MI"
                sx={{
                  maxWidth: "5rem",
                }}
                value={formState?.midInit}
                variant="standard"
                onChange={updateInput}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="DOB *"
                  inputFormat="MM/DD/YYYY"
                  value={formState?.dob}
                  onChange={updateDOB}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <Box sx={{ display: isMobile ? 'grid' : "flex", gridTemplateColumns: isMobile ? '1fr 1fr' : "0fr 0fr", width: "100%" }}>
              <TextField
                id="appointmentType"
                label="Appointment type:"
                {...getErrorValidationProperty("appointmentType")}
                value={formState?.studyType || ""}
                variant="standard"
                onChange={updateInput}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="DOS"
                  inputFormat="MM/DD/YYYY"
                  value={formState?.orderDate || ""}
                  onChange={updateOrderDate}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </LocalizationProvider>
              <TextField
                id="interpretingPhysician"
                {...getErrorValidationProperty("interpretingPhysician")}
                label="Interpreting Physician:"
                value={formState?.prefInterpPhysicianName || ""}
                variant="standard"
                onChange={updateInput}
              />
              <TextField
                id="referringPhysician"
                {...getErrorValidationProperty("")}
                label="Referring Physician:"
                value={formState?.orderingPhysicianName || ""}
                variant="standard"
                onChange={updateInput}

              />
              <TextField
                id="activeLocation"
                {...getErrorValidationProperty("activeLocation")}
                label="Facility "
                value={formState?.location || ""}
                variant="standard"
                onChange={updateInput}

              />
            </Box>
          </Box>
          {/* <Box
            component="div"
            sx={{
              "& .MuiBox-root": { flexGrow: 1 },
              display: "flex",
              gap: "2rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              marginLeft: "1rem",
            }}
          >
            <Box sx={{color :"#1976d2"}}>Questionnaire: Satisfaction Survey -Total Questions: 25 </Box>
            <Box sx={{color :"#1976d2"}}>Document type: Questionnaire Completed on: </Box>
          </Box> */}
        </Box>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
            border: "double",
            borderColor: "rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginTop: "1rem",
            marginBottom: asDialog ? "1rem" : "0",
          }}
        >

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              "& >.MuiBox-root": { marginBottom: '1rem' },
              marginLeft: "1rem",
              marginTop: "1rem",
            }}
          >
            <Box >
              <Typography variant="body1" fontWeight={600}>
                1.	How was the scheduling process by the our Team?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="schedulingProcess"
                  value={formState?.schedulingProcess || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />

                </RadioGroup>
              </Box>
            </Box>
            <Box className={classes.selectionWrapper}>
              2. {" "}
              <SelectComponent id="contactedHospital"
                label="If you were scheduled 3 or more days prior to your sleep study, were you contacted by a hospital representative regarding your out of pocket estimate?"
                formState={formState}
                updateInput={updateInput} />
            </Box>

            {/* <Box >
            2. If you were scheduled 3 or more days prior to your sleep study, were you contacted by a hospital representative regarding your out of pocket estimate?	
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="contactedHospital"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              3. {" "}
              <SelectComponent id="copay" label="Did you have a copay?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            3.	Did you have a copay?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="copay"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />

              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              4. {" "}
              <SelectComponent id="scheduleAppointment" label="Did you schedule your appointment with the our team?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            4.	Did you schedule your appointment with the our team?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="scheduleAppointment"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />

              </RadioGroup>
            </Box>
          </Box> */}
            <Box >
              <Typography variant="body1" fontWeight={600}>
                5.	How was the comfort of the waiting area?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="comfortWaitingArea"
                  value={formState.comfortWaitingArea || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                6.	How do you rate the information provided about your test during the scheduling process?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="schedulingInfoProcess"
                  value={formState.schedulingInfoProcess || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                7.	How was your registration process/wait time at the hospital or sleep lab?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="registrationProcess"
                  value={formState.registrationProcess || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box  >
              <Typography variant="body1" fontWeight={600}>
                8.	How was the cleanliness of the bedroom?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="cleanlinessBedroom"
                  value={formState.cleanlinessBedroom || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                9.	Was the technician helpful and courteous?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="technicianHelpful"
                  value={formState.technicianHelpful || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box className={classes.selectionWrapper}>
              10. {" "}
              <SelectComponent id="technicianIntroduce" label="Did the technician introduce himself/herself?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            10.	Did the technician introduce himself/herself?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="technicianIntroduce"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              11. {" "}
              <SelectComponent id="addressRespectively" label="Did the technician address you respectively using Mr.. Ms., Mrs. (children first name)?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            11.	Did the technician address you respectively using Mr.. Ms., Mrs. (children first name)?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="addressRespectively "
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />

              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              12. {" "}
              <SelectComponent id="technicianAskAnything" label="Did the technician ask if you needed anything (extra blanket, water, ect)?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            12.	Did the technician ask if you needed anything (extra blanket, water, ect)?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="technicianAskAnything"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              13. {" "}
              <SelectComponent id="techHandSanitizer" label="Did your tech use hand sanitizer, wash hands, or use gloves during the process?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            13.	Did your tech use hand sanitizer, wash hands, or use gloves during the process?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="techHandSanitizer"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box >
              <Typography variant="body1" fontWeight={600}>
                14.	Did the technician explain the procedure?
              </Typography>
              <Box>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="explainProcedure"
                  value={formState.explainProcedure || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box className={classes.selectionWrapper}>
              15. {" "}
              <SelectComponent id="techAnswer" label="Did the tech answer all of your questions?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            15.	Did the tech answer all of your questions?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="techAnswer"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />

              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              16. {" "}
              <SelectComponent id="technicianInformed" label="Did the technician keep you informed about delays?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            16.	Did the technician keep you informed about delays?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="technicianInformed"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />

              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              17. {" "}
              <SelectComponent id="homeCareInstructions" label='Before leaving, were "Home care Instructions" explained to you? (A sheet labeled Home care Instructions should have been provided, along with the explanation)' formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            17.	
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="homeCareInstructions"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />

              </RadioGroup>
            </Box>
          </Box> */}
            <Box >
              <Typography variant="body1" fontWeight={600}>
                18.	Did you feel safe & secure while you were here?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="safeSecure"
                  value={formState?.safeSecure || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box  >
              <Typography variant="body1" fontWeight={600}>
                19.	Was the temperature of the room comfortable?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="roomComfortable"
                  value={formState?.roomComfortable || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box  >
              <Typography variant="body1" fontWeight={600}>
                20.	How would you rate the staff's concern for your comfort?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="concernComfort"
                  value={formState?.concernComfort || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                21.	Was your room quiet?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="roomQuiet"
                  value={formState?.roomQuiet || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                22.	How would you rate the staff's concern for your privacy?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="staffsConcern"
                  value={formState?.staffsConcern || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                23.	How do you rate your overall satisfaction?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="overallSatisfaction"
                  value={formState?.overallSatisfaction || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Very Dissatisfied " />
                  <FormControlLabel value="2" control={<Radio />} label="Somehow Dissatisfied " />
                  <FormControlLabel value="3" control={<Radio />} label="Satisfied " />
                  <FormControlLabel value="4" control={<Radio />} label="Very Satisfied " />
                  <FormControlLabel value="5" control={<Radio />} label="Not Applicable " />
                </RadioGroup>
              </Box>
            </Box>
            <Box className={classes.selectionWrapper}>
              24. {" "}
              <SelectComponent id="recommendServices" label='Would you recommend our services to others?' formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box   >

            24.	Would you recommend our services to others?
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="recommendServices"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}

            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                alignItems: "baseline",
              }}
            >
              25. {" "}
              <TextField
              sx={{ width: "100%" }}
                id="improvments"
                label="In your opinion, was there anything we could have done to make your registration process easier or anything to improve our quality of service?"
                value={formState?.improvments  || ""}
                variant="standard"
                onChange={updateInput}
                onBlur={validateEmptyField.bind(null, "improvments")}
              />
            </Box>

          </Box>
        </Box>

        {
          !asDialog &&
          <Box sx={{ paddingTop: isMobile ? "1rem" : "2rem", paddingBottom: isMobile ? "4rem" : "2rem" }}>
            <Button variant="contained" onClick={saveChanges} disabled={isDisabled}>Submit</Button>
          </Box>
        }
      </Container>
      </div>
    );
  }
}

export default SatisfactionSurvey;
