import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../../misc/Dialog";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import StageNamePulldown from "../../../misc/StageNamePulldown";

import classes from "./DocumentDialog.module.css";
import Documentupload from "../documentUpload/Documentupload";
import { adminPostData } from "../../../../axios/api";
import { useAxios } from "../../../../hooks/auth";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../../utils/notification";
import MultipleLocation from "../multiPleLocation/MultipleLocation";
import SelectTemplatePulldown from "../../../misc/SelectTemplatePulldown";
import StudyTypePulldown from "../../../misc/StudyTypePulldown";
import GeneralPulldown from "../../../misc/pulldowns/GeneralPulldown";
import WebFormPulldown from "../../../misc/WebFormPulldown";
const documentCheckBoxList = [
  { label: "General information", key: "generalInfo" },
  { label: "Stage based", key: "stageBased" },
  { label: "Location based", key: "locationBased" },
  { label: "In Lab appt", key: "inLab" },
  { label: "HST appt", key: "hst" },
];

const DocumentDialog = ({
  handleClose,
  documentData,
  setRefreshData = () => {},
}) => {
  const axios = useAxios();
  const [formState, setFormState] = useState({});
  useEffect(() => {
    setFormState({
      ...formState,
      ...documentData,
      documentId: documentData?.documentId,
      file: documentData?.fileBase64,
    });
    let selectedType = "";
    if (documentData?.is_document) {
      selectedType = "is_document";
    } else if (documentData?.is_form) {
      selectedType = "is_form";
    } else if (documentData?.webForm) {
      selectedType = "webForm";
    }
    if (selectedType) {
      setSelectedCheckbox(selectedType);
    }
  }, [documentData]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");

  const [formErrorState, setFormErrorState] = useState({
    document: { error: false },
    file: "",
    title: "",
    kind: { error: false },
    signFormTemplateId:"",
    webFormId : ""
  });
  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
  };
  const updateCheckBox = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
    if (
      (formState?.stageBased && e.target.checked) ||
      (e.target.id === "stageBased" && e.target.checked)
    ) {
      setFormErrorState({
        document: { error: false },
        stageNameId: "",
        file: "",
        title: "",
        kind: { error: false },
      });
    } else if (
      (formState?.locationBased && e.target.checked) ||
      (e.target.id === "locationBased" && e.target.checked)
    ) {
      setFormErrorState({
        document: { error: false },
        documentLocation0: "",
        groupId: "",
        file: "",
        title: "",
        kind: { error: false },
      });
    }else {
      setFormErrorState({
        document: { error: false },
        file: "",
        title: "",
        kind: { error: false },
      });
    }
  };
  const isAnyCheckBoxchecked = () => {
    for (const item of documentCheckBoxList) {
      if (formState?.[item?.key] === 1) {
        return true;
      }
    }
    return false;
  };
  const validateForm = () => {
    if (!isAnyCheckBoxchecked()) {
      setFormErrorState({
        ...formErrorState,
        document: { error: true },
      });
      return false;
    }
    return true;
  };
  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }
  // console.log("form error", formErrorState);
  const saveChanges = () => {
    let isValid = true;
    const errors = { ...formErrorState };
    //debugger
    if (!validateForm()) {
      isValid = false;
      errors["document"] = { error: true };
    }

    if (selectedCheckbox !== "is_document" && selectedCheckbox !== "is_form" && selectedCheckbox !== "webForm") {
      isValid = false;
      errors["kind"] = { error: true };
    }

    if (selectedCheckbox !== "is_form" && selectedCheckbox !== "webForm" && formState.file == undefined) {
      isValid = false;
      errors["file"] = { error: true }
    }

    if (selectedCheckbox === "webForm" && !formState.webFormId) {
      isValid = false;
      errors["webFormId"] = { error: true }
    }

    for (const [key, _] of Object.entries(formErrorState)) {
      if (key !== "document" && key !== "kind" && key !== "file" && key !== "signFormTemplateId" && key !== "webFormId") {
        if (isEmptyField(key)) {
          isValid = false;
          errors[key] = { error: true };
        }
      }
    }
    if(selectedCheckbox === "is_form" && !formState.signFormTemplateId){
      isValid = false;
      errors["signFormTemplateId"] = { error: true }
    }else{
      errors["signFormTemplateId"] = ""
    }
    setFormErrorState({
      ...formErrorState,
      ...errors,
    });
    
    if (!isValid) {
      notificationToastError("Please fill all required fields");
      return;
    }
    if (isValid) {
      setIsSaveButtonDisabled(true);
      const payload = {
        ...formState,
        stageNameId: formState?.stageBased ? formState?.stageNameId : "",
        studyTypeId: formState?.inLab === 1 ? formState?.studyTypeId : "",
        hstDeviceId: formState?.hst === 1 ? formState?.hstDeviceId : ""
      };
      adminPostData(axios, "saveDocument", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
            setRefreshData((prevState) => !prevState);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((ex) => {
          notificationToastError(ex?.message);
        })
        .finally(() => {
          handleClose();
        });
    }
  };

  const updateTemplate = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
    if(isSaveButtonDisabled){
      setIsSaveButtonDisabled(false)
    }
  };

  const updateCheckBoxKind = (event) =>{
    const checkboxId = event.target.id;
    if(event.target.checked){
      setSelectedCheckbox(checkboxId);
    }else{
      setSelectedCheckbox("");
    }
    if(checkboxId === "is_document"){
      setFormState({
        ...formState,
        [event.target.id]: Number(event.target.checked),
        is_form:0,
        webForm:0
      });
    }else if(checkboxId === "is_form"){
      setFormState({
        ...formState,
        [event.target.id]: Number(event.target.checked),
        is_document: 0,
        webForm: 0
      });
    }else if(checkboxId === "webForm"){
      setFormState({
        ...formState,
        [event.target.id]: Number(event.target.checked),
        is_document: 0,
        is_form: 0
      });
    }
    if(isSaveButtonDisabled){
      setIsSaveButtonDisabled(false)
    }
  }

  return (
    <ConfirmDialog
      data-testid="document-dialog" // Add Cypress ID to the ConfirmDialog component
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "Save changes" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
      minwidth={"700px"}
    >
      <Typography
        data-testid="document-info-heading"
        variant="h5"
        className={classes.TitleText}
      >
        Document/Form Info:
      </Typography>
      <Box className={classes.textFieldWrapper}>
        <TextField
          data-testid="title-input" // Add Cypress ID to the title TextField
          id="title"
          label="Document/Form Name *"
          value={formState.title || ""}
          variant="standard"
          onChange={updateInput}
          sx={{ width: "97%" }}
          error={formErrorState?.title?.error && !formState?.title}
          onBlur={validateEmptyField.bind(null, "title")}
        />
        <TextField
          data-testid="description-input" // Add Cypress ID to the description TextField
          id="description"
          label="Description"
          value={formState?.description || ""}
          variant="standard"
          onChange={updateInput}
          sx={{ width: "97%" }}
        />
      </Box>
      <Box>
        <Typography
          sx={{ color: formErrorState?.kind?.error ? "#d32f2f" : "" }}
        >
          Select kind *
        </Typography>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                data-testid={`kind-checkbox`} // Add Cypress ID to each checkbox
                id="is_document"
                checked={(selectedCheckbox === "is_document") || ""}
                onChange={updateCheckBoxKind}
              />
            }
            label={"Document"}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                data-testid={`${document?.key}-checkbox`} // Add Cypress ID to each checkbox
                id="is_form"
                checked={(selectedCheckbox === "is_form") || ""}
                onChange={updateCheckBoxKind}
              />
            }
            label={"PDF Form"}
          />
        </FormControl>

        <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid={`${document?.key}-checkbox`} // Add Cypress ID to each checkbox
                  id="webForm"
                  checked={(selectedCheckbox === "webForm") || ""}
                  onChange={updateCheckBoxKind}
                />
              }
              label={"Web Form"}
            />
            {/* {formState?.webForm === 1 &&
              <TextField
                data-testid="webFormKey-input" // Add Cypress ID to the Web Form Key TextField
                id="webFormKey"
                label="Web Form Key"
                value={formState?.webFormKey || ""}
                variant="standard"
                onChange={updateInput}
                error={formErrorState?.webFormKey?.error && !formState?.webFormKey}
              />
            } */}
        </FormControl>
      </Box>
      <Box className={classes.textFieldWrapper}>
        
        {/* <TextField
          data-testid="signFormTemplateId-input" // Add Cypress ID to the description TextField
          id="signFormTemplateId"
          label="Select Template (for signature required form):"
          value={formState?.signFormTemplateId || ""}
          variant="standard"
          onChange={updateInput}
          sx={{ width: "97%" }}
        /> */}
        
        {selectedCheckbox !== "webForm" ?
          <SelectTemplatePulldown
            formState={formState}
            selectedCheckbox={selectedCheckbox}
            updateTemplate={updateTemplate}
            formErrorState={formErrorState}
          />
          : <WebFormPulldown
            formState={formState}
            selectedCheckbox={selectedCheckbox}
            updateTemplate={updateTemplate}
            formErrorState={formErrorState}
          />}
      </Box>
      <Box>
        <Typography
          sx={{ color: formErrorState?.document?.error ? "#d32f2f" : "" }}
        >
          Select document/form type *
        </Typography>
        <Box className={classes.documentcheckboxWrapper}>
          {documentCheckBoxList?.map((document) => {
            return (
              <Box key={document?.key}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid={`${document?.key}-checkbox`} // Add Cypress ID to each checkbox
                        id={document?.key}
                        checked={Boolean(formState[document?.key])}
                        onChange={updateCheckBox}
                      />
                    }
                    label={document?.label}
                  />
                </FormControl>
              </Box>
            );
          })}
        </Box>

     
      </Box>
      {formState?.stageBased ? (
        <Box>
          <StageNamePulldown
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            validateEmptyField={validateEmptyField}
          />
        </Box>
      ) : null}

      {formState.inLab ? <Box ml={1}>
        <StudyTypePulldown
          key="studyTypeId"
          formState={formState}
          updateInput={updateInput}
          formErrorState={formErrorState}
          attributeName="studyTypeId"
          style={{ maxWidth: "300px" }}
        />
      </Box>  : null}

      {formState.hst ? <Box ml={1}>
        <GeneralPulldown
        key="hstDeviceId"
        formState={formState}
        updateInput={updateInput}
        formErrorState={formErrorState}
        attributeName="hstDeviceId"
        style={{ width: "100%" }}
        pulldownApiName="hstDevicesPulldown"
        itemName="deviceName"
        labelName="Select HST Device"
      /> </Box>: null}

      <Grid container>
   
          <MultipleLocation
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            setFormState={setFormState}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
          />
  
      </Grid>
    
      <Box className={classes.documentWrapper}>
        <Documentupload
          data-testid="document-upload" // Add Cypress ID to the Documentupload component
          setFormState={setFormState}
          setIsSaveButtonDisabled={setIsSaveButtonDisabled}
          formErrorState={formErrorState}
          setFormErrorState={setFormErrorState}
          formState={formState}
        />
      </Box>
    </ConfirmDialog>
  );
};
export default DocumentDialog;
