import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";

function FollowUpPhysicianPulldown({
  formState,
  updateInput,
  attributeName,
  validateEmptyField = () => {},
  formErrorState = {},
}) {

  const id = React.useId();
  const [physicians, setPhysicians] = useState([]);
  const axios = useAxios();
  const payload = {
    followUpPhysicianId: formState?.physicianId,
  };

  useEffect(() => {
    async function setDataFirstTime() {
      pulldownsPostData(axios, "followUpPhysicianPulldown", payload)
        .then((res) => {
          if (res?.data) {
            setPhysicians(res?.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setDataFirstTime();
  }, []);

  return (
    <>
      <FormControl variant="standard" sx={{ margin: "0px 8px", width: "95%" }}>
        <InputLabel
          id="user-label"
          error={
            formErrorState[attributeName]?.error && !formState[attributeName]
          }
          sx={{ fontWeight: 600 }}
        >
          Select Ordering Physician *{" "}
        </InputLabel>
        <Select
          labelId="user-label"
          id={attributeName}
          name={attributeName}
          value={formState[attributeName] || ""}
          label="Physician"
          onChange={updateInput}
          error={
            formErrorState[attributeName]?.error && !formState[attributeName]
          }
          onBlur={validateEmptyField.bind(null, attributeName)}
        >
          <MenuItem key={id + "none"} value="">
            <em>None</em>
          </MenuItem>
          {physicians.map((physician) => (
            <MenuItem key={id + physician.userId} value={physician.userId}>
              {physician.lastName}, {physician.firstName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default FollowUpPhysicianPulldown;
