import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useAuth, useAxios } from "../../hooks/auth";
import {
  Tab,
  Tabs,
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  paginationClasses,
  useMediaQuery,
} from "@mui/material";
import ContactTable from "../../components/table/ContactTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import MUIDataTable from "mui-datatables";
import { Checkbox, Container } from "../../../node_modules/@mui/material/index";
import PatientSearch from "../../pages/PatientSearch";
import { SearchPatient } from "../../components/misc/SearchPatient";
import dayjs from "dayjs";
import { updateSelectedPatient } from "../../store/slices/selectedPatientSlice";
import useIsPrevileges from "../../hooks/useRoleAndPrivileges";
import SelectedPatients from "./components/selectPatients/SelectedPatients";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../utils/notification";
import { ToastContainer } from "react-toastify";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../../constants/sideBarwidth";
import {
  patientsGetData,
  patientsPostData,
  patientsPaginationGetData,
} from "../../axios/api";
import Chart from "react-google-charts";

export default function Patients() {
  const axios = useAxios();
  const isMobile = useMediaQuery("(max-width:800px)");
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const dispatch = useDispatch();
  const privelegesArray = useIsPrevileges();
  const [phpData, setPhpData] = useState("");
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  
  const [isLoading, setIsLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );
  const [formState, setFormState] = useState({});
  const [patientId, setPatientId] = useState("");
  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
  };
  const navigate = useNavigate();
  const patientIdNew = localStorage.getItem('patientId') || patientId;

  useEffect(() => {
    const handleUpdatedDataOfSelectedPatients = () => {
      const paramsName = "patientView";
      const payload = {
        patientId: patientIdNew,
      };
      patientsPaginationGetData(axios, paramsName, payload)
        .then((res) => {
          const updatedSelectedPatient = res?.filter(
            (item) => item?.patientId === Number(patientIdNew)
          );
          setPatientId(updatedSelectedPatient[0]?.patientId)
          if (updatedSelectedPatient?.length) {
            dispatch(updateSelectedPatient(updatedSelectedPatient[0]));
          }
        })
        .catch((err) => notificationToastError(err?.message));
    };
    async function setDataFirstTime() {
      patientsGetData(axios)
        .then((res) => {
          setPhpData(res.data);
          setRowData(res.data);
          setTotalCount(res.count);
          setPage(res.page - 1);

          if (patientIdNew) {
            handleUpdatedDataOfSelectedPatients();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    setDataFirstTime();
  }, [refreshData]);
  const deleteRecord = (id, tableName, idColumn) => {
    const yesToDelete = window.confirm("Are you sure you want to delete it? ");
    if (!yesToDelete) return;
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    patientsPostData(axios, "deleteRecord", payload)
      .then((res) => {
        if (res.data.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
        setRefreshData(!refreshData);
      })
      .catch((err) => notificationToastError(err?.message));
  };

  const newPatient = () => {
    navigate("/patient-view");
  };
  const newSurvey = () => {
    if (selectedPatient?.patientId) {
      navigate("/new-survey");
    } else if (!selectedPatient?.patientId) {
      setIsSelectPatient(true);
    }
  };
  const newOrder = () => {
    if (selectedPatient?.patientId) {
      navigate("/new-order");
    } else if (!selectedPatient?.patientId) {
      setIsSelectPatient(true);
    }
  };
  const newDmeOrder = () => {
    if (selectedPatient?.patientId) {
      navigate("/dme-order");
    } else if (!selectedPatient?.patientId) {
      setIsSelectPatient(true);
    }
  };

  const berlinQuestionaire = () => {
    navigate("/berlin-questionaire");
  };

  const satisfactionSurvey = () => {
    navigate("/satisfaction-survey");
  };
  const qualityImprovementSurvey = () => {
    navigate("/quality-improvement-survey");
  };

  const patientView = (rowData) => {
    localStorage.setItem('patientId', rowData[0])
      window.open(`/patient-view?isnewtab=true`, '_blank');
    // if (rowData[0]) {
    //   const paramsName = "patientView";
    //   const payload = {
    //     patientId: rowData[0],
    //   };

    //   patientsPaginationGetData(axios, paramsName, payload)
    //     .then((res) => {
    //       const updatedSelectedPatient = res?.filter(
    //         (item) => String(item?.patientId) === String(payload.patientId)
    //       );
    //       if (updatedSelectedPatient?.length) {
    //         dispatch(updateSelectedPatient(updatedSelectedPatient[0]));
    //       }
    //     })
    //     .catch((err) => notificationToastError(err?.message));
    // }

    // navigate(`/patient-view`, { state: { row: rowData } });
  };

  const stopBangQuestionaire = () => {
    navigate("/stop-bang");
  };

  const epworthSleepinessScale = () => {
    navigate("/epworth-sleepiness-scale");
  };

  const selectedPatient = useSelector(
    (state) => state.selectedPatientSelector?.value
  );
  const [isPatienSearchVisible, setIsPatienSearchVisible] = useState(true);
  const unselectPatient = () => {
    dispatch(updateSelectedPatient(undefined));
    setIsPatienSearchVisible(false);
  };

  const changePage = (page, searchText) => {
    let paramsName;
    let payload;
    if (searchText) {
      paramsName = "searchText";
      payload = {
        searchWord: searchText,
        page,
      };
    } else {
      paramsName = "pagination";
      payload = {
        page,
      };
    }

    patientsPaginationGetData(axios, paramsName, payload)
      .then((res) => {
        setRowData(res.data);
        setTotalCount(res.count);
        setPage(page);
      })
      .catch((err) => notificationToastError(err?.message));
  };

  const [isSelectPatient, setIsSelectPatient] = useState(false);
  return (
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      {<SearchPatient selectedPatient={selectedPatient} />}

      {!selectedPatient?.patientId && isSelectPatient && (
        <PatientSearch
          isSelectPatient={isSelectPatient}
          setIsSelectPatient={setIsSelectPatient}
        />
      )}

      {/* {selectedPatient &&
        <SelectedPatients selectedPatient={selectedPatient} unselectPatient={unselectPatient} />
      } */}

      <>
        {selectedPatient && (
          <Card sx={{ minWidth: 275, width: "100%", marginBottom: "20px" }}>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  flexDirection: isMobile ? "column" : "",
                }}
              >
                {" "}
                {privelegesArray?.privileges?.subtab?.includes("survey") && (
                  <Button variant="contained" onClick={newSurvey}>
                    New Survey
                  </Button>
                )}{" "}
                {privelegesArray?.privileges?.subtab?.includes("orders") && (
                  <Button variant="contained" onClick={newOrder}>
                    New Sleep Order
                  </Button>
                )}
                {
                  privelegesArray?.privileges?.subtab?.includes("orders") && (
                    <Button variant="contained" onClick={newDmeOrder}>
                    New DME Order
                  </Button>
                  )
                }
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  flexDirection: isMobile ? "column" : "",
                }}
              >
                {" "}
                {/* <Button variant="contained" onClick={stopBangQuestionaire}>
                Stop Bang
              </Button>{" "} */}
                {/* <Button variant="contained" onClick={berlinQuestionaire}>
                  Berlin Questionaire
                </Button>{" "}
                <Button variant="contained" onClick={satisfactionSurvey}>
                  Satisfaction Survey
                </Button>{" "}
                <Button variant="contained" onClick={qualityImprovementSurvey}>
                  Quality Improvement Survey
                </Button>{" "}
                <Button variant="contained" onClick={epworthSleepinessScale}>
                  Epworth Sleepiness Scale
                </Button> */}
              </div>
            </CardContent>
          </Card>
        )}
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              height: "90vh",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <MUIDataTable
            data={rowData}
            columns={[
              {
                name: "patientId",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "patientNum",
                label: "Patient ID",
                options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                    // console.log("tableMeta=======>",tableMeta?.rowData[0]);
                    const patientId = tableMeta.rowData[1];
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "#715d9c",
                        }}
                        onClick={() => {
                          patientView(tableMeta?.rowData);
                        }}
                      >
                        {patientId}
                      </Box>
                    );
                  },
                },
              },
              {
                name: "lastName",
                label: "Patient Name",
                options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                    const lastName = tableMeta.rowData[2];
                    const firstName = tableMeta.rowData[3];

                    return <Box>{`${lastName}, ${firstName}`}</Box>;
                  },
                },
              },
              {
                name: "firstName",
                // label: "First Name",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "dob",
                options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return <>{dayjs(value).format("MM/DD/YYYY")}</>;
                  },
                },
              },

              "gender",
              {
                name: "groupName",
                label: "Group",
              },
              {
                name: "createdAt",
                label: "Created at",
              },
              {
                name: "address1",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "address2",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "city",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "state",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "zip",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "email",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "bestPhone",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "cellPhone",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "primaryInsuranceId",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "secondaryInsuranceId",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "primaryPolicy",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "primaryGroup",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "secondaryPolicy",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "secondaryGroup",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "groupId",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "middleInitial",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "enablePortal",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "parentLastName",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "parentFirstName",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "parentMobile",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "primaryInsuranceName",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "secondaryInsuranceName",
                options: { display: false, viewColumns: false, filter: false },
              },
              //new Field
              {
                name: "age",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "race",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "heightFeet",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "heightInches",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "patWeight",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "patBMI",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "maritalStatus",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "prefLanguage",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "ssn",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "placeofemp",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "delAddress1",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "delAddress2",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "delCity",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "delState",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "delZip",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "emergencyContact",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "emerContactName",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "emerContactNum",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "tertiaryInsuranceId",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "tertiaryPolicy",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "tertiaryGroup",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "heartDisease",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "disabilities",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "specialAccomodation",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "careGiver",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "careGiverName",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "parentEmail",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "months",
                options: { display: false, viewColumns: false, filter: false },
              },//57
              {
                name: "Actions",
                label: "Actions",
                options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Button
                          variant="contained"
                          onClick={patientView.bind(null, tableMeta.rowData)}
                        >
                          View
                        </Button>
                       
               { privelegesArray?.roleId === 4 &&
                        <Button
                          sx={{ marginTop: "1rem" }}
                          variant="contained"
                          onClick={deleteRecord.bind(
                            null,
                            tableMeta.rowData ? tableMeta.rowData[0] : "",
                            "patients",
                            "patientId"
                          )}
                        >
                          Delete
                        </Button>
                  }
                      </Box>
                    );
                  },
                },
              },
            ]}
            options={{
              selectableRows: 'none', // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
              serverSide: true,
              count: totalCount,
              page: page,
              responsive: "vertical",
              // tableBodyHeight: rowData?.length && "calc(100vh - 200px)",
              onSearchChange: (searchText) => {
                if (!searchText) {
                  setRefreshData(!refreshData);
                  return;
                }

                if (searchText != 0) {
                  const paramsName = "searchText";
                  const payload = {
                    searchWord: searchText,
                  };

                  patientsPaginationGetData(axios, paramsName, payload)
                    .then((res) => {
                      setRowData(res.data);
                      setTotalCount(res.count);
                      setPage(page);
                    })
                    .catch((err) => notificationToastError(err?.message));
                }
                // fetch().then()
                // setRowData([])
              },

              onTableChange: (action, tableState) => {
                //  a developer could react to change on an action basis or examine the state as a whole and do whatever they want

                switch (action) {
                  case "changePage":
                    changePage(tableState.page, tableState.searchText);
                    break;
                  default:
                  // console.log("Action not handled.");
                }
              },
            }}
          />
        )}
      </>
      <ToastContainer />
    </Container>
  );
}
