import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";

const InterfaceSupplies = ({ updateCheckBox, dmeOrderState, errorState }) => {
  return (
    <Box
      sx={{
        border: "1px solid rgba(0,0,0, 0.12)",
        borderRadius: "4px",
        marginTop: "20px",
        padding: "16px 5px",
        height: "90%",
      }}
    >
      <Typography variant="h6" sx={{ color: errorState ? "#d32f2f" : "" }}>
        INTERFACE SUPPLIES:(Required) *
      </Typography>

      <Box
        sx={{
          margin: "10px 3px",
          padding: "5px 8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.All)}
                onChange={updateCheckBox}
                name="All"
              />
            }
            label={
              <Typography variant="h6">
                ALL ITEMS BELOW (NON-MEDICARE PATIENTS ONLY)
              </Typography>
            }
          />

          {/* <Typography variant="h6">AND</Typography> */}
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(dmeOrderState?.A7035)}
              onChange={updateCheckBox}
              name="A7035"
            />
          }
          label="HEADGEAR: 1 per 6 MO (A7035)"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
          my={3}
        >
          <Typography>PAP TUBING :</Typography>
          <Typography variant="h6">
            1 per 3 MO (PLEASE SELECT ONE BELOW)
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.A7037)}
                onChange={updateCheckBox}
                name="A7037"
              />
            }
            label="Standard: (A7037)"
          />

          <Typography variant="h6">OR</Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.A4604)}
                onChange={updateCheckBox}
                name="A4604"
              />
            }
            label="Heated (A4604)"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.E0562)}
                onChange={updateCheckBox}
                name="E0562"
              />
            }
            label="HEATED HUMIDIFIER: (E0562)"
          />

          <Typography variant="h6">AND</Typography>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(dmeOrderState?.A7046)}
              onChange={updateCheckBox}
              name="A7046"
            />
          }
          label="Chamber: 1 per 6 MO (A7046)"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.A7038)}
                onChange={updateCheckBox}
                name="A7038"
              />
            }
            label="DISPOSABLE FILTERS: 2 per MO (A7038)"
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(dmeOrderState?.A7039)}
              onChange={updateCheckBox}
              name="A7039"
            />
          }
          label="NON-DISPOSABLE FILTERS: 1 per 6 MO (A7039)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(dmeOrderState?.A9279)}
              onChange={updateCheckBox}
              name="A9279"
            />
          }
          label="MODEM: monitoring device (A9279)"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.A7036)}
                onChange={updateCheckBox}
                name="A7036"
              />
            }
            label="CHINSTRAP: 1 per 6 MO (A7036)"
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(dmeOrderState?.RT94660)}
              onChange={updateCheckBox}
              name="RT94660"
            />
          }
          label="RT INITIATION & PT EDUCATION (94660)"
        />
      </Box>
    </Box>
  );
};

export default InterfaceSupplies;
