import { createSlice } from "@reduxjs/toolkit";

export const sideBarCollapseSlice = createSlice({
  name: "sideBarCollapse",
  initialState: {
    isCollapsed:false,
    isCollapsedOnNavigate:false
  },
  reducers: {
    updateIscollapsed: (state, action) => {
      state.isCollapsed = action.payload;
    },
    updateCollapsedOnNavigate: (state, action) => {
      state.isCollapsedOnNavigate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateIscollapsed,updateCollapsedOnNavigate } = sideBarCollapseSlice.actions;

export default sideBarCollapseSlice.reducer;