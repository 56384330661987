import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { useAxios } from "../hooks/auth";
import ConfirmDialog from "../components/misc/Dialog";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../utils/notification";
import { adminPostData, patientsPostData, pulldownsPostData } from "../axios/api";
import {
  Tabs,
  useMediaQuery,
  Tab,
  FormControlLabel,
  Checkbox,
  FormControl,
  Button,
  Box,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import { facilityBasedLocationSubTab, groupBasedLocationSubTab } from "../constants/tabs";
import TabPanel from "../components/patientsComponent/TabPanel";
import LocationPulldown from "../components/misc/LocationPulldown";
import LocationView from "./LocationView";
import { Delete } from "@mui/icons-material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Documentupload from "../components/adminComponent/documents/documentUpload/Documentupload";

function GroupView({
  groupId,
  groupName,
  handleClose,
  facility,
  refreshData,
  noLoginPractice,
  groupHeaderSleepStudy,
  groupHeaderDMEOrder,
  logoFileId,
  setRefreshData = () => {},
}) {
  
  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );
  const isMobile = useMediaQuery("(max-width:800px)");
  const isSmallSizeMobile = useMediaQuery("(max-width:600px)");
  const [formState, setFormState] = useState({});
  const [formErrorState, setFormErrorState] = useState({
    groupName: "",
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [groupIdForLocationView, setGroupIdForLocationView] = useState(false);
  const [groupLocations, setGroupLocations] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);
  const [locationIndex, setLocationIndex] = useState(0);
  const [reloadOnDelete, setReloadOnDelete] = useState(false);
  const [hidePulldown, setHidePulldown] = useState(false);
  const [image, setImage] = useState(false);

  useEffect(() => {
    setFormState({
      ...formState,
      groupName: groupName,
      facility: facility,
      groupId: groupId,
      noLoginPractice: noLoginPractice,
      groupHeaderSleepStudy: groupHeaderSleepStudy,
      groupHeaderDMEOrder:groupHeaderDMEOrder,
      logoFileId:logoFileId
    });
    if(logoFileId){
      patientsPostData(axios, "downloadFile", {
        id:logoFileId, tableName: "storedFiles",idColumn: "fileId",
      }).then((imgres) => {
        if (imgres.data.file) {
          setImage(imgres.data.file)
        } else {
          notificationToastError("File was not downloaded due to issue.");
        }
      }).catch((err) => notificationToastError(err?.message))
    }
  }, [groupName]);

  const axios = useAxios();

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    if (e.target.name === "locations" || e.target.id === "locations") {
      setCurrentSelectedLocation(e.target.value);
      setDisplayValue(e.target.value);
    } else {
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: e.target.value,
      });
    }
  };

  const addGroupLocations = () => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    const tempGroupLocation = [...groupLocations];
    tempGroupLocation.push(
      locationList?.find((item) => item.locationId === currentSelectedLocation)
    );
    setFormState({
      ...formState,
      [`location${locationIndex}`]: currentSelectedLocation,
    });
    setLocationIndex(locationIndex + 1);
    setGroupLocations(tempGroupLocation);
    setDisplayValue(null);
  };

  useEffect(() => {
    if (formState.groupId && tabValue === 0) {
      const payload = {
        groupId: formState.groupId,
      };
      pulldownsPostData(axios, "locationPulldown", payload)
        .then((res) => {
          if (res?.data) {
            setGroupLocations(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [formState.groupId, tabValue]);

  useEffect(() => {
    if (
      (!formState?.groupName || formState?.groupName?.length === 0) &&
      tabValue === 1
    ) {
      setFormErrorState({ ...formErrorState, groupName: true });
      notificationToastError("Please enter group name. It is required field.");
      setTabValue(0);
    }
  }, [tabValue]);

  const saveChanges = (e) => {
    e.preventDefault();
    if (tabValue === 0) {
      let isValid = true;
      const errors = { ...formErrorState };
      for (const [key, _] of Object.entries(formErrorState)) {
        if (isEmptyField(key)) {
          isValid = false;
          errors[key] = { error: true };
        }
      }

      if (!isValid) {
        setFormErrorState(errors);
        return false;
      }
      const payload = {
        ...formState,
        groupId: groupId,
      };
      setIsSaveButtonDisabled(true);
      adminPostData(axios, "saveGroupInfo", payload, selectedPracticeId)
        .then((res) => { 
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((ex) => {
          notificationToastError(ex?.message);
        })
        .finally(() => handleClose());
    }
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (
      newValue === 1 &&
      !formState?.groupId &&
      formState?.groupName?.length > 0
    ) {
      adminPostData(axios, "saveGroupInfo", formState, selectedPracticeId)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
            setGroupIdForLocationView(res?.data?.groupId);
            setFormState({ ...formState, groupId: res?.data?.groupId });
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((ex) => {
          notificationToastError(ex?.message);
        });
    } else if (formState?.groupId) {
      setGroupIdForLocationView(formState?.groupId);
    }
  };

  const groupIndex = groupBasedLocationSubTab?.findIndex(
    (element) => element === "Add Group"
  );
  const groupBasedLocationIndex = groupBasedLocationSubTab?.findIndex(
    (element) => element === "Add New Location"
  );

  const removeGroupLocations = (locationId) => {
    setReloadOnDelete(false);
    if (!formState.groupId) {
      const tempGroupLocations = [...groupLocations];
      const findIndexOfLocation = groupLocations?.findIndex(
        (item) => item?.locationId === locationId
      );
      tempGroupLocations.splice(findIndexOfLocation, 1);
      setGroupLocations(tempGroupLocations);

      const newObj = {};
      for (const key in formState) {
        if (
          !key.startsWith("location") &&
          !tempGroupLocations.some(
            (item) => `location${item?.locationId}` === key
          )
        ) {
          newObj[key] = formState[key];
        }
      }
      tempGroupLocations.forEach((item, index) => {
        newObj[`location${index}`] = item?.locationId;
      });
      setFormState(newObj);
    } else {
      const payload = {
        groupId: formState.groupId,
        locationId: locationId,
      };
      adminPostData(axios, "deleteLocation", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
            setRefreshData(!refreshData);
            const tempGroupLocations = [...groupLocations];
            const findIndexOfLocation = groupLocations?.findIndex(
              (item) => item?.locationId === locationId
            );
            tempGroupLocations.splice(findIndexOfLocation, 1);
            setGroupLocations(tempGroupLocations);
            setReloadOnDelete(true);
            setHidePulldown(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: Number(e.target.checked),
    });
    setIsSaveButtonDisabled(false);
  };

  const handleBackBtnClick = () => {
    setTabValue(0);
  };

  const tabArr = formState.facility == 0 ? groupBasedLocationSubTab : facilityBasedLocationSubTab
  const groupFacility = formState.facility == 0 ? "Non-Login Group" : "Non-Login Facility"
  const groupSleepStudyHeader = formState.facility == 0 ? "Use Group Header for Sleep Study Order" : "Use Facility Header for Sleep Study Order"
  const groupDmeHeader = formState.facility == 0 ? "Use Group Header for DME Order" : "Use Facility Header for DME Order"

  return (
    <ConfirmDialog
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "Save changes" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
      minwidth="800px"
      tabValue={tabValue}
    >
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        centered
        visibleScrollbar={false}
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons={isMobile ? true : false}
        allowScrollButtonsMobile={isMobile ? true : false}
        sx={{
          ".MuiTabs-flexContainer": {
            justifyContent: "flex-start",
          },
        }}
      >
        {tabArr?.map((item, index) => {
          return <Tab key={item} label={item} index={index} />;
        })}
      </Tabs>
      <TabPanel value={tabValue} index={groupIndex}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              sx={{ width: "100%", marginTop: "15px" }}
              id="groupName"
              label={formState.facility == 0 ? "Group *" : "Facility *"}
              value={formState.groupName}
              variant="standard"
              onChange={updateInput}
              error={formErrorState.groupName?.error && !formState.groupName}
              helperText={
                formErrorState.groupName?.error && !formState.groupName
                  ? "required"
                  : ""
              }
              onBlur={validateEmptyField.bind(null, "groupName")}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl sx={{ mt: 1 }} >
              <FormControlLabel
                control={
                  <Checkbox
                  id="noLoginPractice"
                  checked={Boolean(formState.noLoginPractice)}
                  onChange={updateCheckBox}
                  />
                }
                label={groupFacility}
              />
            </FormControl>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl sx={{ mt: 1 }} >
              <FormControlLabel
                control={
                  <Checkbox
                  id="groupHeaderSleepStudy"
                  checked={Boolean(formState.groupHeaderSleepStudy)}
                  onChange={updateCheckBox}
                  />
                }
                label={groupSleepStudyHeader}
              />
            </FormControl>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl sx={{ mt: 1 }} >
              <FormControlLabel
                control={
                  <Checkbox
                  id="groupHeaderDMEOrder"
                  checked={Boolean(formState.groupHeaderDMEOrder)}
                  onChange={updateCheckBox}
                  />
                }
                label={groupDmeHeader}
              />
            </FormControl>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
            <Documentupload
              data-testid="document-upload" 
              setFormState={setFormState}
              setIsSaveButtonDisabled={setIsSaveButtonDisabled}
              formErrorState={formErrorState}
              setFormErrorState={setFormErrorState}
              formState={formState}
              title="Upload Logo"
            />
          </Grid>
          {
            image ? <Box>
              <img src={image} alt="logo" height={150} width={700} />
            </Box> : ""
          }
         
          {!hidePulldown && (
            <>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{marginTop:2}}>
                <LocationPulldown
                  formState={formState}
                  updateInput={updateInput}
                  setLocationList={setLocationList}
                  formErrorState={formErrorState}
                  attributeName="locations"
                  selectedLocationAttributeNames={ locationList
                    .filter((item) =>
                      groupLocations.some(
                        (item1) => item1?.locationId === item?.locationId
                      )
                    )
                    .map((itm) => {
                      return itm.location;
                    })}
                  view={true}
                  displayValue={displayValue}
                  reloadOnDelete={reloadOnDelete}
                  setHidePulldown={setHidePulldown}
                  style={{ maxWidth: isSmallSizeMobile ? "100%" : "500px" }}
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                sx={{ pt: isSmallSizeMobile ? "10px" : 0, marginTop: 2 }}
              >
                <Button
                  onClick={addGroupLocations}
                  variant="outlined"
                  sx={{
                    marginRight: "1rem",
                    height: "fit-content",
                    marginTop: isSmallSizeMobile ? 0 : "7px ",
                  }}
                >
                  Add Location
                </Button>
              </Grid>
            </>
          )}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={4} mb={2}>
            <Typography variant="h4">Locations:</Typography>
          </Grid>
          {groupLocations?.map((item) => {
            return (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                my={0.5}
                key={item?.locationId}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Typography variant="body1" sx={{ width: "30%" }}>
                    {item?.location}
                  </Typography>
                  <Tooltip title="Delete" placement="bottom">
                    <Button
                      sx={{ width: 60 }}
                      variant="contained"
                      size="small"
                      onClick={() => removeGroupLocations(item?.locationId)}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </TabPanel>

      <TabPanel value={tabValue} index={groupBasedLocationIndex}>
        <Button startIcon={<KeyboardReturnIcon />} onClick={handleBackBtnClick}>
          Back 
        </Button>
        <LocationView
          type="dialog"
          handleClose={handleClose}
          groupIdForLocationView={groupIdForLocationView}
          setTabValue={setTabValue}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          closeDialog={true}
        />
      </TabPanel>
    </ConfirmDialog>
  );
}

export default GroupView;
