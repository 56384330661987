export const tab = [{ tabName: "dashboard", path: "/dashboard" },
{ tabName: "patients", path: "/patients" },
{ tabName: "reports", path: "/reports" },
{ tabName: "admin", path: "/admin" },
{ tabName: "profSettings", path: "/profile" },]
export const subTab = ["locations", "users", "pracGroups","facilities", "roleAccess", "survey", "orders", "signOrder", "insurances",'documents','reports','incomingsms']
export const priviledgesSubTab = [
    { privileges: 'users', subTab: "Users" },
    { privileges: 'pracGroups', subTab: "Groups" },
    { privileges: 'facilities', subTab: "Facilities" },
    { privileges: 'locations', subTab: "Locations" },
    { privileges: 'roleAccess', subTab: "Roles" },
    { privileges: 'insurances', subTab: "Insurance Carrier" },
    { privileges: 'documents', subTab: 'Documents' },
    { privileges: 'reports', subTab: 'Reports' },
    { privileges: 'incomingsms', subTab: 'Incoming SMS' }
];

export const adminSubPath = ["/user-view", "/location-view", "/insurance-view", "/admin"]
export const patientsSubTab = ["/patients", "/new-survey", "/patient-view", "/new-order", "/stop-bang", "/berlin-questionaire",
 "/satisfaction-survey", "/quality-improvement-survey", "/epworth-sleepiness-scale","/order-view","/survey-view"]

 export const roles = {
    PHYSICIAN : "Physician"
 }
export const groupBasedLocationSubTab = ["Add Group", "Add New Location"]
export const facilityBasedLocationSubTab = ["Add Facility", "Add New Location"]