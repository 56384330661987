import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import { Button, FormControl, FormControlLabel, Checkbox,Tooltip, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { patientsPostData, studyPostData } from "../../axios/api";
import ScheduleStudyDialog from "./ScheduleStudyDialog";
import ScoringTechPulldown from "./ScoringTechPulldown";
import { useDispatch } from "react-redux";
import { setSnackbarMessage } from "../../store/slices/snackBarSlice";
import ReschedulePopup from "./ReschedulePopup";
import StudyTypePulldown from "./StudyTypePulldown";
import Documentupload from "../adminComponent/documents/documentUpload/Documentupload";
import classes from "../adminComponent/documents/documentDialog/DocumentDialog.module.css";
import FileView from "../../pages/patients/components/FileUpload/FileView";
import { Download, Visibility } from "@mui/icons-material";
import { Buffer } from "buffer";

export default function ScoreStudyDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  scoreStudyData = {},
  notShowPopup,
  isCurrentVisits = false,
  reloadCurrentVisit,
  isWellnecessitiesUser
}) {
  const [formErrorState, setFormErrorState] = useState({
    SHIP: "",
    SSIP: "",
  });
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  // console.log("row = " , row);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" })

  React.useEffect(() => {
    if (row?.studyIdStudy) {
      setFormState({
        physicianId: row?.physicianIdStudy,
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        //  studyTypeId: row[100],
        locationId: row?.locationIdStudy,
        SHIP: row?.shipDate,
        SSIP: row?.sentForInterpDate,

        studyId: row?.studyIdStudy,
        scoringTechId: row?.scoringTechId,

        statusNameId: row?.statusNameId,
        studyCompletedNotes: row?.studyCompletedNotes,
        studyInfoConfirmed: row?.studyInfoConfirmed,
        hstDeviceId: row?.hstDeviceId,
        hstDeviceIdNight2: row?.hstDeviceIdNight2,
        hstDeviceIdNight3: row?.hstDeviceIdNight3,
        orderingPhysicianName: row?.name,
        patientName: row?.patientName,
        studyTypeCompletedId: row?.studyTypeCompletedId,
        scheduleSecondAppt: row?.scheduleSecondAppt,
        dob: row?.dob
      });
    } else if (scoreStudyData) {
      setFormState({ ...formState, ...scoreStudyData, dob: scoreStudyData?.dob});
      setSHIP(scoreStudyData?.SHIP);
      setSSIP(scoreStudyData?.SSIP);
    }
  }, [row]);

  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
    setIsSaveButtonDisabled(false);
  };

  /* React.useEffect(() => {
    if (formState?.scheduleSecondAppt == 1) {
      setViewScheduleStudyDialogState(true);
    }
  }, [formState?.scheduleSecondAppt]);
*/
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(false);

  const [viewScheduleStudyState, setViewScheduleStudyDialogState] =
    useState(undefined);

  const scheduleStudyDialog = () => {
    setViewScheduleStudyDialogState(false);
  };

  const scheduleStudy = (rowData) => {};

  const openScheduleStudyDialog = (rowData) => {
    // autoSelectPatient(rowData[0]);
    setViewScheduleStudyDialogState(rowData);
  };

  React.useEffect(() => {
  }, [formState]);

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
    setIsSaveButtonDisabled(false);
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };

    if (!SSIP) {
      setErrorSSIP(true);
      return false;
    }

    if (!SHIP) {
      setErrorSHIP(true);
      return false;
    }

    if (formState?.scheduleSecondAppt === 1 && !formState?.studyTypeId) {
      notificationToastError("Please Select Study Type ");
      isValid = false;
    }

    if (!isValid) {
      setErrorMessage("Please fill all required fields");
      setFormErrorState({ ...formErrorState });
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      scoringDate: formState?.SHIP,
      sentForInterpDate: formState?.SSIP,
      scheduleSecondAppt: formState?.scheduleSecondAppt,
    };

    studyPostData(axios, "updateScoreStudyInfo", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
    if (reloadCurrentVisit) reloadCurrentVisit(payload);
    
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);

  const updateSHIP = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSHIP(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, SHIP: newValue.format("YYYY-MM-DD") });
  };

  const handleMainCheckboxChange = (event) => {
    const checkboxId = event.target.id;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const [SSIP, setSSIP] = React.useState(null);
  const [errorSSIP, setErrorSSIP] = React.useState(false);

  const updateSSIP = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSSIP(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, SSIP: newValue.format("YYYY-MM-DD") });
  };

  const patientNameVal = row?.patientName ?? formState?.patientNameVal;

  // download and view file

  const base64ToPdf = (base64String, fileType) => {
    let typeFile = fileType === "pdf" ? "application/pdf" : "image/png"
    const buffer = Buffer.from(base64String, "base64");
    const url = URL.createObjectURL(
      new Blob([buffer], { type: typeFile })
    );
    return url;
  };

  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    }
    patientsPostData(axios, "downloadFile", payload).then((res) => {
      if (res.data.file) {
        if (isView === "view") {
          setOpenPdfModal(true)
          let base64 = res?.data?.file;
          base64 = base64?.split(',')[1]
          let fileType = res?.data?.name;
          fileType = fileType?.split('.')?.pop();
          const pdfUrl = base64ToPdf(base64, fileType);
          setPdfURLState({
            fileUrl: pdfUrl,
            fileType
          })
        } else {
          notificationToastSuccess("File is downloading.");
          const downloadLink = document.createElement("a");
          downloadLink.href = res.data.file;
          downloadLink.download = res.data.name;
          downloadLink.click();
        }

      } else {
        notificationToastError("File is not uploaded yet to download.");
      }
      // setRefreshData(!refreshData);
    }).catch((err) => notificationToastError(err?.message))


  };

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <Box>
        <div>
          <FormControl sx={{ m: "8px 0px" }}>
            <b>Score Study Info: </b>
          </FormControl>
        </div>

        {!notShowPopup && <ReschedulePopup row={row} dob={formState.dob} dos = "Actual DOS:"/>}

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            marginTop: 2,
          }}
        >
          <ScoringTechPulldown
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            attributeName="scoringTechId"
            isPreviousVisits={isPreviousVisits}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            marginTop: 1,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date Data Sent for Scoring *"
              inputFormat="MM/DD/YYYY"
              value={SHIP || formState.SHIP || ""}
              onChange={updateSHIP}
              readOnly={isPreviousVisits}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={errorSHIP && !SHIP}
                  helperText={errorSHIP && !SHIP ? "required" : ""}
                  value={
                    SHIP ? moment.utc(SHIP.toLocaleString()).format("MM/DD/YYYY") : ""
                  }
                  onBlur={() => {
                    setErrorSHIP(!SHIP);
                  }}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                  placeholder="mm/dd/yyyy"
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            marginTop: 1,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date Study Sent for Interpretation * "
              inputFormat="MM/DD/YYYY"
              value={SSIP || formState.SSIP || ""}
              onChange={updateSSIP}
              readOnly={isPreviousVisits}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={errorSSIP && !SSIP}
                  helperText={errorSSIP && !SSIP ? "required" : ""}
                  value={
                    SSIP ? moment.utc(SSIP.toLocaleString()).format("MM/DD/YYYY") : ""
                  }
                  onBlur={() => {
                    setErrorSSIP(!SSIP);
                  }}
                  inputProps={{ readOnly: isPreviousVisits }}
                  onMouseDown={handleFocus}
                  placeholder="mm/dd/yyyy"
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
       {(isPreviousVisits || isCurrentVisits) ? 
          <Box
            className={classes.documentWrapper}
            sx={{ marginTop: 2 }}
          >
            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
                alignItems: "center",
                gap: 1
              }}
            >
              <Typography variant="strong">Preliminary report: </Typography>

              <Tooltip title="View" placement="bottom">
                <Button
                  sx={{ width: 60 }}
                  variant="contained"
                  size="small"
                  onClick={download.bind(
                    null,
                    formState.prelimFileId,
                    "storedFiles",
                    "fileId",
                    "view"
                  )}
                >
                  <Visibility />
                </Button>
              </Tooltip>

              <Tooltip title="Download" placement="bottom">
                <Button
                  sx={{ width: 60}}
                  variant="contained"
                  size="small"
                  onClick={download.bind(
                    null,
                    formState.prelimFileId,
                    "storedFiles",
                    "fileId"
                )}
                >
                  <Download />
                </Button>
              </Tooltip>
            </Box>
          </Box>
       :
        <Box className={classes.documentWrapper} sx={{ marginTop: 2 }}>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
              marginTop: 2,
            }}
          >
            Upload Preliminary Report
            <Documentupload
              data-testid="document-upload" // Add Cypress ID to the Documentupload component
              setFormState={setFormState}
              setIsSaveButtonDisabled={setIsSaveButtonDisabled}
              formErrorState={formErrorState}
              setFormErrorState={setFormErrorState}
              formState={formState}
            />
          </Box>
        </Box>}

        {
          openPdfModal &&
          <FileView setOpenPdfModal={setOpenPdfModal} openPdfModal={openPdfModal} pdfURLState={pdfURLState} />
        }
        {/* <>
          <FormControl sx={{ marginTop: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="scheduleSecondAppt"
                  checked={Boolean(formState?.scheduleSecondAppt)}
                  onChange={updateCheckBox}
                  disabled={isPreviousVisits}
                />
              }
              label="Schedule Second Appointment"
            />
          </FormControl>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
              marginTop: 1,
            }}
          >
            {formState?.scheduleSecondAppt === 1 && (
              <StudyTypePulldown
                key="studyTypeId"
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                attributeName="studyTypeId"
                style={{ maxWidth: "500px" }}
              />
            )}
          </Box>
        </> */}
      </Box>
    );
  }
}
