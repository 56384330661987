import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../components/misc/Dialog";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

import StudyTypePulldown from "../../components/misc/StudyTypePulldown";
import { studyPostData } from "../../axios/api";
import { pulldownsPostData } from "../../axios/api";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

export default function TreatmentScheduleStudyDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess = () => {},
  notificationToastError = () => {},
  asDialog = true,
  isPreviousVisits = false,
  treatmentScheduleStudyData = {},
  isPatientPortal 
}) {
  const [formErrorState, setFormErrorState] = useState({
    studyTypeId: "",
    SDATE: "",
    CDATE: "",
  
  });

  const [selectedDate, handleDateChange] = useState(new Date());

  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";

  React.useEffect(() => {
    if (row) {
      const newFormState = {
        physicianId: row?.physicianIdStudy,
        patientId: row?.patientId,
        surveyId: row?.surveyIdSurvey,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        orderingPhysicianName: row?.name,
        prefInterpPhysId: row?.prefInterpPhysId,
        time: row?.time,
        dmeOrderId: row?.dmeOrderIdOrder,
      };
      setFormState(newFormState);
      if (newFormState?.studyTypeId) ScheduleLocation(newFormState);
    } else if (treatmentScheduleStudyData) {
      // console.log("treatmentScheduleStudyData=====>", treatmentScheduleStudyData);
      setFormState({ ...formState, ...treatmentScheduleStudyData });
      setSDATE(treatmentScheduleStudyData?.studyDate);
      setCDATE(treatmentScheduleStudyData?.contactedPatientDate);
      if (treatmentScheduleStudyData?.studyTypeId) ScheduleLocation(treatmentScheduleStudyData);
    }
  }, [row]);

  const [locations, setLocations] = useState([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    const newFormState = {
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    };
    setFormState(newFormState);

    if (newFormState.studyTypeId && newFormState.prefInterpPhysId)
      ScheduleLocation(newFormState);
  };
  function ScheduleLocation(payload) {
    pulldownsPostData(axios, "scheduleLocationPulldown", payload)
      .then((res) => {
        if (res && res.data) setLocations(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };

    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }

    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      date: SDATE,
      contactedPatientDate: CDATE,
      
    };
    studyPostData(axios, "saveTreatmentStudy", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [CDATE, setCDATE] = React.useState(null);
  const [errorCDATE, setErrorCDATE] = React.useState(false);
  const updateCDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setCDATE(newValue.format("YYYY-MM-DD"));
    setFormState({ ...formState, CDATE: newValue.format("YYYY-MM-DD") });
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSDATE(newValue.format("YYYY-MM-DD"));
    setFormState({ ...formState, SDATE: newValue.format("YYYY-MM-DD") });
  };

  const updateTime = (newValue) => {
    setFormState({ ...formState, time: newValue.format("hh:mm a") });
  };

  let patientNameVal;
  if (row) {
    patientNameVal = row?.patientName;
  }
  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <div data-testid="schedule-study-data">
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6" data-testid="appoinment-text">
              {" "}
              {!asDialog
                ? "Appointment Info :"
                : "Please schedule in treatment appointment to the patient and enter the appointment information below: "}
            </Typography>
          </FormControl>
        </div>
        {asDialog && (
          <TextField
            id="patientName"
            label="Patient Name"
            variant="standard"
            value={patientNameVal}
            readOnly
            InputProps={{ disableUnderline: true }}
          />
        )}

        <Box
          component="div"
          sx={{
            // "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            margin: "8px 0px",
          }}
        >
           <FormControl variant="standard" sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Contacted patient On: *"
                inputFormat="MM/DD/YYYY"
                className="study-date-data"
                value={CDATE || ""}
                onChange={updateCDATE}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                  // data-testid="study-date-data"
                    {...params}
                    variant="standard"
                    error={formErrorState.CDATE?.error && !CDATE}
                    helperText={
                      formErrorState.CDATE?.error && !CDATE ? "required" : ""
                    }
                    value={
                      CDATE ? moment.utc(CDATE.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    onBlur={() => {
                      setErrorCDATE(!CDATE);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl variant="standard" sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Study Date *"
                inputFormat="MM/DD/YYYY"
                className="study-date-data"
                value={SDATE || ""}
                onChange={updateSDATE}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                  // data-testid="study-date-data"
                    {...params}
                    variant="standard"
                    error={formErrorState.SDATE?.error && !SDATE}
                    helperText={
                      formErrorState.SDATE?.error && !SDATE ? "required" : ""
                    }
                    value={
                      SDATE ? moment.utc(SDATE.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    onBlur={() => {
                      setErrorSDATE(!SDATE);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
         
        </Box>
        <StudyTypePulldown
          key="studyTypeId"
          isPreviousVisits={isPreviousVisits}
          formState={formState}
          updateInput={updateInput}
          formErrorState={formErrorState}
          attributeName="studyTypeId"
          style={{ width: "100%" }}
          isPatientPortal={isPatientPortal}
        />


        {!asDialog && !isPreviousVisits && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              disabled={isSaveButtonDisabled}
              sx={{ margin: "1rem 0rem" }}
              onClick={saveChanges}
              data-testid="schedule-save-Button"
            >
              Save Changes
            </Button>
          </div>
        )}
      </div>
    );
  }
}
