import * as yup from "yup";
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
const messages = {
    required: "Required",
    passwordRegx: "Password should be at least one letter, one number and one special character (total 8 characters)",
    comparePassword: "New password and confirm password are not match"
}
// password
export const updatePasswordSchema = (isPatientPortal) => {
    return yup.object({
    //  oldPassword: isPatientPortal ?  yup.string()
    //  .required(messages["required"]): yup.string()
     //   .required(messages["required"]).matches(passwordRegex, messages["passwordRegx"]),
      newPassword: yup.string()
        .required(messages["required"]).matches(passwordRegex, messages["passwordRegx"]),
      confirmNewPassword: yup.string()
        .required(messages["required"]).matches(passwordRegex, messages["passwordRegx"])
        .oneOf([yup.ref('newPassword'), null], messages["comparePassword"]),
    });
  };
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const usMobileRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
const pinRegex = /^\d{4}$/;
const pinMessage = "Pin  should be 4 digits";

// email and mobile
export const emailAndMobileSchema = yup.object({
    receiveEmailNotifications: yup.boolean(),
    email: yup.string().when("receiveEmailNotifications", {
        is: true,
        then: yup.string().required(messages["required"])
            .matches(emailRegex,
                "Please enter valid email"
            ),
            otherwise: yup.string().matches(emailRegex,
                "Please enter valid email"),

    }),
    receiveSMSNotifications: yup.boolean(),
    notificationContactNumber: yup.string().when("receiveSMSNotifications", {
        is: true,
        then: yup.string().required(messages["required"]).matches(usMobileRegex,
            "Phone number should be 10 digits"),
        otherwise: yup.string().matches(usMobileRegex,
            "Phone number should be 10 digits"),
    })
})

// pin 

export const updatePinSchema = yup.object({
    oldPin: yup.string()
        .required(messages["required"]).matches(pinRegex,
            pinMessage
        ),
    newPin: yup.string()
        .required(messages["required"]).matches(
            pinRegex,
            pinMessage
        ),
    confirmPin: yup.string()
        .required(messages["required"]).matches(
            pinRegex,
            pinMessage
        ).oneOf([yup.ref('newPin'), null], "New pin and confirm pin are not match"),
})
export const inssuranceRulesSchema = yup.object({
    epworthRequired: yup.string()
        .required(messages["required"]).matches(pinRegex,
            pinMessage
        ),
        inLabAuthRequired: yup.string()
        .required(messages["required"]).matches(
            pinRegex,
            pinMessage
        ),
})

export const contactUsSchema = yup.object({
    subject: yup.string().required(messages["required"]),
    message: yup.string().required(messages["required"])

})