import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";

function RolePulldown({ formState, updateInput, updateRole, formErrorState }) {
  const [roles, setRoles] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    async function setDataFirstTime() {
      pulldownsPostData(axios, "rolePulldown")
        .then((res) => {
          setRoles(res.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    setDataFirstTime();
  }, []);

  return (
    <>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel
          id="role-label"
          error={formErrorState?.roleId?.error && !formState?.roleId}
        >
          Select Role *
        </InputLabel>
        <Select
          labelId="role-label"
          id="roleId"
          name="roleId"
          value={formState.roleId || ""}
          label="role"
          onChange={(e) => {
            if (updateInput)
              updateInput(e);
            else updateRole({
              target: {
                id: e.target.id,
                name: e.target.name,
                value: roles.find(role=>role.roleId===e.target.value) ,
              },
            });
          }}
          error={formErrorState?.roleId?.error && !formState.roleId}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {roles.map((row) => (
            <MenuItem value={row.roleId} key={row.roleId}>{row.role}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default RolePulldown;
