import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../hooks/auth";
import { useAxios } from "../hooks/auth";
import { updateSelectedPatient } from "../store/slices/selectedPatientSlice";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import MUIDataTable from "mui-datatables";

import dayjs from "dayjs";
import { notificationToastError } from "../utils/notification";
import { patientsPostData } from "../axios/api";
import { Typography, useMediaQuery } from "@mui/material";
import { dateConverter } from "../utils/dateConverter";

export function PatientSearch({ setIsSelectPatient, isSelectPatient }) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();
  const axios = useAxios();
  const [isUserSearch, setIsUserSearch] = useState(false)

  const handleClose = () => {
    setIsSelectPatient(false);
    navigate("/patients");
  };

  function searchPatient() {
    const payload = {
      patientNum: formState.patientNum,
      firstName: formState.firstName,
      lastName: formState.lastName,
      dob: DOB?.format("YYYY-MM-DD"),
    };
    patientsPostData(axios, "searchPatient", payload)
      .then((res) => {
        if (res.data) {
          setRowData(res.data);
          setIsUserSearch(true)
        }
      })
      .catch((err) => notificationToastError(err.message));
  }
  const dispatch = useDispatch();
  const [DOB, setDOB] = React.useState(null);

  const addPatient = () => {
    const date = new Date(DOB)
    navigate("/patient-view", { state: ((formState.firstName ||  formState?.lastName || DOB  ) && isUserSearch && !(formState.firstName &&  formState?.lastName && DOB && rowData?.length)) && { searchPatientInfo: { ...formState, dob: DOB && dateConverter(date), addNewPatient : true }} });
  };

  const updateDOB = (newValue) => {
    setDOB(newValue);
  };

  const [rowData, setRowData] = useState([]);
  const [formState, setFormState] = useState({});
  const updateInput = (e) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
  };
  return (
    <Dialog
      open={isSelectPatient}
      handleClose
      sx={{
        "& .MuiDialog-paper": {
          minWidth: isMobile ? "0px" : "700px",
          minHeight: isMobile ? "0px" : "300px",
        },
        "& .MuiDialogContent-root": {
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle textAlign="center">
        <Typography variant="h6">Search and Select Patient</Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: isMobile ? "grid" : "flex",
              gridTemplateColumns: isMobile ? "1fr 1fr" : "0fr 0fr",
              gap: "1rem",
            }}
          >
            <TextField
              id="lastName"
              label="Last Name:"
              value={formState.lastName}
              variant="standard"
              onChange={updateInput}
            />{" "}
            <TextField
              id="firstName"
              label="First Name:"
              variant="standard"
              onChange={updateInput}
            />
            <TextField
              id="patientNum"
              label="Patient ID:"
              sx={{
                maxWidth: "5rem",
              }}
              value={formState.patientNum}
              variant="standard"
              onChange={updateInput}
            />{" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="DOB:"
                inputFormat="MM/DD/YYYY"
                value={DOB}
                onChange={updateDOB}
                renderInput={(params) => <TextField {...params} variant="standard" />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box sx={{ marginTop: "1rem" }}>
          <MUIDataTable
            data={rowData}
            columns={[
              {
                name: "patientId",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "patientNum",
                label: "Patient ID",
              },
              {
                name: "lastName",
                label: "Last name",
              },
              {
                name: "firstName",
                label: "First Name",
              },
              {
                name: "dob",
                label: "DOB",
                options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return <>{dayjs(value).format("MM/DD/YYYY")}</>;
                  },
                },
              },

              {
                name: "createdAt",
                label: "Created at",
              },
              {
                name: "middleInitial",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "gender",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "email",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "bestPhone",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "cellPhone",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "primaryInsuranceId",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "secondaryInsuranceId",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "primaryPolicy",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "primaryGroup",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "secondaryPolicy",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "secondaryGroup",
                options: { display: false, viewColumns: false, filter: false },
              },
              {
                name: "address1",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "address2",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "city",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "state",
                options: { display: false, viewColumns: false, filter: false },
              },

              {
                name: "zip",
                options: { display: false, viewColumns: false, filter: false },
              },
            ]}
            options={{
              responsive: "vertical",
              download: false,
              filter: false,
              print: false,
              search: false,
              viewColumns: false,
              selectableRows: 'none', // <===== will turn off checkboxes in rows
              onRowClick: (row) => {
                const data = [...row];
                data[4] = data[4]?.props?.children;
          
                dispatch(
                  updateSelectedPatient({
                    firstName: data[3],
                    lastName: data[2],
                    patientId: data[0],
                    patientNum: data[1],
                    dob: data[4],
                    middleInitial: data[6],
                    gender: data[7],
                    email: data[8],
                    bestPhone: data[9],
                    cellPhone: data[10],
                    primaryInsuranceId: data[11],
                    secondaryInsuranceId: data[12],
                    primaryPolicy: data[13],
                    primaryGroup: data[14],
                    secondaryPolicy: data[15],
                    secondaryGroup: data[16],
                    address1: data[17],
                    address2: data[18],
                    city: data[19],
                    state: data[20],
                    zip: data[21],
                  })
                );
                setIsSelectPatient(false)
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {
          //<Button onClick={handleClose}>Cancel</Button>
        }
        <Button variant="contained" onClick={searchPatient}>
          Search
        </Button>

        <Button variant="contained" sx={{minWidth:"110px"}} onClick={addPatient}>
          Add Patient
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PatientSearch;
