import React, { useEffect, useState } from 'react'
import Box from "@mui/material/Box";
import moment from "moment";
import {
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    Checkbox,
    useMediaQuery,
} from "../../../../node_modules/@mui/material/index";
import { TextField } from '@mui/material';
import GroupPulldown from '../../../components/misc/GroupPulldown';
import { Typography } from '@mui/material';
import { FormControl } from '@mui/material';
import PhysicianPulldown from '../../../components/misc/PhysicianPulldown';
import PrefInterpPhysicianPulldown from '../../../components/misc/PrefInterpPhysicianPulldown';
import { orderPostData, pulldownsPostData } from '../../../axios/api';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { InputLabel } from '@mui/material';
import { useAxios } from '../../../hooks/auth';
import Documentupload from '../../../components/adminComponent/documents/documentUpload/Documentupload';
import { Button } from '@mui/material';
import { notificationToastError, notificationToastSuccess } from '../../../utils/notification';
import { useNavigate } from 'react-router-dom';
import { isCheckOneFaceToFaceNote,isChooseISDDiagnosis } from '../../../utils/signOrderFnc';
import DateToVisit from './Order/DateToVisit';

const OrderInfo = ({
    formState,
    updateInput,
    isReadOnly,
    formErrorState,
    setFormErrorState,
    handleFocus = () => { },
    setFormState = () => { },
    setIsSaveButtonDisabled = () => { },
    isSaveButtonDisabled,
    selectedCheckbox,
    setSelectedCheckbox,
    validateEmptyField,
    locations,
    setLocations,
    isDashboard,
    fromPatientView = false,
    latestVisitFlag
}) => {
    const [isSignOrderDisable, setIsSignOrderDisable] = useState(true);
    const axios = useAxios();
    const navigate = useNavigate();
    const [visitDateState, setVisitDate] = useState(new Date());

    const isMobile = useMediaQuery("(max-width:800px)");

    React.useEffect(() => {
        if (formState?.physicianId) {
         pulldownsPostData(axios, "prefInterpPhysician", {
        physicianId: formState?.physicianId
      })
        .then((res) => {
          if(res.data){
            setFormState((prevState) => ({
                ...prevState,
              prefInterpPhysId: res?.data[0]?.userId,
            }));

          }
        })
        .catch((err) => {
          console.log(err);
        });
     }

    },[formState?.physicianId] )

    React.useEffect(() => {
       
        if (formState?.orderId && !fromPatientView) {
            orderPostData(axios, "viewOrder", { orderId: formState?.orderId })
                .then((res) => {
                    if (res?.data?.length) {
                        const orderDetails = res?.data[0];
                        console.log('orderDetails', orderDetails)
                        setFormState((prevState) => ({
                            ...prevState,
                            ...orderDetails,
                        }));
                        setVisitDate(orderDetails.visitDate)

                        let selectedStudyType = "";
                        if (orderDetails["2nightProtocol"]) {
                            selectedStudyType = "2nightProtocol";
                        } else if (orderDetails["splitNightProtocol"]) {
                            selectedStudyType = "splitNightProtocol";
                        } else if (orderDetails["psgBaselineStudy"]) {
                            selectedStudyType = "psgBaselineStudy";
                        } else if (orderDetails["homeSleepTest"]) {
                            selectedStudyType = "homeSleepTest";
                        } else if (orderDetails["cpapTitration"]) {
                            selectedStudyType = "cpapTitration";
                        } else if (orderDetails["bipapTitration"]) {
                            selectedStudyType = "bipapTitration";
                        } else if (orderDetails["bipapStTitration"]) {
                            selectedStudyType = "bipapStTitration";
                        } else if (orderDetails["AsvTitration"]) {
                            selectedStudyType = "AsvTitration";
                        } else if (orderDetails["AvapsTitration"]) {
                            selectedStudyType = "AvapsTitration";
                        } else if (orderDetails["pediatricSleepStudy"]) {
                            selectedStudyType = "pediatricSleepStudy";
                        }
                        if (selectedStudyType) {
                            setSelectedCheckbox(selectedStudyType);
                        }
                    }
                })
                .catch((err) => { });
        }
    }, [formState?.orderId]);

  

    const handleCheckboxChange = (event) => {
        const checkboxId = event.target.id;
        if (event.target.checked) {
            setSelectedCheckbox(checkboxId);
        } else {
            setSelectedCheckbox("");
        }
        if (checkboxId === "2nightProtocol") {
            setFormState({
                ...formState,
                ["2nightHSTSubstitute"]: event.target.checked ? Number(true) : Number(false),
                splitHSTSubstitute: Number(false),
                psgOptions: "",
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
            });
        } else if (checkboxId === "splitNightProtocol") {
            setFormState({
                ...formState,
                splitHSTSubstitute: event.target.checked ? Number(true) : Number(false),
                ["2nightHSTSubstitute"]: Number(false),
                psgOptions: "",
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
            });
        } else if (checkboxId === "psgBaselineStudy") {
            setFormState({
                ...formState,
                psgOptions: event.target.checked ?
                    "SUBSTITUTE HST if in-lab disqualified by insurance or denied OR," : "",
                ["2nightHSTSubstitute"]: Number(false),
                splitHSTSubstitute: Number(false),
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
            });
        } else if (checkboxId === "homeSleepTest") {
            setFormState({
                ...formState,
                hstNights: event.target.checked ? "TWO NIGHT" : "",
                ["2nightHSTSubstitute"]: Number(false),
                splitHSTSubstitute: Number(false),
                psgOptions: "",
                cpapIfHstAbnormal: event.target.checked ? Number(true) : Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
            });
        } else if (checkboxId === "cpapTitration") {
            setFormState({
                ...formState,
                ["2nightHSTSubstitute"]: Number(false),
                splitHSTSubstitute: Number(false),
                psgOptions: "",
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
                cpapSubstitute: event.target.checked ? formState.cpapSubstitute : ""
            });
        } else if (checkboxId === "bipapTitration") {
            setFormState({
                ...formState,
                ["2nightHSTSubstitute"]: Number(false),
                splitHSTSubstitute: Number(false),
                psgOptions: "",
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
            });
        } else if (checkboxId === "bipapStTitration") {
            setFormState({
                ...formState,
                ["2nightHSTSubstitute"]: Number(false),
                splitHSTSubstitute: Number(false),
                psgOptions: "",
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
            });
        } else if (checkboxId === "AsvTitration") {
            setFormState({
                ...formState,
                ["2nightHSTSubstitute"]: Number(false),
                splitHSTSubstitute: Number(false),
                psgOptions: "",
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
            });
        } else if (checkboxId === "AvapsTitration") {
            setFormState({
                ...formState,
                ["2nightHSTSubstitute"]: Number(false),
                splitHSTSubstitute: Number(false),
                psgOptions: "",
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
            });
        } else if (checkboxId === "pediatricSleepStudy") {
            setFormState({
                ...formState,
                ["2nightHSTSubstitute"]: Number(false),
                splitHSTSubstitute: Number(false),
                psgOptions: "",
                hstNights: "",
                cpapIfHstAbnormal: Number(false),
                cpapOptions: "",
                bipapOptions: "",
                pediatricOptions: "",
                pediatricSubstitute: event.target.checked ? formState.pediatricSubstitute : ""
            });
        }
        setIsSaveButtonDisabled(false);
        setIsSignOrderDisable(false);
    };

    const updateCheckBox = (e) => {
        setFormState({
            ...formState,
            [e.target.id || e.target.name]: Number(e.target.checked),
        });
        setIsSaveButtonDisabled(false);
    };

    const updateSurveyCheck = (e) => {
        const newSurveyState = {
            ...formState,
            [e.target.id]: e.target.checked ? 1 : 0,
        };
        setIsSaveButtonDisabled(false);

        setFormState((prevState) => ({
            ...prevState,
            ...newSurveyState
        }))
        // setSurveyCheck(newSurveyState);
    };

    const isEmptyField = (key) =>
        formState[key] === undefined ||
        formState[key] === null ||
        formState[key] === "";

    const saveOrderInfo = () => {
        let isValid = true;
        const errors = formState?.verbiage === 'qualified verbiage' && formState?.validity === 'valid order' ? {physicianId: "" } : {};
        for (const [key, _] of Object.entries(errors)) {
            if (isEmptyField(key)) {
                isValid = false;
                errors[key] = { error: true };
            }
        }

        // orderInfovalidation

        if (formState?.file && (!formState?.verbiage && !formState?.validity)) {
            isValid = false;
            notificationToastError("Please choose verbiage and validty.")
        }

        if(formState?.verbiage === 'qualified verbiage' && formState?.validity === 'valid order'){
            if (!selectedCheckbox) {
                isValid = false;
                notificationToastError("Please choose at least one Study Type");
            }
            if (!isChooseISDDiagnosis(formState)) {
                isValid = false;
                notificationToastError(
                    "Please choose at least one ICD-10 Diagnosis Code"
                );
            }
            if (!isCheckOneFaceToFaceNote(formState)) {
                isValid = false;
                notificationToastError(
                    "Please make a selection for FACE TO FACE NOTES (✔ all that apply)"
                );
            }
            if (formState?.otherFaceNotes && !formState?.otherNotes) {
                isValid = false;
                notificationToastError("Please fill a required field 'Other' . ");
              }
        }
        
        //    if (formState?.file && (!formState?.validity AND ) {
        //      isValid = false;
        //    notificationToastError("Please choose validity")
        //  }
        if (moment(new Date(visitDateState)).format("YYYY/MM/DD") == "Invalid date") {
            isValid = false;
            notificationToastError("Invalid date format");
        }

        if (!isValid) {
            notificationToastError("Please fill all required fields");
            setFormErrorState(errors);
        } else {
        
            if (fromPatientView) fromPatientView = 0; else fromPatientView = formState?.orderId;
           
              let payload = {
                   // ...formState,
                   patientId: formState?.patientId,
                   [selectedCheckbox]: selectedCheckbox ? 1 : 0,
                   E66_2: formState?.E66_2,
                   F51_12: formState?.F51_12,
                   F51_3: formState?.F51_3,
                   F51_4: formState?.F51_4,
                   F51_5: formState?.F51_5,
                   G47_11: formState?.G47_11,
                   G47_12: formState?.G47_12,
                   G47_13: formState?.G47_13,
                   G47_19: formState?.G47_19,
                   G47_30: formState?.G47_30,
                   G47_31: formState?.G47_31,
                   G47_33: formState?.G47_33,
                   G47_411: formState?.G47_411,
                   G47_419: formState?.G47_419,
                   G47_421: formState?.G47_421,
                   G47_421_wo_cataplexy: formState?.G47_421_wo_cataplexy,
                   G47_61: formState?.G47_61,
                   G47_69: formState?.G47_69,
                   G47_9: formState?.G47_9,
                   physicianId: formState?.physicianId,
                   prefInterpPhysId: formState?.prefInterpPhysId,
                   placeOfService: formState?.placeOfService,
                   snoring: formState?.snoring,
                   daytimeSleepiness: formState?.daytimeSleepiness,
                   apneas: formState?.apneas,
                   gasping: formState?.gasping,
                   inappropriateSleep: formState?.inappropriateSleep,
                   verbiage: formState?.verbiage,
                   validity: formState?.validity,
                   file: formState?.file,
                   locationId: formState?.locationId,
                   orderId: fromPatientView,
                   visitDate : visitDateState && moment.utc(new Date(visitDateState)).format("YYYY-MM-DD"),
                   invalidStudyType : formState?.invalidStudyType,
                   invalidDiagnosticCode : formState?.invalidDiagnosticCode,
                   invalidSign : formState?.invalidSign,
                   invalidOther : formState?.invalidOther,
                   invalidOtherText : formState?.invalidOtherText,
                   fatigue : formState?.fatigue,
                   otherFaceNotes : formState?.otherFaceNotes,
                   otherNotes : formState?.otherNotes,
                   consult : formState?.consult,
                   verbalOrderReceived : Number(formState?.verbalOrderReceived),
                   verbalOrderText : formState?.verbalOrderText,
                   ["2nightHSTSubstitute"]: formState?.["2nightHSTSubstitute"],
                   splitHSTSubstitute : formState?.splitHSTSubstitute,
                   psgOptions : formState?.psgOptions,
                   hstNights : formState?.hstNights,
                   cpapIfHstAbnormal : formState?.cpapIfHstAbnormal ,
                   cpapSubstitute : formState?.cpapSubstitute ,
                   bipapSubstitute : formState?.bipapSubstitute ,
                   pediatricOptions : formState?.pediatricOptions ,
                   addlInstructions: formState.addlInstructions
                   
               }

            orderPostData(axios, "saveSignedOrder", payload)
                .then((res) => {
                    if (res?.data?.response === "1") {
                        notificationToastSuccess(res?.data?.message);
                        setTimeout(() => {
                            navigate(`/dashboard`);
                        }, 2000);
                    } else {
                        notificationToastError(res?.data?.message);
                    }
                })
                .catch((err) => notificationToastError(err?.message));
        }
    }

    const cancel = (e) => { navigate('/dashboard')};

    return (
        <>
            <Box
                component="div"
                sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    // m: 1,
                    border: "1px solid rgba(0,0,0, 0.12)",
                    borderRadius: "4px",
                }}
            >
                <Box>
                    <Typography
                        variant='h6'
                        style={{
                            paddingLeft: "0.5rem",
                            //   paddingTop: "1rem",
                            marginTop: "16px",
                            marginBottom: "0px"
                        }}
                    >
                        ORDER INFO
                    </Typography>
                </Box>
                {
                    (isDashboard || latestVisitFlag) ? <Box sx={{display:"flex",flexDirection:"column"}}>
                        <FormControl sx={{ m: 1 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="verbalOrderReceived"
                                        checked={Boolean(formState.verbalOrderReceived)}
                                        onChange={updateSurveyCheck}
                                    />
                                }
                                label="VERBAL ORDER RECEIVED"
                            />
                        </FormControl>
                        {
                            formState?.verbalOrderReceived ?
                                <TextField
                                    id="verbalOrderText"
                                    label="Verbal Order Text"
                                    variant="standard"
                                    value={formState.verbalOrderText || ""}
                                    onChange={updateInput}
                                    placeholder='enter name of person who gave you verbal'
                                    sx={{ m: 1 }}
                                />
                                : ""
                        }
                    </Box> : ""
                }

                <Box sx={{ margin: "8px" }}>
                        <Documentupload
                            data-testid="document-upload" // Add Cypress ID to the Documentupload component
                            setFormState={setFormState}
                            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                            formErrorState={formErrorState}
                            setFormErrorState={setFormErrorState}
                            formState={formState}
                            title={isDashboard ? "UPLOAD CORRECTED SIGNED ORDER" : "Upload Signed Order"}
                        />
                    </Box>

                    {formState?.file ?
                        <>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="verbiage"
                                id="verbiage"
                                sx={{ paddingLeft: "0.5rem", display: "flex", marginTop: "15px" }}
                                value={formState?.verbiage}
                                onChange={updateInput}
                            >

                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Box>
                                        <FormControlLabel
                                            value="qualified verbiage"
                                            control={<Radio />}
                                            label="Qualified Verbiage*"
                                        />
                                    </Box>
                                    <Box sx={{ paddingRight: "12px" }}>
                                        <Typography> OR </Typography>
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            value="non qualified verbiage"
                                            control={<Radio />}
                                            label="Non Qualified Verbiage*"
                                        />
                                    </Box>
                                </Box>
                            </RadioGroup>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="validity"
                                id="validity"
                                sx={{ paddingLeft: "0.5rem", display: "flex", marginTop: "10px" }}
                                value={formState?.validity}
                                onChange={updateInput}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Box>
                                        <FormControlLabel
                                            value="valid order"
                                            control={<Radio />}
                                            label="Valid Order*"
                                        />
                                    </Box>
                                    <Box sx={{ paddingRight: "12px" }}>
                                        <Typography> OR </Typography>
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            value="invalid order"
                                            control={<Radio />}
                                            label="Invalid Order*"
                                        />
                                    </Box>
                                </Box>
                            </RadioGroup>
                        </> : ""}
                        <Box
                            component="div"
                            sx={{
                                marginTop: "1rem",
                            }}
                        >
                            <Box >
                                <Typography
                                    variant='h6'
                                    style={{
                                        paddingLeft: "0.5rem",
                                        paddingTop: "0.5rem",
                                        paddingRight: "0.5rem"
                                    }}
                                >
                                    Reason For Invalid Order:
                                </Typography>
                                <Grid container >
                                    {
                                        (isDashboard && Boolean(formState?.invalidStudyType) || !isDashboard) ? 
                                        <Grid item lg={6} md={4} sm={12} xs={12}>
                                        <FormControl sx={{ m: 1 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="invalidStudyType"
                                                        checked={Boolean(formState.invalidStudyType)}
                                                        onChange={updateSurveyCheck}
                                                        readOnly={isDashboard}
                                                    />
                                                }
                                                label="Study Type"
                                            />
                                        </FormControl>
                                    </Grid> :""
                                    }
                                    {
                                        (isDashboard && Boolean(formState?.invalidDiagnosticCode) || !isDashboard) ?
                                    <Grid item lg={6} md={4} sm={12} xs={12}>
                                        <FormControl sx={{ m: 1 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="invalidDiagnosticCode"
                                                        checked={Boolean(formState.invalidDiagnosticCode)}
                                                        onChange={updateSurveyCheck}
                                                        readOnly={isDashboard}
                                                    />
                                                }
                                                label="Diagnostic Code"
                                            />
                                        </FormControl>
                                    </Grid>:""
                                    }
                                    {
                                        (isDashboard && Boolean(formState?.invalidSign) || !isDashboard) ?
                                        <Grid item lg={6} md={4} sm={12} xs={12}>
                                        <FormControl sx={{ m: 1 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="invalidSign"
                                                        checked={Boolean(formState.invalidSign)}
                                                        onChange={updateSurveyCheck}
                                                        readOnly={isDashboard}
                                                    />
                                                }
                                                label="Provider Signature"
                                            />
                                        </FormControl>
                                    </Grid>:""
                                    }
                                    {
                                        (isDashboard && Boolean(formState?.invalidOther) || !isDashboard) ?
                                            <>
                                                <Grid item lg={3} md={4} sm={12} xs={12}>
                                                    <FormControl sx={{ m: 1 }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id="invalidOther"
                                                                    checked={Boolean(formState.invalidOther)}
                                                                    onChange={updateSurveyCheck}
                                                                    readOnly={isDashboard}
                                                                />
                                                            }
                                                            label="Other"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={3} md={4} sm={12} xs={12}>

                                                    {
                                                        formState.invalidOther ?
                                                            <TextField
                                                                id="invalidOtherText"
                                                                label="Other Text"
                                                                variant="standard"
                                                                value={formState.invalidOtherText || ""}
                                                                onChange={updateInput}
                                                                readOnly={isDashboard}
                                                            />
                                                            : ""
                                                    }
                                                </Grid>
                                            </>
                                            :  ""
                                    }
                                </Grid>
                            </Box>
                        </Box>

                {
                    formState?.verbiage === "qualified verbiage" && formState?.validity === "valid order" &&
                    <>
                        <Box
                            component="form"
                            sx={{
                                "& .MuiTextField-root": { m: 1, width: "25ch" },
                                border: "1px solid rgba(0,0,0, 0.12)",
                                borderRadius: "4px",
                                margin: "10px",
                            }}
                        >
                            <Typography
                                variant="h6"
                                style={{
                                    paddingLeft: "0.5rem",
                                    paddingTop: "1rem",
                                }}
                            >
                                FACE TO FACE NOTES (check all that apply)
                            </Typography>

                            <div>
                                <FormControl sx={{ m: 1 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="snoring"
                                                checked={Boolean(formState.snoring)}
                                                onChange={updateSurveyCheck}
                                            />
                                        }
                                        label="Snoring"
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl sx={{ m: 1 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="fatigue"
                                                checked={Boolean(formState.fatigue)}
                                                onChange={updateSurveyCheck}
                                            />
                                        }
                                        label="Fatigue"
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl sx={{ m: 1 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="daytimeSleepiness"
                                                checked={Boolean(formState.daytimeSleepiness)}
                                                onChange={updateSurveyCheck}
                                            />
                                        }
                                        label="Excessive Daytime Sleepiness"
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl sx={{ m: 1 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="gasping"
                                                checked={Boolean(formState.gasping)}
                                                onChange={updateSurveyCheck}
                                            />
                                        }
                                        label="Wakes up gasping for breath"
                                    />
                                </FormControl>
                            </div>

                            <div>
                                <FormControl sx={{ m: 1 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="apneas"
                                                checked={Boolean(formState.apneas)}
                                                onChange={updateSurveyCheck}
                                            />
                                        }
                                        label="Witnessed Apneas"
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl sx={{ m: 1 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="inappropriateSleep"
                                                checked={Boolean(formState.inappropriateSleep)}
                                                onChange={updateSurveyCheck}
                                            />
                                        }
                                        label="Falls asleep at inappropriate times and/or places"
                                    />
                                </FormControl>
                            </div>

                            <div>
                                <FormControl sx={{ m: 1 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="otherFaceNotes"
                                                checked={Boolean(formState.otherFaceNotes)}
                                                onChange={updateSurveyCheck}
                                            />
                                        }
                                        label="Other"
                                    />
                                </FormControl>
                            </div>
                            {formState?.otherFaceNotes == 1 && (
                                <Box
                                    component="div"
                                    sx={{
                                        "& .MuiFormControl-root": { flexGrow: 1 },
                                        display: "flex",
                                    }}
                                >
                                    <TextField
                                        id="otherNotes"
                                        label="Other *"
                                        value={formState.otherNotes}
                                        variant="standard"
                                        onChange={updateInput}
                                        inputProps={{ maxLength: 200 }}
                                    />
                                </Box>
                            )}

                            <Box>
                                <DateToVisit
                                    setVisitDate={setVisitDate}
                                    visitDateState={visitDateState}
                                    setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                                />
                            </Box>
                        </Box>
                        

                {/* <Box
                    component="div"
                    sx={{
                        "& .MuiFormControl-root": { flexGrow: 1 },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    { <Grid container sx={{ alignItems: "center" }}>
                            <Grid item lg={6} md={5} sm={12} xs={12} >
                                <FormControl
                                    variant="standard"
                                    sx={{ minWidth: "98%", margin: "8px 8px" }}
                                >
                                    <InputLabel
                                        id="location-label"
                                        error={
                                            formErrorState?.locationId?.error && !formState?.locationId
                                        }
                                    >
                                        Facility
                                    </InputLabel>
                                    <Select
                                        labelId="location-label"
                                        id="locationId"
                                        name="locationId"
                                        value={formState.locationId || ""}
                                        label="location"
                                        onChange={updateInput}
                                        // required
                                        error={formErrorState.locationId?.error && !formState.locationId}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {locations?.map((location) => (
                                            <MenuItem value={location.locationId} key={location.locationId}>
                                                {location.location}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formErrorState?.locationId?.error && !formState?.locationId && (
                                        <Typography variant="subtitle2" color={"error"}>
                                            required
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={5} sm={12} xs={12} >
                                <FormControl variant="standard" sx={{ width: "95%", ml: 1 }}>
                                    <InputLabel id="gender-label">Place of Service:</InputLabel>

                                    <Select
                                        labelId="contact-label"
                                        id="placeOfService"
                                        name="placeOfService"
                                        value={formState.placeOfService || ""}
                                        label="Place of Service Requested"
                                        onChange={updateInput}
                                        error={formErrorState?.placeOfService?.error && !formState?.placeOfService}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="Home">Home</MenuItem>
                                        <MenuItem value="Out Patient">Out Patient</MenuItem>
                                        <MenuItem value="IDTF">IDTF</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                </Box> */}

                <Box
                    component="div"
                    sx={{
                        "& .MuiBox-root": { flexGrow: 1 },
                        display: "flex",
                        gap: "2rem",
                        margin: "0.5rem",
                        flexDirection: isMobile ? "column" : "row",
                    }}
                >
                    <Box
                        sx={{
                            border: "1px solid rgba(0,0,0, 0.12)",
                            borderRadius: "4px",
                        }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                paddingLeft: "0.5rem",
                                paddingTop: "1rem",
                            }}
                        >
                            PLEASE CHOOSE ONE STUDY TYPE (required)
                        </Typography>

                        <FormControl sx={{ m: 1 }}>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="2nightProtocol"
                                            checked={selectedCheckbox === "2nightProtocol"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="2 NIGHT PROTOCOL - 1ST Polysomnogram (PSG) THEN 2nd night CPAP trial, if indicated (95810/95811)"
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    sx={{ paddingLeft: "2rem" }}
                                    value="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
                                    control={
                                        <Checkbox
                                            id="2nightHSTSubstitute"
                                            checked={Boolean(formState["2nightHSTSubstitute"])}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="splitNightProtocol"
                                            checked={selectedCheckbox === "splitNightProtocol"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="SPLIT NIGHT PROTOCOL - 1ST night SPLIT - if PAP not initiated, THEN 2nd night CPAP/BiPAP (95810/95811)"
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    sx={{ paddingLeft: "2rem" }}
                                    value="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
                                    control={
                                        <Checkbox
                                            id="splitHSTSubstitute"
                                            checked={Boolean(formState.splitHSTSubstitute)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="psgBaselineStudy"
                                            checked={selectedCheckbox === "psgBaselineStudy"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="PSG baseline study (95810)"
                                />
                            </Box>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="psgOptions"
                                id="psgOptions"
                                sx={{ paddingLeft: "2rem" }}
                                onClick={(e) => {
                                    if (e.target.value === formState?.psgOptions) {
                                        setFormState({ ...formState, psgOptions: null });
                                    } else updateInput(e);
                                }}
                                value={formState?.psgOptions || ""}
                                onChange={updateInput}
                            >
                                <Box>
                                    <FormControlLabel
                                        value="SUBSTITUTE HST if in-lab disqualified by insurance or denied OR,"
                                        control={<Radio />}
                                        label="SUBSTITUTE HST if in-lab disqualified by insurance or denied OR,"
                                    />
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        value="PSG with MSLT (Multiple Sleep Latency Test) 95805"
                                        control={<Radio />}
                                        label="PSG with MSLT (Multiple Sleep Latency Test) 95805"
                                    />
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        value="PSG with MWT (Maintenance of Wakefulness Test) 95805"
                                        control={<Radio />}
                                        label="PSG with MWT (Maintenance of Wakefulness Test) 95805"
                                    />
                                </Box>
                            </RadioGroup>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="homeSleepTest"
                                            checked={selectedCheckbox === "homeSleepTest"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="HOME SLEEP TEST"
                                />
                            </Box>

                            <Box sx={{ paddingLeft: "2rem" }}>

                                {formState && (
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="hstNights"
                                        id="hstNights"
                                        onClick={(e) => {
                                            if (e.target.value === formState?.hstNights) {
                                                setFormState({ ...formState, hstNights: null });
                                            } else updateInput(e);
                                        }}
                                        value={formState?.hstNights || ""}
                                        sx={{
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Box>
                                            <FormControlLabel
                                                value="ONE NIGHT"
                                                control={<Radio />}
                                                label={"ONE NIGHT"}
                                            />
                                        </Box>

                                        <Box>
                                            <FormControlLabel
                                                value="TWO NIGHT"
                                                control={<Radio />}
                                                label="TWO NIGHT"
                                            />
                                        </Box>
                                        <Box>
                                            <FormControlLabel
                                                value="THREE NIGHT"
                                                control={<Radio />}
                                                label="THREE NIGHT"
                                            />
                                        </Box>
                                    </RadioGroup>
                                )}
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="cpapIfHstAbnormal"
                                                checked={Boolean(formState.cpapIfHstAbnormal)}
                                                onChange={updateCheckBox}
                                            />
                                        }
                                        sx={{ marginLeft: "2rem" }}
                                        label="IF abnormal HST then CPAP titration (95811)"
                                    />
                                </Box>
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="cpapTitration"
                                            checked={
                                                selectedCheckbox === "cpapTitration" ||
                                                Boolean(formState.cpapTitration)
                                            }
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="CPAP titration (95811)"
                                />
                            </Box>
                            {selectedCheckbox === "cpapTitration" && (
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue=""
                                    name="cpapOptions"
                                    onClick={(e) => {
                                        if (e.target.value === formState?.cpapOptions) {
                                            setFormState({ ...formState, cpapOptions: null });
                                        } else updateInput(e);
                                    }}
                                    value={formState?.cpapOptions || ""}
                                    onChange={updateInput}
                                    sx={{ paddingLeft: "2rem" }}
                                >
                                    <Box>
                                        <FormControlLabel
                                            value="MSLT (Multiple Sleep Latency Test) 95805"
                                            control={<Radio />}
                                            label="MSLT (Multiple Sleep Latency Test) 95805"
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            value="MWT (Maintenance of Wakefulness Test) 95805"
                                            control={<Radio />}
                                            label="MWT (Maintenance of Wakefulness Test) 95805"
                                        />
                                    </Box>
                                </RadioGroup>
                            )}
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="bipapTitration"
                                            checked={selectedCheckbox === "bipapTitration"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="BiPAP titration (95811)"
                                />
                            </Box>
                            {selectedCheckbox === "bipapTitration" && (
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue=""
                                    name="bipapOptions"
                                    onClick={(e) => {
                                        if (e.target.value === formState?.bipapOptions) {
                                            setFormState({ ...formState, bipapOptions: null });
                                        } else updateInput(e);
                                    }}
                                    value={formState?.bipapOptions || ""}
                                    onChange={updateInput}
                                    sx={{ paddingLeft: "2rem" }}
                                >
                                    <Box>
                                        <FormControlLabel
                                            value="MSLT (Multiple Sleep Latency Test) 95805"
                                            control={<Radio />}
                                            label="MSLT (Multiple Sleep Latency Test) 95805"
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            value="MWT (Maintenance of Wakefulness Test) 95805"
                                            control={<Radio />}
                                            label="MWT (Maintenance of Wakefulness Test) 95805"
                                        />
                                    </Box>
                                </RadioGroup>
                            )}
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="bipapStTitration"
                                            checked={selectedCheckbox === "bipapStTitration"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="BiPAP ST titration (95811)"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="AsvTitration"
                                            checked={selectedCheckbox === "AsvTitration"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="ASV titration (95811)"
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="AvapsTitration"
                                            checked={selectedCheckbox === "AvapsTitration"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="AVAPS titration (95811)"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="pediatricSleepStudy"
                                            checked={selectedCheckbox === "pediatricSleepStudy"}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="PEDIATRICS SLEEP STUDY (5 YEARS OF AGE OR YOUNGER)"
                                />
                            </Box>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="pediatricOptions"
                                onClick={(e) => {
                                    if (e.target.value === formState?.pediatricOptions) {
                                        setFormState({ ...formState, pediatricOptions: null });
                                    } else updateInput(e);
                                }}
                                value={formState?.pediatricOptions || ""}
                                onChange={updateInput}
                                sx={{ paddingLeft: "2rem" }}
                            >
                                <Box>
                                    <FormControlLabel
                                        value="PSG BASELINE STUDY (95782 or 95810 Medicaid only)"
                                        control={<Radio />}
                                        label="PSG BASELINE STUDY (95782 or 95810 Medicaid only)"
                                    />
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        value="CPAP titration (95783 or 95811 Medicaid only)"
                                        control={<Radio />}
                                        label="CPAP titration (95783 or 95811 Medicaid only)"
                                    />
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        value="BiPAP titration (95783 or 95811 Medicaid only)"
                                        control={<Radio />}
                                        label="BiPAP titration (95783 or 95811 Medicaid only)"
                                    />
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid rgba(0,0,0, 0.12)",
                            borderRadius: "4px",
                        }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                paddingLeft: "0.5rem",
                                paddingTop: "1rem",
                            }}
                        >
                            PLEASE CHOOSE AT LEAST ONE ICD-10 DIAGNOSIS CODE (required)
                        </Typography>

                        <FormControl sx={{ m: 1 }}>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_30"
                                            checked={Boolean(formState.G47_30)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.30 Sleep apnea, unspecified"
                                />
                            </Box>

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_33"
                                            checked={Boolean(formState.G47_33)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.33 Obstructive sleep apnea"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_31"
                                            checked={Boolean(formState.G47_31)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.31 Primary Central Sleep Apnea"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_9"
                                            checked={Boolean(formState.G47_9)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.9 Sleep disorder, unspecified"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="E66_2"
                                            checked={Boolean(formState.E66_2)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="E66.2 Morbid (severe) obesity alveolar hypoventilation"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_69"
                                            checked={Boolean(formState.G47_69)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.69 Other sleep related movements"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_61"
                                            checked={Boolean(formState.G47_61)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.61 Periodic limb movement disorders"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="F51_12"
                                            checked={Boolean(formState.F51_12)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="F51.12 Insufficient sleep syndrome"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_11"
                                            checked={Boolean(formState.G47_11)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.11 Idiopathic hypersomnia with long sleep time"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_12"
                                            checked={Boolean(formState.G47_12)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.12 Idiopathic hypersomnia without long sleep time"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_13"
                                            checked={Boolean(formState.G47_13)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.13 Recurrent hypersomnia"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_19"
                                            checked={Boolean(formState.G47_19)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.19 Other hypersomnia"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="F51_4"
                                            checked={Boolean(formState.F51_4)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="F51.4 Sleep terrors (night terrors)"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="F51_5"
                                            checked={Boolean(formState.F51_5)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="F51.5 Nightmare disorders"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="F51_3"
                                            checked={Boolean(formState.F51_3)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="F51.3 Sleepwalking (Somnambulism)"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_411"
                                            checked={Boolean(formState.G47_411)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.411 Narcolepsy with cataplexy"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_419"
                                            checked={Boolean(formState.G47_419)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.419 Narcolepsy without cataplexy"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_421"
                                            checked={Boolean(formState.G47_421)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.421 Narcolepsy in conditions classified elsewhere, with cataplexy"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="G47_421_wo_cataplexy"
                                            checked={Boolean(formState.G47_421_wo_cataplexy)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="G47.421 Narcolepsy in conditions classified elsewhere, without cataplexy"
                                />
                            </Box>
                        </FormControl>
                    </Box>
                </Box>


                <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="addlInstructions"
            label="Special/Additional Instructions:"
            value={formState.addlInstructions || ""}
            variant="standard"
            onChange={updateInput}
            inputProps={{ maxLength: 200 }}
          />
        </Box>
        </Box>

                        <div>
                            <FormControl sx={{ m: 1 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="consult"
                                            checked={Boolean(formState.consult)}
                                            onChange={updateCheckBox}
                                        />
                                    }
                                    label="CONSULT AND TREAT BY BOARD CERTIFIED SLEEP PHYSICIAN"
                                />
                            </FormControl>
                        </div>

                        <Grid container sx={{ display: "flex", justifyContent: "space-between", ml: 1, mt: 1 }}>
                            <Grid item lg={5.8} md={12} sm={12} xs={12} >
                                <PhysicianPulldown
                                    formState={formState}
                                    updateInput={updateInput}
                                    attributeName={"physicianId"}
                                    formErrorState={formErrorState}
                                    validateEmptyField={validateEmptyField}
                                />
                            </Grid>

                            <Grid item lg={6} md={12} sm={12} xs={12} >
                                <PrefInterpPhysicianPulldown
                                    formState={formState}
                                    updateInput={updateInput}
                                    formErrorState={formErrorState}
                                    attributeName="prefInterpPhysId"
                                    physicianId={formState.physicianId}
                                    width="95%"
                                />
                            </Grid>

                        </Grid>
                        
                </> }
               
                {isDashboard ? "" : 
                    <Box sx={{ margin: "10px" }}>
                        <Button
                            variant="contained"
                            disabled={isSaveButtonDisabled}
                            onClick={saveOrderInfo}
                        >
                            SAVE CHANGES
                        </Button>
                        {
                            latestVisitFlag ? "" :
                                <Button
                                    sx={{ marginLeft: "1rem" }}
                                    variant="contained"
                                    onClick={cancel}
                                >
                                    CANCEL
                                </Button>
                        } 
                    </Box>}
            </Box>

        </>
    )
}

export default OrderInfo