import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Typography,
  TextareaAutosize,
  Button,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { studyPostData, orderPostData, pulldownsPostData } from "../../axios/api";
import OrderInfo from "../../pages/patients/components/OrderInfo";
import phoneNumberValidator from "../../utils/phoneNumberValidator";
import GeneralPulldown from "./pulldowns/GeneralPulldown";
import { isCheckOneFaceToFaceNote, isChooseISDDiagnosis } from "../../utils/signOrderFnc";

export default function OrderInfoDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => { }, 
  notificationToastSuccess,
  notificationToastError,
  isPreviousVisits = false,
  isPatientPortal,
  asDialog = true,
  notShowPopup,
  orderInfoData,
  reloadCurrentVisit,
  isWellnecessitiesUser,
  setRefreshData,
  refreshData
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [SDATE, setSDATE] = React.useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [inputSDATEError, setInputSDATEError] = useState(false);
  const axios = useAxios();
  const paramNameIn = "name";
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [locations, setLocations] = useState([]);
  const [visitDateState, setVisitDate] = useState(new Date());
  const [callBackDATE, setCallBackDATE] = useState(null);
  const [errorCallBackDATE, setErrorCallBackDATE] = useState(false);
  const [inputError, setInputError] = useState(false);

  const updateCallBackDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setCallBackDATE(newValue?.format("YYYY-MM-DD"));
    if (!newValue) {
      setInputError(false);
      return 'Invalid Date';
    }
    const { $d } = newValue;

    if ($d == "Invalid Date") {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };
  const updateCheckBox = (e) => {
    const checkboxId = e.target.id;

    if (checkboxId === "studyDeclined") {
      setFormState({
        ...formState,
        patUnreachable: "",
        [e.target.id || e.target.name]: Number(e.target.checked),
      });
    } else if (checkboxId === "patUnreachable") {
      setFormState({
        ...formState,
        studyDeclined: "",
        [e.target.id || e.target.name]: Number(e.target.checked),
      });
    }

    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  React.useEffect(() => {
    if (row) {
      setFormState({
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        bestPhone: row?.bestPhone,
        cellPhone: row?.cellPhone,
        E66_2: row?.E66_2,
        F51_12: row?.F51_12,
        F51_3: row?.F51_3,
        F51_4: row?.F51_4,
        F51_5: row?.F51_5,
        G47_11: row?.G47_11,
        G47_12: row?.G47_12,
        G47_13: row?.G47_13,
        G47_19: row?.G47_19,
        G47_30: row?.G47_30,
        G47_31: row?.G47_31,
        G47_33: row?.G47_33,
        G47_411: row?.G47_411,
        G47_419: row?.G47_419,
        G47_421: row?.G47_421,
        G47_421_wo_cataplexy: row?.G47_421_wo_cataplexy,
        G47_61: row?.G47_61,
        G47_69: row?.G47_69,
        G47_9: row?.G47_9,
        physicianId: row?.physicianIdStudy,
        prefInterpPhysId: row?.prefInterpPhysId,
        placeOfService: row?.placeOfService,
        snoring: row?.snoring,
        daytimeSleepiness: row?.daytimeSleepiness,
        apneas: row?.apneas,
        gasping: row?.gasping,
        inappropriateSleep: row?.inappropriateSleep,
        verbiage: row?.verbiage,
        validity: row?.validity,
        name :row?.name,
        orderingPhysicianPhone : row?.orderingPhysicianPhone,
        orderingPhysicianMobileNum : row?.orderingPhysicianMobileNum,
        addlInstructions: row?.addlInstructions
        // file: formState?.file
      });
      setCallBackDATE(row?.callBackInvalidOrder)
      let selectedStudyType = "";
        if (row['2nightProtocol']) {
          selectedStudyType = "2nightProtocol";
        } else if (row["splitNightProtocol"]) {
          selectedStudyType = "splitNightProtocol";
        } else if (row["psgBaselineStudy"]) {
          selectedStudyType = "psgBaselineStudy";
        } else if (row["homeSleepTest"]) {
          selectedStudyType = "homeSleepTest";
        } else if (row["cpapTitration"]) {
          selectedStudyType = "cpapTitration";
        } else if (row["bipapTitration"]) {
          selectedStudyType = "bipapTitration";
        } else if (row["bipapStTitration"]) {
          selectedStudyType = "bipapStTitration";
        } else if (row["AsvTitration"]) {
          selectedStudyType = "AsvTitration";
        } else if (row["AvapsTitration"]) {
          selectedStudyType = "AvapsTitration";
        } else if (row["pediatricSleepStudy"]) {
          selectedStudyType = "pediatricSleepStudy";
        }
        if (selectedStudyType) {
          setSelectedCheckbox(selectedStudyType);
        }
    }else if(orderInfoData){
      setFormState({ ...formState, ...orderInfoData });
    }
  }, [row, orderInfoData]);

  function ScheduleLocation(payload) {
    pulldownsPostData(axios, "scheduleLocationPulldown", payload)
      .then((res) => {
        if (res && res.data) setLocations(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setFormState((prevState) => ({
      ...prevState,
      [e.target.id || e.target.name]: e.target.value || "",
    }));
    
    if (e.target.id || e.target.name === "prefInterpPhysId" || e.target.name === "physicianId") {
      let payload = {
        physicianId: e.target.name === "physicianId" ? e.target.value : formState?.physicianId,
        patientId: formState?.patientId,
        orderId: formState?.orderId,
        stageId: formState?.stageId,
        prefInterpPhysId: e.target.name === "prefInterpPhysId" ? e.target.value : formState?.prefInterpPhysId,
        studyTypeId: formState?.studyTypeId,
      }
      ScheduleLocation(payload);
    }
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const saveChanges = (e) => {
    e.preventDefault();

    let isValid = true;
    const formStateError = { ...formErrorState };

    // if (!formState.contacAttempt) isValid = false;

    if (formState?.studyDeclined && !formState.reasonDecliningId) {
      isValid = false;
      formStateError["reasonDecliningId"] = { error: true };
    }

    if (!isValid) {
      setErrorMessage("Please fill all required fields");
      setFormErrorState(formStateError);

      return false;
    } else {
      setErrorMessage("");
    }

    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    let isValid = true;
    const errors = formState?.verbiage === 'qualified verbiage' && formState?.validity === 'valid order' ? {physicianId: "" } : {};
    for (const [key, _] of Object.entries(errors)) {
        if (isEmptyField(key)) {
            isValid = false;
            errors[key] = { error: true };
        }
    }

    // orderInfovalidation
    if (formState?.verbiage === 'qualified verbiage' && formState?.validity === 'valid order') {
      if (!selectedCheckbox) {
        isValid = false;
        notificationToastError("Please choose at least one Study Type");
      }
      if (!isChooseISDDiagnosis(formState)) {
        isValid = false;
        notificationToastError(
          "Please choose at least one ICD-10 Diagnosis Code"
        );
      }
      if (!isCheckOneFaceToFaceNote(formState)) {
        isValid = false;
        notificationToastError(
            "Please make a selection for FACE TO FACE NOTES (✔ all that apply)"
        );
    }
    if (formState?.otherFaceNotes && !formState?.otherNotes) {
        isValid = false;
        notificationToastError("Please fill a required field 'Other' . ");
      }
    }
    
    if (formState?.file && (!formState?.verbiage && !formState?.validity)) {
        isValid = false;
        notificationToastError("Please choose verbiage and validty.")
    }
//    if (formState?.file && (!formState?.validity AND ) {
  //      isValid = false;
    //    notificationToastError("Please choose validity")
  //  }
    if (moment(new Date(visitDateState)).format("YYYY/MM/DD") == "Invalid date") {
      isValid = false;
      notificationToastError("Invalid date format");
    }

    if (!isValid) {
        notificationToastError("Please fill all required fields");
        setFormErrorState(errors);
    } else {
      setIsSaveButtonDisabled(true);
        let payload = {
            //...formState,
            patientId: formState?.patientId,
            [selectedCheckbox]: selectedCheckbox ? 1 : 0,
            E66_2: formState?.E66_2,
            F51_12: formState?.F51_12,
            F51_3: formState?.F51_3,
            F51_4: formState?.F51_4,
            F51_5: formState?.F51_5,
            G47_11: formState?.G47_11,
            G47_12: formState?.G47_12,
            G47_13: formState?.G47_13,
            G47_19: formState?.G47_19,
            G47_30: formState?.G47_30,
            G47_31: formState?.G47_31,
            G47_33: formState?.G47_33,
            G47_411: formState?.G47_411,
            G47_419: formState?.G47_419,
            G47_421: formState?.G47_421,
            G47_421_wo_cataplexy: formState?.G47_421_wo_cataplexy,
            G47_61: formState?.G47_61,
            G47_69: formState?.G47_69,
            G47_9: formState?.G47_9,
            physicianId: formState?.physicianId,
            prefInterpPhysId: formState?.prefInterpPhysId,
            placeOfService: formState?.placeOfService,
            snoring: formState?.snoring,
            daytimeSleepiness: formState?.daytimeSleepiness,
            apneas: formState?.apneas,
            gasping: formState?.gasping,
            inappropriateSleep: formState?.inappropriateSleep,
            verbiage: formState?.verbiage,
            validity: formState?.validity,
            file:formState?.file,
            orderId:formState?.orderId,
            stageId: formState?.stageId,
            addlInstructions: formState?.addlInstructions,
            visitDate : visitDateState && moment(new Date(visitDateState)).format("YYYY-MM-DD"),
            invalidStudyType: formState?.invalidStudyType,
            invalidDiagnosticCode: formState?.invalidDiagnosticCode,
            invalidSign: formState?.invalidSign,
            invalidOther: formState?.invalidOther,
            invalidOtherText: formState?.invalidOtherText,
            fatigue: formState?.fatigue,
            otherFaceNotes: formState?.otherFaceNotes,
            otherNotes: formState?.otherNotes,
            consult: formState?.consult,
            verbalOrderReceived: Number(formState?.verbalOrderReceived),
            verbalOrderText: formState?.verbalOrderText,
            ["2nightHSTSubstitute"]: formState?.["2nightHSTSubstitute"],
                   splitHSTSubstitute : formState?.splitHSTSubstitute,
                   psgOptions : formState?.psgOptions,
                   hstNights : formState?.hstNights,
                   cpapIfHstAbnormal : formState?.cpapIfHstAbnormal ,
                   cpapSubstitute : formState?.cpapSubstitute ,
                   bipapSubstitute : formState?.bipapSubstitute ,
                   pediatricOptions : formState?.pediatricOptions ,
                   callBackInvalidOrder : callBackDATE ? moment(callBackDATE).format("YYYY-MM-DD") : ""
        }
        orderPostData(axios, "saveSignedOrder", payload)
            .then((res) => {
                if (res?.data?.response === "1") {
                    notificationToastSuccess(res?.data?.message);
                    setTimeout(() => {
                        setIsSaveButtonDisabled(false);
                        navigate(`/dashboard`);
                        handleClose();
                        setRefreshData(!refreshData)
                    }, 2000);
                   
                } else {
                    notificationToastError(res?.data?.message);
                }
            })
            .catch((err) => notificationToastError(err?.message));
    }   
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const updateSDATE = (newValue) => {
    if (!newValue) {
      setInputSDATEError(false);
      return 'Invalid Date';
    }
    const { $d } = newValue;

    if ($d == "Invalid Date") {
      setInputSDATEError(true);
    } else {
      setInputSDATEError(false);
    }
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSDATE(newValue?.format("MM/DD/YYYY"));
    setFormState({ ...formState, SDATE: newValue?.format("YYYY-MM-DD") });
  };

  const patientNameVal = row?.patientName ?? formState?.patientNameVal;
  const cellPhoneVal = phoneNumberValidator(row?.cellPhone) ?? formState?.cellPhone;
  const bestPhoneVal = phoneNumberValidator(row?.bestPhone) ?? formState?.bestPhone;
  if (asDialog) {
    return (
      <ConfirmDialog
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "Save" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog){
    return (
     <>
        <div>
          {" "}
          <FormControl sx={{ mt: 2,mb:2 }}>
            <Typography variant="h6" mb={1}> Update Order Info: </Typography>
            {!notShowPopup &&
            <>      
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <TextField
              id="patientName"
              label="Patient Name"
              variant="standard"
              value={patientNameVal}
              readOnly
              InputProps={{ disableUnderline: true, readOnly: true }}
            />
            <TextField
              id="bestPhone"
              label="Best Contact Number "
              variant="standard"
              value={bestPhoneVal?.formattedPhoneNumber || ""}
              readOnly
              InputProps={{ disableUnderline: true, readOnly: true }}
            />

            <TextField
              id="cellPhone"
              label="Cell Phone Number"
              variant="standard"
              value={cellPhoneVal?.formattedPhoneNumber || ""}
              readOnly
              InputProps={{ disableUnderline: true, readOnly: true }}
            />
          </Box>

              <Box>
                <FormControl variant="standard" sx={{ width: "60%", mt: 1, mb: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Call back for follow up of Invalid Order"
                      inputFormat="MM/DD/YYYY"
                      value={callBackDATE || ""}
                      onChange={updateCallBackDATE}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={(errorCallBackDATE && !callBackDATE) || inputError}
                          helperText={errorCallBackDATE && !callBackDATE ? "required" : inputError ? 'Date should be entered in the following format mm/dd/yyyy' : ''}
                          value={
                            callBackDATE
                              ? moment
                                .utc(callBackDATE.toLocaleString())
                                .format("MM/DD/YYYY")
                              : ""
                          }
                          onBlur={() => {
                            setErrorCallBackDATE(!callBackDATE);
                          }}
                          onMouseDown={handleFocus}
                          placeholder="mm/dd/yyyy"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
        </>
        }
            </FormControl>
            <OrderInfo
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                setFormErrorState={setFormErrorState}
                selectedCheckbox= {selectedCheckbox}
                setSelectedCheckbox={setSelectedCheckbox}
                isPatientPortal={isPatientPortal}
                setFormState={setFormState}
                isSaveButtonDisabled={isSaveButtonDisabled}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                validateEmptyField={validateEmptyField}
                isDashboard = {orderInfoData ? false : true}
                locations={locations}
                setLocations={setLocations}
                visitDateState={visitDateState}
                setVisitDate={setVisitDate}
                latestVisitFlag={true}
              />
        </div>
       
  
        
     </>

    );
  }
 
}
