import { useState } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { studyPostData } from "../../axios/api";
import ReschedulePopup from "./ReschedulePopup";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormTable from '../../components/patientPortalComponent/FormTable';

export default function ConsentFormsDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  notShowPopup,
  consentInfo,
  isCurrentVisits = false,
  reloadCurrentVisit,
  isWellnecessitiesUser
}) {


  const [formErrorState, setFormErrorState] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [patientConfirmDate, setPatientConfirmDate] = useState({});
  const [patientConfirmDateErr, setPatientConfirmDateErr] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const updateCheckBox = (e) => {
    const checkboxId = e.target.id;
    if(checkboxId === "paymentReceived"){
      if (isSaveButtonDisabled) {
        setIsSaveButtonDisabled(false);
      }
      setFormState({
        ...formState,
         [e.target.id || e.target.name]: Number(e.target.checked),
      });

    } else if(checkboxId === "moveNextAction"){
      if (isSaveButtonDisabled) {
        setIsSaveButtonDisabled(false);
      }
      setFormState({
        ...formState,
         [e.target.id || e.target.name]: Number(e.target.checked),
      });

    } else if(checkboxId === "consentFormCompleted"){
      if (isSaveButtonDisabled) {
        setIsSaveButtonDisabled(false);
      }
      formState["moveNextAction"] = Number(e.target.checked);

      setFormState({
        ...formState,
         [e.target.id || e.target.name]: Number(e.target.checked),
      });

    }else{
      if (checkboxId !== "patientConfirmedScheduledStudy") {
        formState["consentFormCompleted"] = 0;
        formState["skipConsentFormCompleted"] = 0;
    }
     
     formState[checkboxId] = e.target.checked ? 1 : 0;
 
     if (isSaveButtonDisabled) {
       setIsSaveButtonDisabled(false);
     }
     setFormState({
       ...formState,
       //  [e.target.id || e.target.name]: Number(e.target.checked),
     });
    }
   
  };


  React.useEffect(() => {
    if (row) {
      setFormState({
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        surveyId: row?.surveyIdSurvey,
        contacAttempt: row?.contacAttemptOrder,
        studyId: row?.studyIdStudy,
        consentFormCompleted: row?.consentFormCompleted,
        skipConsentFormCompleted: row?.skipConsentFormCompleted,
        patientConfirmedScheduledStudy: row?.patientConfirmedScheduledStudy,
        patientConfirmedDateTime: row?.patientConfirmedDateTime ? row?.patientConfirmedDateTime : new Date(),
        patientConfirmedBySMS: row?.patientConfirmedBySMS,
        paymentReceived:row?.paymentReceived,
        studyTypeId:row?.studyTypeId,
        outTrackingNum: row?.outTrackingNum,
        trackingNum: row?.trackingNum,
        hstSerialNumber: row?.hstSerialNumber,
        deviceCleaned: row?.deviceCleaned
      });
      setPatientConfirmDate(row?.patientConfirmedDateTime ? row?.patientConfirmedDateTime : new Date())
    } else if (consentInfo) {
      const date = consentInfo?.deviceCleanedDatetime && new Date(consentInfo?.deviceCleanedDatetime?.replace(' ', 'T'));
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const formattedDate = date?.toLocaleDateString('en-US', options); 
      const formattedTime = date?.toLocaleTimeString(); 
      setFormState({
        ...formState,
        ...consentInfo,
        consentFormCompleted: consentInfo?.consentFormCompleted,
        skipConsentFormCompleted: consentInfo?.skipConsentFormCompleted,
        patientConfirmedScheduledStudy: consentInfo?.patientConfirmedScheduledStudy,
        paymentReceived: consentInfo?.paymentReceived,
        formattedDate:formattedDate,
        formattedTime:formattedTime
      });
      setPatientConfirmDate(consentInfo?.patientConfirmedDateTime ? consentInfo?.patientConfirmedDateTime : new Date())
    }
  }, [row]);
  
  const [errorMessage, setErrorMessage] = useState("");
  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  
  const saveChanges = (e) => {
    const formStateError = { ...formErrorState };
    let isValid = true;

    if (!(formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10) &&
      !formState?.skipConsentFormCompleted &&
      !formState?.consentFormCompleted && !formState?.patientConfirmedScheduledStudy
    )
      isValid = false;

    if (!isValid) {
      if(formState?.paymentReceived) {
        saveStudy(formState);
      }else{
        setErrorMessage("Please select one of checkboxes.");
        // setFormErrorState(formStateError);
        notificationToastError("Please select one of checkboxes.");
  
        return false;
      }
    } else {
      setErrorMessage("");
      saveStudy(formState);
    }

  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const currentTime = moment().format("HH:mm:ss");;
    const payload = {
      ...formState,
      deviceCleanedDatetime:formState?.deviceCleanedDatetime,
      patientConfirmedDateTime: moment(`${moment(formState.patientConfirmedDateTime).format("YYYY-MM-DD")} ${currentTime}`).format("YYYY-MM-DD HH:mm:ss")
    };

    studyPostData(axios, "updateConsentFormInfo", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
    //  if (isCurrentVisits) window.location.reload(true);

    if (reloadCurrentVisit) reloadCurrentVisit(payload);
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const updateDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setPatientConfirmDate(newValue.format("MM/DD/YYYY"));
    setFormState({
      ...formState,
      patientConfirmedDateTime: newValue.format("MM/DD/YYYY")
    })
  };

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        consentForm="true"
        // isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <Box>
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6"> Patient Consent Forms: </Typography>
          </FormControl>
        </div>
        {!notShowPopup && <ReschedulePopup row={row} />}
          <Box>
          {!notShowPopup && <FormTable patientIdForm={row?.patientId} studyId={row?.studyIdStudy}  consentForm={true} orderId={row?.orderIdOrder}/>}
          
          {notShowPopup && <FormTable patientIdForm={consentInfo?.patientId} studyId={consentInfo?.studyId}  consentForm={true} orderId={consentInfo?.orderId}/>}
        </Box>
        <Box sx={{ m: 1 }}>

          {(formState?.studyTypeId === 8 ||
            formState?.studyTypeId === 9 ||
            formState?.studyTypeId === 10) &&
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                  margin: "10px 0px",
                  alignItems: "left",
                  flexDirection: "column"
                }}
              >
                <TextField
                  sx={{ width: "100%" }}
                  id="outTrackingNum"
                  label="Outbound Tracking Number"
                  value={formState?.outTrackingNum || ""}
                  variant="standard"
                  onChange={updateInput}
                  inputProps={{ readOnly: isPreviousVisits }}
                />

                <TextField
                  sx={{ width: "100%" }}
                  id="trackingNum"
                  label="Inbound Tracking Number"
                  value={formState?.trackingNum || ""}
                  variant="standard"
                  onChange={updateInput}
                  inputProps={{ readOnly: isPreviousVisits }}
                />

                <TextField
                  sx={{ width: "100%" }}
                  id="hstSerialNumber"
                  label="HST Serial Number"
                  value={formState?.hstSerialNumber || ""}
                  variant="standard"
                  onChange={updateInput}
                  inputProps={{ readOnly: isPreviousVisits }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      id="deviceCleaned"
                      checked={Boolean(formState.deviceCleaned)}
                      onChange={isPreviousVisits ? () => { } : updateCheckBox}
                    />
                  }
                  label=" Device Cleaned"
                />

              </Box>
              {formState?.deviceCleaned && isCurrentVisits ? (
                <Box
                  component="div"
                  sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap: "1rem",
                    mb: 2
                  }}
                >

                  <Typography variant="strong">Device Cleaned Date Time : </Typography> <Typography variant="span">{formState?.formattedDate}</Typography>
                  <Typography variant="span">{formState?.formattedTime}</Typography>

                </Box>
              ) : ""}
            </>
          }

          <FormControlLabel
            control={
              <Checkbox
                id="consentFormCompleted"
                checked={Boolean(formState.consentFormCompleted)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label=" Consent forms completed"
          />

          { formState?.consentFormCompleted == 1 && 
           <FormControlLabel
            control={
              <Checkbox
                id="moveNextAction"
                checked={Boolean(formState?.moveNextAction)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label=" Move to next action "
          />
        }
        </Box>

        <Box sx={{ m: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="skipConsentFormCompleted"
                checked={Boolean(formState.skipConsentFormCompleted)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label=" Proceed without completing Consent forms"
          />
        </Box>
        <Box sx={{ m: 1, display: "flex", alignItems:"center" }}>
          <FormControlLabel
            control={
              <Checkbox
                id="patientConfirmedScheduledStudy"
                checked={Boolean(formState.patientConfirmedScheduledStudy)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label=" Patient Confirmed Scheduled Study"
          />
           {formState.patientConfirmedScheduledStudy ?
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Patient Confirmed Date"
                inputFormat="MM/DD/YYYY"
                value={patientConfirmDate || ""}
                onChange={updateDATE}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={patientConfirmDateErr && !patientConfirmDate}
                    helperText={patientConfirmDateErr && !patientConfirmDate ? "required" : ""}
                    value={
                      patientConfirmDate
                        ? moment
                          .utc(patientConfirmDate.toLocaleString())
                          .format("MM/DD/YYYY")
                        : ""
                    }
                    onBlur={() => {
                      setPatientConfirmDateErr(!patientConfirmDate);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                  />
                )}
              />
            </LocalizationProvider>
           :""}
            {/* { (formState?.patientConfirmedScheduledStudy == 1 && formState?.patientConfirmedDateTime) && (<>on { 
           moment.utc(formState?.patientConfirmedDateTime.toLocaleString()).format("MM/DD/YYYY") } </> )} */}
          {(formState?.patientConfirmedBySMS == 1) && <Box ml={4}>by TEXT message</Box>}       

        </Box>
        <Box sx={{ m: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="paymentReceived"
                checked={Boolean(formState.paymentReceived)}
                onChange={isPreviousVisits ? () => {} : updateCheckBox}
              />
            }
            label=" Payment Received"
          />
        </Box>
        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Box>
    );
  }
}
