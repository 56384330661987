import { useState } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import { FormControl, Typography, Button } from "@mui/material";
import { studyPostData } from "../../axios/api";
import ReschedulePopup from "./ReschedulePopup";

export default function CompleteBillingDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState,
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
  };

  React.useEffect(() => {
    if (row?.length) {
      setFormState({
        patientId: row[0],
        orderId: row[11],
        stageId: row[95],
        surveyId: row[10],
        studyId: row[106],
      });
    }
  }, [row]);

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const saveChanges = (e) => {
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
    };

    studyPostData(axios, "updateCompleteBilling", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        // isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <Box>
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6"> Complete Billing: </Typography>
          </FormControl>
        </div>
        <ReschedulePopup row={row} /> 
        {!asDialog && !isPreviousVisits && (
          
          <div style={{ display: "flex", justifyContent: "end" }}>
             
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Box>
    );
  }
}
