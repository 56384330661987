import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
} from "../../node_modules/@mui/material/index";
import { useAxios } from "../hooks/auth";
import ConfirmDialog from "../components/misc/Dialog";
import { notificationToastError, notificationToastSuccess } from "../utils/notification";
import { adminPostData } from "../axios/api";
import { Grid } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import useIsRoleName from "../hooks/roleNameInfo";

function RoleView({
  roleData,
  handleClose,
  privileges,
  privilegesData,
  groupId
}) {

  useEffect(() => {
    if (roleData?.roleId) {
      setFormState({
        ...formState,
        ...privileges,
        ...roleData
      });
    }
  }, []);
 
  const roleName = useIsRoleName();

  const selectedPracticeId = useSelector(
    (state) => state?.practiceSelector?.value
  );
  const axios = useAxios();
  const isMobile = useMediaQuery('(max-width:600px)')
  const [formState, setFormState] = useState({});
  const [formErrorState, setFormErrorState] = useState({
    role: "",
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const updateInput = (e) => {
    if(isSaveButtonDisabled){
      setIsSaveButtonDisabled(false)
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
  };
  const updateCheckBox = (e) => {
    if(isSaveButtonDisabled){
      setIsSaveButtonDisabled(false)
    }
    setFormState((prevState) => {
      const { name, id, checked } = e.target;

      const updatedState = { ...prevState, [name || id]: Number(checked) };
      const { survey, orders, insurances,locations,roleAccess,users,pracGroups,patients,admin} = updatedState;
      if(checked && ( id === "survey" || id === "orders")) {
        updatedState.patients = Number(true);
      }else if(!survey && !orders ){
       // updatedState.patients = Number(false);
      }

      if ( checked &&( id === "insurances" || id === "locations" || id === "roleAccess" || id === "users"  || id === "pracGroups" )) {
        updatedState.admin = Number(true);
      }else if(!locations && !roleAccess && !users && !pracGroups && !insurances ){
        updatedState.admin = Number(false);
      }

      if (id === "patients" && !checked) {
        Object.keys({ survey, orders }).forEach((key) => {
          if (key !== "patients") {
           // updatedState[key] = Number(false);
          }
        });
      }else if( checked && id==="patients"){
        Object.keys({ survey, orders,patients }).forEach((key) => {
      
          //  updatedState[key] = Number(true);
       
        });
      }
      if (id === "admin" && !checked) {
        Object.keys({ insurances, locations,roleAccess ,users,pracGroups}).forEach((key) => {
          if (key !== "admin") {
            updatedState[key] = Number(false);
          }
        });
      }else if( checked && id==="admin"){
        Object.keys({ insurances, locations,roleAccess,pracGroups,users,admin}).forEach((key) => {
            updatedState[key] = Number(true);   
        });
      }
      return updatedState;
    });

  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }
    const payload ={
      ...formState,
    }
    setIsSaveButtonDisabled(true);
    adminPostData(axios,"saveRoleInfo",payload,selectedPracticeId)
      .then((res) => {
        if (res?.data?.response==="1") {
          notificationToastSuccess(res?.data?.message);
        }
        else{
          notificationToastError(res?.data?.message);
        }
      })
      .catch((ex) =>{
        notificationToastError(ex?.message)

        }).finally(()=>{
          handleClose()
        })
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }


  const filteredprivilegesData = (roleName == "Administrator" && groupId.includes(1) ) ? privilegesData : privilegesData.filter(privilege => privilege.privName !== "All Patients")

  return (
    <ConfirmDialog
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "Save changes" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
    >
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="role"
            label="Role *"
            value={formState.role}
            variant="standard"
            onChange={updateInput}
            error={formErrorState.role?.error && !formState.role}
            helperText={
              formErrorState.role?.error && !formState.role ? "required" : ""
            }

          
            inputProps={
              { readOnly:formState.roleId ? true : false }
            } 
          
            onBlur={validateEmptyField.bind(null, "role")}
          />
        </Box>
      </Box>
      
      <Box
        sx={{
          "& .MuiFormControl-root": {flexGrow: 1 },
         
          marginTop: "1rem",
        }}
      >
        <Box sx={{display: isMobile ? "none" : "inherit"}}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6">Privileges:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6">Description:</Typography>
            </Grid>
          </Grid>
        </Box>
        {
          filteredprivilegesData?.length > 0 ?
          filteredprivilegesData?.map((item, index) => (
            <Box key={item.privParam}>
              <Grid container sx={{alignItems: "center"}}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={item.privParam}
                          checked={Boolean(formState[item.privParam])}
                          onChange={updateCheckBox}
                        />
                      }
                      label={<Typography>{item.privName}</Typography>}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box sx={{pl: isMobile ? "33px" : "inherit"}}> 
                    <Typography variant="body1">{item?.description}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )) :
           <Typography variant="h5" sx={{textAlign:"center"}}>
              Privileges not found
          </Typography>
        }
      </Box>
      
      {/* <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": {flexGrow: 1 },
           
            marginTop: "1rem",
          }}
        >
           <Typography variant="h6">Enable Privileges</Typography>
          <FormControl sx={{ margin:" 4px 8px " }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="patients"
                  checked={Boolean(formState.patients)}
                  onChange={updateCheckBox}
                />
              }
              label="Patients"
            />
      
          </FormControl>
          <Box>  
          <FormControl sx={{ margin:" 4px 8px " }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="admin"
                  checked={Boolean(formState.admin)}
                  onChange={updateCheckBox}
                />
              }
              label="Admin"
            />
          </FormControl>
          </Box>
          <Box>  
          <FormControl sx={{margin:" 4px 8px " }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="locations"
                  checked={Boolean(formState.locations)}
                  onChange={updateCheckBox}
                />
              }
              label="Locations"
            />
          </FormControl>
          </Box>
          <Box>  
          <FormControl sx={{margin:" 4px 8px "}}>
            <FormControlLabel
              control={
                <Checkbox
                  id="users"
                  checked={Boolean(formState.users)}
                  onChange={updateCheckBox}
                />
              }
              label="Users"
            />
          </FormControl>
          </Box>
          <Box>  
          <FormControl sx={{margin:" 4px 8px "}}>
            <FormControlLabel
              control={
                <Checkbox
                  id="pracGroups"
                  checked={Boolean(formState.pracGroups)}
                  onChange={updateCheckBox}
                />
              }
              label="Groups"
            />
          </FormControl>
          </Box>
          <Box>  
          <FormControl sx={{margin:" 4px 8px " }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="dashboard"
                  checked={Boolean(formState.dashboard)}
                  onChange={updateCheckBox}
                />
              }
              label="Dashboard"
            />
          </FormControl>
          </Box>
          <Box>  
          <FormControl sx={{margin:" 4px 8px " }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="reports"
                  checked={Boolean(formState.reports)}
                  onChange={updateCheckBox}
                />
              }
              label="Reports"
            />
          </FormControl>
          </Box>
          <Box>  
          <FormControl sx={{ margin:" 4px 8px "}}>
            <FormControlLabel
              control={
                <Checkbox
                  id="profSettings"
                  checked={Boolean(formState.profSettings)}
                  onChange={updateCheckBox}
                />
              }
              label="ProfileSettings"
            />
          </FormControl>
          </Box>

          <Box>  
          <FormControl sx={{ margin:" 4px 8px "}}>
            <FormControlLabel
              control={
                <Checkbox
                  id="roleAccess"
                  checked={Boolean(formState.roleAccess)}
                  onChange={updateCheckBox}
                />
              }
              label="RoleAccess"
            />
          </FormControl>
          </Box>

          <Box>  
          <FormControl sx={{margin:" 4px 8px " }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="survey"
                  checked={Boolean(formState.survey)}
                  onChange={updateCheckBox}
                />
              }
              label="survey"
            />
          </FormControl>
          </Box>
          <Box>  
          <FormControl sx={{margin:" 4px 8px "}}>
            <FormControlLabel
              control={
                <Checkbox
                  id="orders"
                  checked={Boolean(formState.orders)}
                  onChange={updateCheckBox}
                />
              }
              label="orders"
            />
          </FormControl>
          </Box>
          <Box>  
          <FormControl sx={{margin:" 4px 8px " }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="signOrder"
                  checked={Boolean(formState.signOrder)}
                  onChange={updateCheckBox}
                />
              }
              label="signOrder"
            />
          </FormControl>
          </Box>

	  <Box>
          <FormControl sx={{ margin:" 4px 8px " }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="insurances"
                  checked={Boolean(formState.insurances)}
                  onChange={updateCheckBox}
                />
              }
              label="insurances"
            />
          </FormControl>
          </Box>

      </Box> */}
    </ConfirmDialog>
  );
}

export default RoleView;
