import { useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { addEventListeners, removeEventListeners } from './utils/eventListenerUtil';

const SESSION_TIMEOUT = 20 * 60 * 1000; // 20 minutes in milliseconds
const CHECK_INTERVAL = 1000; // Check every second

const useSessionTimeout = () => {
    const [cookies, setCookies, removeCookie] = useCookies();

    const updateActivity = useCallback(() => {
        const now = Date.now();
        localStorage.setItem('lastActivity', now.toString());
        localStorage.setItem('isLoggedOut', 'false'); // Reset the logout flag
        const channel = new BroadcastChannel('session_channel');
        channel.postMessage({ type: 'activity', timestamp: now });
        channel.close();
      }, []);

      const checkSessionTimeout = useCallback(() => {
        const lastActivity = parseInt(localStorage.getItem('lastActivity'), 10);
        const isLoggedOut = localStorage.getItem('isLoggedOut') === 'true';
        const now = Date.now();
        if (now - lastActivity > SESSION_TIMEOUT && !isLoggedOut) {
          // Perform logout action
          localStorage.setItem('isLoggedOut', 'true');
          ["token", "isPatient"].forEach((obj) => removeCookie(obj));
           window.location.href = "/signin-landing";
    
          const channel = new BroadcastChannel('session_channel');
          channel.postMessage({ type: 'logout' });
          channel.close();
        }

    }, []);

    useEffect(() => {
        const handleActivity = () => updateActivity();

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                updateActivity();
            }
        };

        addEventListeners(handleActivity);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        const intervalId = setInterval(checkSessionTimeout, CHECK_INTERVAL);

        const channel = new BroadcastChannel('session_channel');
        channel.onmessage = (event) => {
            if (event.data.type === 'activity') {
              localStorage.setItem('lastActivity', event.data.timestamp.toString());
              localStorage.setItem('isLoggedOut', 'false'); // Reset the logout flag
            } else if (event.data.type === 'logout') {
              localStorage.setItem('isLoggedOut', 'true');
              window.location.href = "/signin-landing"; // Ensure all tabs log out
            }
          };

        return () => {
            removeEventListeners(handleActivity);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearInterval(intervalId);
            channel.close();
        };
    }, [updateActivity, checkSessionTimeout]);

    return null;
};

export default useSessionTimeout;
