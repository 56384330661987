import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const DiagnosisCode = ({
  updateCheckBox,
  dmeOrderState,
  inputHanldeChange,
  errorState,
}) => {
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1 },
        border: "1px solid rgba(0,0,0, 0.12)",
        borderRadius: "4px",
        marginTop: "20px",
        padding: "16px 5px",
      }}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h6" sx={{ color: errorState ? "#d32f2f" : "" }}>
        Diagnosis Code : choose 1; PLEASE CHECK OR FILL IN SPECIFIC CODE IF
        WITHIN A RANGE OF CODES (Required) *
      </Typography>

      <Grid container>
        <Grid item md={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.G47_33)}
                onChange={updateCheckBox}
                name="G47_33"
              />
            }
            label="Obstructive Sleep Apnea (G47.33)"
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.G47_34)}
                onChange={updateCheckBox}
                name="G47_34"
              />
            }
            label="Non-Obstructive Alveolar Hypoventilation (G47.34)"
          />
        </Grid>

        <Grid item md={4} xs={12} sx={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.other)}
                onChange={updateCheckBox}
                name="other"
              />
            }
            label="OTHER"
          />
          {dmeOrderState?.other ? (
            <TextField
              label="Other"
              variant="standard"
              name="otherNote"
              value={dmeOrderState?.otherNote}
              onChange={inputHanldeChange}
            />
          ) :""}
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center">
        <Grid item md={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.G47_31)}
                onChange={updateCheckBox}
                name="G47_31"
              />
            }
            label="Central Sleep Apnea (G47.31 or G47.37)"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={dmeOrderState?.I50_20}
                onChange={updateCheckBox}
                name="I50_20"
              />
            }
            label="CHF (I50.20 – 44)"
          />

          {dmeOrderState?.I50_20 ? (
            <TextField
              label="CHF (I50.20 – 44)"
              variant="standard"
              name="I50_20_desc"
              value={dmeOrderState?.I50_20_desc}
              onChange={inputHanldeChange}
            />
          ) :""}
        </Grid>

        <Grid item md={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.J44_0)}
                onChange={updateCheckBox}
                name="J44_0"
              />
            }
            label="COPD (J44.0 -- .9)"
          />

          {dmeOrderState?.J44_0 ? (
            <TextField
              label="COPD (J44.0 -- .9)"
              variant="standard"
              name="J44_0_desc"
              value={dmeOrderState?.J44_0_desc}
              onChange={inputHanldeChange}
            />
          ) : ""}
        </Grid>

        <Grid item md={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.J43_1)}
                onChange={updateCheckBox}
                name="J43_1"
              />
            }
            label="Emphysema (J43.0--.9)"
          />

          {dmeOrderState?.J43_1 ? (
            <TextField
              label="Emphysema (J43.0--.9)"
              variant="standard"
              name="J43_1_desc"
              value={dmeOrderState?.J43_1_desc}
              onChange={inputHanldeChange}
            />
          ) : ""}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DiagnosisCode;
