import { createSlice } from "@reduxjs/toolkit";

export const dashboardDataSlice = createSlice({
  name: "dashboardData",
  initialState: {
    dashboardData: [],
  },
  reducers: {
    updatedashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatedashboardData } = dashboardDataSlice.actions;

export default dashboardDataSlice.reducer;