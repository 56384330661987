import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

const PAPDeviceFollow = ({ updateInput, errorState }) => {
  return (
    <Box
      sx={{
        border: "1px solid rgba(0,0,0, 0.12)",
        borderRadius: "4px",
        marginTop: "20px",
        padding: "16px 10px",
        height: "85%",
      }}
    >
      <Typography variant="h6" sx={{ color: errorState?.error ? "#d32f2f" : "" }}>
        SLEEP THERAPY PAP DEVICE : select one(Required) *
      </Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="papDevice"
        id="papDevice"
        onChange={updateInput}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel value="cpap" control={<Radio />} label="CPAP " />{" "}
          <Typography variant="h6">OR</Typography>
          <FormControlLabel
            value="autoCpap"
            control={<Radio />}
            label="AutoCPAP(E0601) "
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel value="bipap" control={<Radio />} label="BiPAP " />
          <Typography variant="h6">OR</Typography>
          <FormControlLabel
            value="autoBipap"
            control={<Radio />}
            label="Auto BiPAP(E0470) "
          />
        </Box>
        <FormControlLabel
          value="bipapst"
          control={<Radio />}
          label="BiPAPST(E0471) "
        />
        <FormControlLabel value="asv" control={<Radio />} label="ASV(E0471)" />
        <FormControlLabel
          value="avaps"
          control={<Radio />}
          label="AVAPS(E0466)"
        />
        <FormControlLabel
          value="avapsAe"
          control={<Radio />}
          label="AVAPS-AE / Trilogy (E0466)"
        />
      </RadioGroup>
    </Box>
  );
};
export default PAPDeviceFollow;
