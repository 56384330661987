import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./currentVisits.module.css";
import { visitsGetData } from "../../../axios/api";
import { useAxios } from "../../../hooks/auth";
import VisitsAccordian from "./VisitsAccordian";
import useIsGroupId from "../../../hooks/groupIdInfo";
function CurrentVisits({ formState, setFormState }) {
  const axios = CurrentVisits.useAxios();
  const isMobile = useMediaQuery("(max-width:800px)");
  const [visitsData, setVisitsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userGroupId = useIsGroupId();

  useEffect(() => {
    const setVistData = () => {
      const patientId = formState?.patientIdPat || formState?.patientId;
      visitsGetData(axios, patientId)
        .then((res) => {
          setVisitsData(res);
          
        })
        .catch((err) => {
          console.log("err==>", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (formState?.patientIdPat || formState?.patientId) {
      setVistData();
    }
  }, [formState]);

  const reload = (payload) => {
    setFormState(payload);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "90vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      {!visitsData?.length ? (
        <Box
          sx={{
            display: "flex",
            height: "90vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">
            Current visits not associated with selected patients
          </Typography>
        </Box>
      ) : (
        visitsData?.length !== 0 && (
          <>
            <Box
              className={classes.patientNameWrapper}
              sx={{ flexDirection: isMobile ? "column" : "row" }}
            >
              <Box>
                <Typography variant="strong" data-testid="patient-name-label">
                  {" "}
                  Patient Name :{" "}
                </Typography>
                <Typography variant="span" data-testid="patient-name">
                  {formState?.firstName} {formState?.lastName}{" "}
                </Typography>
              </Box>
              <Box>
                <Typography variant="strong" data-testid="patient-id-label">
                  {" "}
                  Patient ID :{" "}
                </Typography>
                <Typography variant="span" data-testid="patient-id">
                  {formState?.patientNum}
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.patientNameWrapper}
              sx={{ flexDirection: isMobile ? "column" : "row" }}
            >
              <Box>
                <Typography variant="strong" data-testid="patient-name-label">
                  {" "}
                  Best Contact Number :{" "}
                </Typography>
                <Typography variant="span" data-testid="patient-name">
                  {formState?.bestPhone}
                </Typography>
              </Box>
              <Box>
                <Typography variant="strong" data-testid="patient-id-label">
                  {" "}
                  Cell Phone Number :{" "}
                </Typography>
                <Typography variant="span" data-testid="patient-id">
                  {formState?.cellPhone}
                </Typography>
              </Box>
            </Box>
            <VisitsAccordian
              visitsData={visitsData}
              isCurrentVisits={true}
              reloadVisit={reload}
              isWellnecessitiesUser={userGroupId.includes(1) ? true : false}
            />
          </>
        )
      )}
    </>
  );
}
CurrentVisits.useAxios = useAxios;
export default CurrentVisits;
