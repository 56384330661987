import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { YES_NO } from '../../constants/yesNo'

function SelectComponent({formState,updateInput,id,label}) {
  return (
    <FormControl variant="standard" sx={{ minWidth: 120, '& .MuiInputBase-root':{
        width:"98%"
    } }}>
    <InputLabel id={`${id}-label`}>{label}</InputLabel>
    <Select
      labelId={`${id}-label`}
      id={id}
      name={id}
      value={formState[id] || ''}
      onChange={updateInput}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {YES_NO?.map((item) => (
        <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
      ))}
    </Select>
  </FormControl>
  )
}

export default SelectComponent