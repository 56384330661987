import React, { useEffect, useState } from 'react'
import LocationPulldown from '../../../misc/LocationPulldown'
import { Box, Button, Grid } from '@mui/material'
import { notificationToastError } from '../../../../utils/notification';

const MultipleLocation = ({formState,updateInput,formErrorState,setFormState,setIsSaveButtonDisabled=()=>{}}) => {
    const [locations, setLocations] = useState([`documentLocation0`]);
    useEffect(() => {
   
        const inLabs = formState?.dLocations?.split(":");
        let userFormState ={}
        if (inLabs?.length && formState?.locationBased) {
            const locationObjects = [];
          for (let i = 0; i < inLabs.length; i++) {
            locationObjects.push(`documentLocation${i}`);
            userFormState[`documentLocation${i}`] = inLabs[i];
          }
          setFormState({
            ...formState,
            ...userFormState
          })
          setLocations(locationObjects);
          
        }
        else if(inLabs?.length && !formState?.locationBased){
          for (let i = 0; i < inLabs.length; i++) {
            userFormState[`documentLocation${i}`] = "";
          }
          setFormState({
            ...formState,
            ...userFormState,
            locations :"",
            dLocations: "",
            documentType:""

          })
          setLocations([`documentLocation0`]);
        }
    
    }, [formState?.locationBased])
    
    const addinLabLocations = () => {
        setIsSaveButtonDisabled(false)
        locations.push(`documentLocation${locations.length}`);
    
        setLocations([...locations]);
      };
      const removeinLabLocations = (paramNameIn) => {
        setIsSaveButtonDisabled(false)

        delete formState[paramNameIn];
        const locationObjects = [];
        const inLabs = locations.filter((value) => value !== paramNameIn);
        for (let i = 0; i < inLabs.length; i++) {
          locationObjects.push(`documentLocation${i}`);
          formState[`documentLocation${i}`] = formState[inLabs[i]];
        }
        if (locations?.length === 1) {
            notificationToastError("At least one location should be selected");
            return;
          }
        delete formState[`documentLocation${inLabs.length}`];
    
        setLocations(locationObjects);
    
        setFormState(formState);
      };
    return (
      <>
      {
         formState?.locationBased  ?
         locations?.map((paramNameIn,index) => (
          <Grid item lg={12} md={12} id="location-pullDown" key={index}>
              <Box sx={{ display: "flex", alignItems: "baseline", justifyContent:"space-between"}} >
                  <LocationPulldown
                      key={paramNameIn}
                      formState={formState}
                      updateInput={updateInput}
                      formErrorState={formErrorState}
                      attributeName={paramNameIn}
                      selectedLocationAttributeNames={locations.filter(
                          (paramNameFilter) => paramNameFilter !== paramNameIn
                      )}
                      style={{ maxWidth: "350px" }}
                      index={index}
                  />
                  <Box>
                      <Button
                          onClick={addinLabLocations}
                          variant="outlined"
                          sx={{ marginRight: "1rem", height: "fit-content" }}
                          data-testid="add-location-button"
                      >
                          Add Location
                      </Button>

                      <Button
                          onClick={() => removeinLabLocations(paramNameIn)}
                          variant="outlined"
                          sx={{ height: "fit-content" }}
                          data-testid="remove-location-button"
                      >
                          Remove
                      </Button>
                  </Box>
                
              </Box>
          </Grid>
      )) : ''
      }
      </>
       

    )
}

export default MultipleLocation