import { useState, useEffect } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import { FormControl } from "../../node_modules/@mui/material/index";
import { useAxios } from "../hooks/auth";
import ConfirmDialog from "../components/misc/Dialog";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../utils/notification";
import { adminPostData, pulldownsPostData } from "../axios/api";
import {
  Button,
  MenuItem,
  Select,
  InputLabel,
  Typography,
} from "@mui/material";

function ReportsView({ handleClose, viewReports, refreshData, setRefreshData }) {
  const axios = useAxios();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [roles, setRoles] = useState([]);
  const [roleCount, setRoleCount] = useState(1);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    pulldownsPostData(axios, "rolePulldown")
      .then((res) => {
        if (res?.data) {
          setRoles(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (viewReports) {
      const assignedRoleIdArray = viewReports?.assignedRoleId?.split(',').map(value => Number(value.trim()));
      setRoleCount(assignedRoleIdArray?.length || 1)
      setSelectedRoles(assignedRoleIdArray || [])
    }
  }, [viewReports]);

  const updateInput = (event, index) => {
    const updatedRoles = [...selectedRoles];
    updatedRoles[index] = event.target.value;
    setSelectedRoles(updatedRoles);
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  const saveChanges = (e) => {
    e.preventDefault();
    const rolesString = selectedRoles
      .map((value, i) => `assignedRole${i}: ${value}`)
      .join(', ');
    const payload = {
      reportId: viewReports.reportId,
      ...selectedRoles.reduce((acc, value, i) => {
        acc[`assignedRole${i}`] = value;
        return acc;
      }, {}),
    };
    setIsSaveButtonDisabled(true);
    adminPostData(axios, "saveReports", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
          setRefreshData(!refreshData)
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((ex) => {
        notificationToastError(ex?.message);
      })
      .finally(() => {
        handleClose();
      });
  };

  const addSelect = () => {
    setRoleCount((prevCount) => prevCount + 1);
  };

  const removeSelect = (index) => {
    const updatedRoles = [...selectedRoles];
    updatedRoles.splice(index, 1);
    setSelectedRoles(updatedRoles);
    setRoleCount((prevCount) => prevCount - 1);
    setIsSaveButtonDisabled(false);
  };
  

  return (
    <ConfirmDialog
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "Save changes" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
    >
      <Typography variant="h6" data-testid="verification-title-text">
        Edit Assigned Roles For - {viewReports.reportName}
      </Typography>
     
      {Array.from({ length: roleCount }).map((_, index ) => (
         <Box
          key={index}
          sx={{ display: "flex", alignItems: "baseline", marginBottom: "1rem" }}
        >
          <FormControl variant="standard" sx={{ m: 1, width: "97%" }}>
            <InputLabel id={`group-label-${index}`}>
              Select Role {index + 1}
            </InputLabel>
            <Select
              data-testid={`group-pulldown-${index}`}
              labelId={`group-label-${index}`}
              label={`group-${index}`}
              value={ selectedRoles[index] || ""}
              onChange={(event) => updateInput(event, index)}
            >
              {roles.map((item) => (
                <MenuItem key={item.roleId} value={item.roleId}>
                  {item.role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {index === 0 && (
            <>
              <Button
                onClick={addSelect}
                variant="outlined"
                sx={{ marginRight: "1rem", height: "fit-content" }}
              >
                Add
              </Button>
            </>
          )}
          {index > 0 && (
            <Button
              onClick={() => removeSelect(index)}
              variant="outlined"
              sx={{ height: "fit-content" }}
            >
              Remove
            </Button>
          )}
        </Box>
))}
      
      

    </ConfirmDialog>
  );
}

export default ReportsView;
