function generateRandomId() {
    const randomNumber = Math.random().toString(36).substring(2);
    const randomId = `id_${randomNumber}`;
    return randomId;
  }
export const YES_NO = [
    {
        id : generateRandomId(),
        value: "Yes"
    },
    {
        id : generateRandomId(),
        value: "No"
    }
]