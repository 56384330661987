import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const PressureComponent = ({ updateInput, dmeOrderState, updateCheckBox }) => {
  return (
    <Box
      sx={{
        border: "1px solid rgba(0,0,0, 0.12)",
        borderRadius: "4px",
        marginTop: "20px",
        padding: "16px 5px",
        height: "90%",
      }}
    >
      <Typography variant="h6">AIRWAY PRESSURE</Typography>
      <Grid container rowGap={1.5}>
        <Grid item lg={4} xs={6} sm={4} md={6}>
          <TextField
            // placeholder='IPAP PRESSURE'
            id="ipapPress"
            onChange={updateInput}
            label="IPAP Pressure"
            name="ipapPress"
            value={dmeOrderState?.ipapPress || ""}
            type="text"
            variant="standard"
            helperText="min/max in CM/H2O"
          />
        </Grid>
        <Grid item lg={4} xs={6} sm={4} md={6}>
          <TextField
            // placeholder='IPAP PRESSURE'
            id="epapPress"
            onChange={updateInput}
            label="EPAP Pressure"
            name="epapPress"
            value={dmeOrderState?.epapPress || ""}
            type="text"
            autoComplete="email"
            variant="standard"
            helperText="min/max in CM/H2O"
          />
        </Grid>
        <Grid item lg={4} xs={6} sm={4} md={6}>
          <TextField
            // placeholder='IPAP PRESSURE'
            id="rate"
            onChange={updateInput}
            label="Rate"
            name="rate"
            value={dmeOrderState?.rate || ""}
            type="text"
            variant="standard"
            helperText="BPM"
          />
        </Grid>
        <Grid item lg={8} xs={12} sm={8} md={12}>
          <Typography variant="h6">AVAPS / AVAPS-AE</Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(dmeOrderState?.titrate)}
                onChange={updateCheckBox}
                name="titrate"
              />
            }
            label="TITRATE TO PATIENT’S COMFORT"
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={4} md={12}>
          <TextField
            // placeholder='IPAP PRESSURE'
            id="tidalVol"
            onChange={updateInput}
            label="Tidal volume"
            name="tidalVol"
            value={dmeOrderState?.tidalVol || ""}
            type="number"
            variant="standard"
            helperText="in ml"
          />
        </Grid>
        <Grid
          item
          lg={8}
          xs={12}
          sm={8}
          md={12}
          sx={{ display: "flex", gap: "16px", alignItems: "center" }}
        >
          <TextField
            // placeholder='IPAP PRESSURE'
            id="estTimeMonths"
            onChange={updateInput}
            // label="IPAP Pressure"
            name="estTimeMonths"
            value={dmeOrderState?.estTimeMonths || ""}
            type="text"
            variant="standard"
            // helperText="min/max in CM/H2O"
          />
          <Typography variant="h6">OR</Typography>
          <TextField
            // placeholder='IPAP PRESSURE'
            id="estTimeOther"
            onChange={updateInput}
            // label="IPAP Pressure"
            name="estTimeOther"
            value={dmeOrderState?.estTimeOther || ""} 
            // type='text'
            // autoComplete="email"
            variant="standard"
            // helperText="BPM"
          />
        </Grid>

        <Grid item lg={4} xs={12} sm={4} md={6}>
          <TextField
            // placeholder='IPAP PRESSURE'
            id="estTimeramp"
            onChange={updateInput}
            label="RAMP"
            name="estTimeramp"
            value={dmeOrderState?.estTimeramp || ""}
            type="number"
            variant="standard"
            helperText="minutes"
          />
        </Grid>
        <Grid item lg={12} xs={12} md={12}>
          <TextField
            sx={{ width: "95%" }}
            // placeholder='IPAP PRESSURE'
            id="otherDesc"
            onChange={updateInput}
            label="Other"
            name="otherDesc"
            value={dmeOrderState?.otherDesc || ""}
            type="text"
            variant="standard"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PressureComponent;
