import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAuth, useAxios } from '../../../hooks/auth'
import { resetPasswordPostData } from "../../../axios/api";
import { useState } from 'react'
import { notificationToastError, notificationToastSuccess } from '../../../utils/notification'
import { validateEmail } from '../../../utils/emailValidator'
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
          
        </Typography>
    )
}

export const theme = createTheme()

export default function ForgotPassword() {
    
    const { login, error,setError } = useAuth()
    const passwordRef = React.useRef("")
    const [showPassword, setShowPassword] =useState(false);
    const [isvalidEmail, setIsvalidEmail] = useState(true);
    const navigate = useNavigate();
    const axios = useAxios();
    const location = useLocation();
    const isPatientPassReset = location?.state?.row
    ? 1
    : null;

    React.useEffect(() => {
        const handleMouseDown =(e)=>{
            if (showPassword && passwordRef?.current && !passwordRef?.current.contains(e.target)) {
                setShowPassword(false)
            }
        }
        document.addEventListener("mousedown", handleMouseDown);
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [showPassword])
    React.useEffect(() => {
        if (error) {
            notificationToastError(error)
            setError(null)
        }
    }, [error])
    
    const handleInputField = (e) => {
        if (showPassword) {
            setShowPassword(false)
        }
        if (!isvalidEmail) {
          setIsvalidEmail(true)  
        }
    }
    const handleSubmit = async (event) => {
        let isValid =true;
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        let email =data.get('email') 
        if (email) {
            if(!validateEmail(email)){
              isValid = false;  
            }
          } else isValid = false;

          if (!isValid) { 
            setIsvalidEmail(false);
            notificationToastError("Please enter valid email")
          } else {
              sendEmail(email);
             // login({ email, password: data.get('password') })
          }
    }

    const sendEmail = (email) => {
    
        const payload = {
          email
        };
        let paramName ;
        if (isPatientPassReset) paramName = "resetPatientPassword" ; else paramName = "resetPasswordSignIn" ;
        resetPasswordPostData(axios, paramName, payload)
          .then((res) => {
            if (res?.data?.response === "1") {
              notificationToastSuccess(res?.data?.message);
            } else {
              notificationToastError(res?.data?.message);
            }
          })
          .catch((err) => {
            notificationToastError(err?.message);
          });
       
      };

   

    return (
        <ThemeProvider theme={theme}>
            <Container
                component="main"
                maxWidth="xs"
                sx={{ backgroundColor: 'white' }}
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h2" variant="h5">

                    SleepNavigator®
                    </Typography>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    Reset Password
                    </Typography>
                    <Typography component="h1" variant="body2">
                    Please enter your email in the field below and click 'Reset'.
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            onChange={handleInputField}
                            label="Enter Email Address"
                            name="email"
                            type='email'
                            autoComplete="email"
                            error={!isvalidEmail}
                            helperText={
                                !isvalidEmail ? "please enter valid email" : ""
                              }
                            autoFocus
                        />
                        
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Reset
                        </Button>
                        <Link
                        component="button"
                        variant="body2"
                        onClick={()=>navigate('/signin-landing')}
                      >
                       Go Back to Sign In 
                      </Link>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2"></Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
                <ToastContainer/>
            </Container>
        </ThemeProvider>
    )
}
