
import { Box, Button, CircularProgress, Dialog, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { useAxios } from '../../hooks/auth';
import { documentFormData, patientViewGetData } from '../../axios/api';
import { useSelector } from 'react-redux';
import FileView from '../../pages/patients/components/FileUpload/FileView';
import { base64ToPdf } from '../../utils/base64ToPDFConvertor';
import { notificationToastError } from '../../utils/notification';
import CloseIcon from "@mui/icons-material/Close";  
import CygnatureView from '../../pages/patients/components/CygnatureView';
import EpworthSleepinessScale from '../../pages/forms/EpworthSleepinessScale';
import QualityImprovementSurvey from '../../pages/forms/QualityImprovementSurvey';
import SatisfactionSurvey from '../../pages/forms/SatisfactionSurvey';
import BerlinQuestionaire from '../../pages/forms/BerlinQuestionaire';

const FormTable = ({ studyId = "" ,visitDataObj,patientIdForm, consentForm, reloadCurrentVisit, orderId, fromPatientPortal}) => {
  const axios = useAxios();
  const { patientId, patientReduxData } = useSelector(state => state?.patientLogInDataSelector);
  const [patientInfo, setPatientInfo] = useState(null)
  const [formListData, setFormListData] = useState([])
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [openCygnatureModal, setOpenCygnatureModal] = useState(false)
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" })
  const [signURLState, setSignURLState] = useState({ isloaded: false, signUrl: "", signedDocId: null })
  const [documentId, setDocumentId] = useState(null)
  const [reloadRequire, setReloadRequire] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [formSigned, setFormSigned] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [epworthFormOpen, setEpworthFormOpen] = useState(false);
  const [qualityImproveOpen, setQualityImproveOpen] = useState(false);
  const [satisfactionSurveyOpen, setSatisfactionSurveyOpen] = useState(false);
  const [berlinQuestionaireOpen, setBerlinQuestionaireOpen] = useState(false);
  // console.log("formListData==>", formListData);


  useEffect(() => {

  if (!patientReduxData?.patientId) {
      const paramsName = "viewPatient";
      let patientId = consentForm ? patientIdForm : patientId
   
      patientViewGetData (
        axios,
        paramsName,
        patientId
      ).then((res) => {
        if (res?.length) {
          setPatientInfo(res[0])
    
        }
      }).catch((err) => {
        console.log("err==>", err);
      })
   // }, [reloadRequire,epworthFormOpen])

  } else setPatientInfo (patientReduxData)

}, [patientReduxData?.patientId])



  useEffect(() => {
    const paramsName = "forms";
   
    const payload = {
      patientId: consentForm ? patientIdForm : patientId,
      patientPortal: 1,
      studyId,
      orderId
    }

  
    documentFormData(axios, paramsName, payload).then((res) => {
      if (res?.length) {
        setFormListData(res)
        
      }
    }).catch((err) => {
      console.log("err==>", err);
    })
  }, [reloadRequire,epworthFormOpen])
 
  const handleFillSignView = (rowData) => {
    setDocumentId(rowData[0]);
    setOpenCygnatureModal(true)
    if (!signURLState?.isloaded) {
      const payload = {
        patientPortal: 1,
        signFormTemplateId: rowData[4],
        patientId: consentForm ? patientIdForm : patientId,
        studyId: studyId,
      }
      setSignURLState({ ...signURLState, isloaded: true })
      documentFormData(axios, "fillSign", payload).then((res) => {
        if (res?.signUrl) {
          // setOpenCygnatureModal(true)
          setSignURLState({ ...signURLState, signUrl: res?.signUrl, signedDocId: res?.signedDocId })
        } else {
          notificationToastError(res?.message);
        }
      }).catch((err) => {
        notificationToastError(err?.message);
      })
        .finally(() => {
          setSignURLState((prevState) => {
            return {
              ...prevState, isloaded: false
            }
          })
        });
    }
  }

  useEffect(() => {
    if(signURLState?.signUrl && openCygnatureModal) {
      window?.embeddedCygnature.open({
        url: signURLState?.signUrl,
        containerID: "iframeContainer",
        events: {
          loaded: function () {
            setIsIframeLoaded(true);
          },
          signed: function () {
            setDisabledButton(true);
            setOpenCygnatureModal(false);
            const payload = {
              patientId: patientId,
              studyId: studyId,
              documentId: documentId,
              signedDocId: signURLState?.signedDocId,
            }
            documentFormData(axios, "formSigned", payload).then((res) => {
              // console.log("res data==>", res?.data);
              setReloadRequire(true);
              setFormSigned(true);
              window.location.reload();
            }).catch((err) => {
              console.log("err==>", err);
            })
          },
          declined: function () {
            console.log("declined Callback");
          },
          error: function () {
            console.log("error Callback");
          },
        },
      });
    }
  },[signURLState, openCygnatureModal])

  useEffect(() => {
    if(formSigned && signURLState?.signedDocId) {
      documentFormData(axios, "downloadDetailsPurgeSignedDoc", {signedDocId: signURLState?.signedDocId}).then((res) => {
        setDisabledButton(false);
      }).catch((err) => {
        console.log("err==>", err);
      })
    }
  },[formSigned])

const handleFileView = (rowData) => {
  const payload = {
    fileId: rowData[5]
  }
  documentFormData(axios, "downloadSignedForm", payload).then((res) => {
    if (res?.file) {

      setOpenPdfModal(true)
      let base64 = res?.file;
      base64 = base64?.split(',')[1]
      let fileType = res?.name;
      fileType = fileType?.split('.')?.pop();
      const pdfUrl = base64ToPdf(base64, fileType);
      setPdfURLState({
        fileUrl: pdfUrl,
        fileType,
        fileName: res?.name?.split('.')[0]
      })

    } else {
      notificationToastError("File was not downloaded due to issue.");
    }
  }).catch((err) => {
    console.log("err==>", err);
  })

}

const handleWebForm = (docWebFormId) =>{
  switch (docWebFormId) {
    case 4:
      setEpworthFormOpen(true)
      break;
    case 3:
      setQualityImproveOpen(true)
      break;
    case 2:
      setSatisfactionSurveyOpen(true)
      break;
    case 1:
      setBerlinQuestionaireOpen(true)
      break;
  }
}

const handleClose = () =>{
  setEpworthFormOpen(false)
}

const qualityImproveClose = () =>{
  setQualityImproveOpen(false)
}

const satisfactionSurveyClose = () =>{
  setSatisfactionSurveyOpen(false)
}

const berlinQuestionaireClose = () =>{
  setBerlinQuestionaireOpen(false)
}

return (
  <>
  {consentForm ? "" : <Typography variant='h6' mb={1.5} mt={1.5}>Please fill out and sign the following forms : </Typography>}
  <Box mb={1.5} mt={1.5}>
    <MUIDataTable
       
      className="mui-form-data"
      data={formListData}
      columns={[
        {
          name: "documentId",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "title",
          label: "Form Name",
        },

        {
          name: "description",
          label: "Description",
          options: { display: consentForm ? false : true, viewColumns: false, filter: false },
          // options: {
          //   customBodyRender: (value, tableMeta, updateValue) => {
          //     // console.log("tableMeta=======>",tableMeta?.rowData[0]);
          //     // const form = tableMeta.rowData[2];
          //     return (
          //       <Box
          //         sx={{
          //           display: "flex",
          //           justifyContent: "center",
          //           cursor: "pointer",
          //           textDecoration: "underline",
          //           color: "#00008b",
          //         }}
          //         onClick={() => {
          //         //   patientView(tableMeta?.rowData);
          //         }}
          //       >
          //         {value}
          //       </Box>
          //     );
          //   },
          // },
        },
        {
          name: "status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <>{ (( tableMeta.rowData[5] === 1 || tableMeta.rowData[11] === 1 || tableMeta.rowData[8] === 1 || tableMeta.rowData[9] === 1 || tableMeta.rowData[10] === 1 ) || tableMeta.rowData[5]) ? "Completed" : "Incomplete"}</>;
            },
          },
        },
        {
          name: "signFormTemplateId",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "fileId",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "webForm",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "docWebFormId",
          options: { display: false, viewColumns: false, filter: false },
        },

        {
          name: "epworthComplete",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "berlinComplete",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "satisfactionComplete",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "qualityComplete",
          options: { display: false, viewColumns: false, filter: false },
        },

        {
          name: "Actions",
          label: "Actions",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                 ( ((tableMeta.rowData[11] !== 1 && tableMeta.rowData[8] !== 1 && tableMeta.rowData[9] !== 1 && tableMeta.rowData[10] !== 1 ) && !tableMeta.rowData[5]) && ((fromPatientPortal ) || (!fromPatientPortal && tableMeta.rowData[6] === 1 ))) ?
                  <Button
                    variant="contained"
                    onClick={tableMeta.rowData[6] === 1 ? handleWebForm.bind(null,tableMeta.rowData[7]) : handleFillSignView.bind(null, tableMeta.rowData)}
                    data-testid="view-pdf-link"
                    disabled={disabledButton}
                  >
                     Fill / Sign
                    {/* {!signURLState?.isloaded ? 'Fill / Sign' : <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}><CircularProgress style={{ color: 'white' }} size={20} /> Fill / Sign</Box>} */}
                  </Button>
                  :
                  ( tableMeta.rowData[11] === 1 || tableMeta.rowData[8] === 1 || tableMeta.rowData[9] === 1 || tableMeta.rowData[10] === 1 ) ?
                  <Button
                    variant="contained"
                    onClick={ handleWebForm.bind(null,tableMeta.rowData[7])}
                    data-testid="view-pdf-link"
                    disabled={disabledButton}
                  >
                    View 
                  </Button>
                  :
                  
                  ( tableMeta.rowData[5] ) ?
                  <Button
                    variant="contained"
                    onClick={handleFileView.bind(null, tableMeta.rowData)}
                    data-testid="view-pdf-link"
                    disabled={disabledButton}
                  >
                    View 
                  </Button>
                  :
                  ""
              );
            },
          },
        },
      ]}
      options={{
        selectableRows: 'none', // <===== will turn off checkboxes in rows
        rowsPerPageOptions: [],
        serverSide: true,
        responsive: "vertical",
      }}
    />
    </Box>
    {
      openPdfModal &&
      <FileView data-testid="modal-pdf-dialog" setOpenPdfModal={setOpenPdfModal} openPdfModal={openPdfModal} pdfURLState={pdfURLState} isPatientPortal={true} />

    }
    {openCygnatureModal && <CygnatureView setOpenCygnatureModal={setOpenCygnatureModal} openCygnatureModal={openCygnatureModal} signURLState={signURLState} setSignURLState={setSignURLState} isIframeLoaded={isIframeLoaded}/> }

    {/* webform model */}

    {epworthFormOpen && <EpworthSleepinessScale asDialog={true} handleClose={handleClose} patientData={patientInfo} studyId = {studyId} reloadFormTable = {setReloadRequire} providerPortal={consentForm} orderId={orderId} />}
    
    {qualityImproveOpen && <QualityImprovementSurvey asDialog={true} handleClose={qualityImproveClose} patientData={patientInfo} studyId = {studyId} reloadFormTable = {setReloadRequire} providerPortal={consentForm}/>}

    {satisfactionSurveyOpen && <SatisfactionSurvey asDialog={true} handleClose={satisfactionSurveyClose} patientData={patientInfo} visitDataObj={visitDataObj} studyId = {studyId}  reloadFormTable = {setReloadRequire} providerPortal={consentForm}/>}

    {berlinQuestionaireOpen && <BerlinQuestionaire asDialog={true} handleClose={berlinQuestionaireClose} patientData={patientInfo} studyId = {studyId} reloadFormTable = {setReloadRequire} providerPortal={consentForm} />}
  </>
)
}

export default FormTable