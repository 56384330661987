import { useAuth } from "./auth";
import roleAndPrivilegesFnc from "../utils/roleAndPriviledges";
function useIsGroupId () {
    const auth = useAuth();
    if(auth){
        const {groupId}=roleAndPrivilegesFnc(auth?.cookies?.token);
        return  groupId;
    }
   
}

export default useIsGroupId;