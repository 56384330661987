import React, { useEffect, useState } from 'react'
import ConfirmDialog from './Dialog'
import { Box, CircularProgress, Dialog, FormControl, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import phoneNumberValidator from '../../utils/phoneNumberValidator'
import classes from "../../components/patientsComponent/currentVisits/currentVisits.module.css";
import { useSelector } from 'react-redux';
import { visitsGetData } from '../../axios/api';
import OrderView from '../../pages/patients/components/OrderView';
import { useAxios } from '../../hooks/auth';
import { Troubleshoot } from '@mui/icons-material';
import moment from "moment";

const ReschedulePopup = ({ handleRescheduleClose, row,dob , dos = "Scheduled DOS:" }) => {
    const [formState, setFormState] = useState({})
    const [isOrderOpen, setIsOrderOpen] = useState(false);
    const [surveyAndOrderId, setSurveyAndOrderId] = useState({});
    const [visitsData, setVisitsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const axios = useAxios();
    const isMobile = useMediaQuery("(max-width:800px)");

    useEffect(() => {
        if (row) {
            setFormState({
                patientId: row?.patientId,
                orderId: row?.orderIdOrder,
                stageId: row?.stageId,
                contacAttempt: row?.contacAttemptOrder,
                dmeOrderId: row?.dmeOrderIdOrder,
                patUnreachable: row?.patUnreachable,
                contactLaterDate: row?.contactLaterDate,
                studyDeclined: row?.studyDeclined,
                SDATE: row?.dateOfService1 || row?.date,
                patientName: row?.patientName,
                bestPhone: row?.bestPhone,
                cellPhone: row?.cellPhone,
            });
            const setVistData = () => {
                const patientId = row?.patientId
                visitsGetData(axios, patientId).then((res) => {
                    setVisitsData(res)
                }).catch((err) => {
                    console.log("err==>", err);
                }).finally(() => {
                    setIsLoading(false)
                })
            }
            if (row?.patientId) {
                setVistData()
            }
        }
    }, [row]);

    const patientNameVal = row?.patientName ?? formState?.patientNameVal;
    const cellPhoneVal = phoneNumberValidator(row?.cellPhone) ?? formState?.cellPhone;
    const bestPhoneVal = phoneNumberValidator(row?.bestPhone) ?? formState?.bestPhone;

    const handleOrderVisit = async ({
        stages,
        currentStatus,
        origOrderId,
        orderId,
        origPatientId,
        patientId,
        surveyId,
        origSurveyId,
        stageId,
    }) => {
        setIsOrderOpen(true);
        setSurveyAndOrderId({
            orderId: origOrderId || orderId,
            patientId: origPatientId || patientId,
            surveyId: origSurveyId || surveyId,
            currentStatus,
            isOrderOpen: true,
            stages,
            stageId,
        });
    };

    const handleClose = () => {
        setIsOrderOpen(false);
        setSurveyAndOrderId({});
      };
    return (
        <>
           
            <Box
                component="div"
                sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                }}
            >
                <TextField
                    id="patientName"
                    label="Patient Name"
                    variant="standard"
                    value={patientNameVal}
                    readOnly
                    InputProps={{ disableUnderline: true, readOnly: true }}
                />
                {dob ?  <TextField
                        id="dob"
                        label="Patient DOB"
                        variant="standard"
                        value={dob ? moment(dob).format("MM/DD/YYYY") : moment(row?.dob).format("MM/DD/YYYY")|| ""}
                        readOnly
                        InputProps={{ disableUnderline: true, readOnly: true }}
                    /> :
                    <TextField
                        id="bestPhone"
                        label="Best Contact Number "
                        variant="standard"
                        value={bestPhoneVal?.formattedPhoneNumber || ""}
                        readOnly
                        InputProps={{ disableUnderline: true, readOnly: true }}
                    />
                }

                <TextField
                    id="cellPhone"
                    label="Cell Phone Number"
                    variant="standard"
                    value={cellPhoneVal?.formattedPhoneNumber || ""}
                    readOnly
                    InputProps={{ disableUnderline: true, readOnly: true }}
                />
            </Box>

            <Box
                component="div"
                sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    marginTop: 2
                }}
            >
                <Grid item lg={3.9} md={4} xs={12}>
                    {/* {item?.origOrderId && ( */}
                    <>
                        <Typography variant="strong">Order: </Typography>
                        <Typography
                            data-testid="order-open-link"
                            variant="link"
                            className={classes?.visitDate}
                            onClick={() => {
                                handleOrderVisit(visitsData[0]);
                            }}
                        >
                            Click here to open Order
                        </Typography>
                    </>
                    {/* )} */}
                </Grid>
                { formState?.SDATE && (
                   <FormControl sx={{ marginLeft: "1rem"}}>
                   <Typography > { dos } {moment(formState?.SDATE).format("MM/DD/YYYY")} </Typography>
                </FormControl>
                ) }
            </Box>

            {/* order visit popup */}

            {isOrderOpen && (
                <Dialog
                    open={isOrderOpen}
                    onClose={handleClose}
                    data-testid="order-survey-dialog"
                    sx={{
                        "& .MuiDialog-paper": {
                            maxWidth: isMobile ? "auto" : "1200px",
                            minHeight: isMobile ? "auto" : "400px",
                        },
                        "& .MuiDialogContent-root": {
                            display: "flex",
                            flexDirection: "column",
                        },
                    }}
                >
                    {surveyAndOrderId?.isOrderOpen && (
                        <OrderView
                            surveyAndOrderId={surveyAndOrderId}
                            isOrderModal
                            handleClose={handleClose}
                            reschedulePopup={true}
                            data={row}
                        />
                    )}
                </Dialog>
            )}
    </>
    )
}

export default ReschedulePopup