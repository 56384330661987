import React, { useEffect, useState } from 'react'
import { patientViewGetData, patientsPostData } from '../../../axios/api';
import { useAxios } from '../../../hooks/auth';
import { Box, Button, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { notificationToastError, notificationToastSuccess } from '../../../utils/notification';
import { Download, Delete, Preview, Visibility } from '@mui/icons-material';
import { Buffer } from 'buffer';
import FileView from '../../../pages/patients/components/FileUpload/FileView';
import { dateTimeConverter } from '../../../utils/dateConverter';
import FileUpload from '../../../pages/patients/components/FileUpload/FileUpload';

function VisitsFile({ item }) {
  const [fileData, setFileData] = useState([]);
  const [refreshData, setRefreshData] = useState(false)
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" })
  const axios = useAxios()
  useEffect(() => {
    if (item) {
      const patientId = item?.origPatientId || item?.patientId;
      const orderId = item.origOrderId || item?.orderId;
      const studyId = item?.studyId;
      const dmeOrderId = item.origDmeOrderId || item?.dmeOrderId;
      const setFileDataFirstTime = () => {
        patientViewGetData(axios, "listFiles", patientId, orderId, studyId,dmeOrderId ).then((res) => {
          if (res) {
            setFileData(res);
          }
        }).catch((err) => {
          console.log("err", err);
        })
      }
      if (patientId && orderId) {
        setFileDataFirstTime()
      }
    }


  }, [item, refreshData])
  const base64ToPdf = (base64String, fileType) => {
    let typeFile = fileType === "pdf" ? "application/pdf" : "image/png"
    const buffer = Buffer.from(base64String, "base64");
    const url = URL.createObjectURL(
      new Blob([buffer], { type: typeFile })
    );
    return url;
  };
  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    }
    patientsPostData(axios, "downloadFile", payload).then((res) => {
      if (res.data.file) {
        if (isView === "view") {
          setOpenPdfModal(true)
          let base64 = res?.data?.file;
          base64 = base64?.split(',')[1]
          let fileType = res?.data?.name;
          fileType = fileType?.split('.')?.pop();
          const pdfUrl = base64ToPdf(base64, fileType);
          setPdfURLState({
            fileUrl: pdfUrl,
            fileType
          })
        } else {
          notificationToastSuccess("File is downloading.");
          const downloadLink = document.createElement("a");
          downloadLink.href = res.data.file;
          downloadLink.download = res.data.name;
          downloadLink.click();
        }

      } else {
        notificationToastError("File was not downloaded due to issue.");
      }
      // setRefreshData(!refreshData);
    }).catch((err) => notificationToastError(err?.message))


  };

  const deleteRecord = (id, tableName, idColumn) => {
    const yesToDelete = window.confirm("Are you sure you want to delete it? ");
    if (!yesToDelete) return;
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    }
    patientsPostData(axios, "deleteRecord", payload).then((res) => {
      if (res.data.response === "1") {
        notificationToastSuccess(res?.data?.message);
      } else {
        notificationToastError(res?.data?.message);
      }
      setRefreshData(!refreshData);
    }).catch((err) => notificationToastError(err?.message))
  };
  return (
    <Box
    component="form"
    sx={{
      "& .MuiTextField-root": { m: 1 },
      border: "1px solid rgba(0,0,0, 0.12)",
      borderRadius: "4px",
      marginTop: "20px",
    }}
    noValidate
    autoComplete="off"
  >
    <h4
      style={{
        paddingLeft: "0.5rem",
      }}
    >
      Files
    </h4>
    <FileUpload
      patientId={item?.origPatientId || item?.patientId}
      setRefreshData={setRefreshData}
      refreshData={refreshData}
      orderId={item?.orderId || item.origOrderId}
      studyId={item?.studyId}
    />
    <MUIDataTable
      data={fileData}
      className="mui-data-table-files" 
      columns={[
        {
          name: "fileId",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "patientId",
          options: { display: false, viewColumns: false, filter: false },
        },
        {
          name: "fileName",
          label: "File Name",
        },
        {
          name: "category",
          label: "Category",
        },
        {
          name: "storedDate",
          label: "Date Created",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const formattedDate = dateTimeConverter(value); // Format the date using Moment.js
              return formattedDate;
            },
          },
        },

        {
          name: "Actions",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Tooltip title="View" placement="bottom">
                    <Button
                      sx={{ width: 60, marginTop: "1rem" }}
                      variant="contained"
                      size="small"
                      onClick={download.bind(
                        null,
                        tableMeta.rowData ? tableMeta.rowData[0] : "",
                        "storedFiles",
                        "fileId",
                        "view"
                      )}
                    >
                      <Visibility />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Download" placement="bottom">
                    <Button
                      sx={{ width: 60, marginTop: "1rem" }}
                      variant="contained"
                      size="small"
                      onClick={download.bind(
                        null,
                        tableMeta.rowData ? tableMeta.rowData[0] : "",
                        "storedFiles",
                        "fileId"
                      )}
                    >
                      <Download />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Delete" placement="bottom">
                    <Button
                      sx={{ width: 60, marginTop: "1rem" }}
                      variant="contained"
                      size="small"
                      onClick={deleteRecord.bind(
                        null,
                        tableMeta.rowData ? tableMeta.rowData[0] : "",
                        "storedFiles",
                        "fileId"
                      )}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                  {
                    openPdfModal &&
                    <FileView setOpenPdfModal={setOpenPdfModal} openPdfModal={openPdfModal} pdfURLState={pdfURLState} />

                  }
                </Box>
              );
            },
          },
        },
      ]}
      options={{
        selectableRows: 'none', // <===== will turn off checkboxes in rows
      }}
    />
    </Box>
  )
}

export default VisitsFile