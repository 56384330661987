import { Box, Typography } from "@mui/material";

 export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
      style={{width:"100%"}}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "0px 1px "}}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }
  