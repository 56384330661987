import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { studyPostData } from "../../axios/api";

export default function TreatmentAppointmentDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  treatmentAppoinmentData = {},
}) {
  const [formErrorState, setFormErrorState] = useState({ SDATE: "" });
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  React.useEffect(() => {
    if (row?.patientId) {
      setFormState({
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        SDATE: row?.dateTreatmentAppt,
        completedTreatmentAppt: row?.completedTreatmentAppt,
        canceledTreatmentAppt: row?.canceledTreatmentAppt,
        incompleteTreatmentAppt: row?.incompleteTreatmentAppt,
        techNotes: row?.techNotes,
        incompleteTreatmentApptDetails: row?.incompleteTreatmentApptDetails,
        reasonCancelingTreatmentAppt: row?.reasonCancelingTreatmentAppt,
        physicianId: row?.physicianIdStudy,
        locationId: row?.locationIdStudy,
        studyId: row?.studyIdStudy,
        reasonRescheduling: row?.reasonReschedulingId,
        dmeOrderId: row?.dmeOrderIdOrder,
      });
    } else if (treatmentAppoinmentData) {
      // console.log("treatmentAppoinmentData", treatmentAppoinmentData)
      setFormState({
        ...formState,
        ...treatmentAppoinmentData,
        SDATE: treatmentAppoinmentData?.SDATE,
      });
    }
  }, [row]);

  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
    setIsSaveButtonDisabled(false);
  };

  const handleMainCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    formState["completedTreatmentAppt"] = 0;
    formState["incompleteTreatmentAppt"] = 0;
    formState["canceledTreatmentAppt"] = 0;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };

    if (
      !formState.SDATE &&
      (formState?.completedTreatmentAppt === "1" ||
        formState?.completedTreatmentAppt === 1)
    ) {
      setErrorSDATE(true);
      setFormErrorState({ SDATE: { error: true } });
      return false;
    }

    if (
      !formState.reasonCancelingTreatmentAppt &&
      (formState?.canceledTreatmentAppt === "1" ||
        formState?.canceledTreatmentAppt === 1)
    ) {
      isValid = false;
      formErrorState["reasonCancelingTreatmentAppt"] = { error: true };
    }
    if (
      !formState.incompleteTreatmentApptDetails &&
      (formState?.incompleteTreatmentAppt === "1" ||
        formState?.incompleteTreatmentAppt === 1)
    ) {
      isValid = false;
      formErrorState["incompleteTreatmentApptDetails"] = { error: true };
    }

    if (!isValid) {
      setErrorMessage("Please fill all required fields");
      setFormErrorState({ ...formErrorState });

      //setFormErrorState(errors);
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      dateTreatmentAppt: formState.SDATE,
    };

    studyPostData(axios, "treatmentAppointmentInfo", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSDATE(newValue.format("YYYY-MM-DD"));
    setFormState({ ...formState, SDATE: newValue.format("YYYY-MM-DD") });
  };

  const patientNameVal = row?.patientName;

  // console.log(
  //   {
  //     formErrorState: formErrorState?.reasonCancelingTreatmentAppt?.error,
  //     formState: formState?.reasonCancelingTreatmentAppt,
  //   },
  //   "Sapar"
  // );

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <Box>
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6">Treatment Appointment Info:</Typography>
          </FormControl>
        </div>

        <TextField
          id="patientName"
          label="Patient Name"
          variant="standard"
          value={patientNameVal}
          readOnly
          InputProps={{ disableUnderline: true }}
        />

        <Box sx={{ marginTop: 1 }}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  id="completedTreatmentAppt"
                  checked={Boolean(formState.completedTreatmentAppt)}
                  onChange={handleMainCheckboxChange}
                />
              }
              label="Completed Appt"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  id="incompleteTreatmentAppt"
                  checked={Boolean(formState.incompleteTreatmentAppt)}
                  onChange={handleMainCheckboxChange}
                />
              }
              label="Incomplete Appt"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  id="canceledTreatmentAppt"
                  checked={Boolean(formState.canceledTreatmentAppt)}
                  onChange={handleMainCheckboxChange}
                />
              }
              label="Cancel Appt"
            />
          </FormControl>
        </Box>
        {formState?.canceledTreatmentAppt == 1 && (
          <TextField
            id="reasonCancelingTreatmentAppt"
            {...getErrorValidationProperty("reasonCancelingTreatmentAppt")}
            label="Reason for Canceling"
            variant="standard"
            onChange={updateInput}
            value={formState.reasonCancelingTreatmentAppt}
            required
            error={
              formErrorState?.reasonCancelingTreatmentAppt?.error &&
              !formState?.reasonCancelingTreatmentAppt
            }
            helperText={
              formErrorState?.reasonCancelingTreatmentAppt?.error &&
              !formState?.reasonCancelingTreatmentAppt
                ? "required"
                : ""
            }
            onMouseDown={handleFocus}
          />
        )}

        {formState?.incompleteTreatmentAppt == 1 && (
          < FormControl variant="standard" sx={{ width: "100%" }}>
          <TextField
            id="incompleteTreatmentApptDetails"
            {...getErrorValidationProperty("incompleteTreatmentApptDetails")}
            label="Incomplete Appointment Details "
            variant="standard"
            onChange={updateInput}
            value={formState.incompleteTreatmentApptDetails}
            required
            error={
              formErrorState?.incompleteTreatmentApptDetails?.error &&
              !formState?.incompleteTreatmentApptDetails
            }
            helperText={
              formErrorState?.incompleteTreatmentApptDetails?.error &&
              !formState?.incompleteTreatmentApptDetails
                ? "required"
                : ""
            }
            onMouseDown={handleFocus}
          />
          </FormControl>
        )}

        {formState?.completedTreatmentAppt == 1 && (
          <Box
            sx={{
              marginTop: "1rem",
            }}
          >
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label=" Date of Treatment Complete * "
                  inputFormat="MM/DD/YYYY"
                  value={SDATE || formState.SDATE || ""}
                  onChange={updateSDATE}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      error={errorSDATE && !SDATE}
                      helperText={errorSDATE && !SDATE ? "required" : ""}
                      value={
                        SDATE
                          ? moment.utc(SDATE.toLocaleString()).format("MM/DD/YYYY")
                          : ""
                      }
                      onBlur={() => {
                        setErrorSDATE(!SDATE);
                      }}
                      onMouseDown={handleFocus}
                      placeholder="mm/dd/yyyy"
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            <Box>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <TextField
                  id="techNotes"
                  label="Tech Notes"
                  value={formState.techNotes}
                  variant="standard"
                  onChange={updateInput}
                />
              </FormControl>
            </Box>
          </Box>
        )}
        {!asDialog && !isPreviousVisits && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Box>
    );
  }
}
