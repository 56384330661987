import { configureStore } from '@reduxjs/toolkit'
import practiceReducer from './slices/practiceSlice'
import selectedPatientReducer from './slices/selectedPatientSlice'
import  sideBarCollapseSlice  from './slices/sideBarCollapseSlice'
import  snackBar  from './slices/snackBarSlice'
import dashboardDataSlice from './slices/dashboardDataSlice'
import patientLogInDataSlice from './slices/patientLogInDataSlice'
import adminPathSlice from './slices/adminPathSlice'
export default configureStore({
  reducer: {
    practiceSelector: practiceReducer,
    selectedPatientSelector: selectedPatientReducer,
    sideBarCollapseSelector:sideBarCollapseSlice,
    snackBarSelector:snackBar,
    dashboardDataSlice :dashboardDataSlice,
    patientLogInDataSelector:patientLogInDataSlice,
    adminPathSelector :adminPathSlice
    
  },
})