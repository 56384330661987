import { Height } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React from 'react'

function MaskInterFace({ updateCheckBox, dmeOrderState,errorState }) {
  return (
    <Box
      sx={{
        border: "1px solid rgba(0,0,0, 0.12)",
        borderRadius: "4px",
        marginTop: '20px',
        padding: "16px 5px",
        height:"85%"
      }}
    >
         <Typography variant="h6" sx={{ color: errorState ? "#d32f2f" : "" }}>
        MASK INTERFACE KIND:select one(Required) *
      </Typography>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0, 0.12)",
          margin: "10px 3px",
          padding: "5px 8px"
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
          
          <FormControlLabel
            control={<Checkbox checked={Boolean(dmeOrderState?.A7034)} onChange={updateCheckBox} name="A7034" />}
            label="NASAL MASK: 1 per 3 MO (A7034)"
          />

          <Typography variant="h6">AND</Typography>
        </Box>
        <FormControlLabel
          control={<Checkbox checked={Boolean(dmeOrderState?.A7032)} onChange={updateCheckBox} name="A7032" />}
          label="Nasal mask cushion: 2 per 1 MO (A7032)"
        />
        <Typography variant="h6">OR</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
          <FormControlLabel
            control={<Checkbox checked={Boolean(dmeOrderState?.A7030)} onChange={updateCheckBox} name="A7030" />}
            label=" FULL FACE MASK : 1 per 3 MO (A7030)"
          />
          <Typography variant="h6">AND</Typography>
        </Box>
        <FormControlLabel
          control={<Checkbox checked={Boolean(dmeOrderState?.A7031)} onChange={updateCheckBox} name="A7031" />}
          label="Full face mask cushions: 1 per MO (A7031)"
        />

      </Box>
      <FormControlLabel
        sx={{ marginLeft: '2px' }}
        control={
        <Checkbox checked={Boolean(dmeOrderState?.allItemMasked)} onChange={updateCheckBox} name="allItemMasked" />}
        label={<Typography variant='h6'>ALL ITEMS ABOVE (NON-MEDICARE PATIENTS ONLY)</Typography>}
      />
    </Box>
  )
}

export default MaskInterFace