import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'

const PAPDevice = ({ updateInput,errorState,dmeOrderState }) => {
   return (
      <Box sx={{
         border: "1px solid rgba(0,0,0, 0.12)",
         borderRadius: "4px",
         marginTop: '20px',
         padding: "16px 10px",
         height:"85%"
      }}>
        <Typography variant="h6" sx={{ color: errorState ? "#d32f2f" : "" }}>
        SLEEP THERAPY PAP DEVICE : select one(Required) *
      </Typography>
         <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={dmeOrderState?.papDevice}
            name="papDevice"
            id='papDevice'
            onChange={updateInput}
         >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
               <FormControlLabel value="cpap" control={<Radio  checked={ dmeOrderState?.papDevice === "cpap" ? true : false}/>} label="CPAP " /> <Typography variant='h6'>OR</Typography>
               <FormControlLabel value="autoCpap" control={<Radio checked={ dmeOrderState?.papDevice === "autoCpap" ? true : false}/>} label="AutoCPAP(E0601) " />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
               <FormControlLabel value="bipap" control={<Radio checked={ dmeOrderState?.papDevice === "bipap" ? true : false}/>} label="BiPAP " />
               <Typography variant='h6'>OR</Typography>
               <FormControlLabel value="autoBipap" control={<Radio checked={ dmeOrderState?.papDevice === "autoBipap" ? true : false}/>} label="Auto BiPAP(E0470) " />
            </Box>
            <FormControlLabel value="bipapst" control={<Radio checked={ dmeOrderState?.papDevice === "bipapst" ? true : false}/>} label="BiPAPST(E0471) " />
            <FormControlLabel value="asv" control={<Radio checked={ dmeOrderState?.papDevice === "asv" ? true : false} />} label="ASV(E0471)" />
            <FormControlLabel value="avaps" control={<Radio checked={ dmeOrderState?.papDevice === "avaps" ? true : false} />} label="AVAPS(E0466)" />
            <FormControlLabel value="avapsAe" control={<Radio checked={ dmeOrderState?.papDevice === "avapsAe" ? true : false}/>} label="AVAPS-AE / Trilogy (E0466)" />
         </RadioGroup>
      </Box>
   )
}
export default PAPDevice