import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useState } from "react";
import * as React from "react";
import SelectComponent from "../../components/formComponent/SelectComponent";
import classes from './SatisfactorySurvey.module.css';
import ConfirmDialog from "../../components/misc/Dialog";
import { webformPostData, webFormsGetData } from "../../axios/api";
import moment from "moment";
import { notificationToastError, notificationToastSuccess } from "../../utils/notification";
import { useAxios } from "../../hooks/auth";
import { useNavigate } from 'react-router-dom';
import { Download } from "@mui/icons-material";  
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function QualityImprovementSurvey({ asDialog, handleClose, patientData, studyId, reloadFormTable = () => {}, providerPortal }) {
  const isMobile =  useMediaQuery('(max-width:800px)');
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
  });
  const [formState, setFormState] = useState({});
  const [surveyCheck, setSurveyCheck] = useState({});
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false)
  const qualityRef = React.useRef(null);

  const onDownload = () => {
    const input = qualityRef.current;

    // Use html2canvas to convert HTML to canvas
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('qualityImprovementSurvey.pdf');
    });
  }

  React.useEffect(() => {

    if (studyId > 0) {
    
      webFormsGetData (axios, studyId, "qualitySurveyForm").then((res) => {
    
          setFormState({
            ...formState,
            ...res[0],
            patientId: patientData?.patientId,
            lastName: patientData?.lastName,
            firstName: patientData?.firstName,
            midInit: patientData?.middleInitial,
            date: patientData?.dob,
            gender: patientData?.gender,
            age: patientData?.age,
            studyId: studyId,
          })
      }).catch((err) => {
          console.log("err==>", err);
      }).finally(() => {
          setIsLoading(false)
      })

  
    } else {

    setFormState({
      ...formState,
      patientId: patientData?.patientId,
      lastName: patientData?.lastName,
      firstName: patientData?.firstName,
      date: patientData?.dob,
      midInit: patientData?.middleInitial,
      studyId: studyId,
     
    })

  }

  }, [patientData])

  const updateInput = (e) => {
    setFormState({ ...formState, [e.target.id || e.target.name]: e.target.value });
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const [DOB, setDOB] = React.useState(null);
  const updateDOB = (newValue) => {
    setFormState({
      ...formState,
      date:newValue?.format("YYYY-MM-DD")
    })
    setDOB(newValue);
  };
  const [errorDOB, setErrorDOB] = React.useState(false);

  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id || e.target.name]: e.target.checked,
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState, bangScore: sum, [e.target.id || e.target.name]: e.target.value });

    setSurveyCheck(newSurveyState);
  };

  const saveChanges = () => {
    let isValid = true
    if (isValid) {
      setIsDisabled(true)
      const payload = {
        ...formState,
        date: moment(formState.date).format('YYYY-MM-DD'),
        providerPortal: providerPortal ? 1 : 0

      };
      webformPostData(axios, "submitQualImpSurvey", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
           
            notificationToastSuccess(res?.data?.message);
            reloadFormTable(true)
            setIsDisabled(false)
           // setTimeout(() => {   
              
              //navigate('/patient-view') ;
              //window.location.reload();
           // }, 2000);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((ex) => {
          notificationToastError(ex?.message);
        })
        .finally(() => {
          handleClose();
        });
    }
  };

  let isSaveButtonDisabled = false;
 if (formState?.completed) isSaveButtonDisabled = true; 

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        maxWidth="none"
      isSaveButtonDisabled={isSaveButtonDisabled || isDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <div >
        <Button
          sx={{ width: 130, float: "right", mt: 2, mr: 2 }}
          variant="contained"
          onClick={() => onDownload()}
        >
          Download <Download />
        </Button>
      <Container
        maxWidth="lg"
        sx={{
          borderStyle: "double"
        }}
        ref={qualityRef}
      >
        <div
          style={{
            marginTop:"1rem",
            textAlign: "center",
          }}
        >
          {/* <h2>WellNecessities</h2>
        <h4 style={{ paddingTop: "1rem" }}>
          8835 LINE AVENUE•SHREVEPORT LA •71106 • OFFICE 800.892.9994•FAX
          318.222.0883
      </h4> */}
          <Typography
            variant='h4'
            sx={{ textDecoration: "underline" }}
          >
            QUALITY IMPROVEMENT SURVEY
          </Typography>
        </div>
        <div
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
            color :"#1976d2"
          }}
        >
          Thank you for choosing us for your care. We are committed to providing
          you with quality sleep testing with exceptional care. In order for us to
          monitor and/or improve our services, we kindly ask for you to take a few
          minutes to complete this survey. We appreciate your time and feedback.
        </div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
            border:"double",
            borderColor:"rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginTop: "40px",
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <Box sx={{ display: isMobile ? 'grid' : "flex", gridTemplateColumns: isMobile ? '1fr 1fr' : "0fr 0fr", width: "100%" }}>
              <TextField
                id="lastName"
                {...getErrorValidationProperty("lastName")}
                label="Last Name"
                value={formState.lastName || ""}
                variant="standard"
                onChange={updateInput}
                required
              />
              <TextField
                id="firstName"
                label="First Name"
                {...getErrorValidationProperty("firstName")}
                value={formState.firstName || ""}
                variant="standard"
                onChange={updateInput}
                required
              />
              <TextField
                id="midInit"
                label="MI"
                value={formState?.midInit || ""}
                variant="standard"
                onChange={updateInput}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="DOB *"
                  inputFormat="MM/DD/YYYY"
                  value={formState.date}
                  onChange={updateDOB}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
            border:"double",
            borderColor:"rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginTop: "1rem",
            marginBottom: asDialog ? "1rem": "0" ,
          }}
        >
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              "& >.MuiBox-root": { marginBottom: "1rem" },
              marginLeft: "1rem",
              marginTop: "1rem",
            }}
          >
            <Box >
              <Typography variant="body1" fontWeight={600}>
                1.	How was your scheduling process by our team?
              </Typography>

              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="schedulingProcess"
                  value={formState.schedulingProcess || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box>
            <Typography variant="body1" fontWeight={600}>
              2. How do you rate the information provided at time of scheduling?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="informationProvide"
                  value={formState.informationProvide || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>

            <Box >
              <Typography variant="body1" fontWeight={600}>
                3.	How would you rate the ease of the registration process?
              </Typography>

              <Box>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="registrationProcess"
                  value={formState.registrationProcess || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>

            <Box >
              <Typography variant="body1" fontWeight={600}>
                4.	Flow was the comfort of the waiting area?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="waitingArea"
                  value={formState.waitingArea || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box>
              <Typography variant="body1" fontWeight={600}>
                5.	Flow was the cleanliness of the bedroom?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="cleanlinessBedroom"
                  value={formState.cleanlinessBedroom || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box>
              <Typography variant="body1" fontWeight={600}>
                6.	Degree of safety and concern you felt in the sleep lab?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="safetySleepLab"
                  value={formState.safetySleepLab || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box>
              <Typography variant="body1" fontWeight={600}>
                7.	How would you rate the overall concern for your comfort?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="concernComfort"
                  value={formState.concernComfort || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box>
              <Typography variant="body1" fontWeight={600}>
                8.	Flow would rate the staff's explanation of the procedure?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="explanationProcedure"
                  value={formState.explanationProcedure || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>
            {/* <Box>
          <Typography variant="body1">
              9.	Was the technician courteous?
          </Typography>
            <Box>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="technicianCourteous"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              9. {" "}
              <SelectComponent id="technicianCourteous" label="Was the technician courteous?" formState={formState} updateInput={updateInput} />
            </Box>
            <Box className={classes.selectionWrapper}>
              10. {" "}
              <SelectComponent id="technicianIntroduce" label="Did the technician introduce him/her self?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            <Typography variant="body1">
              10.	Did the technician introduce him/her self?
            </Typography>

            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="technicianIntroduce"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              11. {" "}
              <SelectComponent id="technicianAskAnything" label="Did the technician ask if you needed anything (water, blanket)?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            <Typography variant="body1">
              11.	Did the technician ask if you needed anything (water, blanket)?
            </Typography>

            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="technicianAskAnything "
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              12. {" "}
              <SelectComponent id="technicianInformed" label="Did the technician keep you informed of any delays?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            <Typography variant="body1">
              12.	Did the technician keep you informed of any delays?

            </Typography>
            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="technicianInformed"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              13. {" "}
              <SelectComponent id="receiveInstruction" label="Before leaving, did you receive A sheet labeled -
              HOMECARE INSTRUCTION along with an explanation?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            <Typography variant="body1">
              13.	Before leaving, did you receive A sheet labeled -
              HOMECARE INSTRUCTION along with an explanation?
            </Typography>

            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="receiveInstruction"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}
            <Box className={classes.selectionWrapper}>
              14. {" "}
              <SelectComponent id="contactedHospital" label="If you were scheduled 3 days or more days prior to your sleep study, were you contacted by a Hospital Representative regarding your out-of-pocket estimate?" formState={formState} updateInput={updateInput} />
            </Box>
            {/* <Box >
            <Typography variant="body1">
              14.	If you were scheduled 3 days or more days prior to your sleep study, were you contacted by a Hospital Representative regarding your out-of-pocket estimate?

            </Typography>

            <Box>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="contactedHospital"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box> */}

            <Box >
              <Typography variant="body1" fontWeight={600}>
                15. Degree of staff's concern for your privacy
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="concernPrivacy"
                  value={formState.concernPrivacy || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>

            <Box >
              <Typography variant="body1" fontWeight={600}>
                16. How do you rate your Overall satisfaction?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="overallSatisfaction"
                  value={formState.overallSatisfaction || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>


            <Box   >
              <Typography variant="body1" fontWeight={600}>
                17 . Would you recommend our services to others?
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="recommendService"
                  value={formState?.recommendService || ""}
                  onChange={updateSurveyCheck}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Very Dissatisfied "
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Somehow Dissatisfied "
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Satisfied "
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label="Very Satisfied "
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="Not Applicable "
                  />
                </RadioGroup>
              </Box>
            </Box>

            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
              }}
            >
              <TextField
                id="sleepRelatedComplaints"
                label="What can we do to improve our quality of service?"
                value={formState?.sleepRelatedComplaints || ""}
                variant="standard"
                onChange={updateInput}
              />
            </Box>
            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
              }}
            >
              <TextField
                id="patientSignature"
                label="Patient Signature"
                value={formState?.patientSignature || ""}
                variant="standard"
                onChange={updateInput}
              />
            </Box>
          </Box>
        </Box>

        {
          !asDialog &&
          <Box sx={{ paddingTop: isMobile ? "1rem" : "2rem", paddingBottom: isMobile ? "4rem" : "2rem" }}>
          <Button variant="contained" onClick={saveChanges} disabled={isDisabled}>Submit</Button>
        </Box>
        }

      </Container>
      </div>
    );
  }
}

export default QualityImprovementSurvey;
