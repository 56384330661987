import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useState } from "react";
import * as React from "react";
import ConfirmDialog from "../../components/misc/Dialog";
import { notificationToastError, notificationToastSuccess } from "../../utils/notification";
import { webformPostData, webFormsGetData } from "../../axios/api";
import { useAxios } from "../../hooks/auth";
import moment from "moment";
import { Download } from "@mui/icons-material";  
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function EpworthSleepinessScale({ asDialog, handleClose, patientData, studyId , reloadFormTable = () => {}, providerPortal, orderId}) {
  const isMobile = useMediaQuery('(max-width:800px)');
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
  });
  const [formState, setFormState] = useState({});
  const [surveyCheck, setSurveyCheck] = useState({});
  const axios = useAxios();
  const [webFormData, setWebFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false)
  const epworthRef = React.useRef(null);

  const onDownload = () => {
    const input = epworthRef.current;

    // Use html2canvas to convert HTML to canvas
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('epworthSleepinessScale.pdf');
    });
  }

  React.useEffect(() => {

    if (studyId > 0 || orderId > 0) {
    
      webFormsGetData (axios, studyId, "epworthForm", orderId).then((res) => {
       
          setFormState({
            ...formState,
            ...res[0],
            patientId: patientData?.patientId,
            lastName: patientData?.lastName,
            firstName: patientData?.firstName,
            date: patientData?.dob,
            gender: patientData?.gender,
            age: patientData?.age,
            studyId: studyId,
            orderId: orderId
          })
      }).catch((err) => {
          console.log("err==>", err);
      }).finally(() => {
          setIsLoading(false)
      })

 
  
    } else {

    setFormState({
      ...formState,
      patientId: patientData?.patientId,
      lastName: patientData?.lastName,
      firstName: patientData?.firstName,
      date: patientData?.dob,
      gender: patientData?.gender,
      age: patientData?.age,
      studyId: studyId,
      orderId: orderId
    })

  }

  }, [patientData])


  const updateInput = (e) => {
    setFormState({ ...formState, [e.target.id || e.target.name]: e.target.value });
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const [date, setDate] = React.useState(null);
  const updateDate = (newValue) => {
    setFormState({
      ...formState,
      date:newValue?.format("YYYY-MM-DD") 
    })
    setDate(newValue);
  };
  const [errorDate, setErrorDate] = React.useState(false);



  const [changeCheck, setChangeCheck] = useState({});
  const handleChange = (e) => {
    const newChangeState = {
      ...changeCheck,
      [e.target.id || e.target.name]: e.target.value,
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newChangeState)) {
      if (value) sum += Number(value);
    }

    setChangeCheck(newChangeState);

    setFormState({ ...formState, totalScore: sum });

    setSurveyCheck(newChangeState);
  }

  const saveChanges = () => {
    let isValid = true
    if (isValid) {
      setIsDisabled(true)
      const payload = {
        ...formState,
        ...changeCheck,
        date: moment(formState.date).format('YYYY-MM-DD'),
        providerPortal: providerPortal ? 1 : 0
      };
      webformPostData(axios, "submitEpworth", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            
            notificationToastSuccess(res?.data?.message);
            reloadFormTable(true)
            setIsDisabled(false)
           /* setTimeout(() => {   
              window.location.reload();
            }, 2000);
            */
          } else {
            notificationToastError(res?.data?.message);
          }

        })
        .catch((ex) => {
          notificationToastError(ex?.message);
        })
        .finally(() => {
          handleClose();
        });
    }
  };

 let isSaveButtonDisabled = false;
 if (formState?.completed) isSaveButtonDisabled = true; 

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }} 
        maxWidth="none"
       isSaveButtonDisabled={isSaveButtonDisabled || isDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {

    return (
      <div >
        <Button
          sx={{ width: 130, float: "right", mt: 2, mr: 2 }}
          variant="contained"
          onClick={() => onDownload()}
        >
          Download <Download />
        </Button>
        <Container
        maxWidth="lg"
        sx={{
          borderStyle: "double",
        }}
        ref={epworthRef}
      >
        <div
          style={{
            marginTop:"1rem",
            textAlign: "center",
          }}
        >
          {/* <Typography variant="h3">WellNecessities</Typograp hy>
        <Typography variant="h5" style={{ paddingTop: "1rem" }}>
          8835 LINE AVENUE•SHREVEPORT LA •71106 • OFFICE 800.892.9994•FAX
          318.222.0883
        </Typography>
      */}

          <Typography
            variant='h4'
            sx={{ textDecoration: "underline" }}
          >
            The Epworth Sleepiness Scale
          </Typography>

        </div>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
            borderRadius: "4px",
            border:"double",
            borderColor:"rgba(0,0,0, 0.12)",
            marginTop: "40px",
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
              flexDirection: isMobile ? "column" : "unset"
            }}
          >
            <TextField
              id="lastName"
              // {...getErrorValidationProperty("lastName")}
              label="Last Name"
              value={formState.lastName || ""}
              variant="standard"
              onChange={updateInput}
              required
            />
            <TextField
              id="firstName"
              label="First Name"
              // {...getErrorValidationProperty("firstName")}
              value={formState.firstName || ""}
              variant="standard"
              onChange={updateInput}
              required
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="DOB *"
                inputFormat="MM/DD/YYYY"
                value={formState.date}
                onChange={updateDate}
                renderInput={(params) => 
                <TextField 
                {...params} 
                variant="standard"
                />
              }
              />
            </LocalizationProvider>
            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
              }}
            >
              <FormControl variant="standard" sx={{ width: 120, m: 1 }}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  name="gender"
                  value={formState.gender || ""}
                  label="Gender"
                  onChange={updateInput}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              id="age"
              label="Age"
              // sx={{
              //   maxWidth: "5rem",
              // }}
              value={formState.age || ""}
              variant="standard"
              onChange={updateInput}
            />
          </Box>
        </Box>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
            border:"double",
            borderColor:"rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginTop: "1rem",
            marginBottom: asDialog ? "1rem": "0" ,
          }}
        >

          <Box
            component="div"
            sx={{
              "& >.MuiBox-root": { marginBottom: '2rem' },
              marginLeft: "1rem",
              marginTop: "1rem",
            }}
          >
            <Box sx={{width:"100%"}}>
              <Typography variant="body1" sx={{ fontWeight: 600,}}>
                How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired? This refers to your standard way of life in recent times. Even if you have not done some of these things recently try to
                work out how they would have affected you. Use the following scale to choose the most appropriate number for each situation.
              </Typography>
              <div style={{marginTop:"10px",width:"80%"}}>
                <Typography variant="body1" color={"#1976d2"}>
                  0 = Would never doze
                </Typography>
                <Typography variant="body1" color={"#1976d2"}>
                  1 = Slight chance of dozing
                </Typography>
                <Typography variant="body1" color={"#1976d2"}>
                2 = Moderate chance of dozing
                </Typography>
                <Typography variant="body1" color={"#1976d2"}>
                  3 = High chance of dozing
                </Typography>
              </div>
            </Box>

            <Typography variant="h5" sx={{ fontSize: "20px" }}>Situation and Chance of Dozing :</Typography>
            <Box sx={{ marginTop: '1rem' }}>
              <Typography variant="body1" fontWeight={600}>
                Sitting and reading
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  id="sittingReading"
                  name="sittingReading"
                  value={formState?.sittingReading || changeCheck.sittingReading || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Would never doze" />
                  <FormControlLabel value="1" control={<Radio />} label="Slight chance of dozing  " />
                  <FormControlLabel value="2" control={<Radio />} label="Moderate chance of dozing  " />
                  <FormControlLabel value="3" control={<Radio />} label="High chance of dozing " />
                </RadioGroup>
              </Box>
            </Box>

            <Box >
              <Typography variant="body1" fontWeight={600}>
                Watching TV
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="watchingTV"
                  id="watchingTV"
                  value={formState?.watchingTV || changeCheck.watchingTV || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Would never doze" />
                  <FormControlLabel value="1" control={<Radio />} label="Slight chance of dozing  " />
                  <FormControlLabel value="2" control={<Radio />} label="Moderate chance of dozing  " />
                  <FormControlLabel value="3" control={<Radio />} label="High chance of dozing " />
                </RadioGroup>
              </Box>
            </Box>

            <Box >
              <Typography variant="body1" fontWeight={600}>
                Sitting inactive in a public place (e.g., a theater or a meeting)
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="sittingInactive"
                  id="sittingInactive"
                  value={formState?.sittingInactive || changeCheck.sittingInactive || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Would never doze" />
                  <FormControlLabel value="1" control={<Radio />} label="Slight chance of dozing  " />
                  <FormControlLabel value="2" control={<Radio />} label="Moderate chance of dozing  " />
                  <FormControlLabel value="3" control={<Radio />} label="High chance of dozing " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                As a passenger in a car for an hour without a break
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="withoutBreak"
                  id="withoutBreak"
                  value={formState?.withoutBreak || changeCheck.withoutBreak || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Would never doze" />
                  <FormControlLabel value="1" control={<Radio />} label="Slight chance of dozing  " />
                  <FormControlLabel value="2" control={<Radio />} label="Moderate chance of dozing  " />
                  <FormControlLabel value="3" control={<Radio />} label="High chance of dozing " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                Lying down to rest in the afternoon when circumstances permit
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="restAfternoon"
                  id="restAfternoon"
                  value={formState?.restAfternoon || changeCheck.restAfternoon || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Would never doze" />
                  <FormControlLabel value="1" control={<Radio />} label="Slight chance of dozing  " />
                  <FormControlLabel value="2" control={<Radio />} label="Moderate chance of dozing  " />
                  <FormControlLabel value="3" control={<Radio />} label="High chance of dozing " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                Sitting and talking to someone

              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="sittingTalking"
                  id="sittingTalking"
                  value={formState.sittingTalking  || changeCheck.sittingTalking || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Would never doze" />
                  <FormControlLabel value="1" control={<Radio />} label="Slight chance of dozing  " />
                  <FormControlLabel value="2" control={<Radio />} label="Moderate chance of dozing  " />
                  <FormControlLabel value="3" control={<Radio />} label="High chance of dozing " />
                </RadioGroup>
              </Box>
            </Box>
            <Box  >
              <Typography variant="body1" fontWeight={600}>
                Sitting quietly after a lunch without alcohol
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="sittingQuietly"
                  id="sittingQuietly"
                  value={formState?.sittingQuietly || changeCheck.sittingQuietly || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Would never doze" />
                  <FormControlLabel value="1" control={<Radio />} label="Slight chance of dozing  " />
                  <FormControlLabel value="2" control={<Radio />} label="Moderate chance of dozing  " />
                  <FormControlLabel value="3" control={<Radio />} label="High chance of dozing " />
                </RadioGroup>
              </Box>
            </Box>
            <Box >
              <Typography variant="body1" fontWeight={600}>
                In a car, while stopped for a few minutes in traffic
              </Typography>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="stoppedTraffic"
                  id="stoppedTraffic"
                  value={formState?.stoppedTraffic || changeCheck.stoppedTraffic || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel value="0" control={<Radio />} label="Would never doze" />
                  <FormControlLabel value="1" control={<Radio />} label="Slight chance of dozing  " />
                  <FormControlLabel value="2" control={<Radio />} label="Moderate chance of dozing  " />
                  <FormControlLabel value="3" control={<Radio />} label="High chance of dozing " />
                </RadioGroup>
              </Box>
            </Box>

            <Typography variant="h5" >Total Score :</Typography>
            <Box
              component="div"
              sx={{
                "& .MuiFormControl-root": { flexGrow: 1 },
                display: "flex",
              }}
            >
              <Grid item lg={2} md={5} sm={12} xs={12}>
                <TextField
                  id="totalScore"
                  label="Total Score ( /24):"
                  value={formState.totalScore === undefined ? "" : formState.totalScore}
                  variant="standard"
                />
              </Grid>
            </Box>
          </Box>
        </Box>
        {
          !asDialog &&
          <Box sx={{ paddingTop: isMobile ? "1rem" : "2rem", paddingBottom: isMobile ? "4rem" : "2rem" }}>
            <Button variant="contained" onClick={saveChanges} disabled={isDisabled}>Submit</Button>
          </Box>
        }

      </Container>
      </div> 
    );
  }
}

export default EpworthSleepinessScale;
