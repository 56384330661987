import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useAxios } from "../../../hooks/auth";
import CustomizedDialogs from "./ScrollDialog";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  useMediaQuery,
} from "../../../../node_modules/@mui/material/index";
import { useState } from "react";
import * as React from "react";
import PatientSearch from "../../../pages/PatientSearch";
import { updateSelectedPatient } from "../../../store/slices/selectedPatientSlice";
import PhysicianPulldown from "../../../components/misc/PhysicianPulldown";
import InsuranceInfo from "./InsuranceInfo";
import moment from "moment";
import phoneNumberValidator from "../../../utils/phoneNumberValidator";
import FileUpload from "./FileUpload/FileUpload";
import SelectedPatients from "./selectPatients/SelectedPatients";
import { patientsPaginationGetData, surveyPostData } from "../../../axios/api";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../utils/notification";
import CloseRejectDialog from "./CloseRejectDialog";
import PatientInfo from "./PatientInfo";
import { ToastContainer } from "react-toastify";
import { readOnlyBackground } from "../../../Theme/theme";
import { CLOSED_SURVEY } from "../../../constants/visitsPatients";

function SurveyView({ surveyAndOrderId,isOrderModal, handleClose }) {
  const isMobile = useMediaQuery('(max-width:800px)');
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const [refreshData, setRefreshData] = useState(false);
  const [formState, setFormState] = useState({});
  const [viewCloseSurveyState, setViewCloseSurveyDialogState] = useState(false);
  const [BMIpat, setBMIpatState] = useState({});

  const closeSurveyDialog = () => {
    setViewCloseSurveyDialogState(false);
    setRefreshData(!refreshData);
  };
  const [patientsPhoneNumber, setPatientsPhoneNumber] = useState({
    bestPhone: "",
    cellPhone: "",
    bestPhoneError: false,
    cellPhoneError: false,
  });

  const updatePhoneNumber = (e) => {
    const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(
      e.target.value
    );
    setPatientsPhoneNumber({
      ...patientsPhoneNumber,
      [e.target.id]: numericPhoneNumber,
      [`${e.target.id}Error`]: false,
    });

    setFormState((prevState) => {
      return {
        ...prevState,
        [e.target.id]: formattedPhoneNumber,
      };
    });
  };

  const [isEmailRequired, setIsEmailRequired] = useState(false);

  React.useEffect(() => {
    const  row  = location?.state?.row;
    let payload = {};
    if (row) {
      payload = {
        patientId: row[0],
        surveyId: row[10],
      }
    } else if (surveyAndOrderId) {
      payload = {
        patientId: surveyAndOrderId?.patientId,
        surveyId: surveyAndOrderId?.surveyId,
      }
    }
    if (payload?.patientId) {

      patientsPaginationGetData(axios, "patientView", payload).then((res) => {
        if (res?.length) {
          const patientData = res[0]
          const {
            formattedPhoneNumber: bestPhone,
            numericPhoneNumber: bestPhoneNumeric,
          } = phoneNumberValidator(patientData?.bestPhone);
          const {
            formattedPhoneNumber: cellPhone,
            numericPhoneNumber: cellPhoneNumeric,
          } = phoneNumberValidator(patientData?.cellPhone);
          setPatientsPhoneNumber({
            ...patientsPhoneNumber,
            bestPhone: bestPhoneNumeric,
            cellPhone: cellPhoneNumeric,
          });
          setFormState((prevState) => ({
            ...prevState,
            ...patientData,
            bestPhone,
            cellPhone,
            stageId: row ? row[95] : surveyAndOrderId?.stageId,
            orderId : row ? row[11] : surveyAndOrderId?.orderId
          }));
          setBMIpatState({
            ...BMIpat,
            heightFeet: patientData?.heightFeet,
            heightInches: patientData?.heightInches,
            patBMI: patientData?.patBMI,
            patWeight: patientData?.patWeight,
          });
          if (patientData?.dob)  setDOB(dayjs(patientData?.dob))
        }
      }).catch((err) => {
        console.log("err", err);
      }).finally(() => {
      })

      if (payload?.surveyId) {
        surveyPostData(axios, "viewSurvey", payload).then((res) => {
          if (res?.data?.length) {
            const surveyData = res?.data[0]
            

            setFormState((prevState) => ({
              ...prevState,
              ...surveyData,
              interpPhysId: surveyData?.physicianId,
            }));
            setSurveyCheck({
              ...surveyCheck,
              snoring: surveyData?.snoring,
              choking: surveyData?.choking,
              tired: surveyData?.tired,
              bloodPressure: surveyData?.bloodPressure,
              overFifty: surveyData?.overFifty,
              largeNeck: surveyData?.largeNeck,
              genderMale: surveyData?.genderMale,
              patientBMI: surveyData?.patientBMI,
            })
            setBMIState({ ...BMI, ...surveyData })
          }
        }).catch((err) => {

        })
      }
    }
  }, [])
  // React.useEffect(() => {
  //   if (location?.state) {
  //     const { row, patientInfo } = location?.state;
  //     if (row) {
  //       const { formattedPhoneNumber:bestPhone} = phoneNumberValidator(row[87]);
  //       const { formattedPhoneNumber:cellPhone } = phoneNumberValidator(row[88]);
  //       const payload = {
  //         patientId: row[0],
  //         surveyId: row[10],
  //       }
  //       surveyPostData(axios, "viewSurvey", payload)
  //         .then((res) => {
  //           if (res?.data?.length) {
  //             const surveyData = res?.data[0]

  //             setBMIState({ ...BMI, ...surveyData })
  //             setFormState({
  //               ...formState, ...surveyData,
  //               patientNum: row[1],
  //               lastName: row[2],
  //               firstName: row[3],
  //               dob: row[4],
  //               middleInitial: row[80],
  //               gender: row[5],
  //               address1: row[81],
  //               address2: row[82],
  //               city: row[83],
  //               state: row[84],
  //               zip: row[85],
  //               email: row[86],
  //               bestPhone: bestPhone,
  //               cellPhone: cellPhone,
  //               primaryInsuranceId: row[108],
  //               secondaryInsuranceId: row[112],
  //               primaryPolicy: row[110],
  //               primaryGroup: row[111],
  //               secondaryPolicy: row[114],
  //               secondaryGroup: row[115],
  //               stageId: row[95],
  //               statusNameId: row[96],
  //               surveyId: row[10],
  //               orderId: row[11],
  //               bmi: row[15],
  //               usingCpap: row[79],
  //               choking: row[16],
  //               genderMale: row[18],
  //               heightFt: row[19],
  //               heightIn: row[20],
  //               largeNeck: row[21],
  //               legacyStatus: row[22],
  //               overFifty: row[23],
  //               papMachine: row[24],
  //               snoring: row[25],
  //               stopbangScore: row[26],
  //               tired: row[27],
  //               weight: row[28],
  //               bloodPressure: row[14],
  //               interpPhysId: row[13],
  //               locationId: row[12],
  //               patientId: row[0],
  //               patientBMI: row[97]
  //             })
  //             setSurveyCheck({
  //               ...surveyCheck,
  //               snoring: surveyData?.snoring,
  //               choking: surveyData?.choking,
  //               tired: surveyData?.tired,
  //               bloodPressure: surveyData?.bloodPressure,
  //               overFifty: surveyData?.overFifty,
  //               largeNeck: surveyData?.largeNeck,
  //               genderMale: surveyData?.genderMale,
  //               patientBMI: surveyData?.patientBMI,
  //             })
  //           } else {
  //             setFormState({
  //               ...formState,
  //               patientNum: row[1],
  //               lastName: row[2],
  //               firstName: row[3],
  //               dob: row[4],
  //               middleInitial: row[79],
  //               gender: row[5],
  //               address1: row[81],
  //               address2: row[82],
  //               city: row[83],
  //               state: row[84],
  //               zip: row[85],
  //               email: row[86],
  //               bestPhone: bestPhone,
  //               cellPhone: cellPhone,
  //               primaryInsuranceId: row[108],
  //               secondaryInsuranceId: row[112],
  //               primaryPolicy: row[110],
  //               primaryGroup: row[111],
  //               secondaryPolicy: row[114],
  //               secondaryGroup: row[115],
  //               stageId: row[95],
  //               statusNameId: row[96],
  //               surveyId: row[10],
  //               orderId: row[11],
  //               bmi: row[15],
  //               usingCpap: row[79],
  //               choking: row[16],
  //               genderMale: row[18],
  //               heightFt: row[19],
  //               heightIn: row[20],
  //               largeNeck: row[21],
  //               legacyStatus: row[22],
  //               overFifty: row[23],
  //               papMachine: row[24],
  //               snoring: row[25],
  //               stopbangScore: row[26],
  //               tired: row[27],
  //               weight: row[28],
  //               bloodPressure: row[14],
  //               interpPhysId: row[13],
  //               locationId: row[12],
  //               patientId: row[0],
  //               patientBMI: row[97],
  //             });
  //           }
  //         })
  //         .catch((err) => console.log("errr", err));
  //       // setBMIState({heightFt: row[19],
  //       //   heightIn: row[20], bmi: row[15],  weight: row[28] })

  //       if (row[4]) setDOB(dayjs(row[4]));
  //     } else if (patientInfo) {
  //       const {
  //         formattedPhoneNumber: bestPhone,
  //         numericPhoneNumber: bestPhoneNumeric,
  //       } = phoneNumberValidator(patientInfo?.bestPhone);
  //       const {
  //         formattedPhoneNumber: cellPhone,
  //         numericPhoneNumber: cellPhoneNumeric,
  //       } = phoneNumberValidator(patientInfo?.cellPhone);
  //       setPatientsPhoneNumber({
  //         ...patientsPhoneNumber,
  //         bestPhone: bestPhoneNumeric,
  //         cellPhone: cellPhoneNumeric,
  //       });
  //       if (patientInfo?.dob) setDOB(dayjs(patientInfo?.dob));

  //       // API call view-surveyData
  //       const payload = {
  //         patientId: patientInfo?.patientId,
  //         surveyId: patientInfo?.surveyId,
  //       }
  //       surveyPostData(axios, "viewSurvey", payload)
  //         .then((res) => {
  //           if (res?.data?.length) {
  //             const surveyData = res?.data[0]

  //             setBMIState({ ...BMI, ...surveyData })
  //             setFormState({ ...formState, ...surveyData, ...patientInfo, bestPhone, cellPhone })
  //             setSurveyCheck({
  //               ...surveyCheck,
  //               snoring: surveyData?.snoring,
  //               choking: surveyData?.choking,
  //               tired: surveyData?.tired,
  //               bloodPressure: surveyData?.bloodPressure,
  //               overFifty: surveyData?.overFifty,
  //               largeNeck: surveyData?.largeNeck,
  //               genderMale: surveyData?.genderMale,
  //               patientBMI: surveyData?.patientBMI,
  //             })
  //           } else {
  //             setFormState({ ...patientInfo, bestPhone, cellPhone });
  //           }
  //         })
  //         .catch((err) => console.log("errr", err));
  //     }
  //   }
  // }, [location]);

  const selectedPatient = useSelector(
    (state) => state.selectedPatientSelector?.value
  );
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
    interpPhysId: ""
  });
  const [BMIErrorState, setBMIErrorState] = useState({
    heightFt: "",
    heightIn: "",
    weight: "",
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [surveyCheck, setSurveyCheck] = useState({});
  const [BMI, setBMIState] = useState({});

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false)
    }
  };
  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.checked ? 1 : 0,
    });

    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false)
    }
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }
  function getErrorValidationPropertyHieght(key) {
    return {
      ...(BMI[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const [isSelectPatient, setIsSelectPatient] = useState(true);
  const unselectPatient = () => {
    dispatch(updateSelectedPatient(undefined));
    setFormState({});
    setDOB(null);
    setIsSelectPatient(true);
  };

  const axios = useAxios();
  const [DOB, setDOB] = React.useState(null);
  const updateDOB = (newValue) => {
    setDOB(newValue);
  };
  const [errorDOB, setErrorDOB] = React.useState(false);
  const [open, setOpen] = useState(false);
  const validatePositiveNumber = (id, value) => {
    if (id === "heightIn") {
      if (value !== '' && !Number.isNaN(value) && Number(value) > -1) {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: false },
        }));
      } else {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: true },
        }));
      }
    } else {
      if (value !== '' && !Number.isNaN(value) && Number(value) > 0) {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: false },
        }));
      } else {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: true },
        }));
      }
    }
  };
  const updateBMIInput = (e) => {
    const { id, value } = e?.target;
    if (id === 'heightFt' || id === 'heightIn' || id === 'weight') {
      validatePositiveNumber(id, value);
    }
    const newBMIState = { ...BMI, [e.target.id]: e.target.value };
    const height =
      parseInt(newBMIState.heightFt) * 12 +
      parseInt(newBMIState.heightIn);
    const bmi = Math.round((newBMIState.weight * 703) / Math.pow(height, 2));
    if (!isNaN(bmi)) {
      newBMIState.bmi = bmi;
    }
    if (bmi > 25) {
      updateSurveyCheck({ target: { id: "patientBMI", checked: true } });
    } else updateSurveyCheck({ target: { id: "patientBMI", checked: false } });
    setBMIState(newBMIState);
  };

  const updateSurveyCheck = (e) => {
    // debugger;
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: Number(e?.target?.checked)
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState, stopbangScore: sum, ...newSurveyState });
    setSurveyCheck(newSurveyState);
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false)
    }
  };
  const isEmptyField = (key, state) => {
    if (state === "formState") {
      return (
        formState[key] === undefined ||
        formState[key] === null ||
        formState[key] === ""
      );
    } else {
      return BMI[key] === undefined || BMI[key] === null || BMI[key] === "";
    }
  };

  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const saveSurvey = (e) => {
    setOpen(true);
    e.preventDefault();
    let isValid = true;
    const formStateError = { ...formErrorState };
    const BMIStateError = { ...BMIErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key, "formState")) {
        isValid = false;
        formStateError[key] = { error: true };
      }
    }
    for (const [key, _] of Object.entries(BMIErrorState)) {
      if (isEmptyField(key, "BMI")) {
        isValid = false;
        BMIStateError[key] = { error: true };
      }
    }
    if (!DOB) {
      isValid = false;
      setErrorDOB(true);
    }
    const hasNegativeNumber = BMI.heightFt < 1 || BMI.weight < 1 || BMI.heightIn < 0;

    if (hasNegativeNumber && BMI.heightFt && BMI.heightIn && BMI.weight) {
      isValid = false;
      notificationToastError('Height and weight should not be negative numbers');
      return false;
    }
    if (!isValid) {
      notificationToastError("Please fill all required fields");
      setFormErrorState(formStateError);
      setBMIErrorState(BMIStateError);
      return false;
    } else {
      // setErrorMessage("");
    }

    if (isValid) {
      setIsSaveButtonDisabled(true)
      const payload = {
        ...formState,
        ...BMI,
        heightFt: BMI.heightFt,
        heightIn: BMI.heightIn,
        interpPhysId: formState.interpPhysId,
        stopbangScore: formState?.stopbangScore,
        snoring: formState?.snoring,
        tired: formState?.tired,
        choking: formState?.choking,
        bloodPressure: formState?.bloodPressure,
        overFifty: formState?.overFifty,
        genderMale: formState?.genderMale,
        patientBMI: formState?.patientBMI,
        usingCpap: formState.usingCpap

      };
      surveyPostData(axios, "saveSurvey", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            notificationToastSuccess(res?.data?.message);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => notificationToastError(err?.message));
    }

  };

  const viewOrder = (rowData) => {
    navigate(`/order-view`, { state: { surveyOrderData: {...rowData, stages: surveyAndOrderId?.stages}, action: "view" } });
  };

  const createOrder = (rowData) => {
    navigate(`/order-view`, { state: { surveyOrderData: rowData } });
  };
  const openCloseSurveyDialog = () => {
    setViewCloseSurveyDialogState(true);
  };
  const closeSurvey = (reason) => {
    //const yesToDelete = window.confirm("Are you sure you want to close it? ");
    //if (!yesToDelete) return;
    setOpen(true);

    const payload = {
      ...formState,
      stageId: formState.stageId,
      reason,
    };
   
    surveyPostData(axios, "closeSurvey", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    setViewCloseSurveyDialogState(false);
    setTimeout(() => {
      navigate(`/dashboard`);
    }, 2000);
  };

  
  return (
    <Container maxWidth="lg" sx={{margin:isOrderModal ? "30px 10px" :"0px"}}>

      {selectedPatient && !isOrderModal &&  (
        <SelectedPatients
          selectedPatient={selectedPatient}
          unselectPatient={unselectPatient}
        />
      )}
      {
        !isOrderModal && 
        <FileUpload
        isFileUpload={false}
        setOpen={setOpen}
        patientId={selectedPatient?.patientId}
      />
      }
      

      {
        isOrderModal && 
        <Box 
        data-testid="close-survey-button"
        onClick={handleClose}
        sx={{position:"absolute", right:'16px',top:"10px",background:"#cdcfcf",width:"35px",height:"35px",borderRadius:"50%",display:"flex",alignItems:"center",justifyContent:"center",cursor: "pointer"}}>
          <CloseIcon sx={{color:"#fff" }}  />
        </Box>
      }

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
          background:readOnlyBackground
        }}
        noValidate
        autoComplete="off"
      >
        <PatientInfo
          formState={formState}
          updateInput={updateInput}
          DOB={DOB}
          updateDOB={updateDOB}
          errorDOB={errorDOB}
          setErrorDOB={setErrorDOB}
          formErrorState={formErrorState}
          setFormErrorState={setFormErrorState}
          isReadOnly={true}
          handleFocus={handleFocus}
          BMIState={BMIpat}
        />

        <InsuranceInfo
          formState={formState}
          setFormState={setFormState}
          isReadOnly={true}
          handleFocus={handleFocus}
        />

      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
          
        <Typography
          variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          {" "}
          BMI Calculation
        </Typography>

        <TextField
          id="heightFt"
          {...getErrorValidationPropertyHieght("heightFt")}
          label="Height (Feet)"
          type="number"
          value={BMI.heightFt || ""}
          variant="standard"
          onChange={updateBMIInput}
          required
          error={BMIErrorState?.heightFt?.error}
          helperText={
            BMIErrorState.heightFt?.error && !BMI.heightFt ? "required" : (BMIErrorState.heightFt?.error && BMI.heightFt) && "height should be positive number"
          }
        />
        <TextField
          id="heightIn"
          {...getErrorValidationProperty("heightFt")}
          label="Height (Inches)"
          value={BMI.heightIn || ""}
          type="number"
          variant="standard"
          onChange={updateBMIInput}
          required
          error={BMIErrorState?.heightIn?.error}
          helperText={
            BMIErrorState.heightIn?.error && !BMI.heightIn
              ? "required"
              : (BMIErrorState.heightIn?.error && BMI.heightIn) && "height should be positive number"
          }
        />
        <TextField
          id="weight"
          {...getErrorValidationProperty("heightFt")}
          label="Weight (lbs)"
          value={BMI.weight || ""}
          type="number"
          variant="standard"
          onChange={updateBMIInput}
          required
          error={BMIErrorState?.weight?.error}
          helperText={
            BMIErrorState.weight?.error && !BMI.weight ? "required" : (BMIErrorState.weight?.error && BMI.weight) && "weight should be positive number"
          }
        />

        <TextField
          id="bmi"
          label={`Calculated BMI`}
          value={BMI.bmi === undefined ? "" : BMI.bmi}
          helperText=""
          variant="standard"
          onChange={updateBMIInput}
        />
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          PAP Therapy
        </Typography>

        <FormControl sx={{ m: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="usingCpap"
                checked={Boolean(formState.usingCpap)}
                onChange={updateCheckBox}
              />
            }
            label="Are you currently using a CPAP or BiPAP machine while sleeping?"
          />
        </FormControl>
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          Check all that apply
        </Typography>

        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="snoring"
                  checked={Boolean(formState?.snoring)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Do you snore?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="tired"
                  checked={Boolean(formState?.tired)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Do you often feel Tired, Fatigued, or Sleepy during the daytime?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="choking"
                  checked={Boolean(formState.choking)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Has anyone observed you stop breathing or choking/gasping during your sleep?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="bloodPressure"
                  checked={Boolean(formState.bloodPressure)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Do you have (or are you being treated for) high blood pressure?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="overFifty"
                  checked={Boolean(formState.overFifty)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Are you older than 50 years of age?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="largeNeck"
                  checked={Boolean(formState.largeNeck)}
                  onChange={updateSurveyCheck}
                />
              }
              label='Neck size large? (measured around Adam apple. 17" shirt collar for males, or 16" for females)'
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="genderMale"
                  checked={Boolean(formState.genderMale)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Is your gender male?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={<Checkbox id="patientBMI" />}
              label="Patient's BMI > 25?"
              checked={Boolean(surveyCheck.patientBMI)}
            />
          </FormControl>
        </div>
        <div>
          <TextField
            id="stopbangScore"
            label="STOP-BANG score"
            value={
              formState.stopbangScore === undefined
                ? ""
                : formState.stopbangScore
            }
            helperText=""
            variant="standard"
            onChange={updateInput}
          />
        </div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "98%" },
            "& .MuiFormControl-root": { flexGrow: 1 },
            // border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            margin: "10px 0px",
            display: "flex",
          }}
        >
          <PhysicianPulldown
            formState={formState}
            updateInput={updateInput}
            attributeName={"interpPhysId"}
            formErrorState={formErrorState}
            validateEmptyField={validateEmptyField}
          />
        </Box>

      </Box>
      {
        !surveyAndOrderId?.stages?.some(arr => arr.includes(CLOSED_SURVEY)) &&
        (!surveyAndOrderId?.orderId && !formState?.origOrderId && formState.statusNameId !== 2 && (
          <>
            <Box sx={{ paddingTop: "2rem", paddingBottom: "2rem", display: isMobile ? "flex" : "unset", flexDirection: isMobile ? "column" : "unset", gap: isMobile ? 2 : 'unset' }}>
              <Button variant="contained" onClick={saveSurvey} disabled={isSaveButtonDisabled}>
                Save SURVEY
              </Button>{" "}
              <Button
                variant="contained"
                onClick={createOrder.bind(null, formState)}
              >
                Create Order
              </Button>{" "}
              {formState.statusNameId != 2 && (
                <Button variant="contained" onClick={openCloseSurveyDialog}>
                  Close SURVEY
                </Button>
              )}
            </Box>
          </>
        ))
      }

      {(formState?.origOrderId || surveyAndOrderId?.orderId) && (
        <Box sx={{ margin: "20px 0px" }}>
          <Button variant="contained" sx={{textTransform:"capitalize"}}
            onClick={viewOrder.bind(null, formState)}  >
            This Survey already has an Order.
          </Button>
        </Box>
      )}
      {/* {formState?.orderId > 0 && "This Survey already has an Order."} */}
      {(formState?.statusNameId === 2 || surveyAndOrderId?.stages?.some(arr => arr.includes(CLOSED_SURVEY))) && (
        <Box sx={{ margin: "20px 0px", maxWidth: "251px" }}>
          <Alert variant="filled" severity="error">
          This survey is closed.
          </Alert>
        </Box>
      )}

      <div style={{ paddingTop: "2rem" }}>
        By filling out the form, you are agreeing with our
        <CustomizedDialogs />
      </div>

      {viewCloseSurveyState && (
        <CloseRejectDialog
          handleSave={closeSurvey}
          handleClose={closeSurveyDialog}
          {...viewCloseSurveyState}
        />
      )}
      <ToastContainer />
    </Container>
  );
}

export default SurveyView;
