import { Box, IconButton, InputAdornment, OutlinedInput, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import useIsGroupId from '../../../hooks/groupIdInfo';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { Visibility } from '@mui/icons-material';
import { useAxios } from '../../../hooks/auth';
import { getDecryptedData } from '../../../axios/api';

function CCInformation({ formState,setFormState,previleges,setSubmitEnabledCardNum = () => {},setSubmitEnabledCVC = () => {},setIsSaveButtonDisabled = () => {},expirationDate,errorexpirationDate,setErrorExpirationDate,expDateError,updateExpirationDate, handleSSNChange, ssnError, setSsnError }) {
    const isMobile = useMediaQuery('(max-width:800px)')
    const axios = useAxios();
    const [showCC, setShowCC] = useState(false);
    const [showCVC, setShowCVC] = useState(false);
    const [showSSN, setShowSSN] = useState(false);
    const [showERRCC, setERRCC] = useState(false);
    const [showERRCVC, setERRCVC] = useState(false);
    const readOnlyFlag = previleges?.includes("encryptedInfo")

    const toggleShow = async (field, setShow, state) => {
        if (!state && formState[field]) {
            try {
                const res = await getDecryptedData(axios, encodeURIComponent(formState[field].toString()));
                setFormState((prevState) => ({
                    ...prevState,
                    [field]: res?.decryptedData || prevState[field]
                }));
            } catch (error) {
                console.error("Error while fetching decrypted data:", error);
            } finally {
                setShow((prevState) => !prevState); // Toggle setShow after API call completes
                if(field === "cardNumber") {
                    setERRCC(true)
                }else if(field === "cvc"){
                    setERRCVC(true)
                }else if(field === "ssn"){
                    setShowSSN(true)
                }
            }
        } else {
            setShow((prevState) => !prevState); // Toggle setShow immediately if no need for API call
        }
    };

    const updateCCInput = (e) => {
        const { id, value } = e.target;
        let updatedValue = value;
        setIsSaveButtonDisabled(false)
        switch (id) {
            case 'cardNumber':
                setSubmitEnabledCardNum(true)
                setERRCC(true);
                break;
            case 'cvc':
                setSubmitEnabledCVC(true) 
                setERRCVC(true);
                break;
            case 'expirationDate':
                if (updatedValue.length >= 2) {
                    if (updatedValue.length === 2 && value.length > updatedValue.length) {
                      updatedValue += '/';
                    } else if (updatedValue.length === 3 && value.length < updatedValue.length) {
                      updatedValue = updatedValue.slice(0, 2);
                    } else if (updatedValue.length > 2 && updatedValue[2] !== '/') {
                      updatedValue = updatedValue.slice(0, 2) + '/' + updatedValue.slice(2);
                    }
                    if (updatedValue.length > 5) {
                      updatedValue = updatedValue.slice(0, 5); // Limit to MM/YY format
                    }
                  }
                break;
            default:
                break;
        }
    
        setFormState((prevState) => ({
            ...prevState,
            [id]: updatedValue
        }));
    };


    return (
        <Box m={1} sx={{border:"1px solid red"}}>
            <Typography
                variant='h6'
                style={{
                    paddingLeft: "0.5rem",
                    //   paddingTop: "1rem",
                    marginTop: "16px",
                    marginBottom: "0px"
                }}
            >
                Credit Card Details
            </Typography>
            <Box
                component="div"
                sx={{
                    // "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    width:"100%",   
                    gap:"10px",
                    flexDirection: isMobile ? "column": "row"
                }}
            >
                <TextField
                    sx={{width:"90%"}}
                    id="cardNumber"
                    value={showERRCC ? formState?.cardNumber : formState?.cardNumber ? '**********' : "" }
                    onChange={updateCCInput}
                    label="Card Number"
                    variant="standard"
                    autoComplete='off'
                    // type={showCC ? "text" : "password"}
                    error={showERRCC && formState?.cardNumber && formState?.cardNumber?.length > 16} // Display error if length exceeds 16
                    helperText={showERRCC && formState?.cardNumber && formState?.cardNumber?.length > 16 ? "Card number should be no more than 16 digits" : ""}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" aria-label="toggle card visibility" onClick={() => toggleShow("cardNumber", setShowCC, showCC)}>
                                    {showCC ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        readOnly: !readOnlyFlag
                    }}
                />
                <TextField
                    id="cvc"
                    label="cvc"
                    variant="standard"
                    value={showERRCVC ? formState?.cvc : formState?.cvc ? '**********' : ""}
                    onChange={updateCCInput}
                    sx={{width:"90%"}}
                    // type={showCVC ? "text" : "password"}
                    error={showERRCVC && formState?.cvc && formState?.cvc?.length > 4} 
                    helperText={showERRCVC && formState?.cvc && formState?.cvc?.length > 4 ? "cvc should be no more than 4 numbers" : ""}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" aria-label="toggle card visibility" onClick={() => toggleShow("cvc", setShowCVC, showCVC)}>
                                    {showCVC ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        readOnly: !readOnlyFlag
                    }}
                />
                <TextField
                    sx={{ width: "90%" }}
                    id="nameOnCard"
                    label="Name On Card"
                    value={formState?.nameOnCard || ""}
                    variant="standard"
                    onChange={updateCCInput}
                    inputProps={{readOnly: !readOnlyFlag}}
                />
            </Box>
            <Box
                component="div"
                sx={{
                    "& .MuiFormControl-root": { flexGrow: 1 },
                    display: "flex",
                    gap:"10px",
                    flexDirection: isMobile ? "column": "row"
                }}
            >
                <TextField
                    id="expirationDate"
                    label="Expiration Date (MM/YY)"

                    variant="standard"
                    value={formState?.expirationDate || ""}
                    onChange={updateCCInput}
                    sx={{width:"90%"}}
                    error={formState?.expirationDate && formState?.expirationDate?.length > 5} 
                    helperText={formState?.expirationDate && formState?.expirationDate?.length > 5 ? "Expiration date should be no more than 5 characters" : ""}
                    inputProps={{readOnly: !readOnlyFlag}}
                />
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Expiration Date"
                inputFormat="MM/DD/YYYY"
                value={expirationDate}
                onChange={updateExpirationDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "90%" }}
                    variant="standard"
                    // error={(errorexpirationDate && !expirationDate) || expDateError}
                    // helperText={errorexpirationDate && !expirationDate ? "required" : expDateError ? 'Date should be entered in the following format mm/dd/yyyy' : ''}
                    value={
                      expirationDate ? moment.utc(expirationDate.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    // onBlur={() => {
                    //   setErrorExpirationDate(!expirationDate);
                    // }}
                  />
                  
                )}
              />
            </LocalizationProvider> */}
                <TextField
                    id="ssn"
                    label="Social Security #"
                    value={showSSN ? formState.ssn : formState.ssn ? '**********' : ""}
                    variant="standard"
                    inputProps={{ maxLength: 11 }}
                    onChange={(e)=>{setShowSSN(true);handleSSNChange(e);}}
                    sx={{ width: "90%" }}
                    helperText={ssnError ? "enter the (### - ## - ####) format." : showSSN && formState?.ssn && formState?.ssn?.length > 11 ? "ssn no more than 11 characters" : ""}
                    error={ssnError || (showSSN && formState?.ssn && formState?.ssn?.length > 11)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" aria-label="toggle card visibility" onClick={() => toggleShow("ssn", setShowSSN, showSSN)}>
                                    {showSSN ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        readOnly: !readOnlyFlag
                    }}
                />
            </Box>
        </Box>
    )
}

export default CCInformation