export const isChooseISDDiagnosis = (formState) => {
  return (
    formState.G47_30 ||
    formState.G47_33 ||
    formState.G47_31 ||
    formState.G47_9 ||
    formState.E66_2 ||
    formState.G47_69 ||
    formState.G47_61 ||
    formState.F51_12 ||
    formState.G47_11 ||
    formState.G47_12 ||
    formState.G47_13 ||
    formState.G47_19 ||
    formState.F51_4 ||
    formState.F51_5 ||
    formState.F51_3 ||
    formState.G47_411 ||
    formState.G47_419 ||
    formState.G47_421 ||
    formState.G47_421_wo_cataplexy
  );
};
export const isCheckOneFaceToFaceNote = (formState) => {
  return (
    formState.snoring ||
    formState.fatigue ||
    formState.daytimeSleepiness ||
    formState.gasping ||
    formState.apneas ||
    formState.inappropriateSleep ||
    formState.otherFaceNotes
  );
};
