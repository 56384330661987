import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import { Button, FormControl } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import FollowingPhysicianPulldown from "./FollowingPhysicianPulldown";
import { resultsPostData } from "../../axios/api";
import ReschedulePopup from "./ReschedulePopup";

export default function DiscussResultsDialog({
  handleSave,
  handleClose = () => {},
  row,
  setViewCloseDialogState = () => {},
  discussResults = false,
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  patientResultInfo = {},
  notShowPopup,
  reloadCurrentVisit,
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [refreshData, setRefreshData] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  React.useEffect(() => {
    if (row?.studyIdStudy) {
      setFormState({
        physicianId: row?.physicianIdOrder,
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        surveyId: row?.surveyIdSurvey,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        SDATE: row?.date,
        locationId: row?.locationIdStudy,
        SHIP: row?.shipDate,

        studyId: row?.studyIdStudy,
      });
    } else if (patientResultInfo) {
      setFormState({ ...formState, ...patientResultInfo });
      setSDATE(patientResultInfo?.SDATE);
      setSHIP(patientResultInfo?.SHIP);
    }
  }, [row]);

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      dateResultDiscussed: formState.SDATE,
      datePatientReferred: formState.SHIP,
      consultingPhysician: formState.prefInterpPhysId,
    };

    resultsPostData(axios, "saveDiscussResults", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
    if (reloadCurrentVisit) reloadCurrentVisit(payload);
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSHIP(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, SHIP: newValue.format("YYYY-MM-DD") });
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSDATE(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, SDATE: newValue.format("YYYY-MM-DD") });
  };
  const patientNameVal = row?.patientName ?? formState?.patientNameVal;

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <>
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <b>Discuss Results with Patient Info: </b>
          </FormControl>
        </div>
        <FormControl sx={{ m: 1 }}>
          {!notShowPopup && <ReschedulePopup row={row} />}
        </FormControl>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          {discussResults && (
            <FormControl sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date Results discussed with Patient"
                  inputFormat="MM/DD/YYYY"
                  value={SDATE || ""}
                  onChange={updateSDATE}
                  readOnly={isPreviousVisits}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      error={errorSDATE && !SDATE}
                      helperText={errorSDATE && !SDATE ? "required" : ""}
                      value={
                        SDATE
                          ? moment
                              .utc(SDATE.toLocaleString())
                              .format("MM/DD/YYYY")
                          : ""
                      }
                      onBlur={() => {
                        setErrorSDATE(!SDATE);
                      }}
                      inputProps={{ readOnly: isPreviousVisits }}
                      onMouseDown={handleFocus}
                      placeholder="mm/dd/yyyy"
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          )}
        </Box>

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <FormControl sx={{ m: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date Patient referred to Consulting/Treatment"
                inputFormat="MM/DD/YYYY"
                value={SHIP || ""}
                onChange={updateSHIP}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={errorSHIP && !SHIP}
                    helperText={errorSHIP && !SHIP ? "required" : ""}
                    value={
                      SHIP
                        ? moment.utc(SHIP.toLocaleString()).format("MM/DD/YYYY")
                        : ""
                    }
                    onBlur={() => {
                      setErrorSHIP(!SHIP);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <FollowingPhysicianPulldown
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            attributeName="prefInterpPhysId"
            isPreviousVisits={isPreviousVisits}
          />
        </Box>
        {!asDialog && !isPreviousVisits && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </>
    );
  }
}
