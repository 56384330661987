import { Accordion, AccordionDetails, AccordionSummary, Box, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import classes from './BMICalculation.module.css'
import PhysicianPulldown from '../../misc/PhysicianPulldown';
import SurveyCheckBox from './surveyCheckBox/SurveyCheckBox';
import { readOnlyBackground } from '../../../Theme/theme';
function BMICalculation({ formState, updateInput, formErrorState, validateEmptyField, setExpanded, expanded,handleFocus,surveyCheck,
  surveyFormState}) {
    // console.log("surveyFormState==>",surveyFormState);
  // useEffect(() => {
  //   if (formState.heightFeet && formState?.heightInches) {
  //     const height =
  //       parseInt(formState.heightFeet) * 12 +
  //       parseInt(formState.heightInches);
  //     let bmi = (parseInt(formState.weight) * 703) / Math.pow(height, 2);
  //     bmi = bmi.toFixed(1)
  //     setFormState({ ...formState, bmi })
  //   }
  // }, [formState.heightFeet, formState.heightInches, formState.weight])
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion sx={{ boxShadow: 'none',background: readOnlyBackground }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.surveyAccordian}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          "& .MuiAccordionSummary-content.Mui-expanded": {
            marginBottom: "0px !important",
          },
        }}
      >
        <Box className={classes.serveyAccordianContainer}>
          <ArticleIcon />
          <span style={{ color: "black" }} >Survey Results</span> <Typography> (click or tap to expand)</Typography>
        </Box>

      </AccordionSummary>
      <AccordionDetails>
        <Box
          // component="form"
          sx={{
            // "& .MuiTextField-root": { m: 1, width: "98%" },
            "& .MuiFormControl-root": { flexGrow: 1 },
            // border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginBottom: "15px",
            display: "flex",
          }}
        >
          <PhysicianPulldown
            formState={formState}
            updateInput={updateInput}
            attributeName={"interpPhysId"}
            formErrorState={formErrorState}
            validateEmptyField={validateEmptyField}
          />
        </Box>

        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
            // border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            // marginTop: "10px",
          }}
        >
          <Typography
            variant='h6'
            style={{
              paddingLeft: "0.5rem",
              margin: "0px",
              marginBottom: "10px"
            }}
          >
            {" "}
            BMI Calculation
          </Typography>
          <Box className={classes.BMITextFIeldCOntainer}>
            <TextField
              id="heightFt"
              label="Height (Feet)"
              type="number"
              value={surveyFormState?.heightFt || ''}
              variant="standard"
              inputProps={
                { readOnly: true }
              }
              onMouseDown={handleFocus}
            />
            <TextField
              id="heightIn"
              label="Height (Inches)"
              value={surveyFormState?.heightIn || ''}
              type="number"
              variant="standard"
              inputProps={
                { readOnly: true }
              }
              onMouseDown={handleFocus}
            />
            <TextField
              id="weight"
              label="Weight (lbs)"
              value={surveyFormState?.weight || ''}
              type="number"
              variant="standard"
              inputProps={
                { readOnly: true }
              }
              onMouseDown={handleFocus}
            />

            <TextField
              id="bmi"
              label={`Calculated BMI`}
              value={surveyFormState?.bmi ? surveyFormState?.bmi || "" : ""}
              helperText=""
              variant="standard"
              inputProps={
                { readOnly: true }
              }
              onMouseDown={handleFocus}
            />
          </Box>

        </Box>
        <SurveyCheckBox surveyFormState={surveyFormState} surveyCheck={surveyCheck}/>
      </AccordionDetails>
    </Accordion>
  )
}


  

export default BMICalculation