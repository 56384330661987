import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import states from "../constants/states.json";
import MUIDataTable from "mui-datatables";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Input,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Typography,
  useMediaQuery,
} from "../../node_modules/@mui/material/index";
import { useAxios } from "../hooks/auth";
import GroupPulldown from "../components/misc/GroupPulldown";
import { Snackbar } from "@mui/material";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../utils/notification";
import { ToastContainer } from "react-toastify";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../constants/sideBarwidth";
import { adminPostData } from "../axios/api";
import phoneNumberValidator from "../utils/phoneNumberValidator";

function LocationView({
  type = "page",
  groupIdForLocationView = false,
  setTabValue = () => {},
  refreshData = false,
  setRefreshData = () => {},
  closeDialog  = false,
  handleClose = () => {},
}) {

  React.useEffect(() => {
    setFormState({ ...formState, groupId: groupIdForLocationView });
  }, [groupIdForLocationView]);
  
  const isMobile = useMediaQuery("(max-width:800px)");
  const locationInfo = useLocation();
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState({
    phone: "",
    error: false,
    fax:"",
    faxError:false
  });
  React.useEffect(() => {
    const row = locationInfo.state?.row;
    if (row) {
      const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(row[6]);
      const { formattedPhoneNumber: faxFormatted, numericPhoneNumber: faxNumeric } = phoneNumberValidator(row[7]);
      setFormState({
        ...formState,
        locationId: row[0],
        location: row[1],
        groupId: row[10],
        address1: row[2],
        address2: row[9],
        city: row[3],
        state: row[4],
        zip: row[5],
        fax: faxFormatted,
        phone: formattedPhoneNumber,
      });
      
    }
  }, [locationInfo]);

  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );
  const [formState, setFormState] = useState({});
  const [formErrorState, setFormErrorState] = useState({
    location: "",
    groupId: "",
  });

  const navigate = useNavigate();
  const [surveyCheck, setSurveyCheck] = useState({});
  const [BMI, setBMIState] = useState({});

  const axios = useAxios();

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  const updatePhoneNumber = (e) => {
    const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(
      e.target.value
    );
    
    setPhoneNumber({
      ...phoneNumber,
      [e.target.id]: numericPhoneNumber,
      mobileNumError: false,
      error: false,
    });

    setFormState((prevState) => {
      return {
        ...prevState,
        [e.target.id]: formattedPhoneNumber,
      };
    });
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  // const [refreshData, setRefreshData] = useState(false);
  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      notificationToastError("Please fill all required fields");
      setFormErrorState(errors);
      return false;
    }
    const payload = {
      ...formState,
      locationId: formState.locationId,
      phone: phoneNumber?.phone,
      fax: phoneNumber?.fax
    };
    setIsSaveButtonDisabled(true);
    adminPostData(axios, "saveLocationInfo", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
          if (type !== "dialog" || closeDialog) {
            if (closeDialog) handleClose() ;
            setTimeout(() => {
              navigate("/admin");
              setRefreshData(!refreshData);
            }, 2000);
          } else {
            setTabValue(0);
            setRefreshData(!refreshData);
          }
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
  };
  function createData(Type, DateCreated, StopBang, Actions) {
    return {
      Type,
      DateCreated,
      StopBang,
      Actions,
    };
  }
  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const updateBMIInput = (e) => {
    const newBMIState = { ...BMI, [e.target.id]: e.target.value };
    const height =
      parseInt(newBMIState.heightFeet) * 12 +
      parseInt(newBMIState.heightInches);
    const bmi = Math.round((newBMIState.weight * 703) / Math.pow(height, 2));
    if (!isNaN(bmi)) {
      newBMIState.bmi = bmi;
    }
    setBMIState(newBMIState);
  };
  const defaultProps = {
    getOptionLabel: (option) => option.title,
  };
  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: e.target.checked,
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState, bangScore: sum });

    setSurveyCheck(newSurveyState);
  };

  return (
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      {type !== "dialog" && (
        <Typography variant="h6">Location Info ( * required field)</Typography>
      )}
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
          width: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <TextField
            id="location"
            label="Location *"
            value={formState.location}
            variant="standard"
            onChange={updateInput}
            error={formErrorState.location?.error && !formState.location}
            helperText={
              formErrorState.location?.error && !formState.location
                ? "required"
                : ""
            }
            onBlur={validateEmptyField.bind(null, "location")}
          />

          {type !== "dialog" && (
            <GroupPulldown
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              validateEmptyField={validateEmptyField}
            />
          )}
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <TextField
            id="address1"
            label="Address1"
            value={formState.address1}
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="address2"
            label="Address2"
            value={formState.address2}
            variant="standard"
            onChange={updateInput}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <TextField
            id="city"
            label="City"
            value={formState.city}
            variant="standard"
            onChange={updateInput}
          />

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              name="state"
              value={formState.state || ""}
              label="state"
              onChange={updateInput}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {states.map((state,i) => (
                <MenuItem value={state} key={i}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="zip"
            label="Zip"
            value={formState.zip}
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="phone"
            type="tel"
            label={"Phone Number"}
            value={formState.phone || ""}
            helperText="Area Code and Number (only 10 digits)"
            variant="standard"
            onChange={updatePhoneNumber}
            error={phoneNumber.error}
            onBlur={() => {
              if (
                phoneNumber?.phone?.length < 10 &&
                phoneNumber?.phone?.length > 0
              )
                setPhoneNumber({
                  ...phoneNumber,
                  error: true,
                });
            }}
            //
          />
          <TextField
            id="fax"
            label="Fax"
            value={formState.fax}
            helperText="Area Code and Number"
            variant="standard"
            onChange={updatePhoneNumber}
            error={phoneNumber.faxError}
            onBlur={() => {
              if (
                phoneNumber?.fax?.length < 10 &&
                phoneNumber?.fax?.length > 0
              )
                setPhoneNumber({
                  ...phoneNumber,
                  faxError: true,
                });
            }}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        ></Box>

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>

      <Box
        sx={
          type !== "dialog"
            ? { paddingTop: "1rem", paddingBottom: "1rem" }
            : {
                justifyContent: "flex-end",
                display: "flex",
                gap: "5px",
                marginTop: "30px",
              }
        }
      >
        <Button
          variant="contained"
          disabled={isSaveButtonDisabled}
          onClick={saveChanges}
        >
          SAVE CHANGES
        </Button>
      </Box>
      <ToastContainer />
    </Container>
  );
}

export default LocationView;
