import React from 'react'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

const PatientInfoPulldown = ({
    formState,
    updateInput,
    formErrorState,
    attributeName,
    options,
    isPreviousVisits = false,
    isPatientPortal = false,
    pulldownApiName,
    itemName,
    labelName,
    ...remaining
}) => {
    return (
        <>
            <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                {...remaining}
            >
                <InputLabel
                    id="info-label"
                >
                    {labelName}
                </InputLabel>
                <Select
                    labelId="info-label"
                    id={attributeName}
                    name={attributeName}
                    value={formState[attributeName] || ""}
                    label={attributeName}
                    onChange={updateInput}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {options.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}

export default PatientInfoPulldown

