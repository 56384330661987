import { Avatar, Box, Button, Container, CssBaseline, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Copyright, theme } from '../../../components/SignIn';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import classes from './SignInLanding.module.css'
import { useNavigate } from 'react-router-dom';
import { images } from '../../../images/index'

function SignInLanding() {
    const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ backgroundColor: "white" }}
      >
        <CssBaseline />
        {/* <Box
        className={classes.landingPageWrapper}
        >
          <Typography component="h2" variant="h5">
            SleepNavigator®
          </Typography>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Box className={classes.ButtonWrapper}>
            <Button variant="contained" onClick={()=>navigate('/signin')} sx={{bgcolor:"#715d9c",'&:hover': {
            bgcolor: "#574b7e", // Change the hover color as needed
          },}}> Provider Sign In</Button>

            <Button variant="contained" onClick={()=>navigate('/signin-as-patient')} sx={{bgcolor:"#715d9c",'&:hover': {
            bgcolor: "#574b7e", // Change the hover color as needed
          },}}>Patient Sign In</Button>
          </Box>
        </Box> */}
        <Box className={classes.ButtonWrapper}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 6 }}>
            <Box sx={{ margin: '0 auto' }}>
              <img src={images.providerLogo} alt='logo' className='signinlogo' />
            </Box>
            <Button variant="contained" onClick={() => navigate('/signin')} sx={{
              bgcolor: "#715d9c", '&:hover': {
                bgcolor: "#574b7e", // Change the hover color as needed
              },
            }}> Provider Sign In</Button>
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 6 }}>
            <Box sx={{ margin: '0 auto' }}>
              <img src={images.patientLogo} alt='logo' className='signinlogo' />
            </Box>
            <Button variant="contained" onClick={() => navigate('/signin-as-patient')} sx={{
              bgcolor: "#715d9c", '&:hover': {
                bgcolor: "#574b7e", // Change the hover color as needed
              },
            }}>Patient Sign In</Button>
          </Box>
        </Box>
        <Copyright />
        <ToastContainer />
      </Container>
      <Box>
          <iframe
            title="sleep navigator"
            src="Home.html"
            style={{ width: '100%', height: '1550px', frameborder: '0' }}
            allowFullScreen 
          ></iframe>
        </Box>
    </ThemeProvider>
  );
}

export default SignInLanding