import { useAuth } from "./auth";
import roleAndPrivilegesFnc from "../utils/roleAndPriviledges";
function useIsUserId () {
    const auth = useAuth();
    if(auth){
        const {id}=roleAndPrivilegesFnc(auth?.cookies?.token);
        return  id;
    }
   
}

export default useIsUserId;