import { createTheme } from "@mui/material";
const body2FontSize = "16px";
const tableCellFontSize = "16px";
const tableHeadFontSize = "16px";
export const readOnlyBackground = "rgba(0, 0, 0, 0.04)"
const component = {
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiInputBase-input": {
          fontSize: body2FontSize, // this is the default mui body1 font-size
          fontWeight: "normal",
          fontFamily: "sans-serif",
          lineHeight: "1.43"
          // color:'#000'
        },
        "& .MuiInputLabel-root": {
          fontSize: body2FontSize,
          // color:'#000'
        },
        // "& .MuiInputLabel-outlined": {
        //   transform: 'translate(14px, 16px) scale(1)',
        // },
        // "& .MuiInputLabel-shrink": {
        //   transform: 'translate(14px, -6px) scale(0.75)',
        // },
        // "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-outlined": {
        //   transform: 'translate(14px, -6px) scale(0.75)',
        // },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        "& .MuiFormControl-root" : {
        },
        "& .MuiInputBase-input": {
          fontSize: body2FontSize,
          lineHeight: '25px',
        },
        "& .MuiInputLabel-root": {
          fontSize: body2FontSize,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        "& .MuiSvgIcon-root": {
          fontSize: "24px",
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        "& .MuiSvgIcon-root": {
          fontSize: "24px",
        },
      },
    },
  },
  // data table
  MuiTableFooter : {
    styleOverrides: {
      root: {
        '& .MuiTableFooter-root  ': {
          zIndex: 106,
          position: "fixed",
          bottom: "56px",
          background: "#ffff",
          right: "17px",
          left: "19px"
        },
      }
    }
  },
  MUIDataTableBodyRow : {
    styleOverrides: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: "rgba(0, 0, 0, 0.06)",
        },
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: tableCellFontSize,
        textAlign: "center"
      }
    }
  },
  MUIDataTableHeadCell: {
    styleOverrides: {
      root: {
        "& .MuiButtonBase-root" :{
          fontSize: tableHeadFontSize,
          fontWeight: 'bold',
          textTransform: "capitalize",
          fontFamily: "sans-serif",
          lineHeight: "1.43",
          color: "#111211",
        },
      },
    }
  },
}
export const theme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
    fontSize: 15,
    color: "#111211",
    h1: {
      fontSize: '32px', // Customize the font size for h1
      fontWeight: 'bold',
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    h2: {
      fontSize: '28px', // Customize the font size for h2
      fontWeight: 'bold',
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    h3: {
      fontSize: '24px', // Customize the font size for h3
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '20px', // Customize the font size for h4
      fontWeight: 'bold',
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    h5: {
      fontSize: '18px', // Customize the font size for h5
      fontWeight: 'bold',
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    h6: {
      fontSize: '16px', // Customize the font size for h6
      fontWeight: 'bold',
      textTransform: "capitalize",
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    body1: {
      fontSize: '16px', // Customize the font size for subtitle1
      fontWeight: 'normal',
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    body2: {
      fontSize: '14px', // Customize the font size for subtitle1
      fontWeight: 'normal',
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    body3: {
      fontSize: '12px', // Customize the font size for subtitle1
      fontWeight: 'normal',
      color: "#000",
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    strong: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#000",
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    subtitle: {
      fontSize: "14px",
      fontWeight: 700,
      color: "#000",
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    span: {
      fontSize: "16px",
      color: "#000",
      fontWeight: 'normal',
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    span1: {
      fontSize: "15px",
      color: "#000",
      fontWeight: 600,
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    link: {
      fontSize: "15px",
      color: "#715d9c",
      fontWeight: 'normal',
      fontFamily: "sans-serif",
      lineHeight: "1.43",
      cursor : "pointer",
    },
    linkUnderLine: {
      fontSize: "15px",
      color: "#715d9c",
      fontWeight: 'normal',
      fontFamily: "sans-serif",
      lineHeight: "1.43",
      cursor : "pointer",
      textDecoration: "underline"
    },
    subtitleCap: {
      fontSize: "15px",
      color: "#1976d2",
      fontWeight: 400,
      fontFamily: "sans-serif",
      lineHeight: "1.43"
    },
    subtitle1: {
      fontFamily: 'sans-serif',
      fontSize: "12.3px",
      fontWeight: 600,
      color: "#715d9c"
    },
  },
  palette: {
      primary: {
        main: '#715d9c', // Change the primary color
      },
    secondary: {
      main: '#00FF00', // Change the secondary color
    },
  },
  components: component,
})

