import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
// import { useAxios } from "../../../../hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import selectedPatientSlice from "../../../../store/slices/selectedPatientSlice";
import CustomizedDialogs from "../ScrollDialog";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Alert,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { updateSelectedPatient } from "../../../../store/slices/selectedPatientSlice";
import PatientSearch from "../../../PatientSearch";
import PatientInfo from "../PatientInfo";
import InsuranceInfo from "../InsuranceInfo";
import PhysicianPulldown from "../../../../components/misc/PhysicianPulldown";
import phoneNumberValidator from "../../../../utils/phoneNumberValidator";
import FileUpload from "../FileUpload/FileUpload";
import SelectedPatients from "../selectPatients/SelectedPatients";
import { useNavigate } from "react-router-dom";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../../utils/notification";
import { ToastContainer } from "react-toastify";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../../../../constants/sideBarwidth";
import { dashboardGetData, orderPostData } from "../../../../axios/api";
import SignedOrder from "../SignedOrder";
import useIsPrevileges from "../../../../hooks/useRoleAndPrivileges";
import DateToVisit from "../Order/DateToVisit";
import moment from "moment";
import {
  isCheckOneFaceToFaceNote,
  isChooseISDDiagnosis,
} from "../../../../utils/signOrderFnc";
import { useAxios } from "../../../../hooks/auth";
import classes from "./NewOrder.module.css";
import BMICalculation from "../../../../components/patientsComponent/BMICalculation/BMICalculation";
import { updatedashboardData } from "../../../../store/slices/dashboardDataSlice";
import { SearchPatient } from "../../../../components/misc/SearchPatient";
import { readOnlyBackground } from "../../../../Theme/theme";

function NewOrder() {
  const dispatch = useDispatch();
  const privelegesArray = useIsPrevileges();
  const isSignOrderPresent =
    privelegesArray?.privileges?.subtab?.includes("signOrder");
  const isMobile = useMediaQuery("(max-width:800px)");
  const [formState, setFormState] = useState({});
  const [surveyFormState, setSurveyFormState] = useState({});

  const [rowData, setRowData] = useState([]);
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const { dashboardData } = useSelector((state) => state.dashboardDataSlice);
  const [surveyCheck, setSurveyCheck] = useState({});
  const [fallsAsleepCheckBox, setFallsAsleepCheckBox] = useState(false);
  const axios = useAxios();

  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
    physicianId: "",
  });

  const navigate = useNavigate();
  const selectedPatient = useSelector(
    (state) => state.selectedPatientSelector?.value
  );
  const [DOB, setDOB] = React.useState(selectedPatient?.dob);

  const [visitDateState, setVisitDate] = useState(new Date());

  const [patientsPhoneNumber, setPatientsPhoneNumber] = useState({
    bestPhone: "",
    cellPhone: "",
  });

  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isSignOrderDisable, setIsSignOrderDisable] = useState(true);
  const [isOrderSignedSucess, setIsOrderSignedSucess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isSurveyOfSelectedPtients, setIsSurveyOfSelectedPtients] =
    useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [active, setActive] = useState()
  const [BMI, setBMIState] = useState({});

  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: e.target.checked ? 1 : 0,
    };
    setIsSaveButtonDisabled(false);
    setIsSignOrderDisable(false);
    setFormState({ ...formState, ...newSurveyState });
    setSurveyCheck(newSurveyState);
  };
  React.useEffect(() => {
    const {
      formattedPhoneNumber: bestPhone,
      numericPhoneNumber: bestPhoneNumeric,
    } = phoneNumberValidator(selectedPatient?.bestPhone);
    const {
      formattedPhoneNumber: cellPhone,
      numericPhoneNumber: cellPhoneNumeric,
    } = phoneNumberValidator(selectedPatient?.cellPhone);

    let selectedPatientSurveyData = dashboardData?.filter(
      (item) => item?.patientIdPat === selectedPatient?.patientId
    );
    if (selectedPatientSurveyData?.length) {
      selectedPatientSurveyData = selectedPatientSurveyData[0];
      if (
        selectedPatientSurveyData?.surveyIdSurvey ||
        selectedPatientSurveyData?.surveyId
      ) {
        setIsSurveyOfSelectedPtients(true);
      }
    }

    setFormState({
      ...selectedPatient,
      ...formState,
      bestPhone,
      cellPhone,
    });
    setSurveyFormState({
      ...surveyFormState,
      ...selectedPatientSurveyData,
    });
    setPatientsPhoneNumber({
      ...patientsPhoneNumber,
      bestPhone: bestPhoneNumeric,
      cellPhone: cellPhoneNumeric,
    });
    setBMIState({
      ...BMI,
      heightFeet: selectedPatient?.heightFeet,
      heightInches: selectedPatient?.heightInches,
      patBMI: selectedPatient?.patBMI,
      patWeight: selectedPatient?.patWeight,
    });
    if (selectedPatient?.dob) {
      setDOB(dayjs(selectedPatient?.dob));
    }
    // if (!dashboardData?.length) {
    async function setDataFirstTime() {
      dashboardGetData(axios)
        .then((res) => {
          dispatch(updatedashboardData(res["1"]));
          let selectedPatientSurveyDataAPI = res["1"]?.filter((item) => {
            return item?.patientIdPat === selectedPatient?.patientId;
          });
          if (selectedPatientSurveyDataAPI?.length) {
            selectedPatientSurveyDataAPI = selectedPatientSurveyDataAPI[0];
            if (selectedPatientSurveyDataAPI?.surveyIdSurvey) {
              setIsSurveyOfSelectedPtients(true);
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {});
    }
    setDataFirstTime();
    // }
  }, [selectedPatient]);
  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
    setIsSaveButtonDisabled(false);
    setIsSignOrderDisable(false);
  };

  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
    setIsSaveButtonDisabled(false);
    setIsSignOrderDisable(false);
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const saveOrder = (e, newOrderSigned) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!DOB) {
      isValid = false;
      setErrorDOB(true);
    }

    if (formState?.otherFaceNotes && !formState?.otherNotes) {
      isValid = false;
      notificationToastError("Please fill a required field 'Other' . ");
      setFormErrorState(errors);
      return false;
    }

    if(selectedCheckbox === "pediatricSleepStudy" && !formState?.pediatricOptions){
      isValid = false;
      notificationToastError("Please select atleast one pediatric option ");
      setFormErrorState(errors);
      return false;
    }

    if (!isValid) {
      notificationToastError("Please fill all required fields");
      setFormErrorState(errors);
      return false;
    }
    if (!isCheckOneFaceToFaceNote(formState)) {
      isValid = false;
      notificationToastError(
        "Please make a selection for FACE TO FACE NOTES (✔ all that apply)"
      );
    }
    if (!selectedCheckbox) {
      isValid = false;
      notificationToastError("Please choose at least one Study Type");
    }
    if (!isChooseISDDiagnosis(formState)) {
      isValid = false;
      notificationToastError(
        "Please choose at least one ICD-10 Diagnosis Code"
      );
    }

    if(moment(new Date(visitDateState)).format("YYYY/MM/DD") == "Invalid date"){
      isValid = false;
      notificationToastError( "Invalid date format");
    }

    if (isValid) {
      setActive(true)
      let visitDate =
        visitDateState && moment(new Date(visitDateState)).format("YYYY-MM-DD");
      const payload = {
        ...formState,
        bestPhone: patientsPhoneNumber?.bestPhone,
        cellPhone: patientsPhoneNumber?.cellPhone,
        physicianId: formState.physicianId,
        visitDate: visitDate,
        newOrderSigned: newOrderSigned || "",
        fallsAsleepCheckBox: Number(fallsAsleepCheckBox),
        [selectedCheckbox]: selectedCheckbox ? 1 : 0,
        cpapOptions: formState?.cpapSubstitute,
        bipapOptions: formState?.bipapSubstitute,
        ssn: undefined
      };

      orderPostData(axios, "saveOrder", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            if (e.target.id !== "signOrderBtn") {
              setIsSignOrderDisable(true);
              notificationToastSuccess(res?.data?.message);
              setTimeout(() => {
                navigate(`/dashboard`);
              }, 2000);
            } else if (e.target.id === "signOrderBtn") {
              setFormState({
                ...formState,
                orderId:res?.data?.orderId
              })
              setOpen(true);
            }

            // setIsSaveButtonDisabled(true);
            // setIsSignOrderDisable(true);
          } else {
            notificationToastError(res?.data?.message);
            setActive(false)
          }
        })
        .catch((err) => notificationToastError(err?.message));
    }
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const updateDOB = (newValue) => {
    setDOB(newValue);
  };
  const [errorDOB, setErrorDOB] = React.useState(false);

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const [isSelectPatient, setIsSelectPatient] = useState(true);

  const unselectPatient = () => {
    dispatch(updateSelectedPatient(undefined));
    setFormState({});
    setDOB(null);
    setIsSelectPatient(true);
  };
  const handleCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    if(event.target.checked){
      setSelectedCheckbox(checkboxId);
    }else{
      setSelectedCheckbox("");
    }
    if (checkboxId === "2nightProtocol") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: event.target.checked ? Number(true) : Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "splitNightProtocol") {
      setFormState({
        ...formState,
        splitHSTSubstitute: event.target.checked ? Number(true) : Number(false),
        ["2nightHSTSubstitute"]: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "psgBaselineStudy") {
      setFormState({
        ...formState,
        psgOptions:event.target.checked ?
          "SUBSTITUTE HST if in-lab disqualified by insurance or denied OR,":"",
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "homeSleepTest") {
      setFormState({
        ...formState,
        hstNights: event.target.checked ? "TWO NIGHT" :"",
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        cpapIfHstAbnormal: event.target.checked ? Number(true) : Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "cpapTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
        cpapSubstitute: event.target.checked ? formState.cpapSubstitute : ""
      });
    } else if (checkboxId === "bipapTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "bipapStTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "AsvTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "AvapsTitration") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: "",
      });
    } else if (checkboxId === "pediatricSleepStudy") {
      setFormState({
        ...formState,
        ["2nightHSTSubstitute"]: Number(false),
        splitHSTSubstitute: Number(false),
        psgOptions: "",
        hstNights: "",
        cpapIfHstAbnormal: Number(false),
        cpapOptions: "",
        bipapOptions: "",
        pediatricOptions: event.target.checked ? "PSG BASELINE STUDY (95782 or 95810 Medicaid only)" : ""
      });
    }
    setIsSaveButtonDisabled(false);
    setIsSignOrderDisable(false);
  };
  return (
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      {<SearchPatient selectedPatient={selectedPatient} />}
      {/*   {!selectedPatient?.patientId && isSelectPatient && (
        <PatientSearch setIsSelectPatient={setIsSelectPatient} isSelectPatient={isSelectPatient} />
      )}

      {selectedPatient &&
        <SelectedPatients selectedPatient={selectedPatient} unselectPatient={unselectPatient} />}
      */}

      <FileUpload patientId={selectedPatient?.patientId} />

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
          background: readOnlyBackground,
        }}
        noValidate
        autoComplete="off"
      >
        <PatientInfo
          formState={formState}
          updateInput={updateInput}
          DOB={DOB}
          updateDOB={updateDOB}
          errorDOB={errorDOB}
          setErrorDOB={setErrorDOB}
          formErrorState={formErrorState}
          setFormErrorState={setFormErrorState}
          isReadOnly={true}
          handleFocus={handleFocus}
          BMIState={BMI}
        />

        <InsuranceInfo
          formState={formState}
          setFormState={setFormState}
          isReadOnly={true}
          handleFocus={handleFocus}
        />
      </Box>
     {/* {isSurveyOfSelectedPtients && (
        <Box
          sx={{
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            margin: "10px 0px 20px 0px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <BMICalculation
            formState={formState}
            surveyFormState={surveyFormState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            validateEmptyField={validateEmptyField}
            expanded={expanded}
            setExpanded={setExpanded}
            handleFocus={handleFocus}
            surveyCheck={surveyCheck}
          />
        </Box>
      )}

        */}
        
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          Face To Face Notes (check all that apply)
        </Typography>

        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="snoring"
                  checked={Boolean(formState.snoring)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Snoring"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="fatigue"
                  checked={Boolean(formState.fatigue)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Fatigue"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="daytimeSleepiness"
                  checked={Boolean(formState.daytimeSleepiness)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Excessive Daytime Sleepiness"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="gasping"
                  checked={Boolean(formState.gasping)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Wakes up gasping for breath"
            />
          </FormControl>
        </div>

        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="apneas"
                  checked={Boolean(formState.apneas)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Witnessed Apneas"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="inappropriateSleep"
                  checked={Boolean(formState.inappropriateSleep)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Falls asleep at inappropriate times and/or places"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="otherFaceNotes"
                  checked={Boolean(formState.otherFaceNotes)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Other"
            />
          </FormControl>
        </div>
        {formState?.otherFaceNotes == 1 && (
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <TextField
              id="otherNotes"
              label="Other *"
              value={formState.otherNotes}
              variant="standard"
              onChange={updateInput}
              inputProps={{ maxLength: 200 }}
            />
          </Box>
        )}
        <Box>
          <DateToVisit
            setVisitDate={setVisitDate}
            visitDateState={visitDateState}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
          />
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          "& .MuiBox-root": { flexGrow: 1 },
          display: "flex",
          gap: "2rem",
          marginTop: "0.5rem",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "1rem",
            }}
          >
            Please Choose One Study Type (required)
          </Typography>
          <FormControl sx={{ m: 1 }}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="2nightProtocol"
                    // checked={twoNightProtocolCheckBox}
                    // onChange={() => {
                    //   setTwoNightProtocolCheckBox(!twoNightProtocolCheckBox);
                    //   setIsSaveButtonDisabled(false)
                    // }}
                    checked={(selectedCheckbox === "2nightProtocol" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="2 NIGHT PROTOCOL - 1ST Polysomnogram (PSG) THEN 2nd night CPAP trial, if indicated (95810/95811)"
              />
            </Box>

            <Box>
              <FormControlLabel
                sx={{ paddingLeft: "2rem" }}
                value="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
                control={
                  <Checkbox
                    id="2nightHSTSubstitute"
                    checked={Boolean(formState["2nightHSTSubstitute"])}
                    onChange={updateCheckBox}
                  />
                }
                label="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
              />
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="splitNightProtocol"
                    checked={(selectedCheckbox === "splitNightProtocol" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="SPLIT NIGHT PROTOCOL - 1ST night SPLIT - if PAP not initiated, THEN 2nd night CPAP/BiPAP (95810/95811)"
              />
            </Box>

            <Box>
              <FormControlLabel
                sx={{ paddingLeft: "2rem" }}
                value="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
                control={
                  <Checkbox
                    id="splitHSTSubstitute"
                    checked={Boolean(formState.splitHSTSubstitute)}
                    onChange={updateCheckBox}
                  />
                }
                label="SUBSTITUTE HST if in-lab disqualified by insurance or denied"
              />
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="psgBaselineStudy"
                    // checked={psgBaselineCheckBox}
                    // onChange={() => {
                    //   setPsgBaselineCheckBox(!psgBaselineCheckBox);
                    //   setIsSaveButtonDisabled(false)
                    // }}
                    checked={(selectedCheckbox === "psgBaselineStudy" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="PSG baseline study (95810)"
              />
            </Box>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="psgOptions"
              sx={{ paddingLeft: "2rem" }}
              id="psgOptions"
              value={formState?.psgOptions || ""}
              onClick={(e) => {
                if (e.target.value === formState?.psgOptions) {
                  setFormState({
                    ...formState,
                    psgOptions: "",
                  });
                } else updateInput(e)}}
            >
              <Box>
                <FormControlLabel
                  value="SUBSTITUTE HST if in-lab disqualified by insurance or denied OR,"
                  control={<Radio />}
                  label="SUBSTITUTE HST if in-lab disqualified by insurance or denied OR,"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="PSG with MSLT (Multiple Sleep Latency Test) 95805"
                  control={<Radio />}
                  label="PSG with MSLT (Multiple Sleep Latency Test) 95805"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="PSG with MWT (Maintenance of Wakefulness Test) 95805"
                  control={<Radio />}
                  label="PSG with MWT (Maintenance of Wakefulness Test) 95805"
                />
              </Box>
            </RadioGroup>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="homeSleepTest"
                    // checked={homeSleepTestCheckBox}
                    // onChange={() => {
                    //   setHomeSleepTestCheckBox(!homeSleepTestCheckBox);
                    //   setIsSaveButtonDisabled(false)
                    // }}
                    checked={(selectedCheckbox === "homeSleepTest" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="HOME SLEEP TEST"
              />
            </Box>
            <Box sx={{ paddingLeft: "2rem" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="hstNights"
                id="hstNights"
                onClick={(e) => {
                  if (e.target.value === formState?.hstNights) {
                    setFormState({
                      ...formState,
                      hstNights: "",
                    });
                  } else updateInput(e)}}
                value={formState?.hstNights || ""}
                sx={{
                  flexDirection: "row",
                }}
              >
                <Box>
                  <FormControlLabel
                    value="ONE NIGHT"
                    control={<Radio />}
                    label="ONE NIGHT"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value="TWO NIGHT"
                    control={<Radio />}
                    label="TWO NIGHT"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value="THREE NIGHT"
                    control={<Radio />}
                    label="THREE NIGHT"
                  />
                </Box>
              </RadioGroup>

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="cpapIfHstAbnormal"
                      checked={Boolean(formState.cpapIfHstAbnormal)}
                      onChange={updateCheckBox}
                    />
                  }
                  sx={{ marginLeft: "2rem" }}
                  label="IF abnormal HST then CPAP titration (95811)"
                />
              </Box>

            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="cpapTitration"
                    // checked={cpapTitrationCheckBox}
                    // onChange={() => {
                    //   setCpapTitrationCheckBox(!cpapTitrationCheckBox);
                    //   setIsSaveButtonDisabled(false)
                    // }}
                    checked={(selectedCheckbox === "cpapTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="CPAP titration (95811)"
              />
            </Box>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="cpapSubstitute"
              value={formState?.cpapSubstitute || ""}
              onClick={(e) => {
                if (e.target.value === formState?.cpapSubstitute) {
                  setFormState({
                    ...formState,
                    cpapSubstitute: "",
                  });
                } else updateInput(e)}}
              sx={{ paddingLeft: "2rem" }}
            >
              <Box>
                <FormControlLabel
                  value="MSLT (Multiple Sleep Latency Test) 95805"
                  control={<Radio />}
                  label="MSLT (Multiple Sleep Latency Test) 95805"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="MWT (Maintenance of Wakefulness Test) 95805"
                  control={<Radio />}
                  label="MWT (Maintenance of Wakefulness Test) 95805"
                />
              </Box>
            </RadioGroup>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="bipapTitration"
                    // checked={bipapTitrationCheckBox}
                    // onChange={() => {
                    //   setBipapTitrationCheckBox(!bipapTitrationCheckBox);
                    //   setIsSaveButtonDisabled(false)
                    // }}
                    checked={(selectedCheckbox === "bipapTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="BiPAP titration (95811)"
              />
            </Box>
            {selectedCheckbox === "bipapTitration" && (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="bipapSubstitute"
                value={formState?.bipapSubstitute}
                onClick={(e) => {
                  if (e.target.value === formState?.bipapSubstitute) {
                    setFormState({
                      ...formState,
                      bipapSubstitute: "",
                    });
                  } else updateInput(e)}}
                sx={{ paddingLeft: "2rem" }}
              >
                <Box>
                  <FormControlLabel
                    value="MSLT (Multiple Sleep Latency Test) 95805"
                    control={<Radio />}
                    label="MSLT (Multiple Sleep Latency Test) 95805"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value="MWT (Maintenance of Wakefulness Test) 95805"
                    control={<Radio />}
                    label="MWT (Maintenance of Wakefulness Test) 95805"
                  />
                </Box>
              </RadioGroup>
            )}
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="bipapStTitration"
                    checked={(selectedCheckbox === "bipapStTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="BiPAP ST titration (95811)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="AsvTitration"
                    checked={(selectedCheckbox === "AsvTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="ASV titration (95811)"
              />
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="AvapsTitration"
                    checked={(selectedCheckbox === "AvapsTitration" )|| ""}
                    onChange={handleCheckboxChange}
                  />
                }
                label="AVAPS titration (95811)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="pediatricSleepStudy"
                    // checked={pediatricsSleepCheckBox}
                    checked={(selectedCheckbox === "pediatricSleepStudy" )|| ""}
                    // onChange={() => {
                    //   setPediatricsSleepCheckBox(!pediatricsSleepCheckBox);
                    //   setIsSaveButtonDisabled(false)
                    // }}
                    onChange={handleCheckboxChange}
                  />
                }
                label="PEDIATRICS SLEEP STUDY (5 YEARS OF AGE OR YOUNGER)"
              />
            </Box>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="pediatricOptions"
              value={formState?.pediatricOptions || ""}
              onClick={(e) => {
                if (e.target.value === formState?.pediatricOptions) {
                  setFormState({
                    ...formState,
                    pediatricOptions: "",
                  });
                } else updateInput(e)}}
              sx={{ paddingLeft: "2rem" }}
            >
              <Box>
                <FormControlLabel
                  value="PSG BASELINE STUDY (95782 or 95810 Medicaid only)"
                  control={<Radio />}
                  label="PSG BASELINE STUDY (95782 or 95810 Medicaid only)"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="CPAP titration (95783 or 95811 Medicaid only)"
                  control={<Radio />}
                  label="CPAP titration (95783 or 95811 Medicaid only)"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="BiPAP titration (95783 or 95811 Medicaid only)"
                  control={<Radio />}
                  label="BiPAP titration (95783 or 95811 Medicaid only)"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "1rem",
            }}
          >
            Please Choose At Least One ICD-10 Diagnosis Code (required)
          </Typography>

          <FormControl sx={{ m: 1 }}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_30"
                    checked={Boolean(formState.G47_30)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.30 Sleep apnea, unspecified"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_33"
                    checked={Boolean(formState.G47_33)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.33 Obstructive sleep apnea"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_31"
                    checked={Boolean(formState.G47_31)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.31 Primary Central Sleep Apnea"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_9"
                    checked={Boolean(formState.G47_9)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.9 Sleep disorder, unspecified"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="E66_2"
                    checked={Boolean(formState.E66_2)}
                    onChange={updateCheckBox}
                  />
                }
                label="E66.2 Morbid (severe) obesity alveolar hypoventilation"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_69"
                    checked={Boolean(formState.G47_69)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.69 Other sleep related movements"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_61"
                    checked={Boolean(formState.G47_61)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.61 Periodic limb movement disorders"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="F51_12"
                    checked={Boolean(formState.F51_12)}
                    onChange={updateCheckBox}
                  />
                }
                label="F51.12 Insufficient sleep syndrome"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_11"
                    checked={Boolean(formState.G47_11)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.11 Idiopathic hypersomnia with long sleep time"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_12"
                    checked={Boolean(formState.G47_12)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.12 Idiopathic hypersomnia without long sleep time"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="G47_13"
                    checked={Boolean(formState.G47_13)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.13 Recurrent hypersomnia"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_19"
                    checked={Boolean(formState.G47_19)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.19 Other hypersomnia"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="F51_4"
                    checked={Boolean(formState.F51_4)}
                    onChange={updateCheckBox}
                  />
                }
                label="F51.4 Sleep terrors (night terrors)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="F51_5"
                    checked={Boolean(formState.F51_5)}
                    onChange={updateCheckBox}
                  />
                }
                label="F51.5 Nightmare disorders"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="F51_3"
                    checked={Boolean(formState.F51_3)}
                    onChange={updateCheckBox}
                  />
                }
                label="F51.3 Sleepwalking (Somnambulism)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_411"
                    checked={Boolean(formState.G47_411)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.411 Narcolepsy with cataplexy"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_419"
                    checked={Boolean(formState.G47_419)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.419 Narcolepsy without cataplexy"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_421"
                    checked={Boolean(formState.G47_421)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.421 Narcolepsy in conditions classified elsewhere, with cataplexy"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="G47_421_wo_cataplexy"
                    checked={Boolean(formState.G47_421_wo_cataplexy)}
                    onChange={updateCheckBox}
                  />
                }
                label="G47.421 Narcolepsy in conditions classified elsewhere, without cataplexy"
              />
            </Box>
          </FormControl>
        </Box>
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="addlInstructions"
            label="Special/Additional Instructions:"
            value={formState.specialInstructions}
            onChange={updateInput}
            variant="standard"
            inputProps={{ maxLength: 200 }}
          />
        </Box>
        <div>
          <FormControl sx={{ m: 1 }}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="consult"
                    checked={Boolean(formState.consult)}
                    onChange={updateCheckBox}
                  />
                }
                label="CONSULT AND TREAT BY BOARD CERTIFIED SLEEP PHYSICIAN"
              />
            </Box>
          </FormControl>
        </div>
        {!expanded && (
          <Box
            // component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "98%" },
              "& .MuiFormControl-root": { flexGrow: 1 },
              // border: "1px solid rgba(0,0,0, 0.12)",
              borderRadius: "4px",
              margin: "10px 10px 20px 10px",
              display: "flex",
            }}
          >
            <PhysicianPulldown
              formState={formState}
              updateInput={updateInput}
              attributeName={"physicianId"}
              formErrorState={formErrorState}
              validateEmptyField={validateEmptyField}
            />
          </Box>
        )}
      </Box>
      {!isOrderSignedSucess ? (
        <Box
          sx={{
            paddingTop: isMobile ? "1rem" : "2rem",
            paddingBottom: isMobile ? "4rem" : "2rem",
            display: "flex",
            gap: "15px",
          }}
        >
          <Button
            variant="contained"
            disabled={isSaveButtonDisabled || active}
            onClick={saveOrder}
          >
            SAVE ORDER
          </Button>
          {isSignOrderPresent && (
            <SignedOrder
              formState={formState}
              isSignOrderDisable={isSignOrderDisable}
              fallsAsleepCheckBox={fallsAsleepCheckBox}
              setIsSaveButtonDisabled={setIsSaveButtonDisabled}
              visitDateState={visitDateState}
              setIsOrderSignedSucess={setIsOrderSignedSucess}
              saveOrder={saveOrder}
              setOpen={setOpen}
              open={open}
              selectedCheckbox={selectedCheckbox}
              setFormState={setFormState}
            />
          )}
        </Box>
      ) : (
        <Box sx={{ padding: "20px 0px", maxWidth: "251px" }}>
          <Alert variant="filled" severity="success">
            This order has been signed
          </Alert>
        </Box>
      )}

      <ToastContainer />
    </Container>
  );
}

export default NewOrder;
