import { Box, Button, Card, CardActions, CardContent, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth, useAxios } from '../../../../hooks/auth';
import axios from '../../../../axios';
import styles from './selectedPatients.module.css'
import { patientsGetData, patientsPaginationGetData } from '../../../../axios/api';
import { notificationToastError, notificationToastSuccess } from "../../../../utils/notification";

function SelectedPatients({ selectedPatient = {}, unselectPatient = () => { } }) {
  const isMobile = useMediaQuery('(max-width:800px)');
  const navigate = useNavigate();
  const axios =useAxios()
  const [rowData, setRowData] = useState([]);
  React.useEffect(() => {
    async function setDataFirstTime() {
      patientsGetData(axios).then((res) => {
        setRowData(res?.data);
      }).catch((err) => {
        console.log(err);
      })
    }
    setDataFirstTime();
  }, []);
  const handlePatientsView = async () => {

    const paramsName= "patientView"
    const payload = {
      patientId: selectedPatient?.patientId
    }

    patientsPaginationGetData (axios, paramsName, payload).then((res) => {
       setRowData(res);
       navigate(`/patient-view`, { state: { patientInfo: res[0] } });

  }).catch((err) => notificationToastError(err?.message))

  /*console.log ("rowData = " , rowData)
    const selectedPatientData = await rowData?.filter((item) => selectedPatient?.patientId == item?.patientId)
    console.log ("selectedPatientData = ", selectedPatientData)
    if (selectedPatientData?.length) {
      navigate(`/patient-view`, { state: { patientInfo: rowData } });
    }
    */
  }
  return (
    <Card
    className={styles.selectedMainWrapper}
  >
    <CardContent 
       sx={{
        display:  'flex',
        gap : 1,
        flexDirection: isMobile ? 'column' : 'row',
      }}
    >
      <Box sx={{display : isMobile ? "flex" : "unset" }}>
      <Typography variant='strong' className={styles.selectedPatientText} >
        Selected Patient :
      </Typography>
      <Typography variant='span'  className={styles.patientsText}> {" "}
      {selectedPatient.lastName}{","} {selectedPatient.firstName} 
      </Typography > {"  "}
      </Box>

      <Box sx={{display : isMobile ? "flex" : "unset" }}>

      <Typography variant='strong'   className={styles.selectedPatientText}>
        DOB :
      </Typography >
      <Typography variant='span' className={styles.patientsText}> {" "}
        {selectedPatient.dob}
      </Typography>

      </Box>

    </CardContent>
    <CardActions>
    <Button size="small" variant="contained" onClick={handlePatientsView}>
        view
      </Button>
      <Button size="small" variant="contained" onClick={unselectPatient}>
        Unselect Patient
      </Button>
     
    </CardActions>
  </Card>
  )
}

export default SelectedPatients