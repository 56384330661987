import { Box, Checkbox, FormControl, FormControlLabel, Typography, useMediaQuery } from '@mui/material'
import classes from "./InssuranceCPTcode.module.css"
import { DME_CPT_CODES, HLTCPTCODES,  LT_CPT_CODES, PAPM_CPT_CODES, PST_CPT_CODES } from '../../../constants/cptCode'
function InssuranceCPTcode({ formState, updateInssuranceCheckbox }) {
  const isMobile = useMediaQuery('(max-width:800px)');

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
        border: "1px solid rgba(0,0,0, 0.12)",
        borderRadius: "4px",
        marginTop: "10px",
      }}
    >
      <Typography variant='h6' sx={{marginTop:"1rem"}} className={classes.CPTHeading}
      >
        Select CPT Codes
      </Typography>
      <Box className={classes.checkBoxMainWrapper} sx={{display : "flex" , flexDirection : isMobile ? "column" : "row"}}>
        <Box>
          <Typography variant='subtitle' sx={{margin:" 0.5rem"}} >Home Sleep Tests : </Typography>
          <Box className={classes.checkboxWrapper}>
            {
              HLTCPTCODES?.map((hltCptCode) => {
                return (
                  <FormControl sx={{ m: 1 }} key={hltCptCode?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={hltCptCode?.id}
                          checked={Boolean(formState[hltCptCode?.id])}
                          onChange={updateInssuranceCheckbox}
                        />
                      }
                      label={hltCptCode?.label}
                    />
                  </FormControl>
                )
              })
            }
          </Box>

          <Typography variant='subtitle' sx={{margin:" 0.5rem"}}>In Lab Tests : </Typography>
          <Box className={classes.checkboxWrapper}>
            {
              LT_CPT_CODES?.map((hltCptCode) => {
                return (
                  <FormControl sx={{ m: 1 }} key={hltCptCode?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={hltCptCode?.id}
                          checked={Boolean(formState[hltCptCode?.id])}
                          onChange={updateInssuranceCheckbox}
                        />
                      }
                      label={hltCptCode?.label}
                    />
                  </FormControl>
                )
              })
            }
          </Box>

          <Typography variant='subtitle' sx={{margin:" 0.5rem"}}>Pediatric Sleep Tests : ( applies to any patient <strong> &lt; </strong> 6 y/o )</Typography>
          <Box className={classes.checkboxWrapper}>
            {
              PST_CPT_CODES?.map((hltCptCode) => {
                return (
                  <FormControl sx={{ m: 1 }} key={hltCptCode?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={hltCptCode?.id}
                          checked={Boolean(formState[hltCptCode?.id])}
                          onChange={updateInssuranceCheckbox}
                        />
                      }
                      label={hltCptCode?.label}
                    />
                  </FormControl>
                )
              })
            }
          </Box>
        </Box>
        <Box>
          <Typography variant='subtitle' sx={{margin:"0.5rem"}}>Durable Medical Equipment :</Typography>
          <Box className={classes.checkboxWrapper}>
            {
              DME_CPT_CODES?.map((hltCptCode) => {
                return (
                  <FormControl sx={{ m: 1 }} key={hltCptCode?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={hltCptCode?.id}
                          checked={Boolean(formState[hltCptCode?.id])}
                          onChange={updateInssuranceCheckbox}
                        />
                      }
                      label={hltCptCode?.label}
                    />
                  </FormControl>
                )
              })
            }
          </Box>
          <Typography variant='subtitle' sx={{margin:"0.5rem"}}>PAP Machines :</Typography>
          <Box className={classes.checkboxWrapper}>
            {
              PAPM_CPT_CODES?.map((hltCptCode) => {
                return (
                  <FormControl sx={{ m: 1 }} key={hltCptCode?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={hltCptCode?.id}
                          checked={Boolean(formState[hltCptCode?.id])}
                          onChange={updateInssuranceCheckbox}
                        />
                      }
                      label={hltCptCode?.label}
                    />
                  </FormControl>
                )
              })
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default InssuranceCPTcode