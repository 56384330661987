import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import HistoryIcon from '@mui/icons-material/History';
import React from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// const allRoutePaths= [
//     {
//       text: "DASHBOARD",
//       path: "/dashboard",
//       tabName: "dashboard",
//       iconComponent: <DashboardIcon />,
//     },
//     {
//       text: "PATIENTS",
//       path: "/patients",
//       tabName: "patients",
//       iconComponent: <PeopleIcon />,
//     },
//     {
//       text: "REPORTS",
//       path: "/reports",
//       tabName: "reports",
//       iconComponent: <AssessmentIcon />,
//     },
//     {
//       text: "ADMIN",
//       path: "/admin",
//       tabName: "admin",
//       iconComponent: <ManageAccountsIcon />,
//     },
//     {
//       text: "PROFILE",
//       path: "/profile",
//       tabName: "profSettings",
//       iconComponent: <AccountCircleIcon />,
//     },
//   ]

export const allRoutePathsFunc = (isSignOrder=false) => {
      return [
        {
          text: "DASHBOARD",
          path: "/dashboard",
          tabName: "dashboard",
          iconComponent: <DashboardIcon />,
        },
        {
          text: "PATIENTS",
          path: "/patients",
          tabName: "patients",
          iconComponent: <AirlineSeatIndividualSuiteIcon  />,
        },
        {
          text: "REPORTS",
          path: "/reports",
          tabName: "reports",
          iconComponent: <AssessmentIcon />,
        },
        {
          text: "ADMIN",
          path: "/admin",
          tabName: "admin",
          iconComponent: <ManageAccountsIcon />,
        },
        {
          text: "PROFILE",
          path: `${isSignOrder ? "/physician-profile" : "/profile" }`,
          tabName: "profSettings",
          iconComponent: <AccountCircleIcon />,
        },
      ]
}

export const allSubRoutes= [
  {
    text: (
      <React.Fragment>
        Patients {' '} <ArrowForwardIcon fontSize="small" sx={{margin:"0px 6px"}}/> {' '}  New Order
      </React.Fragment>
    ),
    path: "/new-order",
  },
  {
    text: (
      <React.Fragment>
        Patients {' '} <ArrowForwardIcon fontSize="small" sx={{margin:"0px 6px"}}/> {' '}  New Survey
      </React.Fragment>
    ),
    path: "/new-survey",
  },
  {
    text: (
      <React.Fragment>
        Patients {' '} <ArrowForwardIcon fontSize="small" sx={{margin:"0px 6px"}}/> {' '}  Patient View
      </React.Fragment>
    ),
    path: "/patient-view",
  },
  {
    text: (
      <React.Fragment>
        Patients {' '} <ArrowForwardIcon fontSize="small" sx={{margin:"0px 6px"}}/> {' '}  Survey
      </React.Fragment>
    ),
    path: "/survey-view",
  },
  {
    text: (
      <React.Fragment>
        Patients {' '} <ArrowForwardIcon fontSize="small" sx={{margin:"0px 6px"}}/> {' '}  Order
      </React.Fragment>
    ),
    path: "/order-view",
  },
  {
    text: (
      <React.Fragment>
        Patients {' '} <ArrowForwardIcon fontSize="small" sx={{margin:"0px 6px"}}/> {' '}  DME Order
      </React.Fragment>
    ),
    path: "/dme-order",
  },
  
]

export const patientPortalRoutes= 
[
  {
    text: "Portal Home",
    path: "/patient-portal",
    tabName: "patient-portal",
    iconComponent: <DashboardIcon />,
  },
  {
    text: "PROFILE",
    path: "/patient-profile",
    tabName: "patient-profile",
    iconComponent: <AccountCircleIcon />,
  },
  {
    text: "PAST VISITS",
    path: "/patient-past-visits",
    tabName: "patient-past-visits",
    iconComponent: <HistoryIcon  />,
  },
  {
    text: "CONTACT US",
    path: "/contact-us",
    tabName: "contact-us",
    iconComponent: <AddIcCallIcon />,
  }
]
  // export default allRoutePaths;