import { Alert, Box, Button, CircularProgress, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import classes from './PatientPortalHomePage.module.css'
import { collapsedDrawerWidth, openDrawerWidth } from '../../../constants/sideBarwidth';
import { useDispatch, useSelector } from 'react-redux';
import { patientsPaginationGetData, studiesPostData, visitViewPostData, visitsGetData } from '../../../axios/api';
import { useAuth, useAxios } from '../../../hooks/auth';
import { notificationToastError } from '../../../utils/notification';
import { useQuery } from 'react-query';
import PatientReadOnlyInfo from '../../../components/patientPortalComponent/patientReadyOnlyInfo/PatientReadOnlyInfo';
import roleAndPrivilegesFnc from '../../../utils/roleAndPriviledges';
import { ToastContainer } from 'react-toastify';
import { updatePatientData, updatePatientLogin } from '../../../store/slices/patientLogInDataSlice';
import PatientCurrentInfo from '../../../components/patientPortalComponent/patientCurrentInfo/PatientCurrentInfo';
import useIsPrevileges from '../../../hooks/useRoleAndPrivileges';
import FormTable from '../../../components/patientPortalComponent/FormTable';
import DocumentTable from '../../../components/patientPortalComponent/DocumentTable';

function PatientPortalHomePage() {
  const { isCollapsed } = useSelector((state) => state?.sideBarCollapseSelector);
  const { patientId, patientReduxData } = useSelector(state => state?.patientLogInDataSelector);
  const isMobile = useMediaQuery('(max-width:800px)');
  const [visitDataObj, setVisitDataObj] = useState([])
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingVisit, setIsLoadingVisit] = useState(true)
  const [studyId, setStudyId] = useState('')
  const dispatch = useDispatch()
  const { cookies :{token
  } } = useAuth();
  const {id} = roleAndPrivilegesFnc(token);

  useEffect(() => {
    if (!patientId) {
    dispatch(updatePatientLogin(id))
    }
    if (patientId) {
      const paramsName = 'patientView';
      const payload = {
        patientId,
        patientPortal: 1,
      };
      // patient data API call
      patientsPaginationGetData(axios, paramsName, payload).then((res) => {
          if (res?.length) {
            dispatch(updatePatientData(res[0]));
          }
        })
        .catch((err) => {
          console.log("err", err);
      }).finally(() => {
        setIsLoading(false)
        })

      // study.php api call
      studiesPostData(axios, payload,)
        .then((res) => {
          if (res?.length) {
            const payloadOfVisit = {
              studyId: res[0]?.studyId,
              patientPortal: 1,
            };
            setStudyId(res[0]?.studyId)
            // visitView.php API call
            const paramsName = "viewVisit";
            visitViewPostData(axios, paramsName, payloadOfVisit)
            .then((visitRes) => {
                if (visitRes?.length) {
                setVisitDataObj(visitRes[0])
              }})
          }
        }).catch((err)=>{
          console.log("err",err);
        }).finally(()=>{
          setIsLoadingVisit(false)
        })
      // visitsGetData(axios, patientId,).then((res)=>{
      //   if (res?.length) {
      //     setVisitDataObj(res[0])
      //   }
      //   console.log(res);
      // }).catch((err)=>{
      //   console.log("err",err);
      // }).finally(()=>{
      //   setIsLoadingVisit(false)
      // })
    }

  }, [patientId])
  const date = new Date();
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  };

  const formattedDate = date?.toLocaleDateString('en-US', options);
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', height: "90vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Container sx={{ maxWidth: !isMobile ? isCollapsed ? `calc(100vw - ${collapsedDrawerWidth}px) !important` : `calc(100vw - ${openDrawerWidth}px) !important` : "100%", height: "100%" }}  >
      {
        
        (<>
          <Box className={classes?.mainHeadingWrapper}>
            <Typography variant='h4'>
              Welcome, <Typography variant='span' sx={{textTransform:'capitalize',fontWeight: 'bold',fontSize:"20px"}}>{ patientReduxData?.firstName ? patientReduxData?.firstName : ""} </Typography> to the SleepNavigator Patient Portal
            </Typography>
          </Box>
          <Box className={classes.dateWrapper}>
            <Typography variant='link'> Today is {formattedDate}</Typography>
          </Box>
          <Box className={classes.borderBox}
          >
            {
              Object.keys(patientReduxData)?.length !== 0 ?
              <PatientReadOnlyInfo />
              : 
              <Typography variant='h6'sx={{textAlign:"center"}}>Patient Data not found</Typography>
            }
            
          </Box>
        </>)
      }
      {
        isLoadingVisit ?
          (<Box sx={{ display: 'flex', height: "90vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
          </Box>) :
          (
            <Box className={classes.borderBox}
            >
          <PatientCurrentInfo visitDataObj={visitDataObj} />
          {/* form table */}
          <Box>
               <FormTable studyId={studyId} visitDataObj={visitDataObj}  fromPatientPortal={true} />
          </Box>
          {/* document table */}
          <Box>
                <DocumentTable/>
          </Box>

        </Box>
          )
      }

      <ToastContainer />
    </Container>
  )
}

export default PatientPortalHomePage;