import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useMediaQuery } from "@mui/material";

export default function ConfirmDialog({
  title,
  confirmButton,
  handleClose,
  children,
  isSaveButtonDisabled=false,
  minwidth="635px",
  tabValue=false,
  maxWidth,
  consentForm,
  cancelBtText
}) {
  const isMobile = useMediaQuery('(max-width:800px)');
  return (
    <Dialog
      open
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: isMobile ? "auto" : minwidth, minHeight: isMobile ? "auto" : "400px", maxWidth: maxWidth ? maxWidth : "600px", overflowY: consentForm ? "unset" : "auto" },
        "& .MuiDialogContent-root": {
          display: "flex",
          //justifyContent: "center",
          flexDirection: "column",
        },
      }}
      disableEscapeKeyDown
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {tabValue!==1 &&
      <DialogActions>
        <Button variant="contained" onClick={handleClose} >{ cancelBtText || "Cancel" }</Button>
      {confirmButton.title &&  <Button variant="contained" data-testid="save-changes-button" disabled={isSaveButtonDisabled} onClick={confirmButton.action}>{confirmButton.title}</Button>}
      </DialogActions>}
    </Dialog>
  );
}
