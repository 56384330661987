import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import {Box, Tooltip } from "@mui/material";
import { useAuth, useAxios } from "../../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../components/misc/Dialog";
import FormTable from '../../../components/patientPortalComponent/FormTable';
import MUIDataTable from 'mui-datatables';
import { Download, Delete, Preview, Visibility } from '@mui/icons-material';
import { notificationToastError, notificationToastSuccess } from '../../../utils/notification';
import FileView from '../../../pages/patients/components/FileUpload/FileView';
import { Buffer } from "buffer";

import {
  FormControl,
  Button
} from "@mui/material";
import moment from "moment";
import { studyPostData, pulldownsPostData, patientsPostData, patientViewGetData, documentFormData  } from "../../../axios/api";

import { useDispatch } from "react-redux";

export default function StudyFilesFormsDialog({
  handleClose,
  row,
  studyId,
  patientId,
  orderId,
  dmeOrderId,
  setViewCloseDialogState = () => {},
  asDialog = true,
  isPreviousVisits = false,
  studyInfoData = {},
  isPatientPortal,
  notShowPopup,
  reloadCurrentVisit,
  isCurrentVisits,
  isWellnecessitiesUser
}) {


  const [formErrorState, setFormErrorState] = useState({});
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [fileData, setFileData] = useState([]);

  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" })
  const axios = useAxios()
  const paramNameIn = "name";
  const [deviceReturnDate, setDeviceReturnDate] = useState(null);
  const [deviceReturnDateErr, setDeviceReturnDateErr] = useState(null);
  const [uploadDate, setUploadDate] = useState(null);
  const [uploadDateErr, setUploadDateErr] = useState(null);
  const [deviceDate, setDeviceDate] = useState(null);
  const [deviceDateErr, setDeviceDateErr] = useState(null);
  const [refreshData, setRefreshData] = useState(false);

  const base64ToPdf = (base64String, fileType) => {
    let typeFile = fileType === "pdf" ? "application/pdf" : "image/png"
    const buffer = Buffer.from(base64String, "base64");
    const url = URL.createObjectURL(
      new Blob([buffer], { type: typeFile })
    );
    return url;
  };

  const handleFileView =(rowData)=>{

    const payload = {
        id : rowData[0],
        tableName: "storedFiles",
        idColumn: "fileId",
      }
     
      patientsPostData(axios, "downloadFile", payload).then((res) => {
     
      if (res?.data?.file) {
        setOpenPdfModal(true);
        let base64 = res?.data.file;
        base64 = base64?.split(",")[1];
        let fileType = res?.data.name;
        fileType = fileType?.split(".")?.pop();
        const pdfUrl = base64ToPdf(base64, fileType);
        setPdfURLState({
          fileUrl: pdfUrl,
          fileType,
          fileName: res?.data.name?.split(".")[0],
        });
      } else {
        notificationToastError("File was not downloaded due to issue.");
      }
    })
    .catch((err) => {
      console.log("err==>", err);
    });
};

  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    }
   
    patientsPostData(axios, "downloadFile", payload).then((res) => {
      if (res.data.file) {
        if (isView === "view") {
         
          setOpenPdfModal(true)
          let base64 = res?.data?.file;
          base64 = base64?.split(',')[1]
          let fileType = res?.data?.name;
          fileType = fileType?.split('.')?.pop();
          const pdfUrl = base64ToPdf(base64, fileType);
          setPdfURLState({
            fileUrl: pdfUrl,
            fileType
          })
        } else {
          notificationToastSuccess("File is downloading.");
          const downloadLink = document.createElement("a");
          downloadLink.href = res.data.file;
          downloadLink.download = res.data.name;
          downloadLink.click();
        }

      } else {
        notificationToastError("File was not downloaded due to issue.");
      }
      // setRefreshData(!refreshData);
    }).catch((err) => notificationToastError(err?.message))


  };

  React.useEffect(() => {

     // const setFileDataFirstTime = () => {
        patientViewGetData(axios, "listFiles", patientId, orderId, studyId,dmeOrderId ).then((res) => {
          if (res) {
            setFileData(res);
          
          }
        }).catch((err) => {
          console.log("err", err);
        })
   //   }
    //  if (patientId && orderId) {
      //  setFileDataFirstTime()
     // }
    


  }, [refreshData])


  useEffect(() => {
   
      const setFileDataFirstTime = () => {
        patientViewGetData(axios, "listFiles", patientId, orderId, studyId,dmeOrderId ).then((res) => {
          if (res) {
            setFileData(res);
          }
        }).catch((err) => {
          console.log("err", err);
        })
      }
      if (patientId && orderId) {
        setFileDataFirstTime()
      }


  }, [refreshData])


  const [viewScheduleStudyState, setViewScheduleStudyDialogState] =
    useState(undefined);
 

  const dispatch = useDispatch();

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);


  if (asDialog) {
   return (
      <ConfirmDialog
         handleClose={handleClose}
         confirmButton={{ action: "", title: "" }}
         cancelBtText = {"Close"}
         isSaveButtonDisabled={isSaveButtonDisabled}
      >
        { renderForm(asDialog)}
      </ConfirmDialog> 
    );

  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
        <>
      <Box>
        <div>
          <FormControl sx={{ m: "8px 0px" }}>
            <b>Study's Files and Forms: </b>
          </FormControl>
        </div>   

        <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1 },
                    border: "1px solid rgba(0,0,0, 0.12)",
                    borderRadius: "4px",
                    marginTop: "20px",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <h4
                    style={{
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Files
                  </h4>
                
                  <MUIDataTable
                    data={fileData}
                    className="mui-data-table-files" 
                    columns={[
                      {
                        name: "fileId",
                        options: {
                          display: false,
                          viewColumns: false,
                          filter: false,
                        },
                      },
                      {
                        name: "patientId",
                        options: {
                          display: false,
                          viewColumns: false,
                          filter: false,
                        },
                      },
                      {
                        name: "fileName",
                        label: "File Name",
                      },
                      {
                        name: "category",
                        label: "Category",
                      },
                      {
                        name: "storedDate",
                        label: "Date Created",
                      },

                      {
                        name: "Actions",
                        label: "Actions",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                        
                              <Box sx={{ display: "flex", gap: 1 }}>
                                <Tooltip title="View" placement="bottom">
                                  <Button
                                    sx={{ width: 60, marginTop: "1rem" }}
                                    variant="contained"
                                    size="small"
                                    onClick={handleFileView.bind(null, tableMeta.rowData)}
                                    
                                  >
                                    <Visibility />
                                  </Button>
                                </Tooltip>

                                <Tooltip title="Download" placement="bottom">
                                  <Button
                                    sx={{ width: 60, marginTop: "1rem" }}
                                    variant="contained"
                                    size="small"
                                    onClick={download.bind(
                                      null,
                                      tableMeta.rowData ? tableMeta.rowData[0] : "",
                                      "storedFiles",
                                      "fileId"
                                    )}
                                  >
                                    <Download />
                                  </Button>
                                </Tooltip>

                                {
                    openPdfModal &&
                    <FileView setOpenPdfModal={setOpenPdfModal} openPdfModal={openPdfModal} pdfURLState={pdfURLState} />

                  }
                              
                              </Box>
                         
 
                                   );
                          }
                        }

                    
                      }
                    ]}
                    options={{
                        filterType: 'multiselect',
                        selectableRows: 'none', // <===== will turn off checkboxes in rows
                        responsive: "vertical",
                      
                    }}
    
                 />
                 <Box>
                 <h4
                    style={{
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Forms
                  </h4>
                         <FormTable patientIdForm={patientId} studyId={studyId}  consentForm={true}/>
                        </Box>
        </Box>
            
       </Box> 
        </>
      )
  }
}