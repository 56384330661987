import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../../../constants/sideBarwidth";
import classes from "./contactUs.module.css";
import { useAxios } from "../../../hooks/auth";
import { contsctUsPost } from "../../../axios/api";
import { useFormik } from "formik";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../utils/notification";
import { contactUsSchema } from "../../../constants/yupSchema";
import { ToastContainer } from "react-toastify";

function ContactUs() {
  const [isMessageSubbmited, setIsMessageSubbmited] = useState(false)
  const { isCollapsed } = useSelector(
    (state) => state?.sideBarCollapseSelector
  );
  const { patientId } = useSelector((state) => state?.patientLogInDataSelector);
  const axios = useAxios();
  const isMobile = useMediaQuery("(max-width:800px)");
  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },

    // submit call only when no validation error
    onSubmit: async (values,{ resetForm }) => {
      const payloadData = {
        ...values,
        patientId,
        patientPortal :1
      };

      // contact us post API call
      await contsctUsPost(axios, "contactUs", payloadData)
        .then((res) => {
          if (res?.response === "1") {
            setIsMessageSubbmited(true)
            // notificationToastSuccess(res?.message);
            resetForm({
              values: {
                subject: "",
                message: "",
              },
            });
          } else {
            notificationToastError(res?.message);
          }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        });
    },
    validationSchema: contactUsSchema,
    validateOnBlur: true,
    validateOnSubmit: true,
    validateOnChange: true,
  });

  // call only when validation error
  const handleSubmit = async (e, errors) => {
    e.preventDefault();
    formik.submitForm();
    for (const [key, value] of Object.entries(errors)) {
      if (value === "Required") {
        notificationToastError("Please fill all the required fields");
        return;
      }
    }
  };
  return (
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      <Box className={classes.borderBox}>
        {
          isMessageSubbmited &&  <Alert severity="success" >
            <Typography variant="h6">Your request has been sent to the support group. Thank you for contacting us. We will reach out to you within 24 business hours</Typography>
          </Alert> 
        }
          <Box className={classes.ContactUsTextWrapper}>
            <Typography variant="h4">Contact Us</Typography>
          </Box>
  
          {/* contact us form */}
          <form onSubmit={(e) => handleSubmit(e, formik?.errors, formik?.values)}>
            <Grid container gap={2}>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{ width: "100%" }}
                  id="subject"
                  label="Subject"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.subject || ""}
                  error={!!formik.touched.subject && !!formik.errors.subject}
                  helperText={formik.touched.subject && formik.errors.subject}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-input": {
                      minHeight: "80px",
                    },
                  }}
                  id="message"
                  label="Message"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.message || ""}
                  error={!!formik.touched.message && !!formik.errors.message}
                  helperText={formik.touched.message && formik.errors.message}
                  multiline
                />
              </Grid>
            </Grid>
            <Box className={classes.buttonContainer}>
              <Button variant="contained" type="submit" disabled={!formik.dirty}>
                submit
              </Button>
            </Box>
          </form>
      </Box>
      <ToastContainer />
    </Container>
  );
}
export default ContactUs;
