import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as React from "react";

import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  Grid,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import useIsPrevileges from "../../../hooks/useRoleAndPrivileges";
import InsurancePulldown from "../../../components/misc/InsurancePulldown";
import AddCarrier from "../../AddCarrier";

function InsuranceInfo({
  formState,
  setFormState,
  isReadOnly = false,
  handleFocus = () => {},
  setIsSaveButtonDisabled = () => {},
  isPatientPortal = false,
}) {
  const privileges = useIsPrevileges()?.privileges?.subtab;
  let insurancePlan = false;
  if (privileges.includes("insurances")) insurancePlan = true;

  const [addCarrierState, setAddCarrierState] = useState(undefined);
  const addCarrier = () => {
    setIsSaveButtonDisabled(false);
    setAddCarrierState({ planName: formState.planName });
  };
  const closeAddCarrier = () => {
    setAddCarrierState(undefined);
    setFormState({ ...formState });
  };

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
    setIsSaveButtonDisabled(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography
            variant="h6"
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "1rem",
            }}
          >
            Patient Insurance Carrier
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Typography
            variant="h6"
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "1rem",
            }}
          >
            <Typography variant="h6">Insurance Numbers </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <InsurancePulldown
            formState={formState}
            setFormState={setFormState}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
            updateInput={updateInput}
            labelTitle="Primary"
            insuranceId="primaryInsuranceId"
            insuranceName="primaryInsuranceName"
            isReadOnly={isReadOnly}
            isPatientPortal={isPatientPortal}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            id="primaryPolicy"
            label="Primary Policy ID"
            value={formState.primaryPolicy || ""}
            helperText=""
            variant="standard"
            onChange={updateInput}
            inputProps={{
              readOnly:
                isReadOnly === true
                  ? isReadOnly
                  : isPatientPortal
                  ? true
                  : false,
            }}
            onMouseDown={handleFocus}
            sx={{ width: "90%" }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            id="primaryGroup"
            label="Primary Group "
            value={formState.primaryGroup || ""}
            helperText=""
            variant="standard"
            onChange={updateInput}
            inputProps={{
              readOnly:
                isReadOnly === true
                  ? isReadOnly
                  : isPatientPortal
                  ? true
                  : false,
            }}
            onMouseDown={handleFocus}
            sx={{ width: "90%" }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <InsurancePulldown
            formState={formState}
            setFormState={setFormState}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
            updateInput={updateInput}
            labelTitle="Secondary"
            insuranceId="secondaryInsuranceId"
            insuranceName="secondaryInsuranceName"
            isReadOnly={isReadOnly}
            isPatientPortal={isPatientPortal}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            id="secondaryPolicy"
            label="Secondary Policy ID"
            value={formState.secondaryPolicy || ""}
            helperText=""
            variant="standard"
            onChange={updateInput}
            inputProps={{
              readOnly:
                isReadOnly === true
                  ? isReadOnly
                  : isPatientPortal
                  ? true
                  : false,
            }}
            onMouseDown={handleFocus}
            sx={{ width: "90%" }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            id="secondaryGroup"
            label="Secondary Group "
            value={formState.secondaryGroup || ""}
            helperText=""
            variant="standard"
            onChange={updateInput}
            inputProps={{
              readOnly:
                isReadOnly === true
                  ? isReadOnly
                  : isPatientPortal
                  ? true
                  : false,
            }}
            onMouseDown={handleFocus}
            sx={{ width: "90%" }}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <InsurancePulldown
            formState={formState}
            setFormState={setFormState}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
            updateInput={updateInput}
            labelTitle="Tertiary"
            insuranceId="tertiaryInsuranceId"
            insuranceName="tertiaryInsuranceName"
            isReadOnly={isReadOnly}
            isPatientPortal={isPatientPortal}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            id="tertiaryPolicy"
            label="Tertiary Policy ID"
            value={formState.tertiaryPolicy || ""}
            helperText=""
            variant="standard"
            onChange={updateInput}
            inputProps={{
              readOnly:
                isReadOnly === true
                  ? isReadOnly
                  : isPatientPortal
                  ? true
                  : false,
            }}
            onMouseDown={handleFocus}
            sx={{ width: "90%" }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            id="tertiaryGroup"
            label="Tertiary Group "
            value={formState.tertiaryGroup || ""}
            helperText=""
            variant="standard"
            onChange={updateInput}
            inputProps={{
              readOnly:
                isReadOnly === true
                  ? isReadOnly
                  : isPatientPortal
                  ? true
                  : false,
            }}
            onMouseDown={handleFocus}
            sx={{ width: "90%" }}
          />
        </Grid>
      </Grid>
      {insurancePlan && (
        <Box sx={{ "& button": { m: 1 } }}>
          <div>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                !isReadOnly && addCarrier();
              }}
            >
              + ADD Carrier
            </Button>
          </div>
        </Box>
      )}
      {addCarrierState && (
        <AddCarrier {...addCarrierState} handleClose={closeAddCarrier} />
      )}
    </Box>
  );
}

export default InsuranceInfo;
