import { Box, Button, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from "react-signature-canvas";
import { profilePost } from '../../axios/api';
import { useAxios } from '../../hooks/auth';
import { notificationToastError, notificationToastSuccess } from '../../utils/notification';
function UpdateSignature({profileData}) {
    const isMobile = useMediaQuery('(max-width:800px)');
    const sigCanvasRef = useRef("");
    const axios = useAxios()
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
    const [signatureData, setSignatureData] = useState(profileData?.signature);
    const [isSaveButtonDisable,setIsSaveButtonDisable] = useState(false)
    useEffect(() => {
        if (sigCanvasRef.current && signatureData) {
            sigCanvasRef.current.fromDataURL(signatureData);
            setIsSignatureEmpty(false);
        }
    }, [signatureData])
    const editSignature = () => {
        sigCanvasRef.current.clear();
        setIsSignatureEmpty(true);
        setIsSaveButtonDisable(false)
    };
    const saveSignature = async () => {
        const dataUrl = sigCanvasRef.current
            .getTrimmedCanvas()
            .toDataURL("image/png");
        const payloadData={
            signature:dataUrl
        }
        if (!isSignatureEmpty) {
            await profilePost(axios, "saveSignature ", payloadData).then((res) => {
                if (res?.response === "1") {
                    notificationToastSuccess(res?.message)
                  setIsSaveButtonDisable(false)
                }
                else {
                    notificationToastError(res?.message)
                }
              }).catch((err) => {
                notificationToastError(err?.message)
              })
        }
       
    };

    const handleCanvasChange = () => {
        setIsSignatureEmpty(sigCanvasRef.current.isEmpty());
        if(!isSaveButtonDisable){
            setIsSaveButtonDisable(true)
        }
    }
    return (
        <Box
            sx={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
            }}
        >
            <Box
                sx={{
                    border: "1px solid rgba(0,0,0, 0.12)",
                    borderRadius: "4px",
                    marginTop: "20px",
                    marginBottom: "10px",
                    width: "fit-content",
                    height: "fit-content",
                }}
            >
                <SignatureCanvas
                    penColor="green"
                    ref={sigCanvasRef}
                    canvasProps={{ width: isMobile ? 300 : 400, height: isMobile ? 150 : 200, className: "sigCanvas" }}
                    onBegin={handleCanvasChange} onEnd={handleCanvasChange}
                    initialValue={signatureData}
                />
            </Box>
            <Button variant="contained" disabled={isSignatureEmpty} onClick={editSignature}>
                Edit Signature
            </Button>
            <Button variant="contained" disabled={isSignatureEmpty || !isSaveButtonDisable} onClick={saveSignature} sx={{ marginLeft: "10px" }}>
                Save Signature
            </Button>
        </Box>
    )
}

export default UpdateSignature