import React from 'react'
import { PieChart } from "react-minimal-pie-chart";
// import { Legend } from "react-minimal-pie-chart/dist/components/legend";
import classes from "./CustomStagedPieChart.module.css"
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { ANNUAL_FOLLOW_STAGE, CONSULT_STAGE, FOLLOW_UP_RESULTS_STAGE, MONITORING_STAGE, ORDER_STAGE, PATIENT_CARE_PLAN_STAGE, RECOMMENDATIONS_STAGE, RESULTS, SCHEDULE_FOLLOW_UP_STAGE, SCHEDULE_STAGE, STUDY_STAGE, SURVEY_STAGE } from '../../../constants/visitsPatients';

function CustomStagesPieChart({stages}) {
  const isMobile = useMediaQuery('(max-width:800px)');
    const legendData = [
        { title: "identify", value: 9,  legendColor :"#bce292", color :"#bce292"},
        { title: "order", value: 9,   legendColor :"#92d050", color :"#92d050"},
        { title: "schedule", value: 9, legendColor :"#5adb67",color :"#5adb67" },
        { title: "study", value: 9,  legendColor :"#6eebdf",color :"#6eebdf" },
        { title: "results", value: 9,  legendColor :"#74ccef",color :"#74ccef"  },
        { title: "recommendations", value: 9, legendColor :"#51b4f7",color :"#51b4f7"},
        { title: "consult & treat office visit/TM if appl.", value: 9,  color :"#ed8bcc",legendColor :"#ed8bcc"},
        { title: "pt. care plan", value: 9,  legendColor :"#ff4f4f",color :"#ff4f4f"},
        { title: "schedule f/up study or Tx ", value: 9,   legendColor :"#ff8973",color :"#ff8973"},
        { title: "f/up  results & Recommendations", value: 9,   legendColor :"#ffc000",color :"#ffc000"},
        { title: "adherence monitoring program", value: 9,   legendColor :"#f8df57",color :"#f8df57"},
        { title: "yearly office/TM  visit", value: 9,  legendColor :"#ffff9f",color :"#ffff9f"},
    ];
        const data = [
      
        { title: "study", value: 9, color: stages?.some(arr => arr.includes(STUDY_STAGE)) ? "#6eebdf" :"#99a2a8", legendColor :"#6eebdf" },
        { title: "results", value: 9, color: stages?.some(arr => arr.includes(RESULTS)) ? "#74ccef" :"#99a2a8", legendColor :"#74ccef" },
        { title: "recommendations", value: 9, color: stages?.some(arr => arr.includes(RECOMMENDATIONS_STAGE)) ? "#51b4f7" :"#99a2a8" , legendColor :"#51b4f7"},
        { title: "consult & treat office visit/TM if appl.", value: 9, color: stages?.some(arr => arr.includes(CONSULT_STAGE)) ? "#ed8bcc" :"#99a2a8" ,  legendColor :"#ed8bcc"},
        { title: "pt. care plan", value: 9, color: stages?.some(arr => arr.includes(PATIENT_CARE_PLAN_STAGE)) ? "#ff4f4f" :"#99a2a8" ,  legendColor :"#ff4f4f"},
        { title: "schedule f/up study or Tx ", value: 9, color: stages?.some(arr => arr.includes(SCHEDULE_FOLLOW_UP_STAGE)) ? "#ff8973" :"#99a2a8" ,  legendColor :"#ff8973"},
        { title: "f/up  results & Recommendations", value: 9, color: stages?.some(arr => arr.includes(FOLLOW_UP_RESULTS_STAGE)) ? "#ffc000" :"#99a2a8"  ,  legendColor :"#ffc000"},
        { title: "adherence monitoring program", value: 9, color: stages?.some(arr => arr.includes(MONITORING_STAGE)) ? "#f8df57" :"#99a2a8"  ,  legendColor :"#f8df57"},
        { title: "yearly office/TM  visit", value: 9, color: stages?.some(arr => arr.includes(ANNUAL_FOLLOW_STAGE)) ? "#ffff9f" :"#99a2a8" ,  legendColor :"#ffff9f"},
        { title: "identify", value: 9, color: stages?.some(arr => arr.includes(SURVEY_STAGE)) ?  "#bce292" :"#99a2a8" , legendColor :"#bce292" },
        { title: "order", value: 9, color: stages?.some(arr => arr.includes(ORDER_STAGE)) ? "#92d050" :"#99a2a8",  legendColor :"#92d050"},
        { title: "schedule", value: 9, color: stages?.some(arr => arr.includes(SCHEDULE_STAGE)) ?  "#5adb67" :"#99a2a8", legendColor :"#5adb67" },
    ];
    return (
        <div className={classes.pieChartMainWrapper}>
          <Typography variant='h6' ml={2}>Stage Status :</Typography>
          <div className={classes.PieChartWrapper} style={{flexDirection: isMobile ? "column" :"row"}}>
            <div style={{width:"250px",minWidth:"150px"}}>
                <PieChart
                    data={data}
                    animate
                    animationDuration={100}
                    animationEasing="ease-out"
                    lineWidth={60}
                    paddingAngle={3}
                    // background='#6b7a9b'
                    
                    // label={({ dataEntry }) => `${dataEntry.title} (${dataEntry.value})`}
                    labelStyle={
                        { fontSize: "3px", }
                    }
                />
            </div>
             
        </div>
        <Grid container>
                    <Grid lg={4} md={4} xl={4} xs={12}>
                        <div className={classes.legentWrapper}>
                            {legendData?.slice(0, 6)?.map((entry, index) => (
                                <div key={entry.title} className={classes.legenSubWrapper} >
                                    <div
                                        className={classes.pieChartLegenColor}
                                        style={{ backgroundColor: entry.legendColor }}
                                    ></div>
                                    <Typography variant='body2' className={classes.legentTitle}>{entry.title}</Typography>
                                </div>
                            ))}
                        </div>
                    </Grid>
                    <Grid lg={8} md={8} xl={8} xs={12}>
                        <div className={classes.legentWrapper}>
                            {legendData?.slice(6)?.map((entry, index) => (
                                <div key={entry.title} className={classes.legenSubWrapper} >
                                    <div
                                        className={classes.pieChartLegenColor}
                                        style={{ backgroundColor: entry.legendColor }}
                                    ></div>
                                    <Typography variant='body2' className={classes.legentTitle}>{entry.title}</Typography>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
        </div>
  
    )
}

export default CustomStagesPieChart