import { Box, Button, Card, CardContent, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useAxios } from "../../../../hooks/auth";
import LinearProgress from '@mui/material/LinearProgress';
import styles from "./fileUpload.module.css"
import axios from "axios";
import { notificationToastError, notificationToastSuccess } from "../../../../utils/notification";
import { patientsPostData, pulldownsPostData } from "../../../../axios/api";
export function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: "100%" }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress data-testid="linear-progress-bar"  variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary" data-testid="progress-value">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
function FileUpload({ patientId = "", refreshData = false, setRefreshData = () => { }, isPatientPortal=false ,orderId,studyId}) {
    const isMobile = useMediaQuery('(max-width:800px)');
    const [fileCategoryData, setFileCategoryData] = useState([]);
    useEffect(() => {
        pulldownsPostData(axios, "fileCategoriesPulldown","",isPatientPortal).then((res) => {
            if (res?.data?.length) {
                setFileCategoryData(res?.data)

            }
        }).catch((err) => {
            console.log("err");
        })
    }, [])

    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [formState, setFormState] = useState({
        fileCategoryId: "",
        error: false
    })
    const axios = useAxios();
    // console.log("formState==>", formState);

    const handleButtonClick = () => {
        if (!formState?.fileCategoryId) {
            notificationToastError("Please select file category")
        }
    };

    const handleFileChange = (event) => {
        if (formState?.fileCategoryId) {
            const selectedFile = event.target.files[0];
            setSelectedFile(event.target.files[0])
            const fileSizeInMegabytes = selectedFile?.size / (1024 * 1024);
            if (patientId) {
                if (fileSizeInMegabytes < 10 && selectedFile) {
                    const reader = new FileReader();
                    reader.readAsDataURL(selectedFile);
                    reader.onprogress = (event) => {
                        if (event.lengthComputable) {
                            const progress = (event.loaded / event.total) * 100;
                            setUploadProgress(progress);
                        }
                    };
                    reader.onloadend = () => {
                        if (reader.result) {
                            const payload = {
                                file: reader.result,
                                fileName: selectedFile?.name,
                                patientId,
                                fileCategoryId: formState?.fileCategoryId,
                                orderId: orderId,
                                studyId: studyId 
                            }
                            patientsPostData(axios, "uploadFile", payload).then((res) => {
                                if (res.data.response === "1") {
                                    notificationToastSuccess(res?.data?.message);
                                    setRefreshData(!refreshData)
                                } else {
                                    notificationToastError(res?.data?.message);
                                }
                            }).catch((err) => notificationToastError(err?.message))
                        }
                    };
                } else {

                    notificationToastError("File size must be less than 10 MB")
                    setSelectedFile(null)
                    setUploadProgress(0)
                }
            } else {
                notificationToastError("Patient is not selected, please select patient")
                setSelectedFile(null)
                setUploadProgress(0)
            }
        } else {
            setFormState({
                ...formState,
                error: true
            })
            notificationToastError("Please select file category")
        }


    };
    const handleFileTypeChange = (e) => {
        setFormState({
            ...formState,
            [e.target.id || e.target.name]: e.target.value || "",
            error: false
        });
    }
    return (
        <Card
        >
            <CardContent className={styles.fileUploadMainWraper} sx={{ flexDirection: isMobile ? "column" : "row" }} >
                <Box>
                    <FormControl
                        sx={{
                            justifyContent: "center",
                            display: "flex",
                            m: 1, minWidth: 190
                        }}>
                        <InputLabel id="demo-controlled-open-select-label" error={formState?.error && !formState?.fileCategoryId}>
                            Select File Category *
                        </InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="fileCategoryId"
                            name="fileCategoryId"
                            value={formState?.fileCategoryId || ''}
                            label="Practice"
                            onChange={handleFileTypeChange}
                            variant="standard"
                            error={formState?.error && !formState?.fileCategoryId}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {fileCategoryData?.map((item) => (
                                <MenuItem key={`menu-item-practice-${item?.category}`} value={item.fileCategoryId}>
                                    {item?.category}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className={styles.fileUploadWraper} sx={{ flexDirection: isMobile ? "column" : "row" }}>
                    <Button variant="contained" component="label" sx={{ minWidth: "125px" }} onClick={handleButtonClick}>
                        Attach File
                       {formState?.fileCategoryId && <input type="file" hidden onChange={handleFileChange} />} 
                    </Button>

                    <Box className={styles.progressBarWrapper}>
                        {
                            uploadProgress > 0 &&
                            <LinearProgressWithLabel sx={{ height: "20px", borderRadius: "20px" }} value={uploadProgress} />
                        }
                    </Box>

                    {selectedFile &&
                        <Box className={styles.fileNameWrapper}>
                            <Typography color="primary" data-testid="uploded-file-name">{selectedFile?.name}</Typography>
                        </Box>
                    }
                </Box>

            </CardContent>
        </Card>
    );
}

export default FileUpload;