import { createSlice } from "@reduxjs/toolkit";

export const selectedPracticeSlice = createSlice({
  name: "selectedPractice",
  initialState: {
    value: undefined,
  },
  reducers: {
    updateSelectedPractice: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateSelectedPractice } = selectedPracticeSlice.actions;

export default selectedPracticeSlice.reducer;
