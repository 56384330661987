import { Box, Button, Container, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { patientViewPostData, profilePost } from '../../axios/api';
import { useAxios } from '../../hooks/auth';
import { useFormik } from "formik";
import { updatePasswordSchema } from '../../constants/yupSchema';
import { notificationToastError, notificationToastSuccess } from '../../utils/notification';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect, useRef, useState } from 'react';
import { collapsedDrawerWidth, openDrawerWidth } from "../../constants/sideBarwidth";
import { useSelector } from 'react-redux';
import { useError } from '../../Context/ErrorContext';



function UpdatePassword({isPatientPortal=false}) {
    const isMobile = useMediaQuery('(max-width:800px)');
    const { isCollapsed } = useSelector(state => state.sideBarCollapseSelector);
    const { patientId } = useSelector(state => state?.patientLogInDataSelector);
    const [isDisabled, setIsDisabled] = useState(false)
    const { setErrorMessage } = useError();
    const axios = useAxios();
    const [showPassword, setShowPassword] = useState({
        oldPassword: false,
        newPassword: false,
        confirmNewPassword: false
    });
    const oldPasswordref = useRef();
    const newPasswordref = useRef();
    const confirmPasswordref = useRef();

    useEffect(() => {
        const handleMouseDown = (event) => {
            if (
                oldPasswordref?.current &&
                !oldPasswordref?.current.contains(event.target) &&
                showPassword?.oldPassword
            ) {
                setShowPassword((prevState) => ({
                    ...prevState,
                    oldPassword: false,
                }));
            } else if (
                newPasswordref?.current &&
                !newPasswordref?.current.contains(event.target) &&
                showPassword?.newPassword
            ) {
                setShowPassword((prevState) => ({
                    ...prevState,
                    newPassword: false,
                }));
            } else if (
                confirmPasswordref?.current &&
                !confirmPasswordref?.current?.contains(event.target) &&
                showPassword?.confirmNewPassword
            ) {
                setShowPassword((prevState) => ({
                    ...prevState,
                    confirmNewPassword: false,
                }));
            }
        };
        document.addEventListener("mousedown", handleMouseDown);
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [showPassword.oldPassword, showPassword.newPassword, showPassword.confirmNewPassword]);
    const handleClickShowPassword = (id) => {
        setShowPassword((prevState) => {
            return {
                ...prevState,
                [id]: !prevState[id]
            }
        })
    }
    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        onSubmit: async (values) => {
            const payloadData = {
                oldPassword: values?.oldPassword,
                newPassword: values?.newPassword,
                patientId,
                patientPortal: isPatientPortal ? 1 : 0,
            }
            setIsDisabled(true)
            if (isPatientPortal) {
                await patientViewPostData(axios, "updatePassword", payloadData).then((res) => {
                    if (res?.response === "1") {
                        notificationToastSuccess(res?.message)
                        localStorage.setItem('tempPassErr', 0)
                        setErrorMessage(''); 
                    }
                    else {
                        notificationToastError(res?.message)
                    }
                }).catch((err) => {
                    notificationToastError(err?.message)
                })
            }else{
                await profilePost(axios, "updatePassword", payloadData).then((res) => {
                    if (res?.response === "1") {
                        notificationToastSuccess(res?.message)
                    }
                    else {
                        notificationToastError(res?.message)
                    }
                }).catch((err) => {
                    notificationToastError(err?.message)
                })
            }
          
        },
        validationSchema: updatePasswordSchema(isPatientPortal),
        validateOnBlur: true,
        validateOnSubmit: true,
        validateOnChange: true
    });
    const handleChangepassword = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setIsDisabled(false)
        if (id === "oldPassword") {
            formik.setFieldValue(id, value)
            if (showPassword?.oldPassword) {
                setShowPassword({ ...showPassword, oldPassword: false })
            }
        } else if (id === "newPassword") {
            formik.setFieldValue(id, value)
            if (showPassword?.newPassword) {
                setShowPassword({ ...showPassword, newPassword: false })
            }
        } else if (id === "confirmNewPassword") {
            formik.setFieldValue(id, value)
            if (showPassword?.confirmNewPassword) {
                setShowPassword({ ...showPassword, confirmNewPassword: false })
            }
        }
    }
    const handleSubmit = async (e, errors, values) => {
        e.preventDefault();
        formik.submitForm();
        for (const [key, _] of Object.entries(values)) {
            if (errors[Object.keys(errors)[0]] === "Required" || !values[key]) {
                notificationToastError("Please fill all the required fields")
                return
            }
            else {
                if (errors[Object.keys(errors)[0]]) {
                    notificationToastError(errors[Object.keys(errors)[0]])

                }
                return;
            }
        }
    }
    return (
        <>


            <form onSubmit={
                (e) => handleSubmit(e, formik?.errors, formik?.values)

            }
                autoComplete="off"
            >   
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1 },
                        border: "1px solid rgba(0,0,0, 0.12)",
                        borderRadius: "4px",
                        marginBottom : isMobile ? "20%" : "10px", 
                        width:"100%",
                        padding : "0"
                    }}
                    autoComplete="off"
                >
                    <Typography variant='h6' style={{ marginTop: '1rem', marginLeft: '1rem' }}>Update Password</Typography>
                    <Box>
                        <TextField
                            id="oldPassword"
                            label="Old OR Temporary Password *"
                            variant="standard"
                            onBlur={formik.handleBlur}
                            // onChange={formik.handleChange || handleChangepassword}
                            onChange={handleChangepassword}
                            value={formik.values.oldPassword}
                            name="oldPassword"
                           // error={!!formik.touched.oldPassword && !!formik.errors.oldPassword}
                          //  helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                            type={showPassword?.oldPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword("oldPassword")}
                                            edge="end"
                                        >
                                            {showPassword?.oldPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            inputRef={oldPasswordref}
                            sx={{width: isPatientPortal ? "50%" : "90%"}}
                        />
                    </Box>
                   
                    <Box>
                        <TextField
                            id="newPassword"
                            label="New Password *"
                            variant="standard"
                            onBlur={formik.handleBlur}
                            onChange={handleChangepassword}
                            value={formik.values.newPassword}
                            name="newPassword"
                            error={!!formik.touched.newPassword && !!formik.errors.newPassword}
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                            type={showPassword?.newPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword("newPassword")}
                                            edge="end"
                                        >
                                            {showPassword?.newPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            inputRef={newPasswordref}
                            sx={{width: isPatientPortal ? "50%" : "90%"}}
                        />
                    </Box>
                    <Typography variant='body3' sx={{display : isMobile ? "inline-block" : "" , width : "100%" , marginLeft : "0.5rem"}}>Password should be at least one letter, one number and one special character (total 8 characters).</Typography>
                    <Box>
                        <TextField
                            id="confirmNewPassword"
                            label="Confirm New Password *"
                            variant="standard"
                            onBlur={formik?.handleBlur}
                            onChange={handleChangepassword}
                            value={formik?.values.confirmNewPassword}
                            name="confirmNewPassword"
                            error={!!formik?.touched.confirmNewPassword && !!formik?.errors.confirmNewPassword}
                            helperText={formik?.touched.confirmNewPassword && formik?.errors.confirmNewPassword}
                            type={showPassword?.confirmNewPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword("confirmNewPassword")}
                                            edge="end"
                                        >
                                            {showPassword?.confirmNewPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            inputRef={confirmPasswordref}
                            sx={{width: isPatientPortal ? "50%" : "90%"}}
                        />
                    </Box>
                    <Typography variant='body3' sx={{display : isMobile ? "inline-block" : "" , width : "100%" , marginLeft : "0.5rem"}}>Password should be at least one letter, one number and one special character (total 8 characters).</Typography>

                    <Box sx={{ paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '1rem' }}>
                        <Button variant="contained" type='submit' disabled={isDisabled}>
                            UPDATE PASSWORD
                        </Button>
                    </Box>
              </Box> 
            </form>
        </>

    )
}


export default UpdatePassword