import { Box, Grid, Typography } from '@mui/material'
import React, { memo } from 'react'
import ScheduleStudyDialog from '../../misc/ScheduleStudyDialog';

function PatientCurrentInfo({ visitDataObj }) { 
  return (
    <Box>
      {/* <Typography variant='h6' mb={1.5}>Current Visit Info : </Typography> */}
 
      {/* <StageTable stages={visitDataObj?.stages} /> */}
      {
        visitDataObj?.studyId &&
        (
          <>
            {/* Appointment Information:  */}
                <>
                  {/* <Box sx={{ border: "1px solid #d0d6d1", marginTop: "20px" }} /> */}
                  <Box>
                    <ScheduleStudyDialog
                      asDialog={false}
                      studyData={{
                        studyId: visitDataObj?.studyId,
                        physicianId: visitDataObj?.physicianId,
                        patientId: visitDataObj?.patientId || visitDataObj?.origPatientId,
                        surveyId: visitDataObj?.surveyId,
                        orderId: visitDataObj?.orderId || visitDataObj?.origOrderId,
                        studyTypeId: visitDataObj?.studyTypeId,
                        orderingPhysicianName: visitDataObj?.orderingPhysician,
                        prefInterpPhysId: visitDataObj?.prefInterpPhysId,
                        studyDate: visitDataObj?.date,
                        shipDate: visitDataObj?.shipDate,
                        trackingNum: visitDataObj?.trackingNum,
                        outTrackingNum: visitDataObj?.outTrackingNum,
                        hstSerialNumber: visitDataObj?.hstSerialNumber,
                        locationId:visitDataObj?.locationId,
                        time:visitDataObj?.time,
                        deviceReturnDate:visitDataObj?.deviceReturnDate,
                        hstDeviceId: visitDataObj?.hstDeviceId,
                        pickup: visitDataObj?.pickup
                      }}
                      isPreviousVisits={true}
                      isPatientPortal={true}
                    />
                  </Box>
                </>

            {/* Inssurance and EVPA Information */}
            {/* {
              visitDataObj?.stages?.some(arr => arr.includes(SCHEDULE_STAGE)) &&
              (
                <>
                  <Box sx={{ border: "1px solid #d0d6d1", marginTop: "20px" }} />
                  <Box>
                    <VerifyInsuranceDialog
                      inssuranceData={{
                        physicianId: visitDataObj?.physicianId,
                        patientId: visitDataObj?.patientId || visitDataObj?.origPatientId,
                        surveyId: visitDataObj?.surveyId,
                        orderId: visitDataObj?.orderId || visitDataObj?.origOrderId,
                        studyTypeId: visitDataObj?.studyTypeId,
                        stageId: visitDataObj?.stageId,
                        studyId: visitDataObj?.studyId,
                        primaryInsuranceId: visitDataObj?.primaryInsuranceId,
                        secondaryInsuranceId: visitDataObj?.secondaryInsuranceId,
                        primaryPolicy: visitDataObj?.primaryPolicy,
                        secondaryPolicy: visitDataObj?.secondaryPolicy,
                        primaryGroup: visitDataObj?.primaryGroup,
                        secondaryGroup: visitDataObj?.secondaryGroup,
                        shipDate: visitDataObj?.shipDate,
                        trackingNum: visitDataObj?.trackingNum,
                        planNameP: visitDataObj?.planNameP,
                        planNameS: visitDataObj?.planNameS,
                        studyTypeName: visitDataObj?.studyTypeName,
                        placeOfService: visitDataObj?.placeOfService,
                        insVerified: visitDataObj?.insVerified,
                        VDATE: visitDataObj?.insVerifiedDate,
                        cptsVerified: visitDataObj?.cptsVerified,
                        verifiedContact: visitDataObj?.verifiedContact,
                        policyType: visitDataObj?.policyType,
                        planAdmin: visitDataObj?.planAdmin,
                        policyHolder: visitDataObj?.policyHolder,
                        PDATE: visitDataObj?.policyHolderDob,
                        spokeTo: visitDataObj?.spokeTo,
                        referenceNum: visitDataObj?.referenceNum,
                        planType: visitDataObj?.planType,
                        EDATE: visitDataObj?.policyEffDate,
                        deductible: visitDataObj?.deductible,
                        deductibleMet: visitDataObj?.deductibleMet,
                        oop: visitDataObj?.oop,
                        oopMet: visitDataObj?.oopMet,
                        coIns: visitDataObj?.coIns,
                        copay: visitDataObj?.copay,
                        inNetwork: visitDataObj?.inNetwork,
                        applyDeductible: visitDataObj?.applyDeductible,
                        deductibleYear: visitDataObj?.deductibleYear,
                        referralRequired: visitDataObj?.referralRequired,
                        referralRequiredFrom: visitDataObj?.referralRequiredFrom,
                        priorAuthRequired: visitDataObj?.priorAuthRequired,
                        parContact: visitDataObj?.parContact,
                        claimAddress: visitDataObj?.claimAddress,
                        insVerifiedDate: visitDataObj?.insVerifiedDate
                      }}
                      // inssuranceData={item}
                      asDialog={false}
                      isPreviousVisits={true}
                    />
                  </Box>
                </>
              )
            } */}

            {/* Authorization info */}

            {/* {
              visitDataObj?.stages?.some(arr => arr.includes(SCHEDULE_STAGE)) &&
              (
                <>
                  <Box sx={{ border: "1px solid #d0d6d1", marginTop: "20px" }} />
                  <Box>
                    <GetAuthorizationDialog
                      asDialog={false}
                      isPreviousVisits={true}
                      authorizationInfo={
                        {
                          physicianId: visitDataObj?.physicianId,
                          patientId: visitDataObj?.patientId || visitDataObj?.origPatientId,
                          orderId: visitDataObj?.orderId || visitDataObj?.origOrderId,
                          stageId: visitDataObj?.stageId,
                          prefInterpPhysId: visitDataObj?.prefInterpPhysId,
                          studyId: visitDataObj?.studyId,
                          activeAuthorization: visitDataObj?.activeAuthorization,
                          authorizedOn: visitDataObj?.authorizedOn,
                          authorizationStartDate: visitDataObj?.authorizationStartDate,
                          authorizationEndDate: visitDataObj?.authorizationEndDate,
                          authorizationDenied: visitDataObj?.authorizationDenied,
                          reasonAuthDenial: visitDataObj?.reasonAuthDenial,
                          VDATE: visitDataObj?.VDATE || visitDataObj?.insVerifiedDate,
                          PDATE: visitDataObj?.PDATE || visitDataObj?.policyEffDate,
                          EDATE: visitDataObj?.EDATE || visitDataObj?.policyHolderDob,
                        }
                      }
                    />
                  </Box>
                </>
              )
            } */}
          </>

        )
      }

    </Box>
  )
}

export default memo(PatientCurrentInfo)