import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";

import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";
function FollowingPhysicianPulldown({ formState, updateInput, formErrorState, attributeName, isPreviousVisits=false , followUpOrder = false }) {

  const [followingPhysicians, setFollowingPhysicians] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    async function setDataFirstTime() {
      pulldownsPostData(axios, "followingPhysicianPulldown").then((res) => {
        setFollowingPhysicians(res?.data);
      }).catch((err) => {
        console.log(err);
      })
    }
    setDataFirstTime()
  }, []);

  let type
  if (followUpOrder) type = "Ordering" ; else type =  "Following" ;

  return <>
   <FormControl variant="standard" sx={{ margin: "0px 8px", width: "95%" }}>
   
      <InputLabel id="user-label">Select {type} Physician </InputLabel>
      <Select
        labelId="user-label"
        id={attributeName}
        name={attributeName}
        value={formState[attributeName] || ''}
        label="FollowingPhysician"
        onChange={(e)=>updateInput(e)}
        inputProps={{ readOnly: isPreviousVisits }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {followingPhysicians.map((followingPhysician) => (
          <MenuItem value={followingPhysician.userId} key={followingPhysician.userId}>{followingPhysician.lastName}, {followingPhysician.firstName}</MenuItem>
          
        ))}
      </Select>
    </FormControl>
  </>

}

export default FollowingPhysicianPulldown;