import { Box, Button, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useAxios } from "../../hooks/auth";
import { documentFormData } from "../../axios/api";
import { useSelector } from "react-redux";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../utils/notification";
import FileView from "../../pages/patients/components/FileUpload/FileView";
import { base64ToPdf } from "../../utils/base64ToPDFConvertor";

const DocumentTable = () => {
  const axios = useAxios();
  const { patientId } = useSelector((state) => state?.patientLogInDataSelector);
  const [documentListData, setDocumentListData] = useState([]);
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" })
    useEffect(() => {
        const paramsName = "documents";
        const payload = {
            patientId,
            patientPortal: 1,
        }
        documentFormData(axios,paramsName,payload).then((res)=>{
            if (res?.length) {
                setDocumentListData(res)
            }
        }).catch((err)=>{
            console.log("err==>",err);
        })
    }, [])
    const handleFileView =(rowData)=>{
      const payload = {
        id : rowData[0],
        patientPortal: 1,
      }
      documentFormData(axios,"downloadFile",payload).then((res)=>{
        if (res?.file) {
          setOpenPdfModal(true);
          let base64 = res?.file;
          base64 = base64?.split(",")[1];
          let fileType = res?.name;
          fileType = fileType?.split(".")?.pop();
          const pdfUrl = base64ToPdf(base64, fileType);
          setPdfURLState({
            fileUrl: pdfUrl,
            fileType,
            fileName: res?.name?.split(".")[0],
          });
        } else {
          notificationToastError("File was not downloaded due to issue.");
        }
      })
      .catch((err) => {
        console.log("err==>", err);
      });
  };

  return (
    <>
      <Typography variant="h6" mb={1.5} mt={1.5}>
        {" "}
        Important Information To Review Prior To Your Visit
      </Typography>

      <MUIDataTable
        data={documentListData}
        className="mui-document-data"
        columns={[
          {
            name: "documentId",
            options: { display: false, viewColumns: false, filter: false },
          },
          {
            name: "title",
            label: "Document Name",
          },
          {
            name: "description",
            label: "Description",
            // options: {
            //   customBodyRender: (value, tableMeta, updateValue) => {
            //     // console.log("tableMeta=======>",tableMeta?.rowData[0]);
            //     // const form = tableMeta.rowData[2];
            //     return (
            //       <Box
            //         sx={{
            //           display: "flex",
            //           justifyContent: "center",
            //           cursor: "pointer",
            //           textDecoration: "underline",
            //           color: "#00008b",
            //         }}
            //         onClick={() => {
            //         //   patientView(tableMeta?.rowData);
            //         }}
            //       >
            //         {value}
            //       </Box>
            //     );
            //   },
            // },
          },
          {
            name: "Actions",
            label: "Actions",

            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleFileView.bind(null, tableMeta.rowData)}
                      data-testid="view-pdf-link"
                    >
                      View
                    </Button>
                    {/* <Box>
                          <Button
                            sx={{ marginTop: "1rem" }}
                            variant="contained"
                            onClick={deleteRecord.bind(
                              null,
                              tableMeta.rowData ? tableMeta.rowData[0] : "",
                              "users",
                              "userId"
                            )}
                          >
                            Delete
                          </Button>
                        </Box> */}
                  </>
                );
              },
            },
          },
        ]}
        options={{
          selectableRows: 'none', // <===== will turn off checkboxes in rows
          rowsPerPageOptions: [],
          serverSide: true,
          responsive: "vertical",
        }}
      />
      {openPdfModal && (
        <FileView
          setOpenPdfModal={setOpenPdfModal}
          openPdfModal={openPdfModal}
          pdfURLState={pdfURLState}
          isPatientPortal={true}
        />
      )}
    </>
  );
};

export default DocumentTable;
