import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import states from "../constants/states.json";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "../../node_modules/@mui/material/index";
import { useAxios } from "../hooks/auth";
import PhysicianPulldown from "../components/misc/PhysicianPulldown";
import GroupPulldown from "../components/misc/GroupPulldown";
import { adminPostData } from "../axios/api";

function InterpPhysView() {
  const location = useLocation();
  React.useEffect(() => {
    const row = location.state?.row;
    if (row) {
      setFormState({
        ...formState,
        firstName: row[2],
        lastName: row[1],
        email: row[3],
        phone: row[4],
        middleName: row[7],
        address1: row[8],
        address2: row[9],
        city: row[10],
        state: row[11],
        zip: row[12],
        groupId: row[14],
        roleId: row[17],
        userId: row[0],
        fax:row[13],
      });
    }
  }, [location]);
  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );
  const [formState, setFormState] = useState({});
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
    groupId:""
  });  
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [surveyCheck, setSurveyCheck] = useState({});
  const [BMI, setBMIState] = useState({});

  const axios = useAxios();

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };
  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }
    const payload ={
      ...formState,
      interpPhysId: formState.interpPhysId,
    }
    adminPostData(axios, "saveInterpPhysInfo", payload)
    .then((res) => {
      if (res.data.message === "1") {
        setSuccessMessage(res.data.message);
      } else {
        setErrorMessage(res.data.message);
      }
    }).catch((err)=>setErrorMessage(err?.message));
  };

  function createData(Type, DateCreated, StopBang, Actions) {
    return {
      Type,
      DateCreated,
      StopBang,
      Actions,
    };
  }
  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const updateBMIInput = (e) => {
    const newBMIState = { ...BMI, [e.target.id]: e.target.value };
    const height =
      parseInt(newBMIState.heightFeet) * 12 +
      parseInt(newBMIState.heightInches);
    const bmi = Math.round((newBMIState.weight * 703) / Math.pow(height, 2));
    if (!isNaN(bmi)) {
      newBMIState.bmi = bmi;
    }
    setBMIState(newBMIState);
  };
 
  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: e.target.checked,
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState, bangScore: sum });

    setSurveyCheck(newSurveyState);
  };

  return (
    <Container maxWidth="lg">
      {successMessage && (
        <Alert
          severity="success"
          onClose={() => {
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </Alert>
      )}
      <h4>Interpreting Physician Info ( * required field)</h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="firstName"
            label="First Name *"
            value={formState.firstName}
            variant="standard"
            onChange={updateInput}
            error={formErrorState.firstName?.error && !formState.firstName}
            helperText={
              formErrorState.firstName?.error && !formState.firstName
                ? "required"
                : ""
            }
            onBlur={validateEmptyField.bind(null, "firstName")}
          />
          <TextField
            id="middleName"
            label="MI"
            sx={{
              maxWidth: "5rem",
            }}
            value={formState.middleName}
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="lastName"
            label="Last Name *"
            value={formState.lastName}
            variant="standard"
            onChange={updateInput}
            error={formErrorState.lastName?.error && !formState.lastName}
            helperText={
              formErrorState.lastName?.error && !formState.lastName
                ? "required"
                : ""
            }
            onBlur={validateEmptyField.bind(null, "lastName")}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="address1"
            label="Address1"
            value={formState.address1}
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="address2"
            label="Address2"
            value={formState.address2}
            variant="standard"
            onChange={updateInput}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="city"
            label="City"
            value={formState.city}
            variant="standard"
            onChange={updateInput}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              name="state"
              value={formState.state || ""}
              label="state"
              onChange={updateInput}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {states.map((state,i) => (
                <MenuItem value={state} key={i}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="zip"
            label="Zip"
            value={formState.zip}
            variant="standard"
            onChange={updateInput}
          />
         
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
           <TextField
            id="phone"
            label="Phone Number"
            value={formState.phone}
            helperText="Area Code and Number"
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="fax"
            label="FAX"
            value={formState.fax}
            helperText="Area Code and Number"
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="email"
            label="Email"
            value={formState.email}
            helperText=""
            variant="standard"
            onChange={updateInput}
          />
          
        </Box>
       
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            marginTop: "1rem",
            padding: "0 1rem",
            gap:'2rem'
          }}
        >
          <GroupPulldown formState={formState} updateInput={updateInput} formErrorState={formErrorState}/>
          <PhysicianPulldown formState={formState} updateInput={updateInput} />
        </Box>
      </Box>

      <Box sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
        <Button variant="contained" onClick={saveChanges}>
          SAVE CHANGES
        </Button>
      </Box>
    </Container>
  );
}


export default InterpPhysView;
