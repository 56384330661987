const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return "";
    }
    phoneNumber = phoneNumber?.replace(/^1/, "");
    if (phoneNumber?.length === 10) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber?.slice(
        3,
        6
      )}-${phoneNumber.slice(6)}`;
    }
    return phoneNumber;
  };
  export default function phoneNumberValidator(value) {
    let inputPhoneNumber = value;
    let numericPhoneNumber
    if (inputPhoneNumber) {
     numericPhoneNumber = inputPhoneNumber?.replace(/\D/g, "");
      
    }
    if (numericPhoneNumber?.length > 10) {
      numericPhoneNumber = numericPhoneNumber?.slice(0, 10);
    }
    const formattedPhoneNumber = formatPhoneNumber(numericPhoneNumber);
    return {formattedPhoneNumber,numericPhoneNumber};
}
