import React, { memo, useState } from "react";
import classes from "./currentVisits.module.css";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OrderView from "../../../pages/patients/components/OrderView";
import SurveyView from "../../../pages/patients/components/SurveyView";
import DmeOrder from "../../../pages/patients/dmeOrder/DmeOrder";

function SurveyAndOrder({ item }) {
  const [isOrderOpen, setIsOrderOpen] = useState(false);
  const [isDmeOrderOpen, setIsDmeOrderOpen] = useState(false);
  const [surveyAndOrderId, setSurveyAndOrderId] = useState({});
  const isMobile = useMediaQuery("(max-width:800px)");

  const handleSurveyVisit = async ({
    currentStatus,
    origPatientId,
    patientId,
    surveyId,
    origSurveyId,
    stages,
    stageId,
    origOrderId,
    orderId,
  }) => {
    setIsOrderOpen(true);
    setSurveyAndOrderId({
      surveyId: origSurveyId || surveyId,
      patientId: origPatientId || patientId,
      isSurveyOpen: true,
      currentStatus,
      stageId,
      stages,
      orderId: origOrderId || orderId,
    });
  };

  const handleOrderVisit = async ({
    stages,
    currentStatus,
    origOrderId,
    orderId,
    origPatientId,
    patientId,
    surveyId,
    origSurveyId,
    stageId,
    physicianId,
    orderDetails

  }) => {
    setIsOrderOpen(true);
    setSurveyAndOrderId({
      orderId: origOrderId || orderId,
      patientId: origPatientId || patientId,
      surveyId: origSurveyId || surveyId,
      currentStatus,
      isOrderOpen: true,
      stages,
      stageId,
      physicianId: physicianId || orderDetails?.orderPhysicianId
    });
  };

  const handleDMEOrder = ({
    stages,
    currentStatus,
    origDmeOrderId,
    dmeOrderId,
    origPatientId,
    patientId,
    surveyId,
    origSurveyId,
    stageId,
  }) => {
    setIsDmeOrderOpen(true);
    setSurveyAndOrderId({
      // orderId : origOrderId || orderId,
      patientId: origPatientId || patientId,
      dmeOrderId: origDmeOrderId || dmeOrderId,
      currentStatus,
      isDmeOrderOpen: true,
      stages,
      stageId,
    });
  };
  const handleClose = () => {
    setIsOrderOpen(false);
    setIsDmeOrderOpen(false);
    setSurveyAndOrderId({});
  };

  return (
    <Grid container>
      {(item?.origOrderId || item?.origDmeOrderId) && (
        <Grid item lg={3.9} md={4} xs={12}>
          {item?.origOrderId && (
            <>
              <Typography variant="strong">Order : </Typography>
              <Typography
                data-testid="order-open-link"
                variant="link"
                className={classes?.visitDate}
                onClick={() => {
                  handleOrderVisit(item);
                }}
              >
                Click here to open it
              </Typography>
            </>
          )}
          <Box>
            {item?.origDmeOrderId && (
              <>
                <Typography variant="strong">DME Order : </Typography>
                <Typography
                  data-testid="order-open-link"
                  variant="link"
                  className={classes?.visitDate}
                  onClick={() => {
                    handleDMEOrder(item);
                  }}
                >
                  Click here to open it
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      )}
      <Grid item lg={7} md={8} xs={12}>
        {item?.surveyId && item?.surveyDate && (
          <>
            <Typography variant="strong">Survey : </Typography>
            <Typography
              data-testid="survey-open-link"
              variant="link"
              className={classes?.visitDate}
              onClick={() => {
                handleSurveyVisit(item);
              }}
            >
              Click here to open survey
            </Typography>
          </>
        )}
      </Grid>
      {isOrderOpen && (
        <Dialog
          open={isOrderOpen}
          onClose={handleClose}
          data-testid="order-survey-dialog"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: isMobile ? "auto" : "1200px",
              minHeight: isMobile ? "auto" : "400px",
            },
            "& .MuiDialogContent-root": {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {surveyAndOrderId?.isOrderOpen && (
            <OrderView
              surveyAndOrderId={surveyAndOrderId}
              isOrderModal
              handleClose={handleClose}
            />
          )}
          {surveyAndOrderId?.isSurveyOpen && (
            <SurveyView
              surveyAndOrderId={surveyAndOrderId}
              isOrderModal
              handleClose={handleClose}
            />
          )}
          {surveyAndOrderId?.isDmeOrderOpen && (
            <DmeOrder
              surveyAndOrderId={surveyAndOrderId}
              isOrderModal
              handleClose={handleClose}
            />
          )}
        </Dialog>
      )}
      {isDmeOrderOpen && (
        <Dialog
          open={isDmeOrderOpen}
          onClose={handleClose}
          data-testid="order-survey-dialog"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: isMobile ? "auto" : "1200px",
              minHeight: isMobile ? "auto" : "400px",
            },
            "& .MuiDialogContent-root": {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {surveyAndOrderId?.isOrderOpen && (
            <OrderView
              surveyAndOrderId={surveyAndOrderId}
              isOrderModal
              handleClose={handleClose}
            />
          )}
          {surveyAndOrderId?.isSurveyOpen && (
            <SurveyView
              surveyAndOrderId={surveyAndOrderId}
              isOrderModal
              handleClose={handleClose}
            />
          )}
          {surveyAndOrderId?.isDmeOrderOpen && (
            <DmeOrder
              surveyAndOrderId={surveyAndOrderId}
              isOrderModal
              handleClose={handleClose}
            />
          )}
        </Dialog>
      )}
    </Grid>
  );
}

export default memo(SurveyAndOrder);
