import React from 'react'
import { useEffect, useState } from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";

const WebFormPulldown = ({ formState, selectedCheckbox, updateTemplate, formErrorState }) => {
    const [templateData, setTemplateData] = useState([]);
    const axios = useAxios();

    useEffect(() => {
        if(selectedCheckbox === "webForm"){
            async function setDataFirstTime() {
                pulldownsPostData(axios, "webFormsPulldown")
                    .then((res) => {
                        if (res?.data) {
                            setTemplateData(res?.data);
                        }
                    })
                    .catch((err) => {
                        console.log("err", err);
                    });
            }
            setDataFirstTime();
        }
    }, [selectedCheckbox]);

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
    <InputLabel
        id="template-label"
        error={formErrorState?.webFormId?.error && !formState?.webFormId}
    >
        Select Web Forms:
    </InputLabel>
    <Select
        labelId="template-label"
        id="webFormId"
        name="webFormId"
        value={formState.webFormId || ""}
        label="template"
        onChange={updateTemplate}
        error={formErrorState?.webFormId?.error && !formState.webFormId}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        {templateData?.map((row) => (
            <MenuItem value={row.webFormId} key={row.webFormId}>{row.name}</MenuItem>
        ))}
    </Select>
</FormControl>
  )
}

export default WebFormPulldown