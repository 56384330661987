import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAuth } from '../hooks/auth'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '../../node_modules/@mui/material/index'
import { useState } from 'react'
import { notificationToastError } from '../utils/notification'
import { validateEmail } from '../utils/emailValidator'
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';

export function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
          
        </Typography>
    )
}

export const theme = createTheme()

export default function SignIn() {
    const { login, error,setError } = useAuth()
    const passwordRef = React.useRef("")
    const [showPassword, setShowPassword] =useState(false);
    const [isvalidEmail, setIsvalidEmail] = useState(true);
    const navigate = useNavigate();

    React.useEffect(() => {
        const handleMouseDown =(e)=>{
            if (showPassword && passwordRef?.current && !passwordRef?.current.contains(e.target)) {
                setShowPassword(false)
            }
        }
        document.addEventListener("mousedown", handleMouseDown);
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [showPassword])
    React.useEffect(() => {
        if (error) {
            notificationToastError(error)
            setError(null)
        }
    }, [error])
    

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleInputField = (e) => {
        if (showPassword) {
            setShowPassword(false)
        }
        if (!isvalidEmail) {
          setIsvalidEmail(true)  
        }
    }
    const handleSubmit = async (event) => {
        let isValid =true;
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        let email =data.get('email') 
        if (email) {
            if(!validateEmail(email)){
              isValid = false;
              setIsvalidEmail(false);
              notificationToastError("Please enter valid email")
            }
          }
          if (isValid) {
              login({ email, password: data.get('password') })
          }
    }
    return (
        <ThemeProvider theme={theme}>
            <Container
                component="main"
                maxWidth="xs"
                sx={{ backgroundColor: 'white' }}
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h2" variant="h5">

                    SleepNavigator®
                    </Typography>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Provider Sign In
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            onChange={handleInputField}
                            label="Email Address"
                            name="email"
                            type='email'
                            autoComplete="email"
                            error={!isvalidEmail}
                            helperText={
                                !isvalidEmail ? "please enter valid email" : ""
                              }
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            id="password"
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                            size="small"
                                        >
                                            {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handleInputField}
                            inputRef={passwordRef}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 ,bgcolor:'#715d9c','&:hover': {
                                bgcolor: "#574b7e", // Change the hover color as needed
                              }}}
                        >
                            Sign In
                        </Button>
                        <Link
                        component="button"
                        variant="body2"
                        onClick={()=>navigate('/forgot-password')}
                        sx={{color:'#715d9c'}}
                      >
                       Forgot password?
                      </Link>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2" ></Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
                <ToastContainer/>
            </Container>
        </ThemeProvider>
    )
}
