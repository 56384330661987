import moment from "moment";

function SignDmeOrderHTMLString({
  signature,
  formState,
  visitDateState,
  inssuranceData,
  physicianName,
  headerDetail,
  headerLogo,
  downloadOrder,
  action
}) {
  let signOrderDate = new Date();
  let formattedDate = `${(signOrderDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${signOrderDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${signOrderDate.getFullYear()}`;
  signOrderDate = moment(signOrderDate).format("MM/DD/YYYY");
  let currentDateTime = new Date().toLocaleString();
  const visitDate =
    visitDateState && moment.utc(new Date(visitDateState)).format("MM/DD/YYYY");
   const dynamicHTML = headerDetail && headerDetail[1].map((detail) => {
      return `• ${detail?.location}`;
   }).join(' ');
  // console.log("selectedCheckbox==>", selectedCheckbox);
  const htmlString = `<!DOCTYPE html>
  <html>
     <head>
        <meta charset="UTF-8" />
        <title>My A4 Page</title>
     </head>
     <body style="margin: 0; padding: 0; box-sizing: border-box; display:flex; justify-content :center; align-items:center;">
        <div style="
           width: 770px;
           height: 100%;
           padding: 20px;
           font-family: Arial, sans-serif;
           font-size: 16px;
           line-height: 1.4;
           margin :0px 100px
           ">
           <div style="display: flex; flex-direction: column; width: 770px; align-items: center; justify-content: center;">
           <div style="display: flex;align-items: center; justify-content: center;">
           ${ downloadOrder ? `<div style="font-weight: bold;">ORDER FORM</div>` :
        `${headerDetail && headerDetail[0][0]?.logoFileId !== null
           ? `<img src=${headerLogo} style="width: 700px; height: 150px;" alt="signed"/>`
           : `<div style="font-weight: bold;">DME ORDER FORM</div>`}
          </div>
          ${ headerDetail && headerDetail[0][0]?.logoFileId !== null || headerDetail[0][0]?.groupHeaderSleepStudy === 1 ?
           `<div style="font-size: 18px; width: 770px; text-align: center; margin-top: 8px">
            ${dynamicHTML}
            </div>`
           :
           `<div style="font-size: 12px; width: 770px; text-align: center">
             ${headerDetail[1][0]?.address1} ${headerDetail[1][0]?.address2} • ${headerDetail[1][0]?.city} ${headerDetail[1][0]?.state} • ${headerDetail[1][0]?.zip} OFFICE ${headerDetail[1][0]?.phone}
             • FAX ${headerDetail[1][0]?.fax}
          </div>` }`}
          </div>
           <div style="width: 770px; border: 2px solid black;border-bottom: 0;">
              <div style="
                 display: flex;
                 align-items: center;
                 justify-content: space-between;
                 ">
                 <div style="padding: 10px">
                    <div style="font-size: 12px">PATIENT'S FULL NAME</div>
                    <div style="display: flex; align-items: center">
                       <div>
                          <div style="padding: 5px; border: 1px solid black; width: 150px">
                             ${formState.firstName || ""}
                          </div>
                          <div class="lower_lable" style="font-size: 10px">First</div>
                       </div>
                       <div style="margin-left: 10px">
                          <div style="
                             padding: 5px;
                             border: 1px solid black;
                             width: 50px;
                             height: 20px;
                             "></div>
                          <div class="lower_lable" style="font-size: 10px">MI</div>
                       </div>
                       <div style="margin-left: 10px; width: 170px">
                          <div style="padding: 5px; border: 1px solid black">
                             ${formState.lastName || ""}
                          </div>
                          <div class="lower_lable" style="font-size: 10px">Last</div>
                       </div>
                    </div>
                 </div>
                 <div class="patients_DOB" style="border-left:
                    RX DATE:(required)
                    2px solid black">
                    <div style="padding: 10px">
                       <div style="font-size: 12px">PATIENT'S DATE OF BIRTH</div>
                       <div style="display: flex; align-items: center">
                          <div>
                             <div style="padding: 5px; border: 1px solid black; width: 150px">
                                ${formState.dob || ""}
                             </div>
                             <div class="lower_lable" style="font-size: 10px">
                                Month/Day/Year
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
           <div style="width: 770px; border: 2px solid black;border-bottom: 0;">
              <div style="
                 display: flex;
                 align-items: center;
                 justify-content: space-between;
                 ">
                 <div>
                    <div style="
                       display: flex;
                       align-items: center;
                       justify-content: space-between;
                       width: 770px;
                       ">
                       <div class="patients_DOB" >
                          <div style="padding: 10px">
                             <div style="font-size: 12px">
                                PATIENT'S BEST CONTACT NUMBER
                             </div>
                             <div style="display: flex; align-items: center">
                                <div>
                                   <div style="
                                      padding: 5px;
                                      border: 1px solid black;
                                      width: 150px;
                                      ">
                                      ${formState.bestPhone || ""}
                                   </div>
                                   <div class="lower_lable" style="font-size: 10px">
                                      AREA CODE AND NUMBER
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                       <div class="patients_DOB" style="border-left: 1px solid black;border-right: 1px solid black">
                          <div style="padding: 10px">
                             <div style="font-size: 12px">PATIENT'S CELLULAR NUMBER</div>
                             <div style="display: flex; align-items: center">
                                <div>
                                   <div style="
                                      padding: 5px;
                                      border: 1px solid black;
                                      width: 150px;
                                      min-height : 20px;
                                      ">
                                      ${formState.cellPhone || ""}
                                   </div>
                                   <div class="lower_lable" style="font-size: 10px">
                                      AREA CODE AND NUMBER
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                       <div class="patients_DOB" >
                          <div style="padding: 10px;font-size: 12px">
                             <div style="font-size: 12px">PATIENT'S EMAIL ADDRESS</div>
                             <div style="display: flex; align-items: center">
                                <div>
                                   <div style="
                                      padding: 5px;
                                      border: 1px solid black;
                                      min-width: 200px;
                                      min-height: 20px;
                                      word-break: break-all;
                                      ">
                                      ${formState.email || ""}
                                   </div>
                                   <!-- <div class="lower_lable" style="font-size: 10px">
                                      Month/Day/Year
                                      </div> -->
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
           <div style="
              width: 770px;
              border: 2px solid black;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 0;
              ">
              <div style="width: 335px; padding: 10px; border-right: 1px solid">
                 <div style="font-size: 12px">PATIENT'S INSURANCE CARRIER</div>
                 <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 12px
                    ">
                    <div class="patients_DOB">
                       <div>
                          <!-- <div style="font-size: 12px;">PATIENT'S INSURANCE CARRIER</div> -->
                          <div style="display: flex; align-items: center">
                             <div>
                                <div style="
                                   padding: 5px;
                                   border: 1px solid black;
                                   width: 130px;
                                   min-height: 20px;
                                   ">
                                   ${inssuranceData?.primaryInsurance || ""}
                                </div>
                                <div class="lower_lable" style="font-size: 10px">
                                   PRIMARY
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="patients_DOB">
                       <div>
                          <!-- <div style="font-size: 12px;">PATIENT'S DATE OF BIRTH (required)</div> -->
                          <div style="display: flex; align-items: center">
                             <div>
                                <div style="
                                   padding: 5px;
                                   border: 1px solid black;
                                   width: 130px;
                                   min-height: 20px;
                                   ">
                                   ${inssuranceData?.secondaryInsurance || ""}
                                </div>
                                <div class="lower_lable" style="font-size: 10px">
                                   SECONDARY
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <div style="width: 335px; padding: 10px">
                 <div style="font-size: 12px">INSURANCE NUMBERS</div>
                 <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    ">
                    <div class="patients_DOB">
                       <div>
                          <!-- <div style="font-size: 12px;">PATIENT'S INSURANCE CARRIER</div> -->
                          <div style="display: flex; align-items: center">
                             <div>
                                <div style="
                                   padding: 5px;
                                   border: 1px solid black;
                                   width: 130px;
                                   min-height: 20px;
                                   ">
                                   ${formState?.primaryPolicy || ""} ${
    formState?.primaryGroup ? "/" : ""
  } ${formState?.primaryGroup || ""} 
                                </div>
                                <div class="lower_lable" style="font-size: 10px">
                                   Primary Policy and Group #
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="patients_DOB">
                       <div>
                          <!-- <div style="font-size: 12px;">PATIENT'S DATE OF BIRTH (required)</div> -->
                          <div style="display: flex; align-items: center">
                             <div>
                                <div style="
                                   padding: 5px;
                                   border: 1px solid black;
                                   width: 130px;
                                   min-height: 20px;
                                   ">
                                   ${formState?.secondaryPolicy || ""} ${
    formState?.secondaryGroup ? "/" : ""
  }${formState?.secondaryGroup || ""}
                                </div>
                                <div class="lower_lable" style="font-size: 10px">
                                   Secondary Policy And Group #
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
           <!-- <div style="border: 2px solid black; width: 770px">
              <div
                style="
                  padding: 10px;
                  font-size: 14px;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div>FACE TO FACE NOTES ( all that apply)</div>
                <div>
                  <span style="margin-right: 10px">
                    <span style="text-decoration: underline"> DATE OF VISIT </span
                    >(required)
                  </span>
                  03/08/2023
                </div>
              </div>
              <div style="padding: 10px; font-size: 12px; padding-top: 0px">
                [X] Snoring [X] Fatigue [X] Excessive Daytime Sleepiness [ ] Wakes up
                gasping for breath [ ] Witnessed Apneas [ ] Falls asleep at
                inappropriate times and/or places
              </div>
              </div> -->
           <div style="
              width: 770px;
              display: flex;
              justify-content: center;
              border: 2px solid black;
              border-bottom: 0;
              ">
              <div style="width: 335px; border-right: 1px solid black">
                 <div style="font-size: 12px; font-weight: bold; margin-left: 3px; margin-top: 10px;">
                    SLEEP THERAPY PAP DEVICE: select one (Required)
                 </div>
                 <div>
                    <div style="display: flex; gap: 0 20px">
                       <div>
                          <input type="checkbox" id="cpap" name="device-type" ${
                            formState?.papDevice === "cpap" && "checked"
                          } />
                          <label for="cpap" style="font-size: 12px"> CPAP </label>
                       </div>
                       <div style="text-decoration: underline">OR</div>
                       <div>
                          <input type="checkbox" id="autoCpap" name="device-type"${
                            formState?.papDevice === "autoCpap" && "checked"
                          }/>
                          <label for="autoCpap" style="font-size: 12px">
                          Auto CPAP (E0601)
                          </label>
                       </div>
                    </div>
                 </div>
                 <div>
                    <div style="display: flex; gap: 0 20px">
                       <div>
                          <input type="checkbox" id="bipap" 
                          value="bipap" name="device-type" ${
                            formState?.papDevice === "bipap" && "checked"
                          } />
                          <label for="bipap" style="font-size: 12px"> BiPAP </label>
                       </div>
                       <div style="text-decoration: underline">OR</div>
                       <div>
                          <input type="checkbox" id="autoBipap" name="device-type" ${
                            formState?.papDevice === "autoBipap" && "checked"
                          }/>
                          <label for="autoBipap" style="font-size: 12px">
                          Auto BiPAP (E0601)
                          </label>
                       </div>
                    </div>
                 </div>
                 <div>
                    <input type="checkbox" id="bipapst" name="device-type" ${
                      formState?.papDevice === "bipapst" && "checked"
                    } />
                    <label for="bipapst" style="font-size: 12px">
                    BiPAP ST (E0471)
                    </label>
                 </div>
                 <div>
                    <input type="checkbox" id="asv" name="device-type" ${
                      formState?.papDevice === "asv" && "checked"
                    }/>
                    <label for="asv" style="font-size: 12px"> ASV (E0471)</label>
                 </div>
                 <div>
                    <input type="checkbox" id="avaps" name="device-type" ${
                      formState?.papDevice === "avaps" && "checked"
                    }/>
                    <label for="avaps" style="font-size: 12px"> AVAPS (E0466)</label>
                 </div>
                 <div>
                    <input type="checkbox" id="avapsAe" name="device-type" ${
                      formState?.papDevice === "avapsAe" && "checked"
                    } />
                    <label for="avapsAe" style="font-size: 12px">
                    AVAPS-AE / Trilogy (E0466)
                    </label>
                 </div>
              </div>
              <div style="width: 335px;">
                 <div style="font-size: 12px; font-weight: bold; margin-left: 3px; margin-top: 10px;">
                    MASK INTERFACE KIND: select one (Required)
                 </div>
                 <div style="border: 2px solid black; margin: 10px;">
                    <div>
                       <input type="checkbox" id="nasal-mask" name="mask-type" ${
                         formState?.A7034 && "checked"
                       }/>
                       <label for="nasal-mask" style="font-size: 12px">
                       NASAL MASK: 1 per 3 MO (A7034)</label>
                       <span
                          style="text-decoration: underline; margin-left: 3px;font-size: 12px; font-weight: 700;">AND</span>
                    </div>
                    <div>
                       <input type="checkbox" id="nasal-mask-cushion" name="mask-type" ${
                         formState?.A7032 && "checked"
                       }/>
                       <label for="nasal-mask-cushion" style="font-size: 12px"> Nasal mask cushion: 2 per 1 MO
                       (A7032)</label>
                    </div>
                    <div
                       style="text-decoration: underline; margin-top: 3px;  margin-left: 25px; font-size: 12px; font-weight: 700;">
                       OR
                    </div>
                    <div>
                       <input type="checkbox" id="full-face-mask" name="mask-type" ${
                         formState?.A7030 && "checked"
                       }/>
                       <label for="full-face-mask" style="font-size: 12px">
                       FULL FACE MASK: 1 per 3 MO (A7030)</label>
                       <span
                          style="text-decoration: underline; margin-left: 3px;font-size: 12px; font-weight: 700;">AND</span>
                    </div>
                    <div>
                       <input type="checkbox" id="full-face-mask-cushions" name="mask-type" ${
                         formState?.A7031 && "checked"
                       }/>
                       <label for="full-face-mask-cushions" style="font-size: 12px">
                       Full face mask cushions: 1 per MO (A7031)</label>
                    </div>
                 </div>
                 <div style="margin-top: 10px">
                    <input type="checkbox" id="all-items" name="mask-type" ${
                      formState?.allItemMasked && "checked"
                    }/>
                    <label for="all-items" style="font-size: 12px">
                    ALL ITEMS ABOVE (NON-MEDICARE PATIENTS ONLY)</label>
                 </div>
              </div>
           </div>
           <div style="
              display: flex;
              width: 770px;
              border: 2px solid black;
              border-bottom: 0;
              font-size: 12px;
              ">
              <div style="width: 335px; border-right: 1px solid black; ">
                 <div style="
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    margin: 0 5px;">
                    <div class="patients_DOB" style="border-right: 1px solid; margin-right: 2px;">
                       <div>
                          <div
                             style="display: flex; align-items: center; font-size: 10px; box-sizing: border-box; margin-right: 5px;">
                             
                             <div>
                                <span style="font-size: 10px; font-weight: 700;">
                                IPAP PRESSURE
                                </span>
                                <div style="
                                   padding: 5px;
                                   border: 1px solid black;
                                   width: 95px;
                                   height: 30px;
                                   box-sizing: border-box;">
                                   ${formState?.ipapPress || ""}
                                </div>
                                <div class="lower_lable" style="font-size: 10px; margin-top: 2px;">
                                   min/max in CM/H2O
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="patients_DOB" style="border-right: 1px solid; margin-right: 2px;">
                       <div>
                          <div
                             style="display: flex; align-items: center; font-size: 10px; box-sizing: border-box; margin-right: 5px;">
                            
                             <div>
                                <span style="font-size: 10px; font-weight: 700;">
                                EPAP PRESSURE
                                </span>
                                <div style="
                                   padding: 5px;
                                   border: 1px solid black;
                                   width: 95px;
                                   height: 30px;
                                   box-sizing: border-box;">
                                   ${formState?.epapPress || ""}
                                </div>
                                <div class="lower_lable" style="font-size: 10px; margin-top: 2px;">
                                   min/max in CM/H2O
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="patients_DOB">
                       <div>
                          <div style="display: flex; align-items: center">
                             <div>
                         
                                <span style="font-size: 10px; font-weight: 700;">
                                RATE
                                </span>
                                <div style="
                                   padding: 5px;
                                   border: 1px solid black;
                                   width: 95px;
                                   height: 30px;
                                   box-sizing: border-box;">

                                   ${formState?.rate || ""}
                                </div>
                                <div class="lower_lable" style="font-size: 10px; margin-top: 2px;">
                                   BPM
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div style="
                    border-top: 2px solid;
                    ">
                    <div style="display: grid;
                       gap: 10px;
                       grid-template-columns: repeat(3, minmax(0, 1fr));">
                       <div class="patients_DOB"
                          style="margin: 0;padding: 0 2px ;border-right: 1px solid; padding-top: 5px; grid-column: span 2 / span 2;">
                          <div>
                             <div
                                style="display: flex; align-items: center; font-size: 10px; box-sizing: border-box; margin-right: 5px; margin-top: 5px;">
                                <div>
                                   <span style="font-size: 10px; font-weight: 700;">
                                   AVAPS / AVAPS-AE
                                   </span>
                                   <div style="
                                      width: 190px;
                                      box-sizing: border-box;">
                                      <input type="radio" id="comfort" name="device-type" ${
                                        formState?.titrate && "checked"
                                      }/>
                                      <label for="comfort" style="font-size: 10px"> TITRATE TO PATIENT’S COMFORT
                                      </label>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                       <div class="patients_DOB">
                          <div>
                             <div style="display: flex; align-items: center; padding-top: 5px;">
                                <div>
                                   <span style="font-size: 10px; font-weight: 700;">
                                   TIDAL VOLUME
                                   </span>
                                   <div style="
                                      padding: 5px;
                                      border: 1px solid black;
                                      width: 95px;
                                      height: 30px;
                                      box-sizing: border-box;">

                                      ${formState?.tidalVol || ""}
                                   </div>
                                   <div class="lower_lable" style="font-size: 10px; margin-top: 2px;">
                                      ml
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div style="border-top: 2px solid;">
                    <div style="display: grid;
                       gap: 10px;
                       grid-template-columns: repeat(3, minmax(0, 1fr));">
                       <div class="patients_DOB"
                          style="margin: 0;padding: 0 2px ;border-right: 1px solid; padding-top: 5px; grid-column: span 2 / span 2;">
                          <div>
                             <span style="font-size: 10px; font-weight: 700;">ESTIMATED LENGTH OF TIME
                             (months)</span>
                             <div style="display: flex;">
                                <input type="radio" id="all-items-supplies" name="insurance-type" />
                                <div style="display: flex; gap: 5px; align-items: center;">
                                   <div style="
                                      padding: 5px;
                                      border: 1px solid black;
                                      width: 65px;
                                      height: 30px;
                                      box-sizing: border-box;">

                                      ${formState?.estTimeMonths || ""}
                                   </div>
                                   <div
                                      style="text-decoration: underline; margin-top: 3px; font-size: 12px; font-weight: 700;">
                                      OR
                                   </div>
                                   <div style="
                                      padding: 5px;
                                      border: 1px solid black;
                                      width: 85px;
                                      height: 30px;
                                      box-sizing: border-box;">

                                      ${formState?.estTimeOther || ""}
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                       <div class="patients_DOB">
                          <div>
                             <div style="display: flex; align-items: center; padding-top: 5px;">
                                <div>
                                   <span style="font-size: 10px; font-weight: 700;">
                                   RAMP
                                   </span>
                                   <div style="
                                      padding: 5px;
                                      border: 1px solid black;
                                      width: 75px;
                                      height: 30px;
                                      box-sizing: border-box;">

                                      ${formState?.estTimeramp || ""}
                                   </div>
                                   <div class="lower_lable" style="font-size: 10px; margin-top: 2px;">
                                      minutes
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div style="
                    border-top: 2px solid;
                    ">
                    <div>
                       <span style="font-weight: 700; margin-left: 3px; margin-top: 1px;">
                       OTHER:
                       </span>
                    </div>
                    <div style="font-size: 12px;">
                    ${formState?.otherDesc || ""}
                    </div>
                 </div>
              </div>
              <div style="width: 335px; font-size: 10px;">
                 <div style="font-size: 12px; font-weight: bold; margin-left: 3px; margin-top: 10px;">
                    INTERFACE SUPPLIES: (Required)
                 </div>
                 <div>
                    <input type="checkbox" id="all-items-supplies" name="insurance-type" ${
                      formState?.All && "checked"
                    }/>
                    <label for="all-items-supplies" style="font-weight: 600; text-decoration: underline;">
                    ALL ITEMS BELOW (NON-MEDICARE PATIENTS ONLY)</label>
                 </div>
                 <div>
                    <input type="checkbox" id="headgear" name="insurance-type" ${
                      formState?.A7035 && "checked"
                    }/>
                    <label for="headgear" style="font-size: 12px"> HEADGEAR: 1 per 6 MO (A7035)</label>
                    <div style="margin-left: 25px; ">
                       PAP TUBING: 1 per 3 MO (PLEASE SELECT ONE BELOW)
                    </div>
                 </div>
                 <div>
                    <div style="display: flex; gap: 0 20px; align-items: center;">
                       <div>
                          <input type="checkbox" id="standarda7" name="insurance-type" ${
                            formState?.A7037 && "checked"
                          }/>
                          <label for="standarda7" style="font-size: 12px"> Standard: (A7037) </label>
                       </div>
                       <div style="text-decoration: underline; margin-top: 3px;  margin-left: 25px;font-weight: 700;">
                          OR
                       </div>
                       <div>
                          <input type="checkbox" id="heateda4" name="insurance-type" ${
                            formState?.A4604 && "checked"
                          }/>
                          <label for="heateda4" style="font-size: 12px">
                          Heated (A4604)
                          </label>
                       </div>
                    </div>
                 </div>
                 <div>
                    <input type="checkbox" id="heatedhumidifier" name="insurance-type" ${
                      formState?.E0562 && "checked"
                    }/>
                    <label for="heatedhumidifier" style="font-size: 12px"> HEATED HUMIDIFIER: (E0562) </label>
                    <span style="text-decoration: underline; margin-left: 3px;font-weight: 700;">AND</span>
                 </div>
                 <div>
                    <input type="checkbox" id="chamber" name="insurance-type" ${
                      formState?.A7046 && "checked"
                    } />
                    <label for="chamber" style="font-size: 12px"> Chamber: 1 per 6 MO (A7046)</label>
                 </div>
                 <div>
                    <input type="checkbox" id="disposable" name="insurance-type" ${
                      formState?.A7038 && "checked"
                    }/>
                    <label for="disposable" style="font-size: 12px">
                    DISPOSABLE FILTERS: 2 per MO (A7038)</label>
                 </div>
                 <div>
                    <input type="checkbox" id="non-disposable" name="insurance-type" ${
                      formState?.A7039 && "checked"
                    } />
                    <label for="non-disposable" style="font-size: 12px"> NON-DISPOSABLE FILTERS: 1 per 6 MO
                    (A7039)</label>
                 </div>
                 <div>
                    <input type="checkbox" id="modem" name="insurance-type" ${
                      formState?.A9279 && "checked"
                    }/>
                    <label for="modem" style="font-size: 12px"> MODEM: monitoring device (A9279)</label>
                 </div>
                 <div>
                    <input type="checkbox" id="chinstrap" name="insurance-type" ${
                      formState?.A7036 && "checked"
                    }/>
                    <label for="chinstrap" style="font-size: 12px"> CHINSTRAP: 1 per 6 MO (A7036)</label>
                 </div>
                 <div style="margin-bottom: 10px;">
                    <input type="checkbox" id="rtinit" name="insurance-type" ${
                      formState?.RT94660 && "checked"
                    }/>
                    <label for="rtinit" style="font-size: 12px"> RT INITIATION & PT EDUCATION: (94660)</label>
                 </div>
              </div>
           </div>
           <div style="border: 2px solid black; width: 770px; font-size: 12px; ">
              <div style="font-size: 12px; padding: 10px; padding-top: 5px">
                 
                 <span style="font-size: 14px; font-weight: 700;">
                 Diagnosis Code: choose 1;
                 </span>
                 <span style="text-decoration: underline; font-size: 12px; font-weight: 700;">
                 PLEASE CHECK OR FILL IN SPECIFIC CODE IF WITHIN A RANGE OF CODES 
                 </span>
                 <span style="font-size: 12px; font-weight: 700; margin-left: 3px;">
                 (Required) 
                 </span>
              </div>
              <div style="display: flex; align-items: center;">
                 <div style="padding-left: 10px; display: flex; align-items: center;">
                    <input type="checkbox" name="" id="obstructive" ${
                      formState?.G47_33 && "checked"
                    }/>
                    <div>Obstructive Sleep Apnea (G47.33)</div>
                 </div>
                 <div style="padding-left: 10px; display: flex; align-items: center;">
                    <input type="checkbox" name="" id="non-obstructive" ${
                      formState?.G47_34 && "checked"
                    }/>
                    <div>Non-Obstructive Alveolar Hypoventilation (G47.34)</div>
                 </div>
                 <div style="padding-left: 10px; display: flex; align-items: center; position: relative;">
                    <input type="checkbox" name="" id="other-obstructive" ${
                      formState?.other && "checked"
                    }/>
                    <div style="font-weight: 700;">OTHER</div>
                    <div style="position: absolute; right: -75px; top: -10px; width: 70px; border: 1px solid; height: 25px;">
                       <div style="text-align: center; font-size: 10px;">${formState?.otherNote || ""}</div>
                    </div>
                 </div>
              </div>
              <div style="display: flex; align-items: center; margin-bottom: 10px;margin-top: 5px;">
                 <div style="padding-left: 10px;display: flex; align-items: center;">
                    <input type="checkbox" name="" id="obstructive" ${
                      formState?.G47_31 && "checked"
                    }/>
                    <div>Central Sleep Apnea (G47.31 or G47.37)</div>
                 </div>
                 <div style="padding-left: 10px;display: flex; align-items: center;">
                    <input type="checkbox" name="" id="non-obstructive" ${
                      formState?.I50_20 && "checked"
                    }/>
                    <div style="height: 20px; border-bottom: 1px solid; width: 35px;">
                       ${formState?.I50_20_desc && formState?.I50_20_desc != 'null' ? formState?.I50_20_desc : ""}
                    </div>
                    <div>CHF (I50.20 – 44)</div>
                 </div>
                 <div style="padding-left: 10px;display: flex; align-items: center;">
                    <input type="checkbox" name="" id="other-obstructive" ${
                      formState?.J44_0 && "checked"
                    }/>
                    <div style="height: 20px; border-bottom: 1px solid; width: 35px;">
                       ${formState?.J44_0_desc && formState?.J44_0_desc != 'null' ? formState?.J44_0_desc : ""}
                    </div>
                    <div>COPD (J44.0 -- .9)</div>
                 </div>
                 <div style="padding-left: 10px;display: flex; align-items: center;">
                    <input type="checkbox" name="" id="other-obstructive" ${
                      formState?.J43_1  && "checked"
                    }/>
                    <div style="height: 20px; border-bottom: 1px solid; width: 35px;">
                       ${formState?.J43_1_desc && formState?.J43_1_desc != 'null' ? formState?.J43_1_desc : ""}
                    </div>
                    <div>Emphysema (J43.0--.9)</div>
                 </div>
              </div>
           </div>
           <div style="border: 2px solid black; width: 770px; height: 60px; border-bottom: 0; border-top: 0;">
              <div style="font-size: 12px; padding-top: 5px; font-weight: 700;">
                 SPECIAL/ADDITIONAL INSTRUCTIONS :
              </div>
              <div style="font-size: 12px;">${formState?.addNotes || ""}
              </div>
           </div>
           <div style="
              width: 770px;
              border: 2px solid black;
              border-bottom: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              ">
              <div style="
                 width: 335px;
                 display: flex;
                 align-items: center;
                 justify-content: space-between;
                 ">
                 <div class="patients_DOB" style="border-right: 2px solid black">
                    <div style="padding: 10px">
                       <div style="font-size: 12px; font-weight: bold">
                          PHYSICIAN/PHYSICIAN EXTENDER'S PRINTED NAME (required)
                       </div>
                       <div style="display: flex; align-items: center">
                          <div>
 
                             <div style="padding: 5px; border: 1px solid black; width: 300px;></div>
                             ${ action ?  ` <div class="lower_lable" style="font-size: 10px; height:20px">${
                              physicianName}</div>`:` <div class="lower_lable" style="font-size: 10px; height:20px">${
                              physicianName?.firstName
                            }  ${physicianName?.lastName}</div>`}
                        

                             <!-- <div class="lower_lable" style="font-size: 10px;">Month/Day/Year</div> -->
                          </div>
                       </div>
                    </div>
                 </div>
              </div>


              <div style="width: 360px; display: flex; justify-content: space-between; align-self: flex-start;">
                <div class="patients_DOB">
                    <div style="padding: 10px;">
                      <div style="font-size: 12px; font-weight: bold;">SIGNATURE (required)</div>
                      <div style="display: flex; align-items: center;">

                         <div>
                             <div style="padding: 5px; border: 1px solid black; width: 300px; border-left: 0; border-right: 0; border-top: 0; margin-top: 15px;">
                             ${ !action ? ` <div class="left" id="l1"><img src=${signature} style="width: 50px; height: 50px;" alt="signed"/></div>` : ` <div class="left" id="l1"><img src='' style="width: 50px; height: 50px;" alt="signed"/></div>` }
                         </div>

                            ${ action ? `` : downloadOrder ? `  <div class="lower_lable" style="font-size: 10px;"> Electronically Signed by ${physicianName} at ${currentDateTime} </div> `
: `  <div class="lower_lable" style="font-size: 10px;"> Electronically Signed by ${ physicianName?.firstName }  ${physicianName?.lastName} at ${currentDateTime} </div> ` }
                 
                  
                           
                   </div>
                </div>
            </div>
              </div>
           </div>
           </div>


           <div style="
              width: 770px;
              border: 2px solid black;
              ">
              <div style="display: grid;
                 gap: 10px;
                 grid-template-columns: repeat(5, minmax(0, 1fr));">
                 <div style="border-right: 2px solid black; grid-column: span 2 / span 2">
                    <span style="font-size: 10px; font-weight: 600;padding-left: 2px;">
                    PT AUTO LINKED TO A PAP ADHERANCE PROGRAM:
                    </span>
                    <div style="border-bottom: 1px solid; margin: 2px 10px 0 10px; font-size: 10px; height: 15px; text-align: center;">
                    ${ action ? `${physicianName}` : `${physicianName?.firstName}  ${physicianName?.lastName}` }
                    </div>
                    <div style="text-align: center;">
                       <span style="font-size: 10px; font-weight: 600;">Practice or Physician’s name </span>
                    </div>
                 </div>
                 <div style="padding: 2px; border-right: 2px solid black;grid-column: span 2 / span 2">
                    <div style="font-size: 10px; font-weight: 500">
                       FOR TIMELY PATIENT CARE, PLEASE SEND A COPY OF PTS DEMOGRAPHICS,
                       INSURANCE CARD/INFO, AND CURRENT CLINICAL NOTES.
                       <span style="font-weight: bold; font-style: italic;"> THANK YOU.</span>
                       <div style="font-size: 10px;font-weight: 700;">WNH FORM 1.0 DME © 02.09.21</div>
                    </div>
                 </div>
                 <div style="margin-top: 5px;">
                    <div style="font-size: 12px; font-weight: bold">
                       RX DATE : (required)
                    </div>
                    <div>${formattedDate}</div>
                 </div>
              </div>
           </div>
        </div>
        </div>
     </body>
  </html>`;
  return htmlString;
}

export default SignDmeOrderHTMLString;
