import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import states from "../constants/states.json";
import MUIDataTable from "mui-datatables";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Input,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "../../node_modules/@mui/material/index";
import { useAxios } from "../hooks/auth";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../utils/notification";
import { ToastContainer } from "react-toastify";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../constants/sideBarwidth";
import { adminPostData } from "../axios/api";
import InssuranceRules from "../components/adminComponent/Inssurance/InssuranceRules";
import InssuranceCPTcode from "../components/adminComponent/Inssurance/InssuranceCPTcode";
import phoneNumberValidator from "../utils/phoneNumberValidator";

function InsuranceView() {
  const insuranceInfo = useLocation();
  const isMobile = useMediaQuery("(max-width:800px)");
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  React.useEffect(() => {
    const row = insuranceInfo.state?.row;
    if (row) {
      const { formattedPhoneNumber } = phoneNumberValidator(row?.phone);
      const { formattedPhoneNumber: faxFormatted, numericPhoneNumber: faxNumeric } = phoneNumberValidator(row?.fax);
      setFormState({ ...formState, ...row, phoneNum: formattedPhoneNumber, fax: faxFormatted });
      setPhoneNumber({ ...phoneNumber, number: row?.phone,fax: row?.fax });
    }
  }, []);

  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );
  const [formState, setFormState] = useState({});
  const [phoneNumber, setPhoneNumber] = useState({ number: "", error: false, fax: "", faxError: false });
  const [formErrorState, setFormErrorState] = useState({
    planName: "",
    // epworthRequired:"",
    // inLabAuthRequired :"",
    // hstAuthRequired:"",
    // evpaRequired:"",
    // inLabComorbidRequired:""
  });
  const navigate = useNavigate();
  const [surveyCheck, setSurveyCheck] = useState({});
  const [BMI, setBMIState] = useState({});
  console.log('formState', formState)

  const axios = useAxios();

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    if (e.target.id === "phoneNum" || e.target.id === "fax") {
      const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(
        e.target.value
      );
      setPhoneNumber({
        ...phoneNumber,
        number: numericPhoneNumber,
        error: false,
        fax:e.target.id === "fax" ? numericPhoneNumber : phoneNumber.fax
      });
      setFormState({
        ...formState,
        [e.target.id]: formattedPhoneNumber,
      });
    } else {
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: e.target.value,
      });
    }
  };
  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "" ||
    formState[key] === false ||
    formState[key] === 0;
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const updateInssuranceCheckbox = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({ ...formState, [e.target.id]: Number(e.target.checked) });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      notificationToastError("Please fill all required fields");
      setFormErrorState(errors);
      return false;
    }
    if (phoneNumber?.number?.length !== 10 && phoneNumber?.number) {
      setPhoneNumber({
        ...phoneNumber,
        error: true,
      });

      notificationToastError("Phone number should be 10 digit");

      isValid = false;
    }
    if (isValid) {
    setIsSaveButtonDisabled(true);
      const payload = {
        ...formState,
        insuranceId: formState.insuranceId,
        phoneNum: phoneNumber?.number,
        fax: phoneNumber?.fax
      };
      adminPostData(axios, "saveInsuranceInfo", payload)
        .then((res) => {
          if (res.data.response === "1") {
            notificationToastSuccess(res.data.message);
            setTimeout(() => {
              navigate("/admin");
            }, 2000);
          } else {
            notificationToastError(res.data.message);
          }
        })
        .catch((err) => notificationToastError(err?.message));
    }
  };

  return (
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      <Typography variant="h6">
        {" "}
        Insurance Carrier (* required field)
      </Typography>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <TextField
            id="planName"
            label="Insurance Carrier *"
            value={formState.planName}
            variant="standard"
            onChange={updateInput}
            error={formErrorState.planName?.error && !formState.planName}
            helperText={
              formErrorState.planName?.error && !formState.planName
                ? "required"
                : ""
            }
            onBlur={validateEmptyField.bind(null, "planName")}
          />

          <TextField
            id="epayerId"
            label="Epayer ID"
            value={formState.epayerId}
            variant="standard"
            onChange={updateInput}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <TextField
            id="address1"
            label="Address1"
            value={formState.address1}
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="address2"
            label="Address2"
            value={formState.address2}
            variant="standard"
            onChange={updateInput}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <TextField
            id="city"
            label="City"
            value={formState.city}
            variant="standard"
            onChange={updateInput}
          />

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              name="state"
              value={formState.state || ""}
              label="state"
              onChange={updateInput}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {states.map((state,i) => (
                <MenuItem value={state} key={i}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="zip"
            label="Zip"
            value={formState.zip}
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="phoneNum"
            label="Phone Number"
            value={formState?.phoneNum}
            helperText="Area Code and Number (only 10 digits)"
            variant="standard"
            onChange={updateInput}
            error={phoneNumber.error}
            onBlur={() => {
              if (
                phoneNumber?.number?.length < 10 &&
                phoneNumber?.number?.length > 0
              )
                setPhoneNumber({
                  ...phoneNumber,
                  error: true,
                });
            }}
          />
          <TextField
            id="fax"
            label="Fax"
            value={formState.fax}
            helperText="Area Code and Number"
            variant="standard"
            onChange={updateInput}
            error={phoneNumber.faxError}
            onBlur={() => {
              if (
                phoneNumber?.fax?.length < 10 &&
                phoneNumber?.fax?.length > 0
              )
                setPhoneNumber({
                  ...phoneNumber,
                  faxError: true,
                });
            }}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="taxId"
            label="Tax ID"
            variant="standard"
            value={formState.taxId || ""}
            onChange={updateInput}
          />
          <TextField
            id="npi"
            label="NPI"
            variant="standard"
            value={formState.npi || ""}
            onChange={updateInput}
          />
        </Box>

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
      <InssuranceRules
        formState={formState}
        updateInssuranceCheckbox={updateInssuranceCheckbox}
        updateInput={updateInput}
        formErrorState={formErrorState}
        validateEmptyField={validateEmptyField}
      />

      <InssuranceCPTcode
        formState={formState}
        updateInssuranceCheckbox={updateInssuranceCheckbox}
      />
      <Box
        sx={{
          paddingTop: isMobile ? "1rem" : "2rem",
          paddingBottom: isMobile ? "4rem" : "2rem",
        }}
      >
        <Button
          variant="contained"
          disabled={isSaveButtonDisabled}
          onClick={saveChanges}
        >
          SAVE CHANGES
        </Button>
      </Box>
      <ToastContainer />
    </Container>
  );
}

export default InsuranceView;
