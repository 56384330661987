import {  Checkbox, FormControl, FormControlLabel, TextField, Typography } from "@mui/material";

function SurveyCheckBox({ surveyFormState, surveyCheck }) {
    return (
        <>
                <Typography
                variant="h6"
                    style={{
                        paddingLeft: "0.5rem",
                    }}
                >
                    PAP Therapy
                </Typography>

                <FormControl sx={{ m: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="usingCpap"
                                checked={Boolean(surveyFormState?.usingCpap)}
                            // onChange={updateCheckBox}
                            />
                        }
                        label="Are you currently using a CPAP or BiPAP machine while sleeping?"
                    />
                </FormControl>
                <h4
                    style={{
                        paddingLeft: "0.5rem",
                    }}
                >
                    Check all that apply
                </h4>

                <div>
                    <FormControl sx={{ m: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="snoringSurvey"
                                    checked={Boolean(surveyFormState?.snoring)}
                                // onChange={updateSurveyCheck}
                                />
                            }
                            label="Do you snore?"
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ m: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="tired"
                                    checked={Boolean(surveyFormState?.tired)}
                                // onChange={updateSurveyCheck}
                                />
                            }
                            label="Do you often feel Tired, Fatigued, or Sleepy during the daytime?"
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ m: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="choking"
                                    checked={Boolean(surveyFormState?.choking)}
                                // onChange={updateSurveyCheck}
                                />
                            }
                            label="Has anyone observed you stop breathing or choking/gasping during your sleep?"
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ m: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="bloodPressure"
                                    checked={Boolean(surveyFormState?.bloodPressure)}
                                // onChange={updateSurveyCheck}
                                />
                            }
                            label="Do you have (or are you being treated for) high blood pressure?"
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ m: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="overFifty"
                                    checked={Boolean(surveyFormState?.overFifty)}
                                // onChange={updateSurveyCheck}
                                />
                            }
                            label="Are you older than 50 years of age?"
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ m: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="largeNeck"
                                    checked={Boolean(surveyFormState?.largeNeck)}
                                // onChange={updateSurveyCheck}
                                />
                            }
                            label='Neck size large? (measured around Adam apple. 17" shirt collar for males, or 16" for females)'
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ m: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="genderMale"
                                    checked={Boolean(surveyFormState?.genderMale)}
                                // onChange={updateSurveyCheck}
                                />
                            }
                            label="Is your gender male?"
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{ m: 1 }}>
                        <FormControlLabel
                            control={<Checkbox id="patientBMI" />}
                            label="Patient's BMI > 25?"
                            checked={Boolean(surveyFormState?.patientBMI)}
                        />
                    </FormControl>
                </div>
                <div>
                    <TextField
                        id="stopbangScore"
                        label="STOP-BANG score"
                        value={
                            surveyFormState?.stopbangScore === undefined
                                ? ""
                                : surveyFormState?.stopbangScore
                        }
                        helperText=""
                        variant="standard"
                    // onChange={updateInput}
                    />
                </div>
        </>
    );
}

export default SurveyCheckBox