import MUIDataTable from "mui-datatables";
import React from "react";
import { dateConverter, dateTimeConverter } from "../../../utils/dateConverter";
import { Box } from "@mui/material";

function StageTable({ stages }) {
  const columns = [
    {
      name: "Stage",
      label: "Stage",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Completed On",
      label: "Completed On",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Completed By",
      label: "Completed By",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  const data = stages?.map((row) => {
    return {
      Stage: row[0],
      Status: row[1],
      "Completed On": row[2] && dateTimeConverter(row[2]),
      "Completed By": row[3],
    };
  });
  const options = {
    selectableRows: 'none', // <===== will turn off checkboxes in rows,
    // titleStyle: {
    //     fontSize: "16px !important", // Adjust the font size as needed
    //   }
    pagination: false, // Remove pagination
    responsive: "vertical",
    // display : true
  };
  return (
    <Box>
      <MUIDataTable
        title={"Stages History"}
        className="mui-data-table-stages"
        data={data}
        columns={columns}
        options={{
          ...options,
          titleStyle: {
            fontSize: "14px !important", // Adjust the font size as needed
          },
        }}
      />
    </Box>
  );
}

export default StageTable;
