import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

function InssuranceRules({formState,updateInssuranceCheckbox,updateInput,formErrorState,validateEmptyField}) {
  return (
    <Box
    component="form"
    sx={{
      "& .MuiTextField-root": { m: 1, width: "25ch" },
      border: "1px solid rgba(0,0,0, 0.12)",
      borderRadius: "4px",
      marginTop: "10px",
    }}
  >
    <Typography
    variant='h6'
      sx={{
        paddingLeft: "0.5rem",
        paddingTop: "1rem",
      }}
    >
      Rules For Insurance Plans
    </Typography>

    <div>
      <FormControl sx={{ m: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              id="epworthRequired"
              checked={Boolean(formState?.epworthRequired)}
              onChange={updateInssuranceCheckbox}
              // error={formErrorState?.epworthRequired?.error }
            />
          }
          label="Epworth "
        />
      {/* <Typography variant='subtitle2' sx={{color:"#d32f2f"}}> {formErrorState?.epworthRequired?.error &&  !formState?.epworthRequired && "Required"}</Typography> */}
      </FormControl>
    </div>
    <div>
      <FormControl sx={{ m: 1 }} 
      // error={true}
      >
        <FormControlLabel
        //  error={true}
          control={
            <Checkbox
              id="inLabAuthRequired"
              checked={Boolean(formState.inLabAuthRequired)}
              onChange={updateInssuranceCheckbox}
              // error={true}
            />
          }
          label="In-Lab Authorization "
        />
      {/* <Typography variant='subtitle2' sx={{color:"#d32f2f"}}>{formErrorState?.inLabAuthRequired?.error &&  !formState?.inLabAuthRequired && "Required"}</Typography> */}
      </FormControl>
    </div>
    <div>
      <FormControl sx={{ m: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              id="hstAuthRequired"
              checked={Boolean(formState.hstAuthRequired)}
              onChange={updateInssuranceCheckbox}
            />}
          label="HST Authorization "
        />
      {/* <Typography variant='subtitle2' sx={{color:"#d32f2f"}}>{formErrorState?.hstAuthRequired?.error &&  !formState?.hstAuthRequired && "Required"}</Typography> */}
      </FormControl>
    </div>
    <div>
      <FormControl sx={{ m: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              id="evpaRequired"
              checked={Boolean(formState.evpaRequired)}
              onChange={updateInssuranceCheckbox}
            />}
          label="Electronic Verification Prior Authorization "
        />
      {/* <Typography variant='subtitle2' sx={{color:"#d32f2f"}}>{formErrorState?.evpaRequired?.error &&  !formState?.evpaRequired && "Required"}</Typography> */}
      </FormControl>
    </div>

    <div>
      <FormControl sx={{ m: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              id="inLabComorbidRequired"
              checked={Boolean(formState.inLabComorbidRequired)}
              onChange={updateInssuranceCheckbox}
            />}
          label="Comorbid Conditions Required For In-Lab"
        />
      {/* <Typography variant='subtitle2' sx={{color:"#d32f2f"}}>{formErrorState?.inLabComorbidRequired?.error &&  !formState?.inLabComorbidRequired && "Required"}</Typography> */}
      </FormControl>
    </div>
    <div>
     <TextField 
        id="timeNeeded"
        label="Time Needed"
        value={formState.timeNeeded}
        onChange={updateInput}
        variant="standard"
        helperText="Business days"
        type='number'
        sx={{width:"25%"}}
     />
    </div>
  </Box>
  )
}

export default InssuranceRules