import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";

import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";
function ScoringTechPulldown({
  formState,
  updateInput,
  formErrorState,
  attributeName,
  isPreviousVisits = false,
}) {
  const [ScoringTechs, setScoringTechs] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    async function setDataFirstTime() {
      pulldownsPostData(axios, "scoringTechPulldown")
        .then((res) => {
          setScoringTechs(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setDataFirstTime();
  }, []);

  return (
    <>
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <InputLabel id="user-label">Select Scoring Tech * </InputLabel>
        <Select
          labelId="user-label"
          id={attributeName}
          name={attributeName}
          value={formState[attributeName] || ""}
          label="ScoringTech"
          onChange={updateInput}
          inputProps={{ readOnly: isPreviousVisits }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {ScoringTechs.map((scoringTech) => (
            <MenuItem value={scoringTech.userId} key={scoringTech.userId}>
              {scoringTech.lastName}, {scoringTech.firstName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default ScoringTechPulldown;
