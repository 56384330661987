import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MUIDataTable from "mui-datatables";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Grid,
  FormControlLabel,
  Checkbox,
} from "../../../../node_modules/@mui/material/index";
import states from "../../../constants/states.json";
import { Alert } from "@mui/material";
import PulldownAutoselection from "../../../components/misc/PulldownAutoselection";
import useIsGroupId from "../../../hooks/groupIdInfo";
import PatientInfoPulldown from "../../../components/misc/PatientInfoPulldown";
import useIsPrevileges from "../../../hooks/useRoleAndPrivileges";

function PatientInfo({
  formState,
  DOB,
  updateInput,
  updateDOB,
  errorDOB,
  setErrorDOB,
  isReadOnly,
  formErrorState,
  setFormErrorState,
  updatePhoneNumber = () => {},
  patientsPhoneNumber = {},
  setPatientsPhoneNumber = () => {},
  handleFocus = () => {},
  isEmailRequired = false,
  emailRequired = { isvalidEmail: true },
  isPatientPortal,
  setFormState = ()=>{},
  setIsSaveButtonDisabled = ()=>{},
  updateCheckBox,
  updateBMIInput,
  BMIState,
  inputError,
  handleSSNChange = () => {},
  ssnError,
  setSsnError
}) {
  // const location = useLocation();
  // const selectedPracticeId = useSelector(
  //   (state) => state.practiceSelector.value
  // );

  // const [surveyCheck, setSurveyCheck] = useState({});
  // const [BMI, setBMIState] = useState({});
  // const axios = useAxios();

  const genderOptions = ["Male", "Female", "Choose not to respond"];

  const languageOptions = ["English", "Spanish"];

  const maritalOptions = ["Single", "Married", "Divorced", "Widowed", "Domestic Partnership"];

  const userGroupId = useIsGroupId();
  const { roleId } = useIsPrevileges();

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  return (
    <Box>
      {isReadOnly && (
        <Box sx={{ margin: "20px" }}>
          <Alert
            severity="info"
            sx={{ paddingBottom: "5px !important", backgroundColor: '#715d9c' }}
            variant="filled"
          >
            {" "}
            This patient's info is Read only
          </Alert>
        </Box>
      )}
      <Box
        component="div"
        sx={{
          "& .MuiFormControl-root": { flexGrow: 1 },
          display: "flex",
        }}
      >
        <Grid container sx={{mb:2}}>
          <Grid item lg={2.2} md={5} sm={12} xs={12}>
            <TextField
              id="lastName"
              label="Last Name *"
              value={formState?.lastName || ""}
              variant="standard"
              onChange={updateInput}
              error={formErrorState?.lastName?.error && !formState?.lastName}
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
              helperText={
                formErrorState?.lastName?.error && !formState?.lastName
                  ? "required"
                  : ""
              }
              onBlur={validateEmptyField.bind(null, "lastName")}
              sx={{ width: "90%" }}
            />
          </Grid>
          <Grid item lg={2.2} md={5} sm={12} xs={12}>
            <TextField
              id="firstName"
              label="First Name *"
              value={formState.firstName || ""}
              variant="standard"
              onChange={updateInput}
              error={formErrorState?.firstName?.error && !formState?.firstName}
              helperText={
                formErrorState?.firstName?.error && !formState?.firstName
                  ? "required"
                  : ""
              }
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
              onBlur={validateEmptyField.bind(null, "firstName")}
              sx={{ width: "90%" }}
            />
          </Grid>
          <Grid item lg={2.2} md={5} sm={12} xs={12}>
            <TextField
              id="middleInitial"
              label="Middle Name"
              // sx={{
              //   maxWidth: "5rem",
              // }}
              value={formState.middleInitial || ""}
              variant="standard"
              inputProps={{ readOnly: isReadOnly }}
              onChange={updateInput}
              onMouseDown={handleFocus}
              sx={{ width: "90%" }}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={12} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="DOB *"
                inputFormat="MM/DD/YYYY"
                value={DOB || ""}
                onChange={updateDOB}
                readOnly={isReadOnly}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "90%" }}
                    variant="standard"
                    error={(errorDOB && !DOB) || inputError}
                    helperText={errorDOB && !DOB ? "required" : inputError ? 'Date should be entered in the following format mm/dd/yyyy' : ''}
                    value={
                      DOB ? moment.utc(DOB.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    {...(isReadOnly && {
                      inputProps: { readOnly: isReadOnly },
                    })}
                    onBlur={() => {
                      setErrorDOB(!DOB);
                    }}
                    onMouseDown={handleFocus}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          {formState?.age < 1 ? <Grid item lg={1.2} md={5} sm={12} xs={12}>
            <TextField
              id="months"
              label="Age (Months)"
              value={formState.months || ""}
              variant="standard"
              inputProps={{ readOnly: isReadOnly }}
              onChange={updateInput}
              onMouseDown={handleFocus}
              sx={{ width: "90%" }}
              type="number"
            />
          </Grid> : <Grid item lg={1.2} md={5} sm={12} xs={12}>
            <TextField
              id="age"
              label="Age (Years)"
              value={formState.age || ""}
              variant="standard"
              inputProps={{ readOnly: isReadOnly }}
              onChange={updateInput}
              onMouseDown={handleFocus}
              sx={{ width: "90%" }}
              type="number"
            />
          </Grid>}
          <Grid item lg={2} md={5} sm={12} xs={12}>
            <FormControl variant="standard" sx={{ width: "90%" }}>
              <PatientInfoPulldown
                formState={formState}
                updateInput={updateInput}
                options={genderOptions}
                width="120"
                isReadOnly={isReadOnly}
                attributeName="gender"
                labelName="Gender"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="div"
        sx={{
          "& .MuiFormControl-root": { flexGrow: 1 },
          display: "flex",
        }}
      >
        <Grid container sx={{mb:2}}>
          {(roleId === 1 || roleId === 3 || roleId === 6) ? "" :
            <Grid item lg={2} md={5} sm={12} xs={12}>
              <TextField
                id="race"
                label="Race"
                value={formState.race || ""}
                variant="standard"
                inputProps={{ readOnly: isReadOnly }}
                onChange={updateInput}
                onMouseDown={handleFocus}
                sx={{ width: "90%" }}
              />
            </Grid>}
          <Grid item lg={3.5} md={5} sm={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6} >
                <TextField
                  id="heightFeet"
                  label="Height (Feet)"
                  type="number"
                  value={BMIState?.heightFeet|| ""}
                  variant="standard"
                  inputProps={{ readOnly: isReadOnly }}
                  onChange={updateBMIInput}
                  onMouseDown={handleFocus}
                  sx={{ width: "90%" }}
                />
              </Grid>
              <Grid item xs={6} >
                <TextField
                  id="heightInches"
                  label="Inches"
                  type="number"
                  value={BMIState?.heightInches || ""}
                  variant="standard"
                  inputProps={{ readOnly: isReadOnly }}
                  onChange={updateBMIInput}
                  onMouseDown={handleFocus}
                  sx={{ width: "90%" }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={2} md={5} sm={12} xs={12}>
            <TextField
              id="patWeight"
              label="Weight(LBS)"
              type="number"
              value={BMIState?.patWeight || ""}
              variant="standard"
              inputProps={{ readOnly: isReadOnly }}
              onChange={updateBMIInput}
              onMouseDown={handleFocus}
              sx={{ width: "90%" }}
            />
          </Grid>

          <Grid item lg={2} md={5} sm={12} xs={12}>
            <TextField
              id="bmi"
              label="BMI"
              value={BMIState?.patBMI || ""}
              variant="standard"
              inputProps={{ readOnly: true }}
              onChange={updateBMIInput}
              onMouseDown={handleFocus}
              sx={{ width: "90%" }}
            />
          </Grid>

          <Grid item lg={2.5} md={5} sm={12} xs={12}>
            <FormControl variant="standard" sx={{ width: "90%" }}>
              <PatientInfoPulldown
                formState={formState}
                updateInput={updateInput}
                options={maritalOptions}
                isReadOnly={isReadOnly}
                attributeName="maritalStatus"
                labelName="Marital Status"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          "& .MuiFormControl-root": { flexGrow: 1 },
          display: "flex",
        }}
      >
        <Grid container sx={{mb:2}}>
          {(roleId === 1 || roleId === 3 || roleId === 6) ? "" :
            <Grid item lg={3} md={5} sm={12} xs={12}>
              <FormControl variant="standard" sx={{ width: "90%" }}>
                <PatientInfoPulldown
                  formState={formState}
                  updateInput={updateInput}
                  options={languageOptions}
                  width="120"
                  isReadOnly={isReadOnly}
                  attributeName="prefLanguage"
                  labelName="Preferred Language"
                />
              </FormControl>
            </Grid>}

          {/* {
            ( !isReadOnly && (Array.isArray(userGroupId) && userGroupId.includes(1) || userGroupId === 1) ) &&
            <Grid item lg={3} md={5} sm={12} xs={12}>
              <TextField
                id="ssn"
                label="Social Security #"
                value={formState.ssn || ""}
                variant="standard"
                inputProps={{ readOnly: isReadOnly, maxLength:11 }}
                onChange={handleSSNChange}
                onMouseDown={handleFocus}
                sx={{ width: "90%" }}
                helperText={ssnError ? "enter the (### - ## - ####) format." : ""}
                error={ssnError}
                onBlur={() => {
                  if (formState.ssn?.length < 11) {
                    setSsnError(true);
                  }else{
                    setSsnError(false);
                  }
                }}
              />
            </Grid>
          } */}
          {(roleId === 1 || roleId === 3 || roleId === 6) ? "" :
            <Grid item lg={3} md={5} sm={12} xs={12}>
              <TextField
                id="placeofemp"
                label="Place of Employment"
                value={formState.placeofemp || ""}
                variant="standard"
                inputProps={{ readOnly: isReadOnly }}
                onChange={updateInput}
                onMouseDown={handleFocus}
                sx={{ width: "90%" }}
              />
            </Grid>}

          <Grid item lg={3} md={4} sm={12} xs={12}>
            <FormControl sx={{ m: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="enablePortal"
                    checked={Boolean(formState.enablePortal)}
                    onChange={isPatientPortal ? "" : updateCheckBox}
                  />
                }
                label="Enable Patient Portal"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="div"
        sx={{
          "& .MuiFormControl-root": { flexGrow: 1 },
          display: "flex",
        }}
      >
        <Grid container>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <TextField
              id="cellPhone"
              label="Patient's Cell Number *"
              value={formState.cellPhone || ""}
              //helperText={!patientsPhoneNumber?.cellPhone ? "Area Code and Number (only 10 digits)" : "required"}
              variant="standard"
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
              onChange={updatePhoneNumber}
              error={patientsPhoneNumber.cellPhoneError || formErrorState?.cellPhone?.error && !formState?.cellPhone}
              helperText={
                formErrorState?.cellPhone?.error && !formState?.cellPhone
                  ? "required Area Code and Number (only 10 digits)"
                  : ""
              }
              onBlur={() => {
                validateEmptyField.bind(null, "cellPhone");
                if (
                  (patientsPhoneNumber?.cellPhone?.length < 10 &&
                  patientsPhoneNumber?.cellPhone?.length > 0) ||
                  !patientsPhoneNumber.cellPhone
                ) {
                  setPatientsPhoneNumber({
                    ...patientsPhoneNumber,
                    cellPhoneError: true,
                  });
                }
              }}
              sx={{ width: "90%" }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <TextField
              id="bestPhone"
              label="Other Best Contact Number"
              value={formState.bestPhone || ""}
              variant="standard"
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
              onChange={updatePhoneNumber}
              helperText={"Area Code and Number (only 10 digits)"}
              error={patientsPhoneNumber.bestPhoneError}
              onBlur={() => {
                if (
                  (patientsPhoneNumber?.bestPhone?.length < 10 &&
                    patientsPhoneNumber?.bestPhone?.length > 0) 
                ) {
                  setPatientsPhoneNumber({
                    ...patientsPhoneNumber,
                   
                  });
                }
              }}
              sx={{ width: "90%" }}
            />
          </Grid>
         
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <TextField
              id="email"
              label={isEmailRequired ? "PT's Best Email Address *" : "PT's Best Email Address"}
              value={formState.email || ""}
              type="email"
              helperText={
                isEmailRequired
                  ? "required"
                  : !emailRequired?.isvalidEmail
                  ? "Please enter valid email"
                  : ""
              }
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isReadOnly === true ? isReadOnly : isPatientPortal ? true : false }}
              error={isEmailRequired || !emailRequired?.isvalidEmail}
              onMouseDown={handleFocus}
              sx={{ width: "90%" }}
            />
          </Grid>
          
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          "& .MuiFormControl-root": { flexGrow: 1 },
          display: "flex",
        }}
      >
        <Grid container>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              id="address1"
              label="Billing Address1"
              value={formState.address1 || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
              sx={{ width: "90%" }}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              id="address2"
              label="Apt/Unit/Suite"
              value={formState.address2 || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
              sx={{ width: "90%" }}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              sx={{ width: "90%" }}
              id="city"
              label="City"
              value={formState.city || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <PulldownAutoselection options={states} attributeName="state" label="State" formState={formState} setFormState={setFormState} setIsSaveButtonDisabled={setIsSaveButtonDisabled} />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={6}>
            <TextField
              sx={{ width: "90%" }}
              id="zip"
              label="Zip"
              value={formState.zip || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          "& .MuiFormControl-root": { flexGrow: 1 },
          display: "flex",
        }}
      >
        <Grid container>
        <Grid item lg={3} md={4} sm={6} xs={12}>
        <TextField
          id="delAddress1"
          label="Delivery Address1"
          value={formState.delAddress1 || ""}
          variant="standard"
          onChange={updateInput}
          inputProps={{ readOnly: isReadOnly }}
          onMouseDown={handleFocus}
          sx={{ width: "90%" }}
        />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
        <TextField
          id="delAddress2"
          label="Apt/Unit/Suite"
          value={formState.delAddress2 || ""}
          variant="standard"
          onChange={updateInput}
          inputProps={{ readOnly: isReadOnly }}
          onMouseDown={handleFocus}
          sx={{ width: "90%" }}
        />
        </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              sx={{ width: "90%" }}
              id="delCity"
              label="City"
              value={formState.delCity || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <PulldownAutoselection options={states} attributeName="delState" label="State" formState={formState} setFormState={setFormState} setIsSaveButtonDisabled={setIsSaveButtonDisabled} />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={6}>
            <TextField
              sx={{ width: "90%" }}
              id="delZip"
              label="Zip"
              value={formState.delZip || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isReadOnly }}
              onMouseDown={handleFocus}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}


export default PatientInfo;