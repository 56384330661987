import moment from "moment";

export const  dateConverter = (date) => {
    const covertedDate = moment(date).format("MM/DD/YYYY")
    return covertedDate;
}
export const  dateConverterDataBase = (date) => {
    const covertedDate = moment(date).format("YYYY-MM-DD")
    return covertedDate;
}

export const dateTimeConverter = (dateTime) => {
  const convertedDate = moment.utc(dateTime).format("MM/DD/YYYY HH:mm:ss");
  return convertedDate;
}

export const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    let period = 'AM';
    let hours12 = parseInt(hours, 10);

    if (hours12 >= 12) {
      period = 'PM';
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }

    return `${hours12}:${minutes} ${period}`;
  };
