import jwt from "jwt-decode";
import { subTab, tab } from "../constants/tabs";
export default function roleAndPrivilegesFnc(token) {
  let userData = jwt(token);
  let roleData = userData?.data?.role;
  let roleId = userData?.data?.roleId
  let userInfo = userData?.data
  const id = userData?.data?.id;
  const groupId = userData?.data?.groupId;
  const userName = {
    firstName: userData?.data?.firstName,
    lastName: userData?.data?.lastName,
  };


  const tempPassword = userData?.data?.tempPassword;

  userData = userData?.data?.priveleges?.split(",");
  let trimmedUserData = userData?.map((element) => {
    return element.trim();
  });

  let isOrderAuthority = 0;
  if (userData?.includes(" orderAuthority")) {
    isOrderAuthority = 1;
  }

  let isEncryptedInfo = 0;
  if (userData?.includes(" encryptedInfo")) {
    isEncryptedInfo = 1;
  }

  let isAllPatients = 0;
  if (userData?.includes(" allPatients")) {
    isAllPatients = 1;
  }

  let isDiscussResults = 0;
  if (userData?.includes(" discussResults")) {
    isDiscussResults = 1;
  }

  let isReviewResults = 0;
  if (userData?.includes(" reviewResults")) {
    isReviewResults = 1;
  }

  //for facility tab
  if (trimmedUserData?.includes("pracGroups")) {
    trimmedUserData.push("facilities");
  }
  trimmedUserData?.push("incomingsms");
  let tabData = tab?.filter((item) => {
    if (trimmedUserData?.includes(item?.tabName)) {
      return item;
    }
  });
  const subtab = subTab?.filter((item) => {
    if (trimmedUserData?.includes(item)) {
      return item;
    }
  });
  const isProfile = tabData?.some((obj) =>
    Object.values(obj).includes("profSettings")
  );
  if (subtab?.includes("signOrder") && !isProfile) {
    tabData?.push({ tabName: "profSettings", path: "/physician-profile" });
  }
  let roleAndPriviledge = {
    role: roleData,
    privileges: {
      tab: tabData,
      subtab: subtab,
    },
    userName,
    isAllPatients,
    isDiscussResults,
    isReviewResults,
    roleId:roleId,
    userInfo,
    isEncryptedInfo,
    isOrderAuthority,
    id,
    tempPassword
  };
  let tabPath;
  if (tempPassword)
    tabPath = `${
      subtab?.includes("signOrder") ? "/physician-profile" : "/profile"
    }`;
  else tabPath = tabData[0]?.path;

  return {
    tabPath,
    roleAndPriviledge,
    id,
    groupId,
    roleData,
    tempPassword
  };
}
