import { createSlice } from "@reduxjs/toolkit";


export const adminPathSlice = createSlice({
  name: "adminPathData",
  initialState: {
    tabValue: 0,
  },
  reducers: {
    updateAdminPath: (state, action) => {
      state.tabValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAdminPath } = adminPathSlice.actions;

export default adminPathSlice.reducer;