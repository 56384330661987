import { createSlice } from "@reduxjs/toolkit";

export const snackBarSlice = createSlice({
  name: "snackBar",
  initialState: {
    messageObject:{
      message:null,
      type:"success"
    }
  },
  reducers: {
    setSnackbarMessage: (state, action) => {
      state.messageObject = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSnackbarMessage } = snackBarSlice.actions;

export default snackBarSlice.reducer;