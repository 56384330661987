import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import React, { useState } from 'react'

function DateToVisit({setVisitDate,visitDateState,setIsSaveButtonDisabled}) {
  const [inputError, setInputError] = useState(false);
  const updateVisitedDate = (newValue) => {
    if (!newValue) {
      setInputError(false);
      return 'Invalid Date';
    }
    const { $d } = newValue;

    if ($d == "Invalid Date") {
      setInputError(true);
    } else {
      setInputError(false);
    }
    setIsSaveButtonDisabled(false)
    setVisitDate(newValue)
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}
    sx={{minWidth:"250px"}}
    >
      <DesktopDatePicker
        label="Date Of Visit"
        inputFormat="MM/DD/YYYY"
        value={visitDateState || ""}
        onChange={updateVisitedDate}
        renderInput={(params) => 
        <TextField {...params} variant="standard"  
        error={inputError}
        helperText={inputError ? 'Date should be entered in the following format mm/dd/yyyy' : ''}
        value={visitDateState ? moment(visitDateState).format("MM/DD/YYYY") : ""}
        sx={{ "& .MuiFormHelperText-root": { width: "500px" }, }}
         />}
      />
    </LocalizationProvider>
  )
}
export default DateToVisit