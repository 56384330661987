import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";

import ConfirmDialog from "../../../components/misc/Dialog";

export default function CloseRejectDialog({ handleSave, handleClose }) {
  const [reasonToClose, setReasonToClose] = useState("");
  const [formErrorState, setFormErrorState] = useState({
    reasonToClose: "",
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false)
    }
    setReasonToClose(e.target.value);
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }
    handleSave(reasonToClose);
  };

  const isEmptyField = () =>
    reasonToClose === undefined ||
    reasonToClose === null ||
    reasonToClose === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  return (
    <ConfirmDialog
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "Save" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
    >
      <TextField
        id="reasonToClose"
        label="Please Enter Reason *"
        variant="standard"
        onChange={updateInput}
        error={formErrorState.reasonToClose?.error && !reasonToClose}
        helperText={
          formErrorState.reasonToClose?.error && !reasonToClose
            ? "required"
            : ""
        }
        onBlur={validateEmptyField.bind(null, "reasonToClose")}
      />
    </ConfirmDialog>
  );
}
