import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import MUIDataTable from "mui-datatables";
import { dateTimeConverter } from "../../../utils/dateConverter";

function PatientContactLog({ formState, rowContactLog }) {
  return (
    <Box>
      <MUIDataTable
        data={rowContactLog}
        columns={[
          {
            name: "notes",
            label: "Notes",
          },
          {
            name: "createdAt",
            label: "Date Time Created",
            options: {
              customBodyRender: (value) => {
                const formattedDate = dateTimeConverter(value); // Format the date using Moment.js
                return formattedDate;
              },
            },
          },
          {
            name: "uName",
            label: "Created By",
          },
        ]}
        options={{
          selectableRows: 'none', // <===== will turn off checkboxes in rows
        }}
      />
    </Box>
  );
}

export default PatientContactLog;
