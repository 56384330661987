import { useState, useEffect } from "react";
import {Box,Typography, useMediaQuery} from "@mui/material";
import {
  Container,
  CircularProgress,
} from "../../node_modules/@mui/material/index";
import ProfileDetail from "../components/profileComponent/ProfileDetail";
import UpdatePassword from "../components/profileComponent/UpdatePassword";
import UpdateSignature from "../components/profileComponent/UpdateSignature"; 
import Updatepin from "../components/profileComponent/Updatepin";
import { useAxios } from "../hooks/auth";
import { profileGetData } from "../axios/api";
import { ToastContainer } from "react-toastify";
import { width } from "@mui/system";
import { useSelector } from "react-redux";
import { collapsedDrawerWidth, openDrawerWidth } from "../constants/sideBarwidth";

export default function PhysicianProfile() {
  const {isCollapsed}  = useSelector(state=>state.sideBarCollapseSelector); 
  const [profileData, setprofileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width:800px)');

const axios =useAxios()
    useEffect(() => {
      const getProfileData = async ()=>{
        profileGetData(axios).then((res)=>{
          if (res) {
            setprofileData(res)
          }
        }).catch((error)=>{
          console.log("error",error);
        }).finally(()=>{
          setIsLoading(false)
        })
      }
      getProfileData()
    }, [])

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex', height:"90vh", width:"100%", justifyContent:"center", alignItems:"center"}}>
      <CircularProgress />
    </Box>
    )
  }

  return (
  <>
   <Container sx={{ maxWidth: !isMobile ? isCollapsed ? `calc(100vw - ${collapsedDrawerWidth}px) !important` : `calc(100vw - ${openDrawerWidth}px) !important` :"100%",height:"100%" }}  >
    <ProfileDetail profileData={profileData} />
      <Box
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
        }}
        autoComplete="off"
      >
       <UpdateSignature profileData={profileData}   />
      </Box>
      <Box
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "none",
          marginTop: "20px",
        }}
        autoComplete="off"
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "space-between",
            gap: "2rem",
            display : "flex",
            flexDirection : isMobile ? "column" : "row"
          }}
        >
          <Box sx={{ width: isMobile ? "100%" : "50%", paddingLeft: "0px !important", paddingRight: "0px !important"}}>
            <Updatepin profileData={profileData}/>
          </Box>
          <Box sx={{ width: isMobile ? "100%" : "50%",  paddingLeft: "0px !important", paddingRight: "0px !important" }}>
            <UpdatePassword />
          </Box>
        </Box>
      </Box>
      <ToastContainer />
      </Container>

  </>
    
  );
}
