import { useState, useEffect } from 'react'
import Box from "@mui/material/Box";
import { useAxios } from '../hooks/auth'
import {
  CircularProgress,
  Container,
  Typography,

} from "../../node_modules/@mui/material/index";
import { profileGetData } from '../axios/api';
import UpdatePassword from './profileComponent/UpdatePassword';
import ProfileDetail from './profileComponent/ProfileDetail';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { collapsedDrawerWidth, openDrawerWidth } from '../constants/sideBarwidth';
import Updatepin from "../components/profileComponent/Updatepin";
import useIsPrevileges from "../hooks/useRoleAndPrivileges";

export default function Profile() {
  const [profileData, setprofileData] = useState({});
  const [isLoading, setIsLoading] = useState(true)
  const axios =useAxios()
  const {isCollapsed}  = useSelector(state=>state.sideBarCollapseSelector); 
  const privelegesArray = useIsPrevileges();
  const isOrderAuthorityPresent = privelegesArray?.isOrderAuthority;

    useEffect(() => {
      const getProfileData = async ()=>{
        profileGetData(axios).then((res)=>{
          if (res) {
            setprofileData(res)
          }
        }).catch((error)=>{
          console.log("error",error);
        }).finally(()=>{
          setIsLoading(false)
        })
      }
      getProfileData()
    }, [])
    if(isLoading) {
      return (
        <Box sx={{ display: 'flex', height:"90vh", width:"100%", justifyContent:"center", alignItems:"center"}}>
        <CircularProgress />
      </Box>
      )
    }

  return (
    <Container sx={{maxWidth:isCollapsed ? `calc(100vw - ${collapsedDrawerWidth}px) !important` : `calc(100vw - ${openDrawerWidth}px) !important`}}>
      <ProfileDetail profileData={profileData} />
      { isOrderAuthorityPresent == 1 && (
      <Box sx={{ marginTop: "20px" }}>
            <Updatepin profileData={profileData}/>
          </Box>
      )}
      
      <Box sx={{ marginTop: "20px" }}>
        <UpdatePassword />
      </Box>
      <ToastContainer />
    </Container>
  )
}




