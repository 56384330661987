import React, { useEffect, useRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import {Alert, Typography,useMediaQuery} from "@mui/material";
import Button from "@mui/material/Button";
import PermanentDrawerLeft from "./sidebar";
import "./Layout.scss";
import {
  AppBar,
  IconButton,
  Toolbar,
} from "../../node_modules/@mui/material/index";
import {allRoutePathsFunc, allSubRoutes, patientPortalRoutes} from "../constants/allRoutePaths";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useDispatch, useSelector } from "react-redux";
import MobileBottomNavigation from "./sidebar/MobileBottomNavigation";
import { useHistory } from 'react-router-dom';
import { usePrevLocation } from "../hooks/usePrevLocation";
import { updateAdminPath } from "../store/slices/adminPathSlice";
import { useAxios } from "../hooks/auth";
import useIsPrevileges from "../hooks/useRoleAndPrivileges";
import { useCookies } from "react-cookie";
import { useError } from "../Context/ErrorContext";

const openDrawerWidth = 240;
const collapsedDrawerWidth=70;
function Layout({tabPath,isPatient}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:800px)');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const prevLocation = usePrevLocation(location)
  const { userInfo,tempPassword } = useIsPrevileges();
  const [cookies] = useCookies();
  const { errorMessage } = useError();
  
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('patientId');
    };

    // Add event listener when component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBackBtnClick = () => {
    if(prevLocation.search.includes('isnewtab')){
      navigate('/dashboard')
    }else{
      navigate(prevLocation)
    }
    if (location?.pathname === "/location-view") {
     dispatch(updateAdminPath(3))
    } else if (location?.pathname === "/insurance-view") {
    dispatch(updateAdminPath(5))
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const location = useLocation();
  const {isCollapsed}  = useSelector(state=>state.sideBarCollapseSelector);
 
  const tabPathNew = isPatient ? '/patient-portal' : localStorage.getItem('patientId') ? `` : tabPath;
  
  return (
    <div className="App">
      <nav className="app__sidebar">
        {
          !isMobile &&  (
            // tabPath === "/patient-portal" ? <DesktopSidebar/> : 
            <PermanentDrawerLeft tabPath={tabPathNew} email={userInfo?.email}/>
          ) 
        }
       
      </nav>
      <main className= {isMobile ? "app__contentMobile" : "app__content" } >
        <div style={{height: "calc(100vh - 130px)",}}>
        <AppBar
          position="fixed"
          sx={{
            width: isMobile ? "100%" : isCollapsed ? { sm: `calc(100% - ${collapsedDrawerWidth}px)`}  : { sm:  `calc(100% - ${openDrawerWidth}px)`} ,
            ml:  isCollapsed ? { sm: `${collapsedDrawerWidth}px`}: { sm: `${openDrawerWidth}px`} ,
            flexDirection: "row",
          }}
        >
          
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: "flex",
              paddingLeft: "24px",
              alignItems: "center",
            }}
          >
            {
              allRoutePathsFunc().concat(allSubRoutes).find(
                (element) => element.path === location.pathname
              )?.text
            }
              {
                patientPortalRoutes?.find(
                  (element) => element.path === location.pathname
                )?.text
              }
          </Typography>
          <Toolbar sx={{ alignSelf: "flex-end" }}>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton> */}

            <Typography variant="h4" noWrap component="div">
            SleepNavigator®
            </Typography>
          </Toolbar>
        </AppBar>
        <Button
          startIcon={<KeyboardReturnIcon />}
          onClick={handleBackBtnClick}
        >
          Back
        </Button> 
        {
          errorMessage &&
          <Alert severity="error" sx={{ margin: "25px"}}>
            {" "}
            {errorMessage}
          </Alert>
        }
        <Outlet />
        </div>

        { isMobile && <MobileBottomNavigation/>}
      </main>
     
    </div>
  );
}

export default Layout;
