import { Box, Dialog } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Viewer } from "@react-pdf-viewer/core";
import { notificationToastError } from "../../../../utils/notification";
import DownloadIcon from "@mui/icons-material/Download";

function FileView({
  setOpenPdfModal,
  openPdfModal,
  pdfURLState,
  isPatientPortal = false,
}) {

  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = pdfURLState?.fileUrl;
    link.download =
      pdfURLState?.fileType === "pdf"
        ? `${pdfURLState?.fileName}.pdf`
        : `${pdfURLState?.fileName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // revoke the temporary URL to free up memory
    URL.revokeObjectURL(pdfURLState);
  };
  const handleClosePdf = (e, reason) => {
    if (reason === "backdropClick") {
      e.preventDefault();
    } else {
      setOpenPdfModal(false);
    }
  };
  if (
    pdfURLState?.fileType !== "jpg" &&
    pdfURLState?.fileType !== "jpeg" &&
    pdfURLState?.fileType !== "svg" &&
    pdfURLState?.fileType !== "pdf" &&
    pdfURLState?.fileType !== "webp" &&
    pdfURLState?.fileType !== "png"
  ) {
    if (openPdfModal) {
      notificationToastError("Unable to open documnent");
    }
    return <div></div>;
  }
  return (
    <Dialog onClose={handleClosePdf} open={openPdfModal} maxWidth="xl">
      <Box
        sx={{ padding: "20px", width: "1100px" }}
        data-testid="modal-pdf-dialog"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            gap: "15px",
            marginBottom: "10px",
          }}
        >
          {isPatientPortal && (
            <DownloadIcon
              data-testid="file-download-icon"
              sx={{ cursor: "pointer" }}
              onClick={downloadPdf}
            />
          )}
          <CloseIcon
            data-testid="close-button"
            sx={{ cursor: "pointer" }}
            onClick={handleClosePdf}
          />
        </Box>
        {pdfURLState?.fileType === "pdf" ? (
          <Viewer
            fileUrl={pdfURLState?.fileUrl}
            width="100%"
            height={400}
            defaultScale={1.3}
          />
        ) : (
          (pdfURLState?.fileType === "jpg" ||
            pdfURLState?.fileType === "jpeg" ||
            pdfURLState?.fileType === "svg" ||
            pdfURLState?.fileType === "webp" ||
            pdfURLState?.fileType === "png") && (
            <img
              style={{
                width: "100%",
                height: "100%",
              }}
              src={pdfURLState?.fileUrl}
              alt="view"
            />
          )
        )}
      </Box>
    </Dialog>
  );
}
export default React.memo(FileView);
