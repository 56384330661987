import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import states from "../constants/states.json";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
} from "../../node_modules/@mui/material/index";
import { useAxios } from "../hooks/auth";
import RolePulldown from "../components/misc/RolePulldown";
import GroupPulldown from "../components/misc/GroupPulldown";
import LocationFacilityPulldown from "../components/misc/LocationFacilityPulldown";
import PrefInterpPhysicianPulldown from "../components/misc/PrefInterpPhysicianPulldown";
import FollowingPhysicianPulldown from "../components/misc/FollowingPhysicianPulldown";
import phoneNumberValidator from "../utils/phoneNumberValidator";
// import { useStarPassword } from "../hooks/useStarPassword";;
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../utils/notification";
import { ToastContainer } from "react-toastify";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../constants/sideBarwidth";
import { adminPostData, pulldownsPostData } from "../axios/api";
import { validateEmail } from "../utils/emailValidator";

function UserView() {
  // Main function
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:800px)");
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const navigate = useNavigate();
  const [formState, setFormState] = useState({});

  const [phoneNumber, setPhoneNumber] = useState({
    phoneNum: "",
    mobileNum: "",
    error: false,
    mobileNumError: false,
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = (id) => {
    setShowPassword((prevState) => {
      return {
        ...prevState,
        [id]: !prevState[id],
      };
    });
  };
  const [userGroups, setuserGroups] = useState([`userGroup0`]);
  useEffect(() => {
    const row = location.state?.row;
    const noLoginPractice = location.state?.noLoginPractice
    if (row) {
      const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(
        row[4]
      );
      const {
        formattedPhoneNumber: formatedMobile,
        numericPhoneNumber: numericMobile,
      } = phoneNumberValidator(row[25]);

      setPhoneNumber((prevState) => {
        return {
          ...prevState,
          phoneNum: numericPhoneNumber,
          mobileNum: numericMobile,
        };
      });

      const userFormState = {
        ...formState,
        firstName: row[2],
        lastName: row[1],
        email: row[3],
        phoneNum: formattedPhoneNumber,
        role: row[6],
        middleName: row[7],
        address1: row[8],
        address2: row[9],
        city: row[10],
        state: row[11],
        zip: row[12],
        twoFactorEnabled: row[13],
        emailNotification: row[14],
        smsNotification: row[15],

        roleId: row[17],
        followUpOrder: row[18],
        hstCashPricing: row[19],
        prefInterpPhysId: row[20],
        followingPhysId: row[21],
        mobileNum: formatedMobile,
        userId: row[0],
        title: row[27],
        credentials: row[28],
        noLoginPractice: Boolean(noLoginPractice),
        emailNotAvailable: row[30]
      };

      const uGroups = row[24]?.split(":");
      if (uGroups) {
        const locationObjectsGroups = [];

        for (let i = 0; i < uGroups.length; i++) {
          locationObjectsGroups.push(`userGroup${i}`);
          userFormState[`userGroup${i}`] = uGroups[i];
          formErrorState[`userGroup${i}`] = "";
        }

        setFormErrorState({ ...formErrorState });

        setuserGroups(locationObjectsGroups);
      }
      //console.log("row user = ", row) ;

      const inLabs = row[22]?.split(":");
      if (inLabs) {
        const locationObjects = [];

        for (let i = 0; i < inLabs.length; i++) {
          locationObjects.push(`inLabLocation${i}`);
          userFormState[`inLabLocation${i}`] = inLabs[i];
        }
        setinLabLocations(locationObjects);
      }

      const hstLabs = row[23]?.split(":");
      if (hstLabs) {
        const locationObjectsHst = [];
        for (let i = 0; i < hstLabs.length; i++) {
          locationObjectsHst.push(`hstLabLocation${i}`);
          userFormState[`hstLabLocation${i}`] = hstLabs[i];
        }

        sethstLabLocations(locationObjectsHst);
      }

      const privilegedLabs = row[26]?.split(":");
      if (privilegedLabs) {
        const locationObjectsPrivileged = [];
        for (let i = 0; i < privilegedLabs.length; i++) {
          locationObjectsPrivileged.push(`privilegedLabLocation${i}`);
          userFormState[`privilegedLabLocation${i}`] = privilegedLabs[i];
        }

        setprivilegedLabLocations(locationObjectsPrivileged);
      }
        
          setFormState(userFormState);
    }
  }, [location]);
console.log('formState', formState)
  
  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );
  const passwordRef = React.useRef(null);
  const confirmPasswordRef = React.useRef(null);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (
        passwordRef?.current &&
        !passwordRef?.current.contains(event.target) &&
        showPassword?.password
      ) {
        setShowPassword((prevState) => ({
          ...prevState,
          password: false,
        }));
      } else if (
        confirmPasswordRef?.current &&
        !confirmPasswordRef?.current.contains(event.target) &&
        showPassword?.confirmPassword
      ) {
        setShowPassword((prevState) => ({
          ...prevState,
          confirmPassword: false,
        }));
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [showPassword.password, showPassword.confirmPassword]);

  const adduserGroups = () => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    userGroups.push(`userGroup${userGroups.length}`);
    setuserGroups([...userGroups]);
  };
  const removeuserGroups = (paramNameGroup) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    if (userGroups?.length === 1) {
      notificationToastError("At least one Group should be selected");
      return;
    }

    delete formState[paramNameGroup];
    const locationObjects = [];

    const userGroupsRemoved = userGroups.filter(
      (value) => value !== paramNameGroup
    );

    for (let i = 0; i < userGroupsRemoved.length; i++) {
      locationObjects.push(`userGroup${i}`);
      formState[`userGroup${i}`] = formState[userGroupsRemoved[i]];
    }

    delete formState[`userGroup${userGroupsRemoved.length}`];
    setuserGroups(locationObjects);
    setFormState(formState);
    // setuserGroups(userGroups.filter((value) => value !== paramNameGroup));
    delete formErrorState[`userGroup${userGroupsRemoved.length}`];
  };

  const [inLabLocations, setinLabLocations] = useState([`inLabLocation0`]);

  const addinLabLocations = () => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    inLabLocations.push(`inLabLocation${inLabLocations.length}`);

    setinLabLocations([...inLabLocations]);
  };
  const removeinLabLocations = (paramNameIn) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    delete formState[paramNameIn];
    const locationObjects = [];
    const inLabs = inLabLocations.filter((value) => value !== paramNameIn);
    for (let i = 0; i < inLabs.length; i++) {
      locationObjects.push(`inLabLocation${i}`);
      formState[`inLabLocation${i}`] = formState[inLabs[i]];
    }

    delete formState[`inLabLocation${inLabs.length}`];

    setinLabLocations(locationObjects);

    setFormState(formState);
  };

  const [hstLabLocations, sethstLabLocations] = useState([`hstLabLocation0`]);
  const addhstLabLocations = () => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    hstLabLocations.push(`hstLabLocation${hstLabLocations.length}`);

    sethstLabLocations([...hstLabLocations]);
  };

  const removehstLabLocations = (paramNameHst) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    delete formState[paramNameHst];
    const locationObjects = [];

    const hstLabs = hstLabLocations.filter((value) => value !== paramNameHst);
    for (let i = 0; i < hstLabs.length; i++) {
      locationObjects.push(`hstLabLocation${i}`);
      formState[`hstLabLocation${i}`] = formState[hstLabs[i]];
    }

    delete formState[`hstLabLocation${hstLabs.length}`];
    sethstLabLocations(locationObjects);
    setFormState(formState);
  };

  const [privilegedLabLocations, setprivilegedLabLocations] = useState([
    `privilegedLabLocation0`,
  ]);
  const addprivilegedLabLocations = () => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    privilegedLabLocations.push(
      `privilegedLabLocation${privilegedLabLocations.length}`
    );

    setprivilegedLabLocations([...privilegedLabLocations]);
  };
  const removeprivilegedLabLocations = (paramNamePrivileged) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    delete formState[paramNamePrivileged];
    const locationObjects = [];
    const privilegedLabs = privilegedLabLocations.filter(
      (value) => value !== paramNamePrivileged
    );
    for (let i = 0; i < privilegedLabs.length; i++) {
      locationObjects.push(`privilegedLabLocation${i}`);
      formState[`privilegedLabLocation${i}`] = formState[privilegedLabs[i]];
    }

    delete formState[`privilegedLabLocation${privilegedLabs.length}`];
    setprivilegedLabLocations(locationObjects);

    setFormState(formState);
  };

  const row = location?.state?.row;
  const [formErrorState, setFormErrorState] = useState(
    row?.length
      ? {
          firstName: "",
          lastName: "",
          roleId: "",
          email: "",
          credentials: "",
          title: "",
          [userGroups[0]]: "",
        }
      : {
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          roleId: "",
          email: "",
          credentials: "",
          title: "",
          [userGroups[0]]: "",
        }
  );
  const [isvalidEmail, setIsvalidEmail] = useState(true);
  const [surveyCheck, setSurveyCheck] = useState({});
  const [BMI, setBMIState] = useState({});

  const axios = useAxios();
  const updateRole = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value?.roleId || "",
      role: e.target.value?.role,
    });
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };


  const updateInput = (e) => {
    if (e.target.id === "password" && showPassword.password) {
      setShowPassword({
        ...showPassword,
        password: false,
      });
    } else if (
      e.target.id === "confirmPassword" &&
      showPassword.confirmPassword
    ) {
      setShowPassword({
        ...showPassword,
        confirmPassword: false,
      });
    }

    if (!isvalidEmail) {
      setIsvalidEmail(true);
    }
    
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: e.target.value || "",
      });
    
    
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  const updateInputNonLogin = (e,groupData) => {
    if (e.target.id === "password" && showPassword.password) {
      setShowPassword({
        ...showPassword,
        password: false,
      });
    } else if (
      e.target.id === "confirmPassword" &&
      showPassword.confirmPassword
    ) {
      setShowPassword({
        ...showPassword,
        confirmPassword: false,
      });
    }

    if (!isvalidEmail) {
      setIsvalidEmail(true);
    }
    
    if (groupData){
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: e.target.value || "",
        noLoginPractice: groupData?.find((item) => item?.groupId === e.target.value)?.noLoginPractice === 1 ? true : false
      });
    }else{
      setFormState({
        ...formState,
        [e.target.id || e.target.name]: e.target.value || "",
      });
    }
    
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  const updateCheckBox = (e) => {
    if (e.target.id === "smsNotification" && !e.target.checked) {
      setPhoneNumber((prevState) => {
        return {
          ...prevState,
          mobileNumError: false,
        };
      });
    }

    if (e.target.id === "sendPasswordByEmail" && e.target.checked) {
      formState["setPassword"] = false;
    }
    if (e.target.id === "setPassword" && e.target.checked) {
      formState["sendPasswordByEmail"] = false;
    }

    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });

    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };

  // var validNumberForAPI
  const updatePhoneNumber = (e) => {
    const { formattedPhoneNumber, numericPhoneNumber } = phoneNumberValidator(
      e.target.value
    );
    setPhoneNumber({
      ...phoneNumber,
      [e.target.id]: numericPhoneNumber,
      mobileNumError: false,
      error: false,
    });

    setFormState((prevState) => {
      return {
        ...prevState,
        [e.target.id]: formattedPhoneNumber,
      };
    });
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
  };
  const handleUserInfoSaveChanges = () => {
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      userId: formState.userId,
      phoneNum: phoneNumber?.phoneNum,
      mobileNum: phoneNumber?.mobileNum,
      prefInterpPhysId: formState.prefInterpPhysId,
      followingPhysId: formState.followingPhysId,
    };
    adminPostData(axios, "saveUserInfo", payload, selectedPracticeId)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res.data.message);

          setTimeout(() => {
            navigate(`/admin`);
          }, 2000);
        } else {
          notificationToastError(res.data.message);
        }
      })
      .catch((err) => notificationToastError(err?.message));
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        if (
          (!formState.setPassword && (key == "password" || key == "confirmPassword"))) {
        } else if(formState?.sendPasswordByEmail){
        }else if(key == "email" && (formState.emailNotAvailable || formState.noLoginPractice)){
        }else {
          isValid = false;
          errors[key] = { error: true };
        }
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      notificationToastError("Please fill all required fields");
      return false;
    }

    if (phoneNumber?.phoneNum || phoneNumber?.mobileNum) {
      if (phoneNumber?.phoneNum?.length !== 10 && phoneNumber?.phoneNum) {
        setPhoneNumber({
          ...phoneNumber,
          error: true,
        });

        notificationToastError("Phone number should be 10 digit");
        // return ;

        isValid = false;
      }
    }
    if (
      formState?.smsNotification &&
      !phoneNumber.mobileNum &&
      !formState?.mobileNum
    ) {
      notificationToastError("Please fill all required fields");
      setPhoneNumber((prevState) => {
        return {
          ...prevState,
          mobileNumError: true,
        };
      });
      isValid = false;
      return;
    }


    if (formState?.email) {
      if (!validateEmail(formState?.email)) {
        isValid = false;
        setIsvalidEmail(false);
        notificationToastError("Please enter valid email");
      }
    }

    // password validation with POST API calling
    if (isValid) {
      if (formState?.password === formState?.confirmPassword) {
        const regex =
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (regex.test(formState?.password)) {
          handleUserInfoSaveChanges();
        } else if (!formState?.password && !formState?.confirmPassword) {
          handleUserInfoSaveChanges();
        } else {
          setFormState((prevState) => {
            return {
              ...prevState,
              password: "",
              confirmPassword: "",
            };
          });
          setFormErrorState({
            ...formErrorState,
            password: { error: true },
            confirmPassword: { error: true },
          });
          notificationToastError(
            "Password should be at least one letter, one number and one special character (total 8 characters)"
          );
        }
      } else if (
        row?.length &&
        !formState?.confirmPassword &&
        !formState?.password
      ) {
        handleUserInfoSaveChanges();
      } else {
        notificationToastError("Password and confirm password are not match ");
        setFormState((prevState) => {
          return {
            ...prevState,
            password: "",

            confirmPassword: "",
          };
        });
        if (!row?.length) {
          setFormErrorState({
            ...formErrorState,
            password: { error: true },
            confirmPassword: { error: true },
          });
        }
      }
    }
  };

  function createData(Type, DateCreated, StopBang, Actions) {
    return {
      Type,
      DateCreated,
      StopBang,
      Actions,
    };
  }
  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
     
      setTimeout(() =>  setFormErrorState({ ...formErrorState, [key]: { error: true } }),300);
    }
  }

  const updateBMIInput = (e) => {
    const newBMIState = { ...BMI, [e.target.id]: e.target.value };
    const height =
      parseInt(newBMIState.heightFeet) * 12 +
      parseInt(newBMIState.heightInches);
    const bmi = Math.round((newBMIState.weight * 703) / Math.pow(height, 2));
    if (!isNaN(bmi)) {
      newBMIState.bmi = bmi;
    }
    setBMIState(newBMIState);
  };

  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: e.target.checked,
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState, bangScore: sum });

    setSurveyCheck(newSurveyState);
  };
  const handleCopy = (event) => {
    event.preventDefault();
  };

  return (
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      <Typography variant="h6">User Info ( * required field)</Typography>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="lastName"
            label="Last Name *"
            value={formState.lastName || ""}
            variant="standard"
            onChange={updateInput}
            error={formErrorState?.lastName?.error && !formState?.lastName}
            helperText={
              formErrorState?.lastName?.error && !formState?.lastName
                ? "required"
                : ""
            }
            onBlur={validateEmptyField.bind(null, "lastName")}
          />
          <TextField
            id="firstName"
            label="First Name *"
            value={formState?.firstName || ""}
            variant="standard"
            onChange={updateInput}
            error={formErrorState?.firstName?.error && !formState?.firstName}
            helperText={
              formErrorState?.firstName?.error && !formState?.firstName
                ? "required"
                : ""
            }
            onBlur={validateEmptyField.bind(null, "firstName")}
          />
          <TextField
            id="middleName"
            label="MI"
            sx={{
              maxWidth: "5rem",
            }}
            value={formState.middleName}
            variant="standard"
            onChange={updateInput}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="credentials"
            label="Credentials *"
            value={formState?.credentials || ""}
            variant="standard"
            onChange={updateInput}
           error={
              formErrorState?.credentials?.error && !formState?.credentials
            }
            
            helperText={
              formErrorState?.credentials?.error && !formState?.credentials
                ? "required"
                : ""
            }
            onBlur={validateEmptyField.bind(null, "credentials")}
            //onBlur={ ()=> setTimeout(() => validateEmptyField.bind(null, "credentials")(),300)}
          />
          <TextField
            id="title"
            label="Title *"
            value={formState?.title || ""}
            variant="standard"
            onChange={updateInput}
            error={formErrorState?.title?.error && !formState?.title}
            helperText={
              formErrorState?.title?.error && !formState?.title
                ? "required"
                : ""
            }
            onBlur={validateEmptyField.bind(null, "title")}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="address1"
            label="Address1"
            value={formState.address1 || ""}
            variant="standard"
            onChange={updateInput}
          />
          <TextField
            id="address2"
            label="Address2"
            value={formState.address2}
            variant="standard"
            onChange={updateInput}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="city"
            label="City"
            value={formState.city || ""}
            variant="standard"
            onChange={updateInput}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              name="state"
              value={formState.state || ""}
              label="state"
              onChange={(e)=>updateInput(e,undefined)}
             // onChange={updateInput}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {states.map((state,i) => (
                <MenuItem value={state} key={i}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="zip"
            label="Zip"
            value={formState.zip}
            variant="standard"
            onChange={updateInput}
          />
        </Box>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <TextField
            id="phoneNum"
            type="tel"
            label={"Phone Number"}
            value={formState.phoneNum || ""}
            helperText="Area Code and Number (only 10 digits)"
            variant="standard"
            onChange={updatePhoneNumber}
            error={phoneNumber.error}
            onBlur={() => {
              if (
                phoneNumber?.phoneNum?.length < 10 &&
                phoneNumber?.phoneNum?.length > 0
              )
                setPhoneNumber({
                  ...phoneNumber,
                  error: true,
                });
            }}
            //
          />
          <TextField
            id="mobileNum"
            type="tel"
            label={
              formState?.smsNotification ? "Mobile Number *" : "Mobile Number"
            }
            value={formState.mobileNum || ""}
            helperText="Phone To Receive Notifications"
            variant="standard"
            onChange={updatePhoneNumber}
            error={phoneNumber.mobileNumError}
            onBlur={() => {
              if (
                phoneNumber?.mobileNum?.length < 10 &&
                phoneNumber?.mobileNum?.length > 0
              )
                setPhoneNumber({
                  ...phoneNumber,
                  mobileNumError: true,
                });
            }}
            //
          />
        </Box>

        <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },

              marginTop: "1rem",
              // padding: "0 1rem",
              gap: "2rem",
            }}
          >
            {userGroups.map((paramNameGroup,index) => (
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <GroupPulldown
                  key={paramNameGroup}
                  formState={formState}
                  setFormState={setFormState}
                  updateInput={updateInputNonLogin}
                  formErrorState={formErrorState}
                  attributeName={paramNameGroup}
                  style={{ maxWidth: "300px" }}
                  selectedGroupAttributeNames={userGroups.filter(
                    (paramNameFilter) => paramNameFilter !== paramNameGroup
                  )}
                  userView={true}
                />
                {/* for wellnecessities */}
               
                {formState[`userGroup${index}`] == 1 ?
                  <>
                    <Button
                      onClick={adduserGroups}
                      variant="outlined"
                      sx={{ marginRight: "1rem", height: "fit-content" }}
                    >
                      Add Group
                    </Button>
                    <Button
                      onClick={() => removeuserGroups(paramNameGroup)}
                      variant="outlined"
                      sx={{ height: "fit-content" }}
                    >
                      Remove
                    </Button>
                  </>
                  :
                  <Box sx={{ display: "flex", flexGrow: "0.1", justifyContent: "space-between", alignItems: "center" }}>
                    <Button
                      onClick={() => removeuserGroups(paramNameGroup)}
                      variant="outlined"
                      sx={{ height: "fit-content" }}
                    >
                      Remove
                    </Button>
                    <Typography sx={{ fontSize: "14px", color: "red" }}>
                      * More than one group is allowed only for WellNecessities Users.
                    </Typography>
                  </Box>
                }
              </Box>
            ))}
          </Box>
        {!formState.noLoginPractice && < Box m={2}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid={`kind-checkbox`} // Add Cypress ID to each checkbox
                  id="emailNotAvailable"
                  checked={Boolean(formState.emailNotAvailable) || ""}
                  onChange={updateCheckBox}
                />
              }
              label="Email is not available"
            />
          </FormControl>
        </Box>}
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            gap: "10px",
            flexDirection: isMobile ? "column" : "row",
            alignItems:"center"
          }}
        >
          <TextField
            id="email"
            label={formState.emailNotAvailable || formState.noLoginPractice ? "Email" : "Email *"}
            type="email"
            value={formState.email || ""}
            variant="standard"
            onChange={updateInput}
            error={
              (formErrorState?.email?.error && !formState.email && (!formState.emailNotAvailable && !formState.noLoginPractice)) ||
              !isvalidEmail
            }
            helperText={
              formErrorState.email?.error && !formState.email && (!formState.emailNotAvailable && !formState.noLoginPractice) ? "required" : ""
            }
            onBlur={!formState.emailNotAvailable && validateEmptyField.bind(null, "email")}
            sx={{ minWidth: "50%" }}
          />
          {formState.emailNotAvailable || formState.noLoginPractice ? "" :
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="setPassword"
                    checked={Boolean(formState.setPassword) || ""}
                    onChange={updateCheckBox}
                  // disabled={formState.emailNotAvailable}
                  />
                }
                label="Set Password"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="sendPasswordByEmail"
                    checked={Boolean(formState.sendPasswordByEmail) || ""}
                    onChange={updateCheckBox}
                  // disabled={formState.emailNotAvailable}
                  />
                }
                label="Send Temporary Password by Email"
              />
            </Box>} 
        </Box>

        {formState?.setPassword == 1 && (
          <TextField
            id="password"
            label={row?.length ? "Password" : "Password *"}
            value={formState.password || ""}
            variant="standard"
            onChange={updateInput}
            type={showPassword?.password ? "text" : "password"}
            error={formErrorState?.password?.error && !formState.password}
            helperText={
              formErrorState.password?.error && !formState.password
                ? "required"
                : ""
            }
            onCopy={handleCopy}
            onBlur={!row?.length && validateEmptyField.bind(null, "password")}
            inputRef={passwordRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password")}
                    edge="end"
                  >
                    {showPassword?.password ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        {formState?.setPassword == 1 && (
          <TextField
            id="confirmPassword"
            label={row?.length ? "Confirm Password" : "Confirm Password *"}
            value={formState.confirmPassword || ""}
            variant="standard"
            onChange={updateInput}
            error={
              formErrorState.confirmPassword?.error &&
              !formState.confirmPassword
            }
            helperText={
              formErrorState.confirmPassword?.error &&
              !formState.confirmPassword
                ? "required"
                : ""
            }
            onBlur={
              !row?.length && validateEmptyField.bind(null, "confirmPassword")
            }
            onCopy={handleCopy}
            type={showPassword?.confirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("confirmPassword")}
                    edge="end"
                    size="small"
                  >
                    {showPassword?.confirmPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputRef={confirmPasswordRef}
          />
        )}
        <Box>
          {formState?.setPassword == 1 && (
            <Box sx={{ fontSize: 12 }}>
              Password must be at least eight alpha numeric with at least
              one-special character.
            </Box>
          )}
        </Box>

        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="twoFactorEnabled"
                  checked={Boolean(formState.twoFactorEnabled)}
                  onChange={updateCheckBox}
                />
              }
              label="Two Factor Authentication Enabled"
            />
          </FormControl>

          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="emailNotification"
                  checked={Boolean(formState.emailNotification)}
                  onChange={updateCheckBox}
                />
              }
              label="Receive Email Notifications"
            />
          </FormControl>

          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={<Checkbox id="smsNotification" />}
              label="Receive SMS Notifications"
              checked={Boolean(formState.smsNotification)}
              onChange={updateCheckBox}
            />
          </FormControl>
        </Box>

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },

            marginTop: "1rem",
            padding: "0 1rem",
            gap: "2rem",
          }}
        >
          <Box>
            <RolePulldown
              formState={formState}
              updateRole={updateRole}
              formErrorState={formErrorState}
            />
          </Box>
          <hr></hr>
          
        </Box>
      </Box>

      {(formState?.role === "Physician" ||
        formState?.role === "InterpretingPhysician") && (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
            border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginTop: "20px",
          }}
          noValidate
          autoComplete="off"
        >
          <Box sx={{ marginLeft: "10px", marginTop: "10px" }}>
            <Typography variant="h6">Physician Settings:</Typography>
          </Box>

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },

              marginTop: "1rem",
              padding: "0 1rem",
              gap: "2rem",
            }}
          >
            <Typography variant="h6">In-lab Facility:</Typography>
            {inLabLocations.map((paramNameIn) => (
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <LocationFacilityPulldown
                  key={paramNameIn}
                  formState={formState}
                  updateInput={updateInput}
                  formErrorState={formErrorState}
                  attributeName={paramNameIn}
                  selectedLocationAttributeNames={inLabLocations.filter(
                    (paramNameFilter) => paramNameFilter !== paramNameIn
                  )}
                  style={{ maxWidth: "300px" }}
                />
                <Button
                  onClick={addinLabLocations}
                  variant="outlined"
                  sx={{ marginRight: "1rem", height: "fit-content" }}
                >
                  Add Location
                </Button>

                <Button
                  onClick={() => removeinLabLocations(paramNameIn)}
                  variant="outlined"
                  sx={{ height: "fit-content" }}
                >
                  Remove
                </Button>
              </Box>
            ))}
          </Box>
          <hr></hr>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },

              marginTop: "1rem",
              padding: "0 1rem",
              gap: "2rem",
            }}
          >
            <Typography variant="h6">HST Facility:</Typography>
            {hstLabLocations.map((paramNameHst) => (
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <LocationFacilityPulldown
                  key={paramNameHst}
                  formState={formState}
                  updateInput={updateInput}
                  formErrorState={formErrorState}
                  attributeName={paramNameHst}
                  selectedLocationAttributeNames={hstLabLocations.filter(
                    (paramNameFilter) => paramNameFilter !== paramNameHst
                  )}
                  style={{ maxWidth: "300px" }}
                />
                <Button
                  onClick={addhstLabLocations}
                  variant="outlined"
                  sx={{ marginRight: "1rem", height: "fit-content" }}
                >
                  Add Location
                </Button>
                <Button
                  onClick={() => removehstLabLocations(paramNameHst)}
                  variant="outlined"
                  sx={{ height: "fit-content" }}
                >
                  Remove
                </Button>
              </Box>
            ))}
          </Box>
          <hr></hr>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },

              marginTop: "1rem",
              padding: "0 1rem",
              gap: "2rem",
            }}
          >
            <Typography variant="h6">Privileged Facilities:</Typography>
            {privilegedLabLocations.map((paramNamePrivileged) => (
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <LocationFacilityPulldown
                  key={paramNamePrivileged}
                  formState={formState}
                  updateInput={updateInput}
                  formErrorState={formErrorState}
                  attributeName={paramNamePrivileged}
                  selectedLocationAttributeNames={privilegedLabLocations.filter(
                    (paramNameFilter) => paramNameFilter !== paramNamePrivileged
                  )}
                  style={{ maxWidth: "300px" }}
                />
                <Button
                  onClick={addprivilegedLabLocations}
                  variant="outlined"
                  sx={{ marginRight: "1rem", height: "fit-content" }}
                >
                  Add Location
                </Button>
                <Button
                  onClick={() =>
                    removeprivilegedLabLocations(paramNamePrivileged)
                  }
                  variant="outlined"
                  sx={{ height: "fit-content" }}
                >
                  Remove
                </Button>
              </Box>
            ))}
          </Box>
          <hr></hr>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
              marginTop: "1rem",
              padding: "0 1rem",
              gap: "2rem",
            }}
          >
            <PrefInterpPhysicianPulldown
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="prefInterpPhysId"
            />
          </Box>
          <FormControl variant="standard" sx={{ width: "50%" }}>
            <FollowingPhysicianPulldown
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="followingPhysId"
            />
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="followUpOrder"
                  checked={Boolean(formState.followUpOrder)}
                  onChange={updateCheckBox}
                />
              }
              label="Followup Order"
            />
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="hstCashPricing"
                  checked={Boolean(formState.hstCashPricing)}
                  onChange={updateCheckBox}
                />
              }
              label="HST Cash Pricing Available "
            />
          </FormControl>
        </Box>
      )}
      <Box
        sx={{
          paddingTop: isMobile ? "1rem" : "2rem",
          paddingBottom: isMobile ? "4rem" : "2rem",
        }}
      >
        <Button
          variant="contained"
          disabled={isSaveButtonDisabled}
          onClick={saveChanges}
        >
          SAVE CHANGES
        </Button>
      </Box>
      <ToastContainer />
    </Container>
  );
}

export default UserView;
