import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";


import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Typography,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";

function GroupPulldown({ formState, updateInput, formErrorState={}, attributeName = "groupId", selectedGroupAttributeNames , validateEmptyField = () => { }, isPatientPortal,userView, ...remaining }) {

  const [groups, setGroups] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    async function setDataFirstTime() {
      let payload = {}
      if (isPatientPortal) {
        payload = {
          patientPortal: 1,
        }
      } else if (formState?.groupId) {
        payload = {
          groupId: formState?.groupId,
        }

      }

      pulldownsPostData(axios, "groupPulldown",payload).then((res) => {
        if (res?.data) {
          setGroups(res?.data);
        }
      }).catch((err) => {
        console.log(err);
      })

    }
    setDataFirstTime()
  }, [formState?.groupId]);

  const data = userView ? groups : groups
  .filter((groupFilter) => {
    return (
      !selectedGroupAttributeNames ||
      selectedGroupAttributeNames.every(
        (attr) =>
          String(formState[attr]) !==
          String(groupFilter.groupId)
      )
    );
  })

  return <>
    <FormControl variant="standard" sx={{ m: 1, width: "97%" }} {...remaining} >
      <InputLabel id="group-label" error={formErrorState[attributeName]?.error && !formState[attributeName]}>Provider Group * { attributeName === "userGroup0" && "*"} </InputLabel>
      <Select
        data-testid="group-pulldown" 
        labelId="group-label"
        id={attributeName}
        name={attributeName}
        value={formState[attributeName] || ''}
        label="group"
        onChange={(e)=>updateInput(e,data)}
        error={formErrorState[attributeName]?.error && !formState[attributeName]}
        onBlur={validateEmptyField.bind(null, attributeName)}
        readOnly={isPatientPortal}

      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {data.map((group) => (
          <MenuItem value={group.groupId} key={group.groupId}>{group.groupName}</MenuItem>
        ))}

      </Select>
      {formErrorState[attributeName]?.error && !formState[attributeName] && <Typography variant="subtitle2" color={"error"}>required</Typography>}
    </FormControl>
  </>

}

export default GroupPulldown;