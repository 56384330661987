export const SURVEY_STAGE ="Survey"
export const ORDER_STAGE ="Order"
export const SCHEDULE_STAGE ="Schedule"
export const STUDY_STAGE ="Study"
export const RESULTS ="Results"
export const RECOMMENDATIONS_STAGE ="Recommendations"
export const CONSULT_STAGE ="Consult"
export const PATIENT_CARE_PLAN_STAGE ="Patient Care Plan"
export const SCHEDULE_FOLLOW_UP_STAGE ="Schedule Follow up"
export const FOLLOW_UP_RESULTS_STAGE ="Follow Up Results"
export const MONITORING_STAGE ="Monitoring"
export const ANNUAL_FOLLOW_STAGE ="Annual Follow up"

// signed and completed stages
export const SIGNED_ORDER ="Signed"
export const REJECTED_ORDER ="Rejected"
export const CLOSED_SURVEY ="Closed"

