import { Button,Box, CircularProgress, Container, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { collapsedDrawerWidth, openDrawerWidth } from '../../../constants/sideBarwidth';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { patientViewGetData, patientsPostData, pulldownsPostData, studiesPostData, visitViewPostData, visitsGetData } from '../../../axios/api';
import { useAxios } from '../../../hooks/auth';
import { convertTo12HourFormat, dateConverter } from '../../../utils/dateConverter';
import { base64ToPdf } from '../../../utils/base64ToPDFConvertor';
import { notificationToastError } from '../../../utils/notification';
import FileView from '../../patients/components/FileUpload/FileView';
import StudyFilesFormsDialog from './StudyFilesFormsDialog';

function PatientPortalPastVisits() {
  const { isCollapsed } = useSelector((state) => state?.sideBarCollapseSelector);
  const isMobile = useMediaQuery('(max-width:800px)');
  const { patientId } = useSelector(state => state?.patientLogInDataSelector);
  const axios = useAxios();
  const [locations, setLocations] = useState([]);
  const [studyTypes, setStudyTypes] = useState([])
  const [prevVisitsData, setprevVisitsData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" })

 
  const handleClose = () => {
       
  }
let studyId
let orderId
let dmeOrderId 

  useEffect(() => { 
    const payload = {
      patientId,
      patientPortal : 1
    };
    const arr = [];
    const paramsName = "viewVisit";
    studiesPostData(axios, payload, "previousVisit")
      .then((res) => {
        if (res.length) {
          res?.forEach((element) => {
          
            const payloadOfVisit = {
              studyId: element?.studyId,
              patientPortal: 1,
            };
            visitViewPostData(axios, paramsName, payloadOfVisit)
              .then((visitRes) => {
                if (visitRes?.length) {
                  visitRes?.forEach((visitElement) => {
                    if (visitElement?.orderId) {
                 //     orderId = visitElement?.orderId
                      patientViewGetData(
                        axios,
                        "listFiles",
                        patientId,
                        visitElement?.orderId,
                        visitElement?.studyId
                      )
                        .then((resFile) => {
                          if (resFile && resFile.length) {
                            const visitElementWithFiles = {
                              ...visitElement,
                              files: resFile,
                            };
                            arr.push(visitElementWithFiles);
                          }
                          // if (res?.length - 1 === arr?.length) {
                          // }
                          setprevVisitsData(arr);
                          setIsLoading(false);

                        })
                        .catch((err) => {
                          setIsLoading(false);
                          console.log("err", err);
                        });
                    }
                  });
                }
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("err", err);
              });
          });
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err==>", err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
   
    async function setDataFirstTime() {
      const payload ={
        patientPortal : 1
      }
      if (!locations?.length) {
        pulldownsPostData(axios, "locationPulldown",payload)
          .then((res) => {
            setLocations(res?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      pulldownsPostData(axios, "StudyTypePulldown",payload)
        .then((res) => {
          setStudyTypes(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setDataFirstTime();
  }, [prevVisitsData]);

  const [openStudyDialog, setOpenStudyDialog] = useState(null)
  const handleCloseDialog = () => setOpenStudyDialog(null)

  // handle file view
  const download = (id, fileName) => {
    const payload = {
      id,
      tableName: "storedFiles",
      idColumn: "fileId",
      patientPortal: 1,
    };
    patientsPostData(axios, "downloadFile", payload)
      .then((res) => {
        if (res.data.file) {
            setOpenPdfModal(true)
          let base64 = res?.data?.file;
            base64 = base64?.split(',')[1]
          let fileType = res?.data?.name;
            fileType = fileType?.split('.')?.pop();
          const pdfUrl = base64ToPdf(base64, fileType);
          setPdfURLState({
              fileUrl : pdfUrl,
              fileType ,
              fileName : fileName
            })
        } else {
          notificationToastError("File was not downloaded due to issue.");
        }
      })
      .catch((err) => notificationToastError(err?.message));
  };
  // table columns
  const columns = [
    {
      name: "Date",
      label: "Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Time",
      label: "Time",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Location",
      label: "Location",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Type",
      label: "Type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Docs",
      label: "Docs",
      options: {
        customBodyRender: (value ) => {  
        
            return (
             <> <Button
                            variant="contained"
                            size="small"
                            onClick={ ()=> setOpenStudyDialog(data[0],{patientId},data[1],data[2]) 
                            }
                            // sx={{ marginTop: "1rem" }}
                          >
                            View Files and Forms
                          </Button>
            
          </>
            )
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "patientId",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: true,
      },

    },
    {
      name: "studyId",

      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: true,
      },

    },
    {
      name: "dmeOrderId",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: true,
      },

    },
    {
      name: "orderId",

      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: true,
      },

    }

  ];
  // past visitstable data
  
  const data = prevVisitsData?.map((row) => {
    return {
      Date: row?.date && dateConverter(row?.date),
      Time: row?.time && convertTo12HourFormat(row?.time ),
      Location: row?.locationId && locations?.find((item)=>item?.locationId === row?.locationId)?.location,
      Type: row?.studyTypeId && studyTypes?.find((item)=>item?.studyTypeId === row?.studyTypeId)?.studyType ,
      Docs : row?.files,
      patientId : row?.patientId,
      studyId : row?.studyId,
      orderId : row?.orderId,
      dmeOrderId : row?.dmeOrderId,
    };
  });
  const options = {
    selectableRows: 'none', // <===== will turn off checkboxes in rows,

        responsive : "vertical",
  };
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', height: "90vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      {/* past visits table */}
      <MUIDataTable
        title={"Past Visits"}
        data={data}
        columns={columns}
        options={{
          ...options,
          titleStyle: {
            fontSize: "14px !important", // Adjust the font size as needed
          },
        }}
      />
      {/* file view modale */}
      {openPdfModal && (
        <FileView
          setOpenPdfModal={setOpenPdfModal}
          openPdfModal={openPdfModal}
          pdfURLState={pdfURLState}
          isPatientPortal
        />
      )}
       
    </Container>

 { openStudyDialog && (<StudyFilesFormsDialog  
                studyId= { openStudyDialog?.studyId}
                patientId = {openStudyDialog?.patientId}
                orderId= { openStudyDialog?.orderId}
                dmeOrderId = {openStudyDialog?.dmeOrderId}
                handleClose = {handleCloseDialog}
                />)
 }
 </>
  );
}


export default PatientPortalPastVisits
