export const HLTCPTCODES = [
    {
        id : "95800_watchpat",
        label :"95800 (WatchPat & SleepImage)"
    },
    {
        id : "95806_alicenight1",
        label :"95806 (Alice NightOne)"
    }
]
export const LT_CPT_CODES = [
    {
        id : "95810_npsg",
        label :"95810 (NPSG)"
    },
    {
        id : "95811_split_cpap",
        label :"95811 (Split to CPAP)"
    },
    {
        id : "95811_split_bipap",
        label :"95811 (Split to BiPAP)"
    },
    {
        id : "95811_split_bipapst",
        label :"95811(Split to BiPAP ST)"
    },
    {
        id : "95811_cpap",
        label :"95811 (CPAP)"
    },
    {
        id : "95811_bipap",
        label :"95811 (BiPAP)"
    },
    {
        id : "95811_bipapst",
        label :"95811 (BiPAP ST)"
    },
    {
        id : "95811_asv",
        label :"95811 (ASV)"
    },
    {
        id : "95811_avaps",
        label :"95811 (AVAPS)"
    },
    {
        id : "95811_oacpap",
        label :"95811 (Oral Appliance/CPAP)"
    },
    {
        id : "95805_mslt",
        label :"95805 (MSLT)"
    },
    {
        id : "95805_mwt",
        label :"95805 (MWT)"
    }
]

export const PST_CPT_CODES = [
    {
        id : "95782_npsg",
        label :"95782 (NPSG)"
    },
    {
        id : "95810_npsg_medicaid",
        label :"95810 (NPSG for Medicaid patients)"
    },
    {
        id : "95783_cpap",
        label :"95783 (CPAP)"
    },
    {
        id : "95783_bipap",
        label :"95783 (BiPAP)"
    },
    {
        id : "95811_cpap_medicaid",
        label :"95811 (CPAP for Medicaid patients)"
    },
    {
        id : "95811_bipap_medicaid",
        label :"95811 (BiPAP for Medicaid patients)"
    },
]

export const DME_CPT_CODES = [
    {
        id : "a7030_ffm",
        label :"A7030 (Full Face Mask - 1 Per 30 days)"
    },
    {
        id : "a7031_ffmc",
        label :"A7031 (Full Face Mask Cushion - 1 per 30 days)"
    },
    {
        id : "a7032_nmc",
        label :"A7032 (Nasal Mask Cushion - 2 per 30 days)"
    },
    {
        id : "a7033_npc",
        label :"A7033 (Nasal Pillow Cushion - 2 per 30 days)"
    },
    {
        id : "a7034_nm",
        label :"A7034 (Nasal Mask - 2 per 30 days)"
    },
    {
        id : "a7035_hg",
        label :"A7035 (Headgear -1 per 6 months)"
    },
    {
        id : "a7036_cs",
        label :"A7036 (Chinstrap - 1 per 6 months)"
    },
    {
        id : "a7037_nht",
        label :"A7037 (DS Non-Heated Tubing - 1 per 90 days)"
    },
    {
        id : "a7038_df",
        label :"A7038 (DS Disposable Filter - 2 per 30 days - 1 unit)"
    },
    {
        id : "a7038_df5",
        label :"A7038 (DS Disposable Filter - 2 per 30 days - 5 units))"
    },
    {
        id : "a4739_rf",
        label :"A4739 (DS Reusable Filter - 1 per 6 months)"
    },
    {
        id : "a7046_whp",
        label :"A7046 (Water Chamber Prototype - 1 per 6 months)"
    },
    {
        id : "a9279_mmd",
        label :"A9279 (Modem/Monitoring Device)"
    },
    {
        id : "94660_rtf",
        label :"94660 (Respiratory Therapist Fee)"
    },
    {
        id : "E0562_hh",
        label :"E0562 (Heated Humidifier)"
    },
]

export const PAPM_CPT_CODES = [
    {
        id : "e0601_cpap",
        label :"E0601 (APAP or CPAP)"
    },
    {
        id : "e0470_bipap",
        label :"E0470 (BiPAP)"
    },
    {
        id : "e0471_bipapst",
        label :"E0471 (BiPAP ST or ASV)"
    },
    {
        id : "e0466_avaps",
        label :"E0466 (AVAPS)"
    },
]