// getInfo API
export const getInfoData = async (axios, paramsName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/getInfo.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// profile post API
export const profilePost = async (axios, paramsName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/profile.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// profile get API
export const profileGetData = async (axios) => {
  try {
    const { data } = await axios({
      url: "/api/profile.php",
      method: "get",
    });
    return data;
  } catch (error) {
    throw error;
  }
};
// patients get API
export const patientsGetData = async (axios) => {
  try {
    const { data } = await axios({
      url: "/api/patients.php",
      method: "get",
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// patients pagination get API
export const patientsPaginationGetData = async (axios, paramsName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/patients.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// study post API
export const studyPostData = async (axios, paramsName, payload) => {
  try {
    const data = await axios({
      url: "/api/scheduling.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// reset password API
export const resetPasswordPostData = async (axios, paramsName, payload) => {
  try {
    const data = await axios({
      url: "/api/resetPassword.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// results post API
export const resultsPostData = async (axios, paramsName, payload) => {
  try {
    const data = await axios({
      url: "/api/results.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// patients post API
export const patientsPostData = async (
  axios,
  paramsName,
  payload,
  fileCategoryId
) => {
  try {
    const data = await axios({
      url: "/api/patients.php",
      method: "post",
      params: {
        [paramsName]: 1,
        // fileCategoryId
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// admin post API
export const adminPostData = async (axios, paramsName, payload, practiceId) => {
  try {
    const data = await axios({
      url: "/api/admin.php",
      method: "post",
      params: {
        [paramsName]: 1,
        practiceId,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// admin get API
export const adminsGetData = async (axios) => {
  try {
    const { data } = await axios({
      url: "/api/admin.php",
      method: "get",
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// dashboard get API
export const dashboardGetData = async (axios) => {
  try {
    const { data } = await axios({
      url: "/api/dashboard.php",
      method: "get",
    });
    return data;
  } catch (error) {
    throw error;
  }
};

//  pulldown API
export const pulldownsPostData = async (
  axios,
  paramsName,
  payload,
  isPatientPortal
) => {

  try {
    const data = await axios({
      url: "/api/pulldowns.php",
      method: "post",
      params: isPatientPortal
        ? {
            [paramsName]: 1,
            patientPortal: 1,
            payload,
            
          }
        : {
            [paramsName]: 1,
            ...payload
          },
      data: isPatientPortal
        ? {
            patientPortal: 1,
          }
        : payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
// pull down with additional params
export const pulldownsPostWithId = async ({ axios, paramsName, groupId }) => {
  try {
    const data = await axios({
      url: "/api/pulldowns.php",
      method: "post",
      params: { [paramsName]: 1, groupId },
    });
    return data;
  } catch (error) {
    throw error;
  }
};
// new survey post API
export const surveyPostData = async (axios, paramsName, payload) => {
  try {
    const data = await axios({
      url: "/api/survey.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// new order post API
export const orderPostData = async (axios, paramsName, payload) => {
  try {
    const data = await axios({
      url: "/api/order.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// report get api
export const reportsGetData = async (axios, reportName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/reports.php",
      method: "post",
      params: {
        reportName: reportName,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// getReports API
export const getReports = async (axios) => {
  try {
    const { data } = await axios({
      url: "/api/getReports.php",
      method: "get",
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// scheduling post API
export const schedulingPostData = async (axios, paramsName, payload) => {
  try {
    const data = await axios({
      url: "/api/scheduling.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// patient-view API
export const patientViewGetData = async (
  axios,
  paramsName,
  patientId,
  orderId,
  studyId,
  dmeOrderId,
  fileCategoriesPulldown
) => {
  try {
    // console.log("fileCategoriesPulldown=>",fileCategoriesPulldown);
    const { data } = await axios({
      url: "/api/patientView.php",
      method: "get",
      params: orderId
        ? {
            [paramsName]: 1,
            patientId,
            orderId,
            studyId,
            dmeOrderId,
            // fileCategoriesPulldown:1
          }
        : {
            [paramsName]: 1,
            patientId,
            studyId,
            dmeOrderId,
            // fileCategoriesPulldown:1
          },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// patient-view post API
export const patientViewPostData = async (
  axios,
  paramsName,
  payload,
  patientId
) => {
  try {
    const { data } = await axios({
      url: "/api/patientView.php",
      method: "post",
      params: {
        [paramsName]: 1,
        patientId,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};


// webForms get API

export const webFormsGetData = async (axios, studyId, paramsName, orderId) => {
  try {
    const { data } = await axios({
      url: "/api/webForms.php",
      method: "get",
      params: paramsName
        ? {
            [paramsName]: 1,
            studyId,
            orderId
          }
        : ""
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// visits get API

export const visitsGetData = async (axios, patientId, paramsName) => {
  try {
    const { data } = await axios({
      url: "/api/visits.php",
      method: "get",
      params: paramsName
        ? {
            [paramsName]: 1,
            patientId,
          }
        : {
            patientId,
          },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const visitGetNewData = async (
  axios,
  paramsName,
  patientId,
  payload,
  studyId
) => {
  try {
    const { data } = await axios({
      url: "/api/viewVisit.php",
      method: "get",
      params: {
        [paramsName]: 1,
        // patientId,
        // studyId
      },
      data: payload,
      // data:{patientId}
      // params:
      // paramsName ? {
      //   [paramsName]: 1,
      //   patientId
      // } : {
      //   patientId
      // },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const visitsGetStudyData = async (axios, paramsName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/studyInfo.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload
    });
    return data;
  } catch (error) {
    throw error;
  }
};


// post visits

export const visitViewPostData = async (axios, paramsName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/viewVisit.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// studies.php API

export const studiesPostData = async (axios, payload, paramsName) => {
  try {
    const { data } = await axios({
      url: "/api/studies.php",
      method: "post",
      params: paramsName && {
        [paramsName]: 1,
        // patientId,
        // studyId
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// contact us API
export const contsctUsPost = async (axios, paramsName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/contact.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// dme order api
export const dmeOrderPostData = async (axios, paramsName, payload) => {
  try {
    const data = await axios({
      url: "/api/dmeOrder.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// report post api
export const documentFormData = async (axios, paramsName,payload) => {
  try {
    const { data } = await axios({
      url: "/api/documentForms.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// webform post api

// admin post API
export const webformPostData = async (axios, paramsName, payload) => {
  try {
    const data = await axios({
      url: "/api/webForms.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};


//dashboard column filter api
export const columnFilterData = async (axios, paramsName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/filters.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};


export const getHeaderData = async (axios, paramsName, payload) => {
  try {
    const { data } = await axios({
      url: "/api/header.php",
      method: "post",
      params: {
        [paramsName]: 1,
      },
      data: payload,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getDecryptedData = async (axios, encryptedValue) => {
  try {
    const { data } = await axios({
      url: `/api/decrApi.php?encryptedData=${encryptedValue}`,
      method: "post",
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getDashboardData = async (axios, payload) => {
  try {
    const { data } = await axios({
      url: `/api/getActionInfo.php`,
      method: "post",
      data: payload
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRefreshPreviligeData = async (axios, payload) => {
  try {
    const { data } = await axios({
      url: `/api/refreshPrivilges.php`,
      method: "post",
      data: payload
    });
    return data;
  } catch (error) {
    throw error;
  }
};