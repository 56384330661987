import { Box, Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { adminPostData, patientsPostData } from "../../../../axios/api";
import { useAxios } from "../../../../hooks/auth";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../../utils/notification";
import { base64ToPdf } from "../../../../utils/base64ToPDFConvertor";
import FileView from "../../../../pages/patients/components/FileUpload/FileView";

const DocumentTable = ({
  rowDataDocument,
  setViewDocuments = () => {},
  setRefreshData = () => {},
}) => {

  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" });
  const axios = useAxios();

  const documentView = (row) => {
    if (rowDataDocument?.length) {
      const filterRole = rowDataDocument?.filter(
        (item) => item?.documentId === row[0]
      )?.[0];
      setViewDocuments({
        isDocumentOpen: true,
        documentData: { ...filterRole },
      });
    }
  };

  const handleFileView = (rowData) => {
    const flteredData = rowDataDocument?.filter(
      (item) => item?.documentId === rowData[0]
    );
    setOpenPdfModal(true);
    let base64 = flteredData[0]?.fileBase64;
    base64 = base64?.split(",")[1];
    let fileType = flteredData[0]?.fileName;
    fileType = fileType?.split(".")?.pop();
    const pdfUrl = base64ToPdf(base64, fileType);
    setPdfURLState({
      fileUrl: pdfUrl,
      fileType,
      fileName: flteredData[0]?.fileName?.split(".")[0],
    });
  };

  const deleteRecord = (id, tableName, idColumn) => {
    const yesToDelete = window.confirm("Are you sure you want to delete it? ");
    if (!yesToDelete) return;
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    adminPostData(axios, "deleteRecord", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
          setRefreshData((prevState) => !prevState);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
  };

  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    patientsPostData(axios, "downloadFile", payload)
      .then((res) => {
        if (res.data.file) {
          if (isView === "view") {
            setOpenPdfModal(true);
            let base64 = res?.data?.file;
            base64 = base64?.split(",")[1];
            let fileType = res?.data?.name;
            fileType = fileType?.split(".")?.pop();
            const pdfUrl = base64ToPdf(base64, fileType);
            setPdfURLState({
              fileUrl: pdfUrl,
              fileType,
            });
          } else {
            notificationToastSuccess("File is downloading.");
            const downloadLink = document.createElement("a");
            downloadLink.href = res.data.file;
            downloadLink.download = res.data.name;
            downloadLink.click();
          }
        } else {
          notificationToastError("File was not downloaded due to issue.");
        }
        // setRefreshData(!refreshData);
      })
      .catch((err) => notificationToastError(err?.message));
  };

  return (
    <>
      <MUIDataTable
        data={rowDataDocument}
        columns={[
          {
            name: "documentId",
            options: {
              display: false,
              viewColumns: false,
              filter: false,
            },
          },
          {
            name: "title",
            label: "Document/Form Name",
          },
          {
            name: "kind",
            label: "Kind",
          },
          {
            name: "fileName",
            label: "File Name",
          },
          {
            name: "documentType",
            label: "Document/Form  Type",
          },
          {
            name: "groupName",
            label: "Group Name",
          },
          {
            name: "stageName",
            label: "Stage Name",
          },
          {
            name: "locations",
            label: "Locations",
          },

          // {
          //   name: "city",
          //   label: "City",
          // },
          // {
          //   name: "state",
          //   label: "State",
          // },
          // {
          //   name: "zip",
          //   label: "Zip",
          // },
          // {
          //   name: "phone",
          //   label: "Phone",
          // },
          // {
          //   name: "fax",
          //   label: "Fax",
          // },
          // {
          //   name: "groupName",
          //   label: "Group",
          // },
          // {
          //   name: "address2",
          //   options: {
          //     display: false,
          //     viewColumns: false,
          //     filter: false,
          //   },
          // },
          // {
          //   name: "groupId",
          //   options: {
          //     display: false,
          //     viewColumns: false,
          //     filter: false,
          //   },
          // },
          {
            name: "Actions",
            label: "Actions",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <>
                    {tableMeta.rowData[2] !== " Form " && (
                      <Button
                        variant="contained"
                        onClick={download.bind(
                          null,
                          tableMeta.rowData ? tableMeta.rowData[0] : "",
                          "documents",
                          "documentId",
                          "view"
                        )}
                      >
                        View
                      </Button>
                    )}
                    <Box>
                      <Button
                        variant="contained"
                        onClick={documentView.bind(null, tableMeta.rowData)}
                        sx={{ marginTop: "1rem" }}
                      >
                        Edit
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        sx={{ marginTop: "1rem" }}
                        variant="contained"
                        onClick={deleteRecord.bind(
                          null,
                          tableMeta.rowData ? tableMeta.rowData[0] : "",
                          "documents",
                          "documentId"
                        )}
                      >
                        Delete
                      </Button>
                    </Box>
                  </>
                );
              },
            },
          },
        ]}
        options={{
          selectableRows: 'none', // <===== will turn off checkboxes in rows
          responsive: "vertical",
          // tableBodyHeight:rowDataLocations?.length && "calc(100vh - 200px)",
        }}
      />

      {openPdfModal && (
        <FileView
          setOpenPdfModal={setOpenPdfModal}
          openPdfModal={openPdfModal}
          pdfURLState={pdfURLState}
          isPatientPortal={true}
        />
      )}
    </>
  );
};

export default DocumentTable;
