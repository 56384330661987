import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { useAxios } from "../../../../hooks/auth";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./fileUpload.module.css";
import axios from "axios";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../../utils/notification";
import { resultsPostData } from "../../../../axios/api";
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
function InterpFileUpload({
  patientId = "",
  orderId = "",
  studyId = "",
  surveyId = "",
  refreshData = false,
  setRefreshData = () => {},
  setViewCloseDialogState,
  notificationToastSuccess,
  notificationToastError,
  email = "",
  cellPhone = "",
  patientName="",
  apptLocationName="",
  isPreviousVisits=false
}) {
  
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const axios = useAxios();
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    const fileSizeInMegabytes = selectedFile?.size / (1024 * 1024);
    if (patientId) {
      if (fileSizeInMegabytes < 10 && selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            setUploadProgress(progress);
          }
        };
        reader.onloadend = () => {
          if (reader.result) {
            const payload = {
              file: reader.result,
              fileName: selectedFile?.name,
              patientId,
              orderId,
              studyId,
              surveyId,
              cellPhone,
              email,
              patientName,
              apptLocationName,
            };
            resultsPostData(axios, "interpUploadFile", payload)
              .then((res) => {
                if (res.data.response === "1") {
                  notificationToastSuccess(res?.data?.message);
                  setRefreshData(!refreshData);
                  setTimeout(() => {
                    setViewCloseDialogState(false);
                  }, 2000);
                } else {
                  notificationToastError(res?.data?.message);
                }
              })
              .catch((err) => notificationToastError(err?.message));
          }
        };
      } else {
        notificationToastError("File size must be less than 10 MB");
        setSelectedFile(null);
        setUploadProgress(0);
      }
    } else {
      notificationToastError("Patient is not selected, please select patient");
      setSelectedFile(null);
      setUploadProgress(0);
    }
  };
  return (
    <Card>
      <CardContent className={styles.fileUploadWraper}>
        <Button
          variant="contained"
          component="label"
          sx={{ minWidth: "125px" }}
          disabled={isPreviousVisits}
        >
          Attach File
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
        <Box className={styles.progressBarWrapper}>
          {uploadProgress > 0 && (
            <LinearProgressWithLabel
              sx={{ height: "20px", borderRadius: "20px" }}
              value={uploadProgress}
            />
          )}
        </Box>

        {selectedFile && (
          <Box className={styles.fileNameWrapper}>
            <Typography color="primary">{selectedFile?.name}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default InterpFileUpload;
