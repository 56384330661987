export const isMaskInterface = (formState) => {
    return (
      formState.A7034 ||
      formState.A7032 ||
      formState.A7030 ||
      formState.A7031 ||
      formState.allItemMasked
    );
  };
  
  export const isInterfaceSupplies = (formState) => {
    return (
      formState.All ||
      formState.A7035 ||
      formState.G47_31 ||
      formState.A7037 ||
      formState.A4604 ||
      formState.E0562 ||
      formState.A7046 ||
      formState.A7038 ||
      formState.A7039 ||
      formState.A9279 ||
      formState.A7036 ||
      formState.RT94660
    );
  };
  
  export const isDiagnosisCode = (formState) => {
    return (
      formState.G47_33 ||
      formState.G47_34 ||
      formState.OTHER ||
      formState.G47_31 ||
      formState.I50_20 ||
      formState.J44_0 ||
      formState.J43_1
    );
  };