import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useAxios } from "../hooks/auth";
import ConfirmDialog from "../components/misc/Dialog";
import {

  Alert,
} from "../../node_modules/@mui/material/index";
import { notificationToastError, notificationToastSuccess } from "../utils/notification";
import { patientsPostData } from "../axios/api";

function AddCarrier({
  planName,
  handleClose,

}) {
  useEffect(() => {
    setFormState({ ...formState, planName: planName });
  }, [planName]);
  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [formState, setFormState] = useState({});
  const [formErrorState, setFormErrorState] = useState({
    planName: "",
  });

  const axios = useAxios();

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false)
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
  };

  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      notificationToastError("Please fill all the required fields")
      setFormErrorState(errors);
      return false;
    }
    const payload = {
      planName: formState.planName
    }
    patientsPostData(axios, "saveCarrierInfo", payload)
      .then((res) => {
        if (res.data.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
        handleClose();
      })
      .catch((err) => {notificationToastError(err?.message) 
         handleClose();
        });
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  return (
    <ConfirmDialog
      handleClose={handleClose}
      confirmButton={{ action: saveChanges, title: "Save changes" }}
      isSaveButtonDisabled={isSaveButtonDisabled}
    >
      <TextField
        id="planName"
        label="Insurance Carrier *"
        value={formState.planName || ""}
        variant="standard"
        onChange={updateInput}
        error={formErrorState.planName?.error && !formState.planName}
        helperText={
          formErrorState.planName?.error && !formState.planName ? "required" : ""
        }
        onBlur={validateEmptyField.bind(null, "planName")}
      />




    </ConfirmDialog>
  );
}

export default AddCarrier;
