import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Typography,
} from "@mui/material";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData } from "../../axios/api";

function StudyTypePulldown({
  formState,
  updateInput,
  formErrorState,
  attributeName,
  isPreviousVisits = false,
  isPatientPortal = false,
  req = "*",
  ...remaining
}) {
  const [locations, setLocations] = useState([]);
  const axios = useAxios();

  useEffect(() => {
    async function setDataFirstTime() {
      let payload = {};
      if (isPatientPortal) {
        payload = {
          patientPortal: 1,
        };
      }
      pulldownsPostData(axios, "StudyTypePulldown", payload)
        .then((res) => {
          setLocations(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setDataFirstTime();
  }, []);

  return (
    <>
      <FormControl
        variant="standard"
        sx={{ mt: 1, mb: 1, minWidth: 120, width: "100%" }}
        {...remaining}
      >
        <InputLabel
          id="location-label"
          error={formErrorState?.studyTypeId?.error && !formState?.studyTypeId}
        >
          Select Study Type {req}
        </InputLabel>
        <Select
          labelId="location-label"
          id={attributeName}
          name={attributeName}
          value={formState[attributeName] || ""}
          label="studyTypeId"
          onChange={updateInput}
          required
          error={
            formErrorState[attributeName]?.error && !formState[attributeName]
          }
          inputProps={{ readOnly: isPreviousVisits }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {locations.map((type) => (
            <MenuItem value={type.studyTypeId} key={type.studyTypeId}>{type.studyType}</MenuItem>
          ))}
        </Select>

        {formErrorState?.studyTypeId?.error && !formState?.studyTypeId && (
          <Typography variant="subtitle2" color={"error"}>
            required
          </Typography>
        )}
      </FormControl>
    </>
  );
}

export default StudyTypePulldown;
