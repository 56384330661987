import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import PAPDeviceFollow from "../../components/patientsComponent/dmeOrder/PAPDeviceFollow";
import {
  FormControl,
  Autocomplete,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import FollowingPhysicianPulldown from "./FollowingPhysicianPulldown";
import { SNAutocomplete } from "./SNAutocomplete";
import { resultsPostData } from "../../axios/api";
import ReschedulePopup from "./ReschedulePopup";

export default function ResultsFollowupDialog({
  handleSave,
  handleClose = () => {},
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  isCurrentVisits=false,
  resultFollowupData = {},
  notShowPopup
}) {

  const [formErrorState, setFormErrorState] = useState({ papDevice: false });

  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [refreshData, setRefreshData] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  React.useEffect(() => {
   
    if (row?.length) {
      setFormState({
        resultsFollowup: row[158],
        orderOtherTreatment: row[159],
        patientId: row[0],
        orderId: row[11],
        surveyId: row[10],
        stageId: row[95],
        prefInterpPhysId: row[103],
        locationId: row[102],
        studyId: row[106],
      });
    } else if (resultFollowupData) {
      setFormState({ ...formState, ...resultFollowupData });
    }
  }, [row]);

  let selectedStudyType = "";

  if (resultFollowupData ) {

  if (formState["2nightProtocol"]) {
    selectedStudyType = "2nightProtocol";
  } else if (formState?.psgBaselineStudy) {
    selectedStudyType = "psgBaselineStudy";
  } else if (formState?.homeSleepTest) {
    selectedStudyType = "homeSleepTest";
  } else if (formState?.cpapTitration) {
    selectedStudyType = "cpapTitration";
  } else if (formState?.bipapTitration) {
    selectedStudyType = "bipapTitration";
  } else if (formState?.bipapStTitration) {
    selectedStudyType = "bipapStTitration";
  } else if (formState?.AsvTitration) {
    selectedStudyType = "AsvTitration";
  } else if (formState?.AvapsTitration) {
    selectedStudyType = "AvapsTitration";
  } else if (formState?.pediatricSleepStudy) {
    selectedStudyType = "pediatricSleepStudy";
  }
  
}

  if (selectedStudyType) {
    setSelectedCheckbox(selectedStudyType);
  }

  const updateInput = (e) => {
    if (formErrorState?.papDevice?.error) {
      setFormErrorState({
        ...formErrorState,
        papDevice: { error: false },
      });
    }

    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
    setIsSaveButtonDisabled(false);
  };

  const handleCheckboxChange = (event) => {
  
    const checkboxId = event.target.id || event.target.name;
   if (selectedCheckbox === checkboxId) {
      setSelectedCheckbox("");
    } else {
      setSelectedCheckbox(checkboxId);
    }
 
    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  
  };

  const handleMainCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    formState["followupSleepSpecialist"] = 0;
    formState["autoCPAP"] = 0;
    formState["dmeOrder"] = 0;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
   

    if (formState.dmeOrder) formErrorState.papDevice = { error: false };
    else delete formErrorState.papDevice;

    const errors = { ...formErrorState };

    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }
  

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
    const payload = {
      ...formState,
      consultingPhysician: formState.prefInterpPhysId,
    };

    resultsPostData(axios, "saveResultsFollowup", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    newValue && setSHIP(newValue.format("YYYY-MM-DD"));
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    
    newValue && setSDATE(newValue.format("YYYY-MM-DD"));
  };

  let patientNameVal;
  if (row) {
    patientNameVal = row[2];
  } else {
    patientNameVal = formState?.patientNameVal;
  }

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <Box>
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6" data-testid="appoinment-text">
              {" "}
              {!asDialog
                ? "Review interpretation and select recommendations for followup :"
                : " Review interpretation and select recommendations for followup : "}
            </Typography>
          </FormControl>
        </div>
        <Box sx={{ marginTop: "1rem" }}>
        {!notShowPopup && <ReschedulePopup row={row} />}
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <Grid item lg={6} md={5} sm={12} xs={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <Box
                component="div"
                sx={{
                  "& .MuiFormControl-root": { flexGrow: 1 },
                  display: "flex",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="autoCPAP"
                      checked={Boolean(formState.autoCPAP)}
                      // onChange={updateCheckBox}
                      //  checked={selectedCheckbox === "autoCPAP"}
                      onChange={handleMainCheckboxChange}
                    //  disabled={isPreviousVisits || isCurrentVisits}
                    
            
                    />
                  }
                  label="Sleep Study"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      id="dmeOrder"
                      checked={Boolean(formState.dmeOrder)}
                      //onChange={updateCheckBox}
                      onChange={handleMainCheckboxChange}
                      disabled={isPreviousVisits}
                    />
                  }
                  label="DME order"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="followupSleepSpecialist"
                      checked={Boolean(formState.followupSleepSpecialist)}
                      //onChange={updateCheckBox}
                      onChange={handleMainCheckboxChange}
                      disabled={isPreviousVisits}
                    />
                  }
                  label="Followup with Sleep Specialist"
                />
              </Box>
              {formState?.autoCPAP == 1 && (
                <Box sx={{ marginTop: "2rem" }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="2nightProtocol"
                          // checked={twoNightProtocolCheckBox}
                          // onChange={() => {
                          //   setTwoNightProtocolCheckBox(!twoNightProtocolCheckBox);
                          //   setIsSaveButtonDisabled(false);

                          // }}
                          checked={selectedCheckbox === "2nightProtocol"}
                          onChange={handleCheckboxChange}
                          disabled={isPreviousVisits || isCurrentVisits}
                        />
                      }
                      label="2 NIGHT PROTOCOL - 1ST Polysomnogram (PSG) THEN 2nd night CPAP trial, if indicated (95810/95811)"
                    />
                  </Box>

                  {selectedCheckbox === "2nightProtocol" && (
                    <Box>
                      <FormControlLabel
                        sx={{ paddingLeft: "2rem" }}
                        value="SUBSTITUTE HST IF IN LAB DENIED"
                        control={
                          <Checkbox
                            id="2nightHSTSubstitute"
                            checked={Boolean(formState["2nightHSTSubstitute"])}
                            onChange={updateCheckBox}
                          />
                        }
                        label="SUBSTITUTE HST IF IN LAB DENIED"
                      />
                    </Box>
                  )}

                  <Box sx={{ marginTop: "1rem" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="splitNightProtocol"
                          checked={
                            selectedCheckbox === "splitNightProtocol" ||
                            Boolean(formState.splitNightProtocol)
                          }
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="SPLIT NIGHT PROTOCOL - 1ST night SPLIT - if PAP not initiated, THEN 2nd night CPAP/BiPAP (95810/95811)"
                    />
                  </Box>

                  {selectedCheckbox === "splitNightProtocol" && (
                    <Box>
                      <FormControlLabel
                        sx={{ paddingLeft: "2rem" }}
                        value="SUBSTITUTE HST IF IN LAB DENIED"
                        control={
                          <Checkbox
                            id="splitHSTSubstitute"
                            checked={Boolean(formState.splitHSTSubstitute)}
                            onChange={updateCheckBox}
                          />
                        }
                        label="SUBSTITUTE HST IF IN LAB DENIED"
                      />
                    </Box>
                  )}
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="psgBaselineStudy"
                          checked={selectedCheckbox === "psgBaselineStudy"}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="PSG baseline study (95810)"
                    />
                  </Box>

                  {selectedCheckbox === "psgBaselineStudy" && (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="psgOptions"
                      id="psgOptions"
                      sx={{ paddingLeft: "2rem" }}
                      value={formState?.psgOptions}
                      onChange={updateInput}
                    >
                      <Box>
                        <FormControlLabel
                          value="SUBSTITUTE HST IF IN LAB DENIED OR,"
                          control={<Radio />}
                          label="SUBSTITUTE HST IF IN LAB DENIED OR,"
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="PSG with MSLT (Multiple Sleep Latency Test) 95805"
                          control={<Radio />}
                          label="PSG with MSLT (Multiple Sleep Latency Test) 95805"
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="PSG with MWT (Maintenance of Wakefulness Test) 95805"
                          control={<Radio />}
                          label="PSG with MWT (Maintenance of Wakefulness Test) 95805"
                        />
                      </Box>
                    </RadioGroup>
                  )}
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="homeSleepTest"
                          checked={selectedCheckbox === "homeSleepTest"}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="HOME SLEEP TEST"
                    />
                  </Box>

                  {selectedCheckbox === "homeSleepTest" && (
                    <Box sx={{ paddingLeft: "2rem" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        name="hstNights"
                        id="hstNights"
                        onChange={updateInput}
                        value={formState?.hstNights}
                        sx={{
                          flexDirection: "row",
                        }}
                      >
                        <Box>
                          <FormControlLabel
                            value="ONE NIGHT"
                            control={<Radio />}
                            label="ONE NIGHT"
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            value="TWO NIGHT"
                            control={<Radio />}
                            label="TWO NIGHT"
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            value="THREE NIGHT"
                            control={<Radio />}
                            label="THREE NIGHT"
                          />
                        </Box>
                      </RadioGroup>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="cpapIfHstAbnormal"
                              checked={Boolean(formState.cpapIfHstAbnormal)}
                              onChange={updateCheckBox}
                            />
                          }
                          sx={{ marginLeft: "2rem" }}
                          label="IF abnormal HST then CPAP titration (95811)"
                        />
                      </Box>
                    </Box>
                  )}
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="cpapTitration"
                          checked={
                            selectedCheckbox === "cpapTitration" ||
                            Boolean(formState.cpapTitration)
                          }
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="CPAP titration (95811)"
                    />
                  </Box>
                  {selectedCheckbox === "cpapTitration" && (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="cpapOptions"
                      value={formState?.cpapOptions}
                      onChange={updateInput}
                      sx={{ paddingLeft: "2rem" }}
                    >
                      <Box>
                        <FormControlLabel
                          value="MSLT (Multiple Sleep Latency Test) 95805"
                          control={<Radio />}
                          label="MSLT (Multiple Sleep Latency Test) 95805"
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="MWT (Maintenance of Wakefulness Test) 95805"
                          control={<Radio />}
                          label="MWT (Maintenance of Wakefulness Test) 95805"
                        />
                      </Box>
                    </RadioGroup>
                  )}
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="bipapTitration"
                          checked={selectedCheckbox === "bipapTitration"}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="BiPAP titration (95811)"
                    />
                  </Box>
                  {selectedCheckbox === "bipapTitration" && (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="bipapOptions"
                      value={formState?.bipapOptions}
                      onChange={updateInput}
                      sx={{ paddingLeft: "2rem" }}
                    >
                      <Box>
                        <FormControlLabel
                          value="MSLT (Multiple Sleep Latency Test) 95805"
                          control={<Radio />}
                          label="MSLT (Multiple Sleep Latency Test) 95805"
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="MWT (Maintenance of Wakefulness Test) 95805"
                          control={<Radio />}
                          label="MWT (Maintenance of Wakefulness Test) 95805"
                        />
                      </Box>
                    </RadioGroup>
                  )}
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="bipapStTitration"
                          checked={selectedCheckbox === "bipapStTitration"}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="BiPAP ST titration (95811)"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="AsvTitration"
                          checked={selectedCheckbox === "AsvTitration"}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ASV titration (95811)"
                    />
                  </Box>

                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="AvapsTitration"
                          checked={selectedCheckbox === "AvapsTitration"}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="AVAPS titration (95811)"
                    />
                  </Box>
                </Box>
              )}
              {formState?.dmeOrder == 1 && (
                <Grid item xl={5.95} lg={5.9} md={5.9} xs={12} sm={12}>
                  <PAPDeviceFollow
                    updateInput={updateInput}
                    errorState={formErrorState?.papDevice}
                    isPreviousVisits={isPreviousVisits}
                    isCurrentVisits={isCurrentVisits}
                  />
                </Grid>
              )}
            </FormControl>
          </Grid>
        </Box>
        {!asDialog && !isPreviousVisits && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Box>
    );
  }
}
