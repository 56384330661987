import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import { Button, FormControl, Typography, Tooltip } from "@mui/material";
import { Download, Delete, Visibility } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import FollowingPhysicianPulldown from "./FollowingPhysicianPulldown";
import { resultsPostData, patientsPostData } from "../../axios/api";
import ReschedulePopup from "./ReschedulePopup";

export default function InterpretationAvailableDialog({
  handleSave,
  handleClose = () => {},
  row,
  discussResults = false,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  patientResultInfo = {},
  notShowPopup,
  isWellnecessitiesUser
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [refreshData, setRefreshData] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  let title;

  if (discussResults) {
    title = "Discuss Results with Patient Info:";
  } else {
    title = "Interpretation Available: Referred to Sleep Specialist";
  }

  const [studyInfoFormState, setStudyInfoFormState] = useState({});
  React.useEffect(() => {
    if (row?.studyIdStudy) {
      setFormState({
        physicianId: row?.physicianIdStudy,
        patientId: row?.patientId,
        orderId: row?.orderIdOrder,
        surveyId: row?.surveyIdSurvey,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        // SDATE: row[99],
        locationId: row?.locationIdStudy,
        SHIP: row?.shipDate,
        studyId: row?.studyIdStudy,
        parentOrderCpapId: row?.parentOrderCpapId,
        parentOrderId: row?.parentOrderId,
        dateResultDiscussed: row?.dateResultDiscussed,
        interpDownloadedCompleted: row?.interpDownloaded
      });

      if (row?.orderIdOrder) {
        const payload = {
          orderId: row?.orderIdOrder
        };

        resultsPostData(axios, "getResultStudy", payload)
          .then((res) => {
            if (res?.data) {
              const dateOfInterpretation = res.data[0]["dateOfInterpretation"];
              const studyDate = res.data[0]["date"];
              const studyId = res.data[0]["studyId"];
              const fileId = res.data[0]["fileId"];

              setStudyInfoFormState({
                dateOfInterpretation,
                studyDate,
                studyId,
                fileId,
              });
            } else {
            }
          })
          .catch((err) => {
            notificationToastError(err?.message);
          });
      }
    } else if (patientResultInfo) {
      setFormState({ ...formState, ...patientResultInfo });
      setSDATE(patientResultInfo?.SDATE);
      setSHIP(patientResultInfo?.SHIP);
      if(patientResultInfo.orderId){
        resultsPostData(axios, "getResultStudy", {orderId : patientResultInfo.orderId})
          .then((res) => {
            if (res?.data) {
              const dateOfInterpretation = res.data[0]["dateOfInterpretation"];
              const studyDate = res.data[0]["date"];
              const studyId = res.data[0]["studyId"];
              const fileId = res.data[0]["fileId"];

              setStudyInfoFormState({
                dateOfInterpretation,
                studyDate,
                studyId,
                fileId,
              });
            } else {
            }
          })
          .catch((err) => {
            notificationToastError(err?.message);
          });
      }
    }
  }, [row]);


  // Download file
  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
      patientId: formState?.patientId
    };
    patientsPostData(axios, "downloadFile", payload)
      .then((res) => {
        if (res.data.file) {
          notificationToastSuccess("File is downloading.");
          const downloadLink = document.createElement("a");
          downloadLink.href = res.data.file;
          downloadLink.download = res.data.name;
          downloadLink.click();
          if (discussResults) {
          } else {
            const payload = {
              studyId: studyInfoFormState?.studyId,
              interpDownloaded: 1,
              stageId: formState?.stageId,
              dateResultDiscussed: formState?.dateResultDiscussed,
              orderId: formState?.orderId,
              patientId: formState?.patientId
            };

            resultsPostData(axios, "saveDiscussResults", payload)
              .then((res) => {
                if (res?.data?.response === "1") {
                  notificationToastSuccess(res?.data?.message);
                } else {
                  notificationToastError(res?.data?.message);
                }
              })
              .catch((err) => {
                notificationToastError(err?.message);
              });
            notificationToastSuccess("success");
            setTimeout(() => {
              setViewCloseDialogState(false);
            }, 2000);
          }
        } else {
          notificationToastError("File was not downloaded due to issue.");
        }
        // setRefreshData(!refreshData);
      })
      .catch((err) => notificationToastError(err?.message));
  };

  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        isValid = false;
        errors[key] = { error: true };
      }
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);

    const payload = {
      ...formState,
      dateResultDiscussed: formState.SDATE,
      
    };

    resultsPostData(axios, "saveDiscussResults", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSHIP(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, SHIP: newValue.format("YYYY-MM-DD") });
  };

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSDATE(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, SDATE: newValue.format("YYYY-MM-DD") });
  };
  const patientNameVal = row?.patientName ?? formState?.patientNameVal;

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog, title)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog, title);
  }

  function renderForm(asDialog, title) {
    return (
      <>
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <b>{title}</b>
          </FormControl>
        </div>
        <FormControl sx={{ m: 1 }}>
          {!notShowPopup && <ReschedulePopup row={row} dos="Date of Study: " />}
        </FormControl>
        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          
          <FormControl sx={{ marginLeft: 1 }}>
            <Typography>
              {" "}
              Date of Interpretation:{" "}
              {studyInfoFormState?.dateOfInterpretation &&
                moment(studyInfoFormState?.dateOfInterpretation).format(
                  "MM/DD/YYYY"
                )}{" "}
            </Typography>
          </FormControl>
        </Box>
        {discussResults && (
          
        <Box
        component="div"
        sx={{
          "& .MuiFormControl-root": { flexGrow: 1 },
          display: "flex",
        }}
      >
          <FormControl sx={{ m: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date Results discussed with Patient"
                inputFormat="MM/DD/YYYY"
                value={SDATE || ""}
                onChange={updateSDATE}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={errorSDATE && !SDATE}
                    helperText={errorSDATE && !SDATE ? "required" : ""}
                    value={
                      SDATE
                        ? moment
                            .utc(SDATE.toLocaleString())
                            .format("MM/DD/YYYY")
                        : ""
                    }
                    onBlur={() => {
                      setErrorSDATE(!SDATE);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
          </Box>
        )}

        <Box
          component="div"
          sx={{
            "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
          }}
        >
          <Tooltip title="Download" placement="bottom">
            <Button
              sx={{ marginTop: "2rem" }}
              variant="contained"
              size="small"
              onClick={download.bind(
                null,
                studyInfoFormState?.fileId,
                "storedFiles",
                "fileId"
              )}
            >
              Download Interpretation
              <Download />
            </Button>
          </Tooltip>
        </Box>

        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </>
    );
  }
}
