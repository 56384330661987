import { useAuth } from "./auth";
import roleAndPrivilegesFnc from "../utils/roleAndPriviledges";
function useIsPrevileges() {
  const auth = useAuth();
  if (auth) {
    const { roleAndPriviledge } = roleAndPrivilegesFnc(auth?.cookies?.token);

    return roleAndPriviledge;
  }
}

export default useIsPrevileges;
