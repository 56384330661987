import TextField from "@mui/material/TextField";
import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./Dialog";
import {
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import ReschedulePopup from "./ReschedulePopup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import StudyTypePulldown from "./StudyTypePulldown";
import { studyPostData, pulldownsPostData } from "../../axios/api";
import GeneralPulldown from "./pulldowns/GeneralPulldown";

import { useDispatch } from "react-redux";
import { setSnackbarMessage } from "../../store/slices/snackBarSlice";

export default function UpdateStudyInfoDialog({
  handleSave,
  handleClose,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess,
  notificationToastError,
  asDialog = true,
  isPreviousVisits = false,
  studyInfoData = {},
  isPatientPortal,
  notShowPopup,
  reloadCurrentVisit,
  isCurrentVisits,
  isWellnecessitiesUser
}) {
  const [formErrorState, setFormErrorState] = useState({});
  const { cookies } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const axios = useAxios();
  const paramNameIn = "name";
  const [deviceReturnDate, setDeviceReturnDate] = useState(null);
  const [deviceReturnDateErr, setDeviceReturnDateErr] = useState(null);
  const [uploadDate, setUploadDate] = useState(null);
  const [uploadDateErr, setUploadDateErr] = useState(null);
  const [deviceDate, setDeviceDate] = useState(null);
  const [deviceDateErr, setDeviceDateErr] = useState(null);

  React.useEffect(() => {
    if (formState?.scheduleSecondAppt == 1) {
      setViewScheduleStudyDialogState(true);
    }
  }, [formState?.scheduleSecondAppt]);

  const [viewScheduleStudyState, setViewScheduleStudyDialogState] =
    useState(undefined);

  const scheduleStudyDialog = () => {
    setViewScheduleStudyDialogState(false);
  };

  const scheduleStudy = (rowData) => {};

  const openScheduleStudyDialog = (rowData) => {
    // autoSelectPatient(rowData[0]);
    setViewScheduleStudyDialogState(rowData);
  };

  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(false);

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const [SDATE, setSDATE] = React.useState(null);
  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const [inputSDATEError, setInputSDATEError] = useState(false);
  const updateSDATE = (newValue) => {
    if (!newValue) {
      setInputSDATEError(false);
      return 'Invalid Date';
    }
    const { $d } = newValue;

    if ($d == "Invalid Date") {
      setInputSDATEError(true);
    } else {
      setInputSDATEError(false);
    }
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSDATE(newValue?.format("YYYY-MM-DD"));
  };

  const [SDATE2, setSDATE2] = React.useState(null);
  const [errorSDATE2, setErrorSDATE2] = React.useState(false);
  const [inputSDATE2Error, setInputSDATE2Error] = useState(false);
  const updateSDATE2 = (newValue) => {
    if (!newValue) {
      setInputSDATE2Error(false);
      return 'Invalid Date';
    }
    const { $d } = newValue;

    if ($d == "Invalid Date") {
      setInputSDATE2Error(true);
    } else {
      setInputSDATE2Error(false);
    }
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSDATE2(newValue?.format("YYYY-MM-DD"));
  };

  const [SDATE3, setSDATE3] = React.useState(null);
  const [errorSDATE3, setErrorSDATE3] = React.useState(false);
  const [inputSDATE3Error, setInputSDATE3Error] = useState(false);
  const updateSDATE3 = (newValue) => {
    if (!newValue) {
      setInputSDATE3Error(false);
      return 'Invalid Date';
    }
    const { $d } = newValue;

    if ($d == "Invalid Date") {
      setInputSDATE3Error(true);
    } else {
      setInputSDATE3Error(false);
    }
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }

    setSDATE3(newValue?.format("YYYY-MM-DD"));
  };

  const [actualStudyTypeCompleted, setActualStudyTypeCompleted] = useState([]);

  React.useEffect(() => {
  
    if (row?.studyIdStudy) {
      setFormState({
        physicianId: row?.physicianIdOrder,
        patientId: row?.patientId,
        dob: row?.dob,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        prefInterpPhysId: row?.prefInterpPhysId,
        studyTypeId: row?.studyTypeId,
        locationId: row?.locationIdStudy,
        SDATE: row?.dateOfService1,
        SDATE2: row?.dateOfService2,
        SDATE3: row?.dateOfService3,
        reasonIncompleteStudyId: row?.reasonIncompleteStudyId,
        studyIncomplete: row?.studyIncomplete,
        studyCompleted: row?.studyCompleted,
        studyId: row?.studyIdStudy,
        statusNameId: row?.statusNameId,
        studyCompletedNotes: row?.studyCompletedNotes,
        studyInfoConfirmed: row?.studyInfoConfirmed,
        hstDeviceId: row?.hstDeviceId,
        hstDeviceIdNight2: row?.hstDeviceIdNight2,
        hstDeviceIdNight3: row?.hstDeviceIdNight3,
        orderingPhysicianName: row?.name,
        patientName: row?.patientName,
        studyTypeCompletedId: row?.studyTypeCompletedId,
        studyTypeId2: row?.studyTypeId2,
        studyCompletedNotes2: row?.studyCompletedNotes2,
        studyTypeCompletedId2: row?.studyTypeCompletedId2,
        studyCompletedNotes3: row?.studyCompletedNotes3,
        studyTypeCompletedId3: row?.studyTypeCompletedId3,
        apptCompleted1: row?.apptCompleted1,
        apptCompleted2: row?.apptCompleted2,
        apptCompleted3: row?.apptCompleted3,
        reasonIncompleteStudyId1: row?.reasonIncompleteStudyId1,
        studyIncomplete1: row?.studyIncomplete1,
        reasonIncompleteStudyId2: row?.reasonIncompleteStudyId2,
        studyIncomplete2: row?.studyIncomplete2,
        reasonIncompleteStudyId3: row?.reasonIncompleteStudyId3,
        studyIncomplete3: row?.studyIncomplete3,
        deviceReturnDate: row?.deviceReturnDate,
      });
      setDeviceReturnDate(row?.deviceReturnDate);
      setUploadDate(row?.uploadDate);
      setDeviceDate(row?.deviceDate);
    } else if (studyInfoData) {
      setFormState({ ...formState, ...studyInfoData });
      setSDATE(studyInfoData?.SDATE);
      setDeviceReturnDate(studyInfoData.deviceReturnDate);
      setUploadDate(studyInfoData.uploadDate);
      setDeviceDate(studyInfoData.deviceDate);
      if (studyInfoData.studyTypeId) {
        let payload1 = {
          studyTypeId: studyInfoData.studyTypeId,
          studyTypeId2: studyInfoData.studyTypeId2,
          dob: moment(studyInfoData.dob).format("MM/DD/YYYY"),
        };
        setDataFirstTime(payload1);
      }
    }

    async function setDataFirstTime(payload) {
      pulldownsPostData(axios, "actualStudyTypeCompleted", payload)
        .then((res) => {
          setActualStudyTypeCompleted(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (row?.studyTypeId) {
      let payload1 = {
        studyTypeId: row?.studyTypeId,
        studyTypeId2: row?.studyTypeId2,
        dob: row?.dob,
      };
      setDataFirstTime(payload1);
    }
    
  }, [row]);

  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  const updateCheckbox = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.checked ? 1 : 0,
    });
    setIsSaveButtonDisabled(false);
  };

  /*
  if (formState?.studyTypeId) {
     let payload1 = {
       studyTypeId: formState?.studyTypeId,
       dob: formState?.dob
     };
     setDataFirstTime(payload1);
  }
*/

  const updateInput = (e) => {
    if (e.target.name == "studyTypeId" || e.target.name == "studyTypeId2") {
      let payload2 = {
        studyTypeId: e.target.value,
        dob: formState.dob,
      };
      async function setDataFirstTime1(payload) {
        pulldownsPostData(axios, "actualStudyTypeCompleted", payload)
          .then((res) => {
            setActualStudyTypeCompleted(res?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setDataFirstTime1(payload2);
    }

    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
    setIsSaveButtonDisabled(false);
  };

  const handleMainCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    formState["studyCompleted"] = 0;
    formState["studyIncomplete"] = 0;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const handleMainCheckboxChange2 = (event) => {
    const checkboxId = event.target.id;

    if (checkboxId === "apptCompleted1") formState["studyIncomplete1"] = 0;
    if (checkboxId === "apptCompleted2") formState["studyIncomplete2"] = 0;
    if (checkboxId === "apptCompleted3") formState["studyIncomplete3"] = 0;
    if (checkboxId === "studyIncomplete1") formState["apptCompleted1"] = 0;
    if (checkboxId === "studyIncomplete2") formState["apptCompleted2"] = 0;
    if (checkboxId === "studyIncomplete3") formState["apptCompleted3"] = 0;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    if (
      !SDATE &&
      !formState?.SDATE &&
      (formState?.studyCompleted === "1" || formState?.studyCompleted === 1)
    ) {
      setErrorSDATE(true);
      formErrorState["studyTypeCompletedId"] = { error: true }
      return false;
    }

    if (
      !SDATE &&
      !formState?.SDATE &&
      (formState?.apptCompleted1 === "1" || formState?.apptCompleted1 === 1)
    ) {
      setErrorSDATE(true);
      formErrorState["studyTypeCompletedId"] = { error: true }
      return false;
    }

    if (!formState?.studyIncomplete1 && formState?.studyTypeId && (actualStudyTypeCompleted?.length !== 0 && !formState?.studyTypeCompletedId) && (!formState?.SDATE || !SDATE)) {
      isValid = false;
      setErrorSDATE(true);
      formErrorState["studyTypeCompletedId"] = { error: true }
    }

    if (formState?.SDATE && !formState?.studyTypeId) {
      isValid = false;
      formErrorState["studyTypeId"] = { error: true };
    }

    if (formState?.SDATE && !formState?.studyTypeCompletedId) {
      isValid = false;
      formErrorState["studyTypeCompletedId"] = { error: true }
    }

    if (!formState?.studyIncomplete2 && formState?.studyTypeId2 && !formState?.studyTypeCompletedId2 && (!formState?.SDATE2 && !SDATE2)) {
      isValid = false;
      formErrorState["studyTypeCompletedId2"] = { error: true }
      setErrorSDATE2(true)
    }

    if ( formState?.apptCompleted2 && ( (formState?.studyTypeId !== 8 && formState?.studyTypeId !== 9 && formState?.studyTypeId !== 10 ) && (!formState?.studyTypeId2 && !formState?.studyTypeCompletedId2 || (!formState?.SDATE2 && !SDATE2) ) ) )  {
      isValid = false;
      formErrorState["studyTypeId2"] = { error: true };
      formErrorState["studyTypeCompletedId2"] = { error: true }
      setErrorSDATE2(true)
    }

    if ( formState?.apptCompleted2 && ( (formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10 ) &&  (!formState?.studyTypeCompletedId2 || ( !formState?.SDATE2 && !SDATE2 ) ) ) ) {
      isValid = false;
      formErrorState["studyTypeId2"] = { error: true };
      formErrorState["studyTypeCompletedId2"] = { error: true }
      setErrorSDATE2(true)
    }

    if (!formState?.studyIncomplete1 && (formState?.hstDeviceId || formState?.SDATE) && !formState.studyTypeCompletedId) {
      isValid = false;
      formErrorState["studyTypeCompletedId"] = { error: true }
    }

    if (!formState?.studyIncomplete1 && formState?.hstDeviceId && (!formState.SDATE && !SDATE)) {
      isValid = false;
      setErrorSDATE(true);
    }

    if ((formState?.hstDeviceIdNight2 || formState?.SDATE2 || SDATE2 || formState?.apptCompleted2) && ((formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10 ) && !formState.studyTypeCompletedId2)) {
      isValid = false;
      formErrorState["studyTypeCompletedId2"] = { error: true }
    }

    if (( !formState?.studyIncomplete1 && !formState?.studyIncomplete2 && ( formState?.hstDeviceIdNight2 || formState.studyTypeCompletedId2 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10 || formState?.apptCompleted2 )) && (!SDATE2 && !formState.SDATE2)) {
      isValid = false;
      setErrorSDATE2(true)
    }

    if ((formState?.hstDeviceIdNight3 || formState?.SDATE3 || SDATE3 || formState?.apptCompleted3) && !formState.studyTypeCompletedId3) {
      isValid = false;
      formErrorState["studyTypeCompletedId3"] = { error: true }
    }

    if ((formState?.hstDeviceIdNight3 || formState.studyTypeCompletedId3 || formState?.apptCompleted3) && (!SDATE3 && !formState.SDATE3)) {
      isValid = false;
      setErrorSDATE3(true)
    }

    if (
      formState?.SDATE2 &&
      !formState?.studyTypeId2 &&
      formState?.studyTypeId !== 8 &&
      formState?.studyTypeId !== 9 &&
      formState?.studyTypeId !== 10
    ) {
      isValid = false;
      formErrorState["studyTypeId2"] = { error: true };
    }

    if (
      !formState.reasonIncompleteStudyId &&
      (formState?.studyIncomplete === "1" || formState?.studyIncomplete === 1)
    ) {
      isValid = false;
      formErrorState["reasonIncompleteStudyId"] = { error: true };
    }

    if (!formState?.studyIncomplete1 && (formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10) && !uploadDate) {
      isValid = false;
      setUploadDateErr(true)
    }

    if (!isValid) {
      setErrorMessage("Please fill all required fields");
      setFormErrorState({ ...formErrorState });
      notificationToastError("Please fill all required fields");
      return false;
    }
    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
   
       const payload = {
         ...formState,
         dateOfService1: SDATE || formState?.SDATE,
         dateOfService2: SDATE2 || formState?.SDATE2,
         dateOfService3: SDATE3 || formState?.SDATE3,
     };

    studyPostData(axios, "updateStudyInfo", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);
    if (reloadCurrentVisit) reloadCurrentVisit(payload);
  };

  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  //update device return due date
  const updateDueDate = (newValue,name) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    if(name==="deviceReturnDate"){
      setDeviceReturnDate(newValue.format("MM/DD/YYYY"));
      setFormState({
        ...formState,
        deviceReturnDate: newValue.format("YYYY-MM-DD"),
      });
    }else if(name==="uploadDate"){
      setUploadDate(newValue.format("MM/DD/YYYY"));
      setFormState({
        ...formState,
        uploadDate: newValue.format("YYYY-MM-DD"),
      });
    }else if(name==="deviceDate"){
      setDeviceDate(newValue.format("MM/DD/YYYY"));
      setFormState({
        ...formState,
        deviceDate: newValue.format("YYYY-MM-DD"),
      });
    }
    
  };

  const patientNameVal = row?.patientName ?? formState?.patientNameVal;

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <Box>
        <div>
          <FormControl sx={{ m: "8px 0px" }}>
            <b>Complete / Update Study: </b>
          </FormControl>
        </div>
        <Box
          sx={{
            marginTop: 1,
          }}
        >
          {!notShowPopup && (
            <ReschedulePopup
              row={row}
              dos={
                formState.statusNameId === 19 ? "Actual DOS:" : "Scheduled DOS:"
              }
            />
          )}
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
          }}
        >
          <b>
            {formState?.studyTypeId !== 8 &&
              formState?.studyTypeId !== 9 &&
              formState?.studyTypeId !== 10 &&
              "First "}
            Appointment Info:
          </b>
          <Box sx={{ marginTop: 1 }}>
            <StudyTypePulldown
              key="studyTypeId"
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="studyTypeId"
              style={{ width: "100%" }}
            />
          </Box>

          {(formState?.studyTypeId === 8 ||
            formState?.studyTypeId === 9 ||
            formState?.studyTypeId === 10) && (
              <>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Device Return Due Date *"
                    inputFormat="MM/DD/YYYY"
                    className="study-date-data"
                    value={deviceReturnDate || ""}
                    onChange={(e)=>updateDueDate(e,"deviceReturnDate")}
                    readOnly={isPreviousVisits}
                    renderInput={(params) => (
                      <TextField
                        // data-testid="study-date-data"
                        {...params}
                        variant="standard"
                        error={deviceReturnDateErr && !deviceReturnDate}
                        helperText={
                          deviceReturnDateErr && !deviceReturnDate
                            ? "required"
                            : ""
                        }
                        value={
                          deviceReturnDate
                            ? moment.utc(deviceReturnDate.toLocaleString()).format(
                              "MM/DD/YYYY"
                            )
                            : ""
                        }
                        onBlur={() => {
                          setDeviceReturnDateErr(!deviceReturnDate);
                        }}
                        inputProps={{ readOnly: isPreviousVisits }}
                        onMouseDown={handleFocus}
                        placeholder="mm/dd/yyyy"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              {/* upload date */}
              <FormControl variant="standard" sx={{ width: "100%", marginTop: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Upload Date *"
                    inputFormat="MM/DD/YYYY"
                    className="study-date-data"
                    value={uploadDate || ""}
                    onChange={(e) => updateDueDate(e, "uploadDate")}
                    readOnly={isPreviousVisits}
                    renderInput={(params) => (
                      <TextField
                        // data-testid="study-date-data"
                        {...params}
                        variant="standard"
                        error={uploadDateErr && !uploadDate}
                        helperText={
                          uploadDateErr && !uploadDate
                            ? "required"
                            : ""
                        }
                      
                        value={
                          uploadDate
                            ? moment.utc(uploadDate.toLocaleString()).format(
                              "MM/DD/YYYY"
                            )
                            : ""
                        }
                        onBlur={() => {
                          setUploadDateErr(!uploadDate);
                        }}
                        inputProps={{ readOnly: isPreviousVisits }}
                        onMouseDown={handleFocus}
                        placeholder="mm/dd/yyyy"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              {/* device return date */}
              <FormControl variant="standard" sx={{ width: "100%", marginTop: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Device Return Date"
                    inputFormat="MM/DD/YYYY"
                    className="study-date-data"
                    value={deviceDate || ""}
                    onChange={(e) => updateDueDate(e, "deviceDate")}
                    readOnly={isPreviousVisits}
                    renderInput={(params) => (
                      <TextField
                        // data-testid="study-date-data"
                        {...params}
                        variant="standard"
                        error={deviceDateErr && !deviceDate}
                        helperText={
                          deviceDateErr && !deviceDate
                            ? "required"
                            : ""
                        }
                
                        value={
                          deviceDate
                            ? moment.utc(deviceDate.toLocaleString()).format(
                              "MM/DD/YYYY"
                            )
                            : ""
                        }
                        inputProps={{ readOnly: isPreviousVisits }}
                        onMouseDown={handleFocus}
                        placeholder="mm/dd/yyyy"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              </>
           
          )}

          {formState?.studyTypeId !== 8 &&
            formState?.studyTypeId !== 9 &&
            formState?.studyTypeId !== 10 &&
            actualStudyTypeCompleted?.length !== 0 && (
            <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                <InputLabel id="user-label" error={formErrorState.studyTypeCompletedId?.error && !formState.studyTypeCompletedId}>
                  Select Actual Study Type Completed{" "}*
                </InputLabel>
                <Select
                  labelId="user-label"
                  id="studyTypeCompletedId"
                  name="studyTypeCompletedId"
                  value={formState?.studyTypeCompletedId || ""}
                  label="ActualStudyTypeCompleted"
                  onChange={updateInput}
                  inputProps={{ readOnly: isPreviousVisits }}
                  required
                  error={formErrorState.studyTypeCompletedId?.error && !formState.studyTypeCompletedId}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {actualStudyTypeCompleted?.map((studyTypeCompleted) => (
                    <MenuItem value={studyTypeCompleted.studyTypeCompletedId} key={studyTypeCompleted.studyTypeCompletedId}>
                      {studyTypeCompleted.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

          {(formState?.studyTypeId === 8 ||
            formState?.studyTypeId === 9 ||
            formState?.studyTypeId === 10) && (
            <>
              <FormControl variant="standard" sx={{ marginTop: "1rem" }}>
                <b>1-Night Info:</b>
              </FormControl>
              <FormControl variant="standard" sx={{ mt: 2, mb: 1, width: "100%" }}>
                <InputLabel id="user-label" error={formErrorState.studyTypeCompletedId?.error && !formState.studyTypeCompletedId}>
                  Select Actual Study Type Completed{" "}
                </InputLabel>
                <Select
                  labelId="user-label"
                  id="studyTypeCompletedId"
                  name="studyTypeCompletedId"
                  value={formState?.studyTypeCompletedId || ""}
                  label="ActualStudyTypeCompleted"
                  onChange={updateInput}
                  inputProps={{ readOnly: isPreviousVisits }}
                  error={formErrorState.studyTypeCompletedId?.error && !formState.studyTypeCompletedId}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {actualStudyTypeCompleted?.map((studyTypeCompleted) => (
                    <MenuItem value={studyTypeCompleted.studyTypeCompletedId} key={studyTypeCompleted.studyTypeCompletedId}>
                      {studyTypeCompleted.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <GeneralPulldown
                key="hstDeviceId"
                isPreviousVisits={isPreviousVisits}
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                attributeName="hstDeviceId"
                style={{ width: "100%" }}
                isPatientPortal={isPatientPortal}
                pulldownApiName="hstDevicesPulldown"
                itemName="deviceName"
                labelName="Select HST Device"
              />
              <FormControl variant="standard" sx={{ width: "100%", mt: 1, mb: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date of Service * "
                    inputFormat="MM/DD/YYYY"
                    value={SDATE || formState?.SDATE || ""}
                    onChange={updateSDATE}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        error={errorSDATE && (formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10 )  && !SDATE || inputSDATEError}
                        helperText={errorSDATE && (formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10 ) && !SDATE ? "required" : inputSDATEError ? 'Date should be entered in the following format mm/dd/yyyy' : ''}
                        value={
                          SDATE
                            ? moment
                                .utc(SDATE.toLocaleString())
                                .format("MM/DD/YYYY")
                            : ""
                        }
                        onBlur={() => {
                          setErrorSDATE(!SDATE);
                        }}
                        
                        onMouseDown={handleFocus}
                        placeholder="mm/dd/yyyy"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl variant="standard" sx={{ width: "100%" }}>
                <TextField
                  id="studyCompletedNotes"
                  //{...getErrorValidationProperty("studyCompletedNotes")}
                  label="Notes"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.studyCompletedNotes}
                  onMouseDown={handleFocus}
                />
              </FormControl>

              <FormControl variant="standard" sx={{ marginTop: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="apptCompleted1"
                      checked={Boolean(formState.apptCompleted1)}
                      onChange={handleMainCheckboxChange2}
                      disabled={isPreviousVisits}
                    />
                  }
                  label="1-Night Completed "
                />
              </FormControl>
              <Box>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="studyIncomplete1"
                        checked={Boolean(formState.studyIncomplete1)}
                        onChange={handleMainCheckboxChange2}
                        disabled={isPreviousVisits}
                      />
                    }
                    label="Study Incomplete 1-Night"
                  />
                </FormControl>
              </Box>

              {formState?.studyIncomplete1 == 1 && (
                <FormControl sx={{ width: "100%" }}>
                  <GeneralPulldown
                    key="reasonIncompleteStudyId1"
                    isPreviousVisits={isPreviousVisits}
                    formState={formState}
                    updateInput={updateInput}
                    formErrorState={formErrorState}
                    attributeName="reasonIncompleteStudyId1"
                    dbKeyColumn="reasonIncompleteStudyId"
                    style={{ width: "100%" }}
                    isPatientPortal={isPatientPortal}
                    pulldownApiName="ReasonIncompleteStudyPulldown"
                    itemName="reason"
                    labelName="Select Reason For Incomplete Study For 1-Night"
                  />
                </FormControl>
              )}
            </>
          )}

          {(formState?.studyTypeId === 9 || formState?.studyTypeId === 10) && (
            <>
              <FormControl
                variant="standard"
                sx={{ marginTop: "1rem", width: "100%" }}
              >
                {" "}
                <b>2-Night Info:</b>
              </FormControl>

              <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                <InputLabel id="user-label" error={formErrorState.studyTypeCompletedId2?.error && !formState.studyTypeCompletedId2}>
                  Select Actual Study Type Completed{" "}
                </InputLabel>
                <Select
                  labelId="user-label"
                  id="studyTypeCompletedId2"
                  name="studyTypeCompletedId2"
                  value={formState?.studyTypeCompletedId2 || ""}
                  label="ActualStudyTypeCompleted"
                  onChange={updateInput}
                  inputProps={{ readOnly: isPreviousVisits }}
                  error={formErrorState.studyTypeCompletedId2?.error && !formState.studyTypeCompletedId2}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {actualStudyTypeCompleted?.map((studyTypeCompleted) => (
                    <MenuItem value={studyTypeCompleted.studyTypeCompletedId} key={studyTypeCompleted.studyTypeCompletedId}>
                      {studyTypeCompleted.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <GeneralPulldown
                key="hstDeviceIdNight2"
                isPreviousVisits={isPreviousVisits}
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                attributeName="hstDeviceIdNight2"
                dbKeyColumn="hstDeviceId"
                style={{ width: "100%" }}
                isPatientPortal={isPatientPortal}
                pulldownApiName="hstDevicesPulldown"
                itemName="deviceName"
                labelName="Select HST Device"
              />
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date of Service - 2 "
                    inputFormat="MM/DD/YYYY"
                    value={SDATE2 || formState?.SDATE2 || ""}
                    onChange={updateSDATE2}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        error={errorSDATE2 && !SDATE2 || inputSDATE2Error}
                        helperText={errorSDATE2 && !SDATE2 ? "required" : inputSDATE2Error ? 'Date should be entered in the following format mm/dd/yyyy' : ''}
                        value={
                          SDATE2
                            ? moment
                                .utc(SDATE2.toLocaleString())
                                .format("MM/DD/YYYY")
                            : ""
                        }
                        onBlur={() => {
                          setErrorSDATE(!SDATE2);
                        }}
                        onMouseDown={handleFocus}
                        placeholder="mm/dd/yyyy"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl variant="standard" sx={{ width: "100%" }}>
                <TextField
                  id="studyCompletedNotes2"
                 // {...getErrorValidationProperty("studyCompletedNotes2")}
                  label="Notes"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.studyCompletedNotes2}
                  onMouseDown={handleFocus}
                />
              </FormControl>
              <FormControl sx={{ marginTop: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="apptCompleted2"
                      checked={Boolean(formState.apptCompleted2)}
                      onChange={handleMainCheckboxChange2}
                      disabled={isPreviousVisits}
                    />
                  }
                  label="2-Night  Completed "
                />
              </FormControl>
              <Box>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="studyIncomplete2"
                        checked={Boolean(formState.studyIncomplete2)}
                        onChange={handleMainCheckboxChange2}
                        disabled={isPreviousVisits}
                      />
                    }
                    label="Study Incomplete 2-Night"
                  />
                </FormControl>
              </Box>


              {formState?.studyIncomplete2 == 1 && (
                <FormControl sx={{ width: "100%" }}>
                  <GeneralPulldown
                    key="reasonIncompleteStudyId2"
                    isPreviousVisits={isPreviousVisits}
                    formState={formState}
                    updateInput={updateInput}
                    formErrorState={formErrorState}
                    attributeName="reasonIncompleteStudyId2"
                    dbKeyColumn="reasonIncompleteStudyId"
                    style={{ width: "100%" }}
                    isPatientPortal={isPatientPortal}
                    pulldownApiName="ReasonIncompleteStudyPulldown"
                    itemName="reason"
                    labelName="Select Reason For Incomplete Study For 2-Night"
                  />
                </FormControl>
              )}
            </>
          )}

          {formState?.studyTypeId === 10 && (
            <>
              <FormControl
                variant="standard"
                sx={{ marginTop: "2rem", width: "100%" }}
              >
                {" "}
                <b>3-Night Info:</b>
              </FormControl>

              <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                <InputLabel id="user-label" error={formErrorState.studyTypeCompletedId3?.error && !formState.studyTypeCompletedId3}>
                  Select Actual Study Type Completed{" "}
                </InputLabel>
                <Select
                  labelId="user-label"
                  id="studyTypeCompletedId3"
                  name="studyTypeCompletedId3"
                  value={formState?.studyTypeCompletedId3 || ""}
                  label="ActualStudyTypeCompleted"
                  onChange={updateInput}
                  inputProps={{ readOnly: isPreviousVisits }}
                  error={formErrorState.studyTypeCompletedId3?.error && !formState.studyTypeCompletedId3}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {actualStudyTypeCompleted?.map((studyTypeCompleted) => (
                    <MenuItem value={studyTypeCompleted.studyTypeCompletedId} key={studyTypeCompleted.studyTypeCompletedId}>
                      {studyTypeCompleted.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <GeneralPulldown
                key="hstDeviceIdNight3"
                isPreviousVisits={isPreviousVisits}
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                attributeName="hstDeviceIdNight3"
                dbKeyColumn="hstDeviceId"
                style={{ width: "100%" }}
                isPatientPortal={isPatientPortal}
                pulldownApiName="hstDevicesPulldown"
                itemName="deviceName"
                labelName="Select HST Device"
              />
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date of Service - 3 "
                    inputFormat="MM/DD/YYYY"
                    value={SDATE3 || formState?.SDATE3 || ""}
                    onChange={updateSDATE3}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        error={errorSDATE3 && !SDATE3 || inputSDATE3Error}
                        helperText={errorSDATE3 && !SDATE3 ? "required" : inputSDATE3Error ? 'Date should be entered in the following format mm/dd/yyyy' : ''}
                        value={
                          SDATE3
                            ? moment
                                .utc(SDATE3.toLocaleString())
                                .format("MM/DD/YYYY")
                            : ""
                        }
                        onBlur={() => {
                          setErrorSDATE(!SDATE3);
                        }}
                        onMouseDown={handleFocus}
                        placeholder="mm/dd/yyyy"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl variant="standard" sx={{ width: "100%" }}>
                <TextField
                  id="studyCompletedNotes3"
                  //{...getErrorValidationProperty("studyCompletedNotes3")}
                  label="Notes"
                  variant="standard"
                  onChange={updateInput}
                  value={formState.studyCompletedNotes3}
                  onMouseDown={handleFocus}
                />
              </FormControl>
              <FormControl sx={{ marginTop: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="apptCompleted3"
                      checked={Boolean(formState.apptCompleted3)}
                      onChange={handleMainCheckboxChange2}
                      disabled={isPreviousVisits}
                    />
                  }
                  label="3-Night  Completed "
                />
              </FormControl>
              <Box>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="studyIncomplete3"
                        checked={Boolean(formState.studyIncomplete3)}
                        onChange={handleMainCheckboxChange2}
                        disabled={isPreviousVisits}
                      />
                    }
                    label="Study Incomplete 3-Night"
                  />
                </FormControl>
              </Box>

              {formState?.studyIncomplete3 == 1 && (
                <FormControl sx={{ width: "100%" }}>
                  <GeneralPulldown
                    key="reasonIncompleteStudyId3"
                    isPreviousVisits={isPreviousVisits}
                    formState={formState}
                    updateInput={updateInput}
                    formErrorState={formErrorState}
                    attributeName="reasonIncompleteStudyId3"
                    dbKeyColumn="reasonIncompleteStudyId"
                    style={{ width: "100%" }}
                    isPatientPortal={isPatientPortal}
                    pulldownApiName="ReasonIncompleteStudyPulldown"
                    itemName="reason"
                    labelName="Select Reason For Incomplete Study For 3-Night"
                  />
                </FormControl>
              )}
            </>
          )}

          {formState?.studyTypeId != 8 &&
            formState?.studyTypeId != 9 &&
            formState?.studyTypeId != 10 && (
              <>
              <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Date of Service * "
                      inputFormat="MM/DD/YYYY"
                      value={SDATE || formState?.SDATE || ""}
                      onChange={updateSDATE}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={errorSDATE && !SDATE || inputSDATEError}
                          helperText={errorSDATE && !SDATE ? "required" :  inputSDATEError ? 'Date should be entered in the following format mm/dd/yyyy' : ''}
                          value={
                            SDATE
                              ? moment
                                  .utc(SDATE.toLocaleString())
                                  .format("MM/DD/YYYY")
                              : ""
                          }
                          onBlur={() => {
                            setErrorSDATE(!SDATE);
                          }}
                          onMouseDown={handleFocus}
                          placeholder="mm/dd/yyyy"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>

              <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                  <TextField
                    id="studyCompletedNotes"
                   // {...getErrorValidationProperty("studyCompletedNotes")}
                    label="Notes"
                    variant="standard"
                    onChange={updateInput}
                    value={formState.studyCompletedNotes || ""}
                    onMouseDown={handleFocus}
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="apptCompleted1"
                        checked={Boolean(formState.apptCompleted1)}
                        onChange={handleMainCheckboxChange2}
                        disabled={isPreviousVisits}
                      />
                    }
                    label="First Appointment Completed "
                  />
                </FormControl>

                <Box>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="studyIncomplete1"
                          checked={Boolean(formState.studyIncomplete1)}
                          onChange={handleMainCheckboxChange2}
                          disabled={isPreviousVisits}
                        />
                      }
                      label="First Appointment Incomplete"
                    />
                  </FormControl>
                </Box>

                {formState?.studyIncomplete1 == 1 && (
                  <FormControl sx={{ width: "100%" }}>
                    <GeneralPulldown
                      key="reasonIncompleteStudyId1"
                      isPreviousVisits={isPreviousVisits}
                      formState={formState}
                      updateInput={updateInput}
                      formErrorState={formErrorState}
                      attributeName="reasonIncompleteStudyId1"
                      dbKeyColumn="reasonIncompleteStudyId"
                      style={{ width: "100%" }}
                      isPatientPortal={isPatientPortal}
                      pulldownApiName="ReasonIncompleteStudyPulldown"
                      itemName="reason"
                      labelName="Select Reason For Incomplete (First Appointment)"
                    />
                  </FormControl>
                )}
                <Box sx={{ marginTop: "1rem" }}>
                  <b>Second Appointment Info: </b>
                  <StudyTypePulldown
                    key="studyTypeId2"
                    formState={formState}
                    updateInput={updateInput}
                    formErrorState={formErrorState}
                    attributeName="studyTypeId2"
                    style={{ width: "100%" }}
                    req=""
                  />
                  {actualStudyTypeCompleted?.length !== 0 && (
                    <FormControl
                      variant="standard"
                    sx={{ mt: 1, mb: 1, width: "100%" }}
                    >
                      <InputLabel id="user-label" error={formErrorState.studyTypeCompletedId2?.error && !formState.studyTypeCompletedId2}>
                        Select Actual Study Type Completed{" "}
                      </InputLabel>
                      <Select
                        labelId="user-label"
                        id="studyTypeCompletedId2"
                        name="studyTypeCompletedId2"
                        value={formState?.studyTypeCompletedId2 || ""}
                        label="ActualStudyTypeCompleted"
                        onChange={updateInput}
                        inputProps={{ readOnly: isPreviousVisits }}
                        error={formErrorState.studyTypeCompletedId2?.error && !formState.studyTypeCompletedId2}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {actualStudyTypeCompleted?.map((studyTypeCompleted) => (
                          <MenuItem
                            value={studyTypeCompleted.studyTypeCompletedId}
                            key={studyTypeCompleted.studyTypeCompletedId}
                          >
                            {studyTypeCompleted.name}{" "}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Date of Service"
                        inputFormat="MM/DD/YYYY"
                        value={SDATE2 || formState?.SDATE2 || ""}
                        onChange={updateSDATE2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            error={errorSDATE2 && !SDATE2 || inputSDATE2Error}
                            helperText={
                              errorSDATE2 && !SDATE2 ? "required" :  inputSDATE2Error ? 'Date should be entered in the following format mm/dd/yyyy' : ''
                            }
                            value={
                              SDATE2
                                ? moment
                                    .utc(SDATE2.toLocaleString())
                                    .format("MM/DD/YYYY")
                                : ""
                            }
                            onBlur={() => {
                              setErrorSDATE2(!SDATE2);
                            }}
                            onMouseDown={handleFocus}
                            placeholder="mm/dd/yyyy"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>

                <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: "100%" }}>
                    <TextField
                      id="studyCompletedNotes2"
                     // {...getErrorValidationProperty("studyCompletedNotes2")}
                      label="Notes"
                      variant="standard"
                      onChange={updateInput}
                      value={formState.studyCompletedNotes2 || ""}
                      onMouseDown={handleFocus}
                    />
                  </FormControl>

                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="apptCompleted2"
                          checked={Boolean(formState.apptCompleted2)}
                          onChange={handleMainCheckboxChange2}
                          disabled={isPreviousVisits}
                        />
                      }
                      label="Second Appointment Completed "
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="studyIncomplete2"
                          checked={Boolean(formState.studyIncomplete2)}
                          onChange={handleMainCheckboxChange2}
                          disabled={isPreviousVisits}
                        />
                      }
                      label="Second Appointment Incomplete"
                    />
                  </FormControl>
                </Box>

                {formState?.studyIncomplete2 == 1 && (
                  <FormControl sx={{ width: "100%" }}>
                    <GeneralPulldown
                      key="reasonIncompleteStudyId2"
                      isPreviousVisits={isPreviousVisits}
                      formState={formState}
                      updateInput={updateInput}
                      formErrorState={formErrorState}
                      attributeName="reasonIncompleteStudyId2"
                      dbKeyColumn="reasonIncompleteStudyId"
                      style={{ width: "100%" }}
                      isPatientPortal={isPatientPortal}
                      pulldownApiName="ReasonIncompleteStudyPulldown"
                      itemName="reason"
                      labelName="Select Reason For Incomplete (Second Appointment)"
                    />
                  </FormControl>
                )}
              </>
            )}

          {(formState.statusNameId === 19 ||
            isPreviousVisits ||
            isCurrentVisits) && (
            <Box
              sx={{
                marginTop: "1rem",
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="studyInfoConfirmed"
                      checked={Boolean(formState.studyInfoConfirmed)}
                      onChange={updateCheckbox}
                    />
                  }
                  label="Study Info Confirmed"
                />
              </FormControl>{" "}
            </Box>
          )}
        </Box>

        <Box sx={{ marginTop: "1rem" }}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  id="studyCompleted"
                  checked={Boolean(formState.studyCompleted)}
                  onChange={handleMainCheckboxChange}
                  disabled={isPreviousVisits}
                />
              }
              label="Send for Scoring (Check if at least one of the studies completed)"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  id="studyIncomplete"
                  checked={Boolean(formState.studyIncomplete)}
                  onChange={handleMainCheckboxChange}
                  disabled={isPreviousVisits}
                />
              }
              label="Send for Rescheduling"
            />
          </FormControl>
        </Box>

        {formState?.studyIncomplete == 1 && (
          <FormControl sx={{ width: "100%" }}>
            <GeneralPulldown
              key="reasonIncompleteStudyId"
              isPreviousVisits={isPreviousVisits}
              formState={formState}
              updateInput={updateInput}
              formErrorState={formErrorState}
              attributeName="reasonIncompleteStudyId"
              style={{ width: "100%" }}
              isPatientPortal={isPatientPortal}
              pulldownApiName="ReasonIncompleteStudyPulldown"
              itemName="reason"
              labelName="Select Reason For Incomplete Study"
            />
          </FormControl>
        )}

        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              sx={{ margin: "1rem 0rem" }}
              disabled={isSaveButtonDisabled}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </div>
        )}
      </Box>
    );
  }
}
