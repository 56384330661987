import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

const PulldownAutoselection = ({ options, optionsForId=[], attributeName, attributeId, formState = {}, setFormState = () => { }, label = "", setIsSaveButtonDisabled = () => { }, formErrorState = {}, isReadOnly = false }) => {

    const handleInputChange = (event, newValue) => {
        setIsSaveButtonDisabled(false)
        setFormState({
            ...formState,
            [attributeName]: newValue,
            [attributeId]: optionsForId?.find((item) => item.name === newValue)?.id
        })
    };

    const handleAutoSelectionBlur = () => {
        if (!options?.includes(formState[attributeName])) {
            setFormState({
                ...formState,
                [attributeName]: "",
                [attributeId]: null,
            })
        }

    }
    const handleKeyDown = (event) => {
        // console.log("event", event.target.value);
        if (event.key === "Enter") {
            event.preventDefault();
            const inputValue = event.target.value.trim().toLowerCase();
            // const matchedOption = options.find(
            //     (option) =>
            //         option.toLowerCase().indexOf(inputValue) === 0
            // );
            const matchedOption = options.find((option) =>
                option.toLowerCase().startsWith(inputValue.toLowerCase())
            );
            if (matchedOption) {
                updateInput({ target: { name: attributeName, value: matchedOption } });
            }
        }
    };

    const updateInput = ({ target }) => {
        // console.log("target===>", target, attributeName);
        setIsSaveButtonDisabled(false)
        setFormState({
            ...formState,
            [attributeName]: target?.value,
            [attributeId]: optionsForId?.find((item) => item.name === target?.value)?.id
        })
    }

    function customFilter(options, { inputValue }) {
        return options?.filter(option =>
            option?.toLowerCase()?.startsWith(inputValue?.toLowerCase())
        );
    }

    return (
        <Box sx={{ width: "90%", margin: '-2px' }}>
            {/* <Autocomplete
                disablePortal
                filterOptions={customFilter}
                id={attributeName}
                name={attributeName}
                value={formState?.[attributeName] || ""}
                options={options}
                onChange={(_, values) =>
                    updateInput({ target: { name: attributeName, value: values } })
                }
                readOnly={isReadOnly}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name={attributeName}
                        value={formState?.[attributeName] || ""}
                        variant="standard"
                        label={label}
                        onKeyDown={handleKeyDown}
                        error={formErrorState[attributeId]?.error && !formState[attributeId]}
                        helperText={formErrorState[attributeId]?.error && !formState[attributeId] ? "required" : ""}
                    />
                )}
                // onInputChange={handleInputChange}
                onBlur={handleAutoSelectionBlur}
            /> */}
             <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 160 }}
                
            >
            <InputLabel id="state-label">{label}</InputLabel>
            <Select
                labelId="state-label"
                id={attributeName}
                name={attributeName}
                value={formState?.[attributeName] || ""}
                label={label}
                onChange={updateInput}
                inputProps={
                    { readOnly: isReadOnly }
                }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {options?.map((type) => (
                <MenuItem value={type} >{type}</MenuItem>
              ))}
            </Select>
            </FormControl>
        </Box>
    );
};

export default PulldownAutoselection