import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Typography,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { pulldownsPostData, pulldownsPostWithId } from "../../axios/api";

function LocationPulldown({
  formState={},
  updateInput,
  formErrorState={},
  attributeName,
  selectedLocationAttributeNames,
  isGroupId=false,
  index,
  view=false,
  displayValue=null,
  reloadOnDelete=false,
  setLocationList=()=>{},
  setHidePulldown=()=>{},
  ...remaining
}) {
  const [locations, setLocations] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    async function setDataFirstTime() {


      pulldownsPostData(axios, view ? "groupLocationsPulldown" : "locationPulldown")
        .then((res) => {
          setLocations(res?.data);
          setLocationList(res?.data)
          if(res?.data?.length === 0){
            setHidePulldown(true)
          } else {
            setHidePulldown(false)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const setDataWithId = ()=>{
      const payloadData={
        axios,
        paramsName:"locationPulldown",
        groupId:formState?.groupId
      }
      pulldownsPostWithId(payloadData).then((res)=>{
        setLocations(res?.data);
      }).catch((err)=>{
        console.log("err",err);
      })
    }
   

    if ( isGroupId) {
      setDataWithId()
    }else{
      setDataFirstTime();
    }
  }, [formState?.groupId,reloadOnDelete]);
  return (
    <>
      <FormControl
        variant="standard"
        sx={{ m: view ? 0 : 1, width:"90%",minWidth:"120px" }}
        {...remaining}
      >
        <InputLabel
          id="location-label"
          error={formErrorState[attributeName]?.error && !formState[attributeName]}
        >
          Location (Facility)
        </InputLabel>
        <Select
          labelId="location-label"
          data-testid={`location-pulldown-${index}`}
          id={attributeName}
          name={attributeName}
          value={formState[attributeName] || displayValue || ""}
          label="location"
          onChange={(e)=>updateInput(e,locations)}
          error={
            formErrorState[attributeName]?.error && !formState[attributeName]
          }
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {(view ? locations.filter((item) => !selectedLocationAttributeNames.includes(item.location)) : locations
            .filter((locationFilter) => {
              return (
                !selectedLocationAttributeNames ||
                selectedLocationAttributeNames.every(
                  (attr) =>
                    String(formState[attr]) !==
                    String(locationFilter.locationId)
                )
              );
            }))
            .map((location) => (
              <MenuItem value={location.locationId} key={location.locationId}>
                {location.location}
              </MenuItem>
            ))}
        </Select>
        {formErrorState?.locationId?.error && !formState?.locationId && (
          <Typography variant="subtitle2" color={"error"}>
            required
          </Typography>
        )}
      </FormControl>
    </>
  );
}

export default LocationPulldown;
