import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Button
                variant="contained"
                sx={{ marginLeft: '10px' }}
                onClick={handleClickOpen}
            >
                Patient User License Agreement
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    <p align="center">Patient Agreement </p>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <div className="mud-dialog-content">
                            <p align="center">
                                <strong>PATIENT</strong>
                            </p>
                            <p align="center">
                                <strong>LICENSE AGREEMENT</strong>
                            </p>
                            <p>
                                This Patient License Agreement (“
                                <strong>Agreement</strong>”) is a binding
                                agreement between you, or your parent or legal
                                guardian, (“<strong>you</strong>”) and Vital
                                Sleep LLC d/b/a Sleep Navigator (“
                                <strong>Vital Sleep</strong>”). This Agreement
                                governs your use and access to the Platform
                                (including all related documentation, the “
                                <strong>Platform</strong>”) operating under the
                                brand name SleepNavigator® .{' '}
                            </p>
                            <p>
                                BY CLICKING THE “AGREE” BUTTON OR ACCESSING THE
                                PLATFORM, YOU: (A) ACKNOWLEDGE THAT YOU HAVE
                                READ AND UNDERSTAND THIS AGREEMENT; (B)
                                REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER
                                INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS
                                AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND
                                BY ITS TERMS. IF YOU DO NOT AGREE TO THESE
                                TERMS, DO NOT ACCESS OR USE THE PLATFORM.
                            </p>
                            <div
                                style={{
                                    marginLeft: '15px',
                                }}
                            >
                                <ol>
                                    <li>
                                        <u>License Grant.</u> Subject to the
                                        terms of this Agreement, Vital Sleep
                                        grants you a limited, non-exclusive, and
                                        nontransferable license to download,
                                        install, access, and/or use the Platform
                                        for your personal use strictly in
                                        accordance with the Platform’s
                                        documentation .
                                    </li>
                                    <li>
                                        <u>License Restrictions.</u> You shall
                                        not: (a) copy the Platform, except as
                                        expressly permitted by this license; (b)
                                        modify, translate, adapt, or otherwise
                                        create derivative works or improvements,
                                        whether or not patentable, of the
                                        Platform; (c) reverse engineer,
                                        disassemble, decompile, decode, or
                                        otherwise attempt to derive or gain
                                        access to the source code of the
                                        Platform or any part thereof; (d)
                                        remove, delete, alter, or obscure any
                                        trademarks or any copyright, trademark,
                                        patent, or other intellectual property
                                        or proprietary rights notices from the
                                        Platform, including any copy thereof;
                                        (e) rent, lease, lend, sell, sublicense,
                                        assign, distribute, publish, transfer,
                                        or otherwise make available the
                                        Platform, or any features or
                                        functionality of the Platform, except
                                        patient users; or (f) remove, disable,
                                        circumvent, or otherwise create or
                                        implement any workaround to any copy
                                        protection, rights management, or
                                        security features in or protecting the
                                        Platform.
                                    </li>
                                    <li>
                                        <u>Diagnosis and Treatment.</u> The
                                        Platform does not make clinical, medical
                                        or other professional decisions, and is
                                        not a substitute for professional
                                        judgment and analysis.
                                    </li>
                                    <li>
                                        <u>Reservation of Rights.</u>
                                        <ol type="a">
                                            <li>
                                                You acknowledge and agree that
                                                the Platform is provided under
                                                license, and not sold, to you.
                                                You do not acquire any ownership
                                                interest in the Platform under
                                                this Agreement, or any other
                                                rights thereto other than to use
                                                the Platform in accordance with
                                                the license granted, and subject
                                                to all terms, conditions, and
                                                restrictions, under this
                                                Agreement. Vital Sleep and its
                                                affiliated companies reserve and
                                                shall retain their entire right,
                                                title, and interest in and to
                                                the Platform, including all
                                                copyrights, trademarks, and
                                                other intellectual property
                                                rights therein or relating
                                                thereto, except as expressly
                                                granted to you in this
                                                Agreement.
                                            </li>
                                            <li>
                                                For any comments, submissions or
                                                other feedback you may provide
                                                to Vital Sleep concerning the
                                                functionality and performance of
                                                the Platform, including
                                                identification of potential
                                                errors and improvements, the
                                                parties acknowledge that Vital
                                                Sleep is free to use, disclose,
                                                reproduce, license or otherwise
                                                distribute, and exploit such
                                                information as Vital Sleep sees
                                                fit, in its sole discretion, and
                                                without any obligation or
                                                restriction of any kind to you.
                                                There is no obligation for Vital
                                                Sleep to review feedback,
                                                comments or submissions made by
                                                you, and there is no obligation
                                                to keep any such information
                                                confidential.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <u>
                                            Collection and Use of Your
                                            Information.
                                        </u>{' '}
                                        You acknowledge that when you download,
                                        install, access and/or use the Platform,
                                        Vital Sleep may use automatic means
                                        (including, for example, cookies and web
                                        beacons) to collect information about
                                        the device(s) on which you access and
                                        about your use of the Platform. In using
                                        the Platform you will provide certain
                                        information about yourself as a
                                        condition to downloading, installing,
                                        accessing and/or using the Platform or
                                        certain of its features or
                                        functionality. The Platform may provide
                                        .
                                    </li>
                                    <li>
                                        <u>Data Privacy and Compliance.</u>
                                        <ol type="a">
                                            <li>
                                                In using the Platform, you will
                                                provide personal data (
                                                <strong>“Your Data”</strong>)
                                                about yourself of your medical
                                                conditions as a condition to
                                                downloading, installing,
                                                accessing and/or using the
                                                Platform, or certain of its
                                                features or functionality. The
                                                Platform may provide Your Data
                                                to the medical provider
                                                designated by you through the
                                                Platform.
                                            </li>
                                            <li>
                                                In accordance with the Business
                                                Associate Agreement (“BAA”)
                                                between your provider and Vital
                                                Sleep, Vital Sleep may
                                                de-identify Your Data in
                                                accordance with the “safe
                                                harbor” requirements of section
                                                164.514(b)(2) of the HIPAA
                                                regulations, or in a manner that
                                                otherwise meets the requirements
                                                of section 164.514 (“
                                                <strong>
                                                    De-identified Data
                                                </strong>
                                                ”), before such data is
                                                incorporated into any collection
                                                of De-identified Data from
                                                various customers of Vital Sleep
                                                (an “
                                                <strong>
                                                    Analytics Database
                                                </strong>
                                                ”). De-Identified Data may be
                                                aggregated with other
                                                de-identified data from persons
                                                into one or more Analytics
                                                Databases. You grant Vital Sleep
                                                a non-exclusive, worldwide,
                                                paid-up, perpetual and
                                                irrevocable right and license
                                                to: (i) extract, copy,
                                                aggregate, process and create
                                                derivative works of Your Data to
                                                derive, or add to, the Analytics
                                                Databases; (ii) employ data
                                                analytics on the Analytics
                                                Databases for purposes of
                                                developing Data Analytics
                                                solutions; and (iii) prepare
                                                derivative works of the
                                                Analytics Databases, and use,
                                                execute, reproduce, display,
                                                perform, transfer, distribute,
                                                and sublicense the Analytics
                                                Databases and such derivative
                                                works. Vital Sleep will not
                                                individually identify you as a
                                                source of the De-identified Data
                                                for the Analytics Databases,
                                                although Vital Sleep may
                                                disclose that certain of its
                                                users allow the use of data for
                                                such purposes. You agree that
                                                such access to, and retention
                                                of, said De-Identified Data and
                                                any Analytics Databases created
                                                by Vital Sleep, shall not
                                                constitute a breach of this
                                                Agreement.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <u>Updates.</u>
                                        Vital Sleep may from time to time in its
                                        sole discretion develop and provide
                                        Platform updates, which may include
                                        upgrades, bug fixes, patches, other
                                        error corrections, and/or new features
                                        (collectively, including related
                                        documentation, “<strong>Updates</strong>
                                        ”). Updates may also modify or delete in
                                        their entirety certain features and
                                        functionality. You agree that Vital
                                        Sleep has no obligation to provide any
                                        Updates or to continue to provide or
                                        enable any particular features or
                                        functionality. You further agree that
                                        all Updates will be deemed part of the
                                        Platform and be subject to all terms and
                                        conditions of this Agreement.
                                    </li>
                                    <li>
                                        <u>Third-Party Materials.</u> The
                                        Platform may display, include, or make
                                        available third-party content (including
                                        data, information, applications, and
                                        other products, services, and/or
                                        materials) or provide links to
                                        third-party websites or services,
                                        including through third-party
                                        advertising (“
                                        <strong>Third-Party Materials</strong>
                                        ”). You acknowledge and agree that Vital
                                        Sleep is not responsible for Third-Party
                                        Materials, including their accuracy,
                                        completeness, timeliness, validity,
                                        copyright compliance, legality, decency,
                                        quality, or any other aspect thereof.
                                        Vital Sleep does not assume and will not
                                        have any liability or responsibility to
                                        you or any other person or entity for
                                        any Third-Party Materials. Third-Party
                                        Materials and links thereto are provided
                                        solely as a convenience to you, and you
                                        access and use them entirely at your own
                                        risk and subject to such third parties’
                                        terms and conditions.
                                    </li>
                                    <li>
                                        <u>Term and Termination.</u>
                                        The term of Agreement commences when you
                                        download, install, access, and/or use
                                        the Platform and will continue in effect
                                        until terminated by you or Vital Sleep
                                        as set forth in this Section. You may
                                        terminate this Agreement by written
                                        communication with Vital Sleep. Vital
                                        Sleep may terminate this Agreement at
                                        any time without notice. In addition,
                                        this Agreement will terminate
                                        immediately and automatically without
                                        any notice if you violate any of the
                                        terms and conditions of this Agreement.
                                        Upon termination, all rights granted to
                                        you under this Agreement will also
                                        terminate, and you must cease all use of
                                        the Platform. Termination will not limit
                                        any of Vital Sleep’s rights or remedies
                                        at law or in equity.
                                    </li>
                                    <li>
                                        <u>Disclaimer of Warranties.</u> THE
                                        PLATFORM IS PROVIDED TO USER “AS IS” AND
                                        WITH ALL FAULTS AND DEFECTS WITHOUT
                                        WARRANTY OF ANY KIND. TO THE MAXIMUM
                                        EXTENT PERMITTED UNDER APPLICABLE LAW,
                                        VITAL SLEEP, ON ITS OWN BEHALF AND ON
                                        BEHALF OF ITS AFFILIATES AND RELATED
                                        COMPANIES, EXPRESSLY DISCLAIMS ALL
                                        WARRANTIES, WHETHER EXPRESS, IMPLIED,
                                        STATUTORY, OR OTHERWISE, WITH RESPECT TO
                                        THE PLATFORM, INCLUDING ALL IMPLIED
                                        WARRANTIES OF MERCHANTABILITY, FITNESS
                                        FOR A PARTICULAR PURPOSE, TITLE, AND
                                        NON-INFRINGEMENT, AND WARRANTIES THAT
                                        MAY ARISE OUT OF COURSE OF DEALING,
                                        COURSE OF PERFORMANCE, USAGE, OR TRADE
                                        PRACTICE. WITHOUT LIMITATION TO THE
                                        FOREGOING, VITAL SLEEP PROVIDES NO
                                        WARRANTY OR UNDERTAKING, AND MAKES NO
                                        REPRESENTATION OF ANY KIND THAT THE
                                        PLATFORM WILL MEET YOUR REQUIREMENTS,
                                        ACHIEVE ANY INTENDED RESULTS, BE
                                        COMPATIBLE, OR WORK WITH ANY OTHER
                                        SOFTWARE, APPLICATIONS, SYSTEMS, OR
                                        SERVICES, OPERATE WITHOUT INTERRUPTION,
                                        MEET ANY PERFORMANCE OR RELIABILITY
                                        STANDARDS, OR BE ERROR-FREE, OR THAT ANY
                                        ERRORS OR DEFECTS CAN OR WILL BE
                                        CORRECTED.
                                    </li>
                                    <li>
                                        <u>Limitation of Liability.</u> TO THE
                                        FULLEST EXTENT PERMITTED BY APPLICABLE
                                        LAW, IN NO EVENT WILL VITAL SLEEP OR ITS
                                        AFFILIATES AND RELATED COMPANIES HAVE
                                        ANY LIABILITY ARISING FROM OR RELATED TO
                                        YOUR USE OF OR INABILITY TO USE THE
                                        PLATFORM OR THE CONTENT AND SERVICES
                                        FOR:
                                        <ol type="a">
                                            <li>
                                                PERSONAL INJURY, PROPERTY
                                                DAMAGE, LOST PROFITS, COST OF
                                                SUBSTITUTE GOODS OR SERVICES,
                                                LOSS OF DATA, LOSS OF GOODWILL,
                                                BUSINESS INTERRUPTION, COMPUTER
                                                FAILURE OR MALFUNCTION, OR ANY
                                                OTHER CONSEQUENTIAL, INCIDENTAL,
                                                INDIRECT, EXEMPLARY, SPECIAL, OR
                                                PUNITIVE DAMAGES.
                                            </li>
                                            <li>
                                                DIRECT DAMAGES IN AMOUNTS THAT
                                                IN THE AGGREGATE EXCEED THE
                                                AMOUNT ACTUALLY PAID BY YOU FOR
                                                ACCESS TO THE PLATFORM.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                                <p>
                                    THE FOREGOING LIMITATIONS WILL APPLY WHETHER
                                    SUCH DAMAGES ARISE OUT OF BREACH OF
                                    CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                                    OTHERWISE AND REGARDLESS OF WHETHER SUCH
                                    DAMAGES WERE FORESEEABLE OR VITAL SLEEP WAS
                                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                                </p>
                                <ol>
                                    <li>
                                        <u>Indemnification.</u> You agree to
                                        indemnify, defend, and hold harmless
                                        Vital Sleep, affiliates, and related
                                        companies, as well their officers,
                                        directors, employees, agents,
                                        affiliates, successors, and assigns from
                                        and against any and all losses, damages,
                                        liabilities, deficiencies, claims,
                                        actions, judgments, settlements,
                                        interest, awards, penalties, fines,
                                        costs, or expenses of whatever kind,
                                        including reasonable attorneys’ fees,
                                        arising from or relating to your use or
                                        misuse of the Platform or your breach of
                                        this Agreement, including but not
                                        limited to the content you submit or
                                        make available through this Platform.
                                    </li>
                                    <li>
                                        <u>Export Regulation.</u> The Platform
                                        may be subject to US export control
                                        laws, including the Export Control
                                        Reform Act and its associated
                                        regulations. You shall not, directly or
                                        indirectly, export, re-export, or
                                        release the Platform to, or make the
                                        Platform accessible from, any
                                        jurisdiction or country to which export,
                                        re-export, or release is prohibited by
                                        law, rule, or regulation. You shall
                                        comply with all applicable federal laws,
                                        regulations, and rules, and complete all
                                        required undertakings (including
                                        obtaining any necessary export license
                                        or other governmental approval), prior
                                        to exporting, re-exporting, releasing,
                                        or otherwise making the Platform
                                        available outside the US.
                                    </li>
                                    <li>
                                        <u>Severability.</u> If any provision of
                                        this Agreement is illegal or
                                        unenforceable under applicable law, the
                                        remainder of the provision will be
                                        amended to achieve as closely as
                                        possible the effect of the original term
                                        and all other provisions of this
                                        Agreement will continue in full force
                                        and effect.
                                    </li>
                                    <li>
                                        <u>Governing Law.</u> This Agreement is
                                        governed by and construed in accordance
                                        with the internal laws of the State of
                                        Texas without giving effect to any
                                        choice or conflict of law provision or
                                        rule. Any legal suit, action, or
                                        proceeding arising out of or related to
                                        this Agreement or the Platform shall be
                                        instituted exclusively in the federal
                                        courts of the United States or the
                                        courts of the State of Texas in each
                                        case located in Travis County, Texas.
                                        You waive any and all objections to the
                                        exercise of jurisdiction over you by
                                        such courts and to venue in such courts.
                                    </li>
                                    <li>
                                        <u>
                                            Limitation of Time to File Claims.
                                        </u>{' '}
                                        ANY CAUSE OF ACTION OR CLAIM YOU MAY
                                        HAVE ARISING OUT OF OR RELATING TO THIS
                                        AGREEMENT OR THE PLATFORM MUST BE
                                        COMMENCED WITHIN ONE (1) YEAR AFTER THE
                                        CAUSE OF ACTION ACCRUES OTHERWISE SUCH
                                        CAUSE OF ACTION OR CLAIM IS PERMANENTLY
                                        BARRED.
                                    </li>
                                    <li>
                                        <u> Entire Agreement.</u> This Agreement
                                        and its related agreements (e.g., the
                                        BAA with your provider and Vital Sleep,
                                        and a master agreement between your
                                        provider and Vital Sleep) constitute the
                                        entire agreement between you and Vital
                                        Sleep with respect to the Platform and
                                        supersede all prior or contemporaneous
                                        understandings and agreements, whether
                                        written or oral, with respect to the
                                        Platform.
                                    </li>
                                    <li>
                                        <u>Waiver.</u> No failure to exercise,
                                        and no delay in exercising, on the part
                                        of either party, any right or any power
                                        hereunder shall operate as a waiver
                                        thereof, nor shall any single or partial
                                        exercise of any right or power hereunder
                                        preclude further exercise of that or any
                                        other right hereunder. In the event of a
                                        conflict between this Agreement and any
                                        applicable purchase or other terms, the
                                        terms of this Agreement shall govern.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <p align="center">
                        <Button autoFocus onClick={handleClose}>
                            Accept
                        </Button>
                    </p>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}
