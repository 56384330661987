import { useState } from "react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MUIDataTable from "mui-datatables";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Container,
  Alert,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
} from "../../../node_modules/@mui/material/index";
import { useAxios } from "../../hooks/auth";
import { notificationToastError, notificationToastSuccess } from "../../utils/notification";
import { ToastContainer } from "react-toastify";
import { YES_NO } from "../../constants/yesNo";
import ConfirmDialog from "../../components/misc/Dialog";
import { webformPostData, webFormsGetData } from "../../axios/api";
import moment from "moment";
import { Download } from "@mui/icons-material";  
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function BerlinQuestionaire({ asDialog, handleClose, patientData, studyId, reloadFormTable = () => {}, providerPortal}) {
  const isMobile = useMediaQuery('(max-width:800px)');
  const [viewCarrierState, setViewCarrierState] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [BMI, setBMIState] = useState({});
  const [BMIErrorState, setBMIErrorState] = useState({
    heightFeet: "",
    heightInches: "",
    weight: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false)
  const berlinQueRef = React.useRef(null);

  const onDownload = () => {
    const input = berlinQueRef.current;

    // Use html2canvas to convert HTML to canvas
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('berlinQuestionaire.pdf');
    });
  }

  React.useEffect(() => {

    if (studyId > 0) {
    
      webFormsGetData (axios, studyId, "pedQuestionnaireForm").then((res) => {

          if (res[0]) {
          setFormState({
            ...formState,
            ...res[0],
            patientId: patientData?.patientId,
            lastName: patientData?.lastName,
            firstName: patientData?.firstName,
            midInit: patientData?.middleInitial,
            date: patientData?.dob,
            gender: patientData?.gender,
            age: patientData?.age,
            studyId: studyId,
            heightFeet: res[0]['heightFt'] ,
            heightInches: res[0]['heightIn'] 
        
          })
        } else {

          setFormState({
            ...formState,
            patientId: patientData?.patientId,
            lastName: patientData?.lastName,
            firstName: patientData?.firstName,
            midInit: patientData?.middleInitial,
            date: patientData?.dob,
            gender: patientData?.gender,
            age: patientData?.age,
            studyId: studyId,
            heightFeet: patientData?.heightFeet,
            heightInches: patientData?.heightInches,
            weight: patientData?.patWeight,
            bmi: patientData?.patBMI
          })

            setBMIState({
                ...BMI,
                heightFeet: patientData?.heightFeet,
                heightInches: patientData?.heightInches,
                weight: patientData?.patWeight,
                bmi: patientData?.patBMI
              })    
      

        }


      }).catch((err) => {
          console.log("err==>", err);
      }).finally(() => {
          setIsLoading(false)
      })

 
  
    } else {

    setFormState({
      ...formState,
      patientId: patientData?.patientId,
      lastName: patientData?.lastName,
      firstName: patientData?.firstName,
      date: patientData?.dob,
      midInit: patientData?.middleInitial,
      studyId: studyId,
    })
  }

  }, [patientData])



  const validatePositiveNumber = (id, value) => {
    if (id==="heightInches") {
      if (value !== '' && !Number.isNaN(value) && Number(value) > -1) {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: false },
        }));
      } else {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: true },
        }));
      }
    }else{
      if (value !== '' && !Number.isNaN(value) && Number(value) > 0) {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: false },
        }));
      } else {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: true },
        }));
      }
    }
  };
  const updateBMIInput = (e) => {
    const {id, value} = e?.target ;
    if (id === 'heightFeet' || id === 'heightInches' || id === 'weight') {
      validatePositiveNumber(id, value);
    }
    const newBMIState = { ...BMI, [e.target.id]: e.target.value };
    const height =
      parseInt(newBMIState.heightFeet) * 12 +
      parseInt(newBMIState.heightInches);
    const bmi = Math.round((newBMIState.weight * 703) / Math.pow(height, 2));
    if (!isNaN(bmi)) {
      newBMIState.bmi = bmi;
    }
    if (bmi > 25) {
      updateSurveyCheck({ target: { id: "patientBMI", checked: true } });
    } else updateSurveyCheck({ target: { id: "patientBMI", checked: false } });
    setBMIState(newBMIState);
  };

  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }
  React.useEffect(() => {
    const row = location.state?.row;
    if (row) {
      setFormState({
        ...formState,
        patientNum: row[1],
        lastName: row[2],
        firstName: row[3],
        dob: row[4],
        middleInitial: row[23],
        gender: row[5],
      
     
      });
      if (row[4]) setDOB(dayjs(row[4]));
    }
  }, [location]);

  const addCarrier = () => {
    navigate("/add-carrier");
  };

  const closeViewCarrierDialog = () => {
    setViewCarrierState({});
    //setRefreshData(!refreshData);
  };

  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
  };

  const selectedPracticeId = useSelector(
    (state) => state.practiceSelector.value
  );
  const [formState, setFormState] = useState({});
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [surveyCheck, setSurveyCheck] = useState({});

  const [DOB, setDOB] = React.useState(null);
  const axios = useAxios();
  const [errorDOB, setErrorDOB] = React.useState(false);
  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
  };

  const cancel = (e) => {};
  
  const saveChanges = (e) => {
    e.preventDefault();
  
    let isValid = true;
    const payload = {
      ...formState,
      heightFeet: BMI.heightFeet,
      heightInches: BMI.heightInches,
      bmi: BMI.bmi,
      weight: BMI.weight,
      date: moment(formState.date).format('YYYY-MM-DD'),
        providerPortal: providerPortal ? 1 : 0
    };
   
    const hasNegativeNumber = BMI.heightFeet < 1 || BMI.weight < 1 || BMI.heightInches < 0 ;
    if (hasNegativeNumber) {
      isValid = false;
      notificationToastError('Height and weight should not be negative numbers');
      return false;
    } 
    setIsDisabled(true)
    webformPostData(axios, "submitBerlinQuest", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            
            notificationToastSuccess(res?.data?.message);
            reloadFormTable(true)
            setIsDisabled(false)
           /*
            setTimeout(() => {   
              window.location.reload();
            }, 2000);
            */
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        })
        .finally(() => {
          handleClose();
        });
  };

  function createData(Type, DateCreated, StopBang, Actions) {
    return {
      Type,
      DateCreated,
      StopBang,
      Actions,
    };
  }
  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";
  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }
  const updateDOB = (newValue) => {
    setDOB(newValue);
  };
  
  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: e.target.checked,
    };
    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState });

    setSurveyCheck(newSurveyState);
  };

  

  let isSaveButtonDisabled = false;
  if (formState?.completed) isSaveButtonDisabled = true; 

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleClose}
        confirmButton={{ action: saveChanges, title: "Save" }}
        maxWidth="none"
        isSaveButtonDisabled={isSaveButtonDisabled || isDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }
  function renderForm(asDialog) {
    return (
      <div >
        <Button
          sx={{ width: 130, float: "right", mt: 2, mr: 2 }}
          variant="contained"
          onClick={() => onDownload()}
        >
          Download <Download />
        </Button>
      <Container
        maxWidth="lg"
        sx={{
          borderStyle: "double"
        }}
        ref={berlinQueRef}
      >
        <div
          style={{
            marginTop: "1rem",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" sx={{ textDecoration: "underline" }}>Berlin Questionnaire (Pediatrics)</Typography>
          <Typography variant="h6" style={{ paddingTop: "1rem" }}>
            Questionnaire for pediatric patients. Used during the scheduling process to help obtain insurance authorization.
          </Typography>
        </div>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
            border:"double",
            borderColor:"rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            marginTop: "20px",
            marginBottom: asDialog ? "1rem": "0" ,
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <Box sx={{ display: isMobile ? 'grid' : "flex", gridTemplateColumns: isMobile ? '1fr 1fr' : "0fr 0fr", width: "100%" }}>
              <TextField
                id="lastName"
                label="Last Name *"
                value={formState?.lastName || ""}
                variant="standard"
                onChange={updateInput}
                error={formErrorState.lastName?.error && !formState.lastName}
                helperText={
                  formErrorState.lastName?.error && !formState.lastName
                    ? "required"
                    : ""
                }
                onBlur={validateEmptyField.bind(null, "lastName")}
              />
              <TextField
                id="firstName"
                label="First Name *"
                value={formState?.firstName  || ""}
                variant="standard"
                onChange={updateInput}
                error={formErrorState.firstName?.error && !formState.firstName}
                helperText={
                  formErrorState.firstName?.error && !formState.firstName
                    ? "required"
                    : ""
                }
                onBlur={validateEmptyField.bind(null, "firstName")}
              />
              <TextField
                id="midInit"
                label="MI"
                sx={{
                  maxWidth: "5rem",
                }}
                value={formState.midInit}
                variant="standard"
                onChange={updateInput}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="DOB *"
                  id="dob"
                  inputFormat="MM/DD/YYYY"
                  value={formState?.date  || ""}
                  onChange={updateDOB}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                    // error={errorDOB && !DOB}
                    // helperText={errorDOB && !DOB ? "required" : ""}
                    // onBlur={() => {
                    //   setErrorDOB(!DOB);
                    // }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

          </Box>

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <FormControl variant="standard" sx={{ width: 120, m: 1 }}>
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                id="gender"
                name="gender"
                value={formState?.gender || ""}
                label="Gender"
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="answeredBy"
              label="Answered by:"
              value={formState?.firstName  || ""}
              variant="standard"
              onChange={updateInput}

              onBlur={validateEmptyField.bind(null, "answeredBy")}
            />

          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              border: "1px solid rgba(0,0,0, 0.12)",
              borderRadius: "4px",
              margin: "10px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                paddingLeft: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              {" "}
              BMI Calculation
            </Typography>

            <TextField
              id="heightFeet"
              {...getErrorValidationProperty("heightFeet")}
              label="Height (Feet)"
              type="number"
              value={BMI?.heightFeet || formState?.heightFeet  || "" }
              variant="standard"
              onChange={updateBMIInput}
              required
              error={BMIErrorState?.heightFeet?.error}
              helperText={
                (BMIErrorState.heightFeet?.error && BMI.heightFeet) ? "height should be positive number" : ""
              }
            />
            <TextField
              id="heightInches"
              {...getErrorValidationProperty("heightFeet")}
              label="Height (Inches)"
              value={BMI?.heightInches  || formState?.heightInches  || ""}
              // helperText=""
              type="number"
              variant="standard"
              onChange={updateBMIInput}
              required
              error={BMIErrorState?.heightInches?.error}
              helperText={
                (BMIErrorState.heightInches?.error && BMI.heightInches) ? "height should be positive number" : ""
              }
            />
            <TextField
              id="weight"
              {...getErrorValidationProperty("heightFeet")}
              label="Weight (lbs)"
              value={BMI?.weight  || formState?.weight  || ""}
              // helperText=""
              type="number"
              variant="standard"
              onChange={updateBMIInput}
              required
              error={BMIErrorState?.weight?.error}
              helperText={
                (BMIErrorState.weight?.error && BMI.weight) ? "height should be positive number" : ""
              }
            />

            <TextField
              id="bmi"
              label={`Calculated BMI`}
              value={BMI.bmi === undefined ? formState?.bmi : BMI.bmi}
              helperText=""
              variant="standard"
              onChange={updateBMIInput}
            />
          </Box>
          <Box
            component="div"
            sx={{
              border: "1px solid rgba(0,0,0, 0.12)",
              margin: "10px",
              borderRadius: "4px",
            }}
          >
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="weightRecentlyChanged-label">Has your weight recently changed?</InputLabel>
              <Select
                labelId="weightRecentlyChanged-label"
                id="weightRecentlyChanged"
                name="weightRecentlyChanged"
                value={formState?.weightRecentlyChanged || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              sx={{ width: "100%" }}
              id="recentlyChangedExplain"
              label="If yes, please explain:"
              value={formState.recentlyChangedExplain}
              variant="standard"
              onChange={updateInput}

              onBlur={validateEmptyField.bind(null, "recentlyChangedExplain")}
            />
          </Box>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="snore-label">Do you snore?</InputLabel>
              <Select
                labelId="snore-label"
                id="snore"
                name="snore"
                value={formState?.snore || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="noticedBreathing-label">Has anyone noticed you quit breathing in your sleep?</InputLabel>
              <Select
                labelId="noticedBreathing-label"
                id="noticedBreathing"
                name="noticedBreathing"
                value={formState?.noticedBreathing || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "100%" }}
              id="noticedBreathingWho"
              label="If so, who?"
              value={formState.noticedBreathingWho}
              variant="standard"
              onChange={updateInput}

              onBlur={validateEmptyField.bind(null, "noticedBreathingWho")}
            />
          </Box>

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="tiredAfterSleeping-label">Are you tired after sleeping?</InputLabel>
              <Select
                labelId="tiredAfterSleeping-label"
                id="tiredAfterSleeping"
                name="tiredAfterSleeping"
                value={formState?.tiredAfterSleeping || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              sx={{ width: "100%" }}
              id="tiredOften"
              label="If yes, how often?"
              value={formState.tiredOften}
              variant="standard"
              onChange={updateInput}

              onBlur={validateEmptyField.bind(null, "tiredOften")}
            />
          </Box>

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="fallenAsleep-label">Have you ever nodded off or fallen asleep in class?</InputLabel>
              <Select
                labelId="fallenAsleep-label"
                id="fallenAsleep"
                name="fallenAsleep"
                value={formState?.fallenAsleep || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="highBloodPressure-label">Do you have high blood pressure?</InputLabel>
              <Select
                labelId="highBloodPressure-label"
                id="highBloodPressure"
                name="highBloodPressure"
                value={formState?.highBloodPressure || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="morningHeadaches-label">Do you have morning headaches?</InputLabel>
              <Select
                labelId="morningHeadaches-label"
                id="morningHeadaches"
                name="morningHeadaches"
                value={formState?.morningHeadaches || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="initiatingSleep-label">Do you have difficulty initiating sleep?</InputLabel>
              <Select
                labelId="initiatingSleep-label"
                id="initiatingSleep"
                name="initiatingSleep"
                value={formState?.initiatingSleep || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
          </Box>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="tonsils-label">Do you still have your tonsils ?</InputLabel>
              <Select
                labelId="tonsils-label"
                id="tonsils"
                name="tonsils"
                value={formState?.tonsils || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="adenoids-label">Do you still have your adenoids ?</InputLabel>
              <Select
                labelId="adenoids-label"
                id="adenoids"
                name="adenoids"
                value={formState?.adenoids || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="sleepWalk-label">Do you sleep walk or talk?</InputLabel>
              <Select
                labelId="sleepWalk-label"
                id="sleepWalk"
                name="sleepWalk"
                value={formState?.sleepWalk || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "100%" }}
              id="pleaseBeSpecific"
              label="If yes, please be specific:"
              value={formState.pleaseBeSpecific}
              variant="standard"
              onChange={updateInput}
              onBlur={validateEmptyField.bind(null, "pleaseBeSpecific")}
            />
          </Box>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { flexGrow: 1 },
              display: "flex",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="legsHurt-label">Do your legs hurt upon wakening?</InputLabel>
              <Select
                labelId="legsHurt-label"
                id="legsHurt"
                name="legsHurt"
                value={formState?.legsHurt || ''}
                onChange={updateInput}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {YES_NO?.map((item) => (
                  <MenuItem key={item?.id} value={item?.value} >{item?.value}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              sx={{ width: "100%" }}
              id="sleepRelatedComplaints"
              label="Are there any other sleep related complaints?"
              value={formState.sleepRelatedComplaints}
              variant="standard"
              onChange={updateInput}
              onBlur={validateEmptyField.bind(null, "sleepRelatedComplaints")}
            />
          </Box>
          </Box>      
        </Box>

        {
          !asDialog &&
          <Box sx={{ paddingTop: isMobile ? "1rem" : "2rem", paddingBottom: isMobile ? "4rem" : "2rem" }}>
            <Button variant="contained" onClick={saveChanges} disabled={isDisabled}>
              SUBMIT
            </Button>

            <Button
              sx={{ marginLeft: "1rem" }}
              variant="contained"
              onClick={cancel}
            >
              CANCEL
            </Button>
          </Box>
        }
        
        <ToastContainer />

      </Container>
      </div>
    );
  }
}

export default BerlinQuestionaire;
